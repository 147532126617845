import VueI18n from 'vue-i18n';
import Vue from 'vue';
import { en, ja } from '@client/plugins/i18n/locales';
import { Optional } from '@common/types';

Vue.use(VueI18n);

// Define Locales here
export class Locale {
  static readonly EN: Locale = new Locale('en', 'English');
  static readonly JA: Locale = new Locale('ja', '日本語');
  static readonly definedLocales: Array<Locale> = [Locale.EN, Locale.JA];

  // private to disallow creating other instances of this type
  private constructor(private readonly key: string, public readonly value: string) {}

  get id(): string {
    return this.key;
  }

  get text(): string {
    return this.value;
  }
}

/**
 * maps enables locales from environment variable VUE_APP_I18N_LOCALES
 * to defined locales
 */
function getLocales() {
  const enabledLocales: string = process.env.VUE_APP_I18N_LOCALES || Locale.EN.id;
  const locales: Array<Locale> = [];
  return enabledLocales.split(',').reduce((arr: Array<Locale>, localeId: string) => {
    const found: Optional<Locale> = Locale.definedLocales.find((locale: Locale) => {
      return locale.id.toLowerCase() === localeId.toLowerCase();
    });
    if (found) {
      arr.push(found);
    }
    return arr;
  }, locales);
}

export const availableLocales: Array<Locale> = getLocales();

export const defaultLocale: string = process.env.VUE_APP_I18N_LOCALE || Locale.EN.id;

export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.EN.id,
  messages: {
    en,
    ja,
  },
});
