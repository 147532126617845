/**
 * Converts a duration in seconds to HH:mm:ss
 * @param duration duration in seconds
 */
const videoDurationParser = (duration: number | undefined): string => {
  if (!duration) {
    return '-';
  }
  // If the duration never exceeds 3600s we can change this to .substr(14, 5) and return HH:ss instead.
  return new Date(duration * 1000).toISOString().slice(11, 19);
};

export default videoDurationParser;
