
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItemSelectionTarget } from '@client/enums';
import { ContentItem } from '@client/models/ContentModels/types';
import PlaylistItem from '@client/components/ContentItems/ListItems/PlaylistItem.vue';
import VideoItem from '@client/components/ContentItems/ListItems/VideoItem.vue';
import ImageItem from '@client/components/ContentItems/ListItems/ImageItem.vue';
import FolderItem from '@client/components/ContentItems/ListItems/FolderItem.vue';

/**
 * Renders a single row in the content items table.
 */
@Component({
  components: { FolderItem, ImageItem, VideoItem, PlaylistItem },
})
export default class ContentItemsListItem extends Vue {
  /* DECLARATIONS */
  @Prop()
  private contentItem!: ContentItem;
  @Prop()
  private index!: number;
  @Prop()
  private expand!: (value: boolean) => void;
  @Prop()
  private isExpanded!: boolean;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private showParentFolder!: boolean;
  @Prop()
  private isModalEmbedded!: boolean;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget!: ContentItemSelectionTarget;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop()
  private isSelected!: boolean;
  @Prop()
  private setSelectedRow!: (id: string) => void;
  @Prop()
  private openPlaylistDialog!: (contentItem: ContentItem) => void;
  @Prop()
  private deleteContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  private updateContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  private setSelectedFolder!: (folderId: string) => void;
  @Prop()
  private openMoveDialog!: (contentItemToMove: ContentItem) => void;
  @Prop()
  private openEditTagsDialog!: (contentItemToEdit: ContentItem) => void;
  @Prop()
  private onToggleSelection!: (contentItem: ContentItem) => void;
  @Prop()
  private isChecked!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  /* GETTERS */
}
