import { BaseEventLog } from '../EventLog.model';
import Device from '../../DeviceModels/Device.model';
import { DeviceStatusEventLogJSON, DeviceStatusEventType, EventType } from '@common/eventlog/types';
import { Optional } from '@common/types';

export class DeviceStatusEventLog extends BaseEventLog {
  device: Optional<Device>;
  deviceStatus: DeviceStatusEventType;
  eventExecutionTimestamp: string;
  longId: string;
  shortId: string;

  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    device: Optional<Device>,
    deviceStatus: DeviceStatusEventType,
    eventExecutionTimestamp: string,
    longId: string,
    shortId: string
  ) {
    super(id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.device = device;
    this.deviceStatus = deviceStatus;
    this.eventExecutionTimestamp = eventExecutionTimestamp;
    this.longId = longId;
    this.shortId = shortId;
  }

  public static fromJSON(eventLogJSON: DeviceStatusEventLogJSON): DeviceStatusEventLog {
    const {
      _id,
      azureStoreId,
      storeId,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      device,
      deviceStatus,
      eventExecutionTimestamp,
      longId,
      shortId,
    }: DeviceStatusEventLogJSON = eventLogJSON;
    return new DeviceStatusEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      device ? Device.fromJSON(device) : undefined,
      deviceStatus,
      eventExecutionTimestamp,
      longId,
      shortId
    );
  }
}
