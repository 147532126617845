
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from '@client/router';

@Component({})
export default class RepublishButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private templateId!: string;
  /**
   * Only needed for pages where we have multiple templates
   */
  @Prop({ default: '' })
  private templateName!: string;
  @Prop({ default: 'primary' })
  private buttonColor!: string;
  @Prop({ default: true })
  private isIcon!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /**
   * Redirect to republish page for this template
   * @param gondolaTemplateId
   */
  async rePublishGondolaTemplate(gondolaTemplateId?: string): Promise<void> {
    if (!gondolaTemplateId) {
      return;
    }
    await router.push(`/republish/${gondolaTemplateId}`);
  }

  /**
   * Redirect to publish template to sections page for this template
   * @param gondolaTemplateId
   */
  async publishToSections(gondolaTemplateId?: string): Promise<void> {
    if (!gondolaTemplateId) {
      return;
    }
    await router.push(`/publish/${gondolaTemplateId}`);
  }
  /* GETTERS */
}
