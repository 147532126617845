
import { Component, Prop, Vue } from 'vue-property-decorator';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader },
})
export default class DeleteAisleButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private aisle!: string;
  @Prop()
  private storeId!: string;

  private isDeleteAisleDialogOpen: boolean = false;

  private storesStore: StoresStore = useStoresStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async deleteAisle(): Promise<void> {
    await this.storesStore.deleteAisle(this.storeId, this.aisle);
  }

  closeDialog(): void {
    this.isDeleteAisleDialogOpen = false;
  }
  /* GETTERS */
}
