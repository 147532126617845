
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  GondolaTemplatesStore,
  DeviceContentSelection,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import { ForegroundContent, DeviceTemplate } from '@client/models';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import LabelItem from '@client/components/Device/CurrentSelectedContentDetails/LabelItem.vue';
import { Optional } from '@common/types';
import ImageModel from '@client/models/ContentModels/Image.model';

/**
 * Contains the list of object available in the canvas/template
 * Contains a button to select the background, and each of the labels
 * Also contains a button to add a label (only if the base layer is selected and number of labels < 12)
 */
@Component({ components: { LabelItem } })
export default class DeviceLayerList extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  async onAddForeground(): Promise<void> {
    await this.gondolaTemplatesStore.addDeviceForegroundSlot(
      this.$route.params.id,
      Number(this.$route.params.row),
      Number(this.$route.params.col),
      this.deviceTemplate,
      ForegroundContent.fromContentItem(ImageModel.getDefaultPlaceHolderContentItem())
    );
  }

  updateDeviceContentSelection(isForeground: boolean, index: number): void {
    this.gondolaTemplatesStore.updateDeviceContentSelection({ isForeground, index });
  }

  getLabelClass(index: number): Optional<string> {
    if (index - 1 !== this.deviceContentSelection.index) {
      return '';
    }
    return 'active';
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }

  get deviceContentSelection(): DeviceContentSelection {
    return this.gondolaTemplatesStore.deviceContentSelection;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get isLabelAddButtonEnabled(): boolean {
    return this.deviceTemplate.foregroundContent.length < 12 && !this.selectedSchedule;
  }

  get backgroundClass(): Optional<string> {
    if (this.deviceContentSelection.isForeground) {
      return '';
    }
    return 'active';
  }
}
