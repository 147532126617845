import { defineStore, Store, StoreDefinition } from 'pinia';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import { ContentItemJSON, ContentReferencesJSON, FolderJSON } from '@common/content-item/types';
import { EntityUpdateReference, Optional } from '@common/types';
import Vue from 'vue';
import { calculateQueueItemPriorityScore } from '@client/components/ContentItems/utils';
import { ContentItem, ContentReferences } from '@client/models/ContentModels/types';
import { contentItemFromJSON } from '@client/models/ContentModels/utils';
import { ContentType } from '@common/enums';
import { logAxiosError } from '@client/utils/logger';
import { normalizeString } from '@common/utils/validation';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import Tag from '@client/models/SettingsModels/Tag.model';

export interface ContentItemsGetters {
  getContentItems: (state: ContentItemsState) => () => Array<ContentItem>;
  getSortedUploadQueue: (state: ContentItemsState) => () => Array<QueueItem>;
  getCurrentlyUploadingItem: (state: ContentItemsState) => () => Optional<QueueItem>;
  getNextItemInQueue: (state: ContentItemsState) => () => Optional<QueueItem>;
  getQueueProgressPercentage: (state: ContentItemsState) => () => number;
  getAreItemsStillUploading: (state: ContentItemsState) => () => boolean;
  getIsMetadataLoading: (state: ContentItemsState) => (contentItemId: string) => boolean;
  getOriginalNameByAzureBlobName: (state: ContentItemsState) => (azureBlobName: string) => string;
  getContentItemById: (state: ContentItemsState) => (contentItemId: string) => Optional<ContentItem>;
}

export interface ContentItemsActions {
  getFolderContentItem(folderId?: string): Promise<Array<ContentItem>>;
  search(searchTerm: string, typeFilter: Array<ContentType>, tagIds: Array<string>): Promise<Array<ContentItem>>;
  fetchContentItems(): Promise<void>;
  createContentItem(contentItem: ContentItem): Promise<AxiosResponse<ContentItemJSON>>;
  addContentItemToStore(contentItem: ContentItem): Promise<void>;
  updateContentItemInStore(contentItem: ContentItem): Promise<void>;
  updateContentItemMapName(contentItem: ContentItem): void;
  removeContentItemByIdInStore(contentItemId: string): Promise<void>;
  updateContentItem(contentItem: ContentItem): Promise<void>;
  updateContentItemTags(contentItem: ContentItem, selectedTags: Array<TagReference>): Promise<void>;
  bulkMoveContentItems(contentItems: Array<ContentItem>, targetFolder: string): Promise<void>;
  updateContentItemDimensions(contentItem: ContentItem): Promise<void>;
  recomputeContentItemPreview(contentItem: ContentItem): Promise<void>;
  deleteContentItem(contentItem: ContentItem): Promise<void>;
  bulkDeleteContentItems(contentItems: Array<ContentItem>): Promise<void>;
  fetchPlaylistsContainingContentItem(contentItemId: string): Promise<Optional<Array<ContentItem>>>;
  fetchReferencesContainingContentItem(contentItemId: string): Promise<Optional<ContentReferences>>;
  bulkFetchReferencesContainingContentItem(contentItems: Array<ContentItem>): Promise<Optional<ContentReferences>>;
  createFolderHierarchy(): Promise<number>;
  clearContentItems(): void;
  setPendingFiles(files: Array<QueueFile>, isFolderUpload: boolean): void;

  /**
   * Queues the pending files to the upload stream
   */
  queuePendingFiles(): void;
  clearPendingFiles(): void;
  addFileToQueue(file: QueueFile): void;
  clearUploadQueue(): void;
  removeItemFromQueue(index: number): void;
  setMetadataLoadingStatus(contentItemId: string): void;
  removeMetadataLoadingStatus(contentItemId: string): void;
}

export interface ContentItemsState {
  contentItems: Array<ContentItem>;
  /**
   * This map matches the azure blob name to the original file name
   */
  contentItemsOriginalNamesMap: { [azureBlobName: string]: string };
  isFetching: boolean;
  fetched: boolean;
  /**
   * Used for waiting for CRUD responses
   */
  isActionPending: boolean;
  /**
   * Array of string containing the list of content item ids that have their metadata still loading
   */
  metadataLoading: Array<string>;
  upload: {
    /**
     * Selected files by the user input, these files are still pending until the user confirm the selection
     */
    pendingFiles: Array<QueueFile>;
    /**
     * Files in the upload queue, contains pending files or already uploaded files
     */
    uploadQueue: Array<QueueItem>;
    /**
     * Flag when folder upload is selected
     */
    isFolderUpload: boolean;
  };
}

export enum UploadState {
  queued = 'queued',
  uploading = 'uploading',
  uploaded = 'uploaded',
  error = 'error',
  cancelled = 'cancelled',
}

export interface QueueItem {
  fileData: QueueFile;
  loadedBytes: number;
  uploadState: UploadState;
}

export interface QueueFile {
  file: File;
  targetFolder: string;
}

export type ContentItemsStoreDefinition = StoreDefinition<
  'contentItems',
  ContentItemsState,
  ContentItemsGetters,
  ContentItemsActions
>;

export type ContentItemsStore = Store<'contentItems', ContentItemsState, ContentItemsGetters, ContentItemsActions>;

export const useContentItemsStore: ContentItemsStoreDefinition = defineStore('contentItems', {
  state: (): ContentItemsState => ({
    contentItems: new Array<ContentItem>(),
    contentItemsOriginalNamesMap: {},
    isFetching: false,
    fetched: false,
    isActionPending: false,
    metadataLoading: new Array<string>(),
    upload: {
      pendingFiles: [],
      uploadQueue: [],
      isFolderUpload: false,
    },
  }),
  getters: {
    getContentItems: (state: ContentItemsState) => () => {
      return state.contentItems;
    },
    getOriginalNameByAzureBlobName: (state: ContentItemsState) => (azureBlobName: string) => {
      return state.contentItemsOriginalNamesMap[azureBlobName] || '';
    },
    getSortedUploadQueue: (state: ContentItemsState) => () => {
      return state.upload.uploadQueue.sort((a: QueueItem, b: QueueItem) => {
        return calculateQueueItemPriorityScore(b) - calculateQueueItemPriorityScore(a);
      });
    },
    getCurrentlyUploadingItem: (state: ContentItemsState) => () => {
      return state.upload.uploadQueue.find((item: QueueItem) => item.uploadState === UploadState.uploading);
    },
    getNextItemInQueue: (state: ContentItemsState) => () => {
      return state.upload.uploadQueue.find((item: QueueItem) => item.uploadState === UploadState.queued);
    },
    getAreItemsStillUploading: (state: ContentItemsState) => () => {
      return state.upload.uploadQueue.some(
        (item: QueueItem) => item.uploadState === UploadState.queued || item.uploadState === UploadState.uploading
      );
    },
    getQueueProgressPercentage: (state: ContentItemsState) => () => {
      const numberOfUploadedFiles: number = state.upload.uploadQueue.filter(
        (item: QueueItem) => item.uploadState === UploadState.uploaded
      ).length;
      const totalNumberOfValidFilesInQueue: number = state.upload.uploadQueue.filter(
        (item: QueueItem) => item.uploadState !== UploadState.error && item.uploadState !== UploadState.cancelled
      ).length;
      // number / 0 yields infinity in javascript, so we handle that case
      if (numberOfUploadedFiles === 0 || totalNumberOfValidFilesInQueue === 0) {
        return 0;
      }
      return Math.floor((numberOfUploadedFiles / totalNumberOfValidFilesInQueue) * 100);
    },
    getIsMetadataLoading: (state: ContentItemsState) => (contentItemId: string) => {
      return !!state.metadataLoading.find((id: string) => id === contentItemId);
    },
    getContentItemById: (state: ContentItemsState) => (contentItemId: string) => {
      return state.contentItems.find((item: ContentItem) => item._id === contentItemId);
    },
  },
  actions: {
    async fetchContentItems(): Promise<void> {
      if (!this.isFetching && !this.fetched) {
        this.isFetching = true;
        const response: AxiosResponse<{ contentItems: Array<ContentItemJSON> }> = await axios.get(
          `${Config.getApiUrl()}/content-items`
        );

        if (response.status === 200) {
          const fetchedContentItems: Array<ContentItem> = response.data.contentItems.map(
            (contentItemData: ContentItemJSON) => {
              return contentItemFromJSON(contentItemData);
            }
          );
          Vue.set(this, 'contentItems', fetchedContentItems);
          this.contentItemsOriginalNamesMap = {};
          // Populate the original names map
          this.contentItems.forEach((contentItem: ContentItem) => {
            this.updateContentItemMapName(contentItem);
          });
        } else {
          console.error(response);
        }
        this.isFetching = false;
      }
    },
    async getFolderContentItem(folderId: string): Promise<Array<ContentItem>> {
      const response: AxiosResponse<Array<ContentItemJSON>> = await axios.get(
        `${Config.getApiUrl()}/content-items/children`,
        {
          params: {
            parentFolder: folderId ? folderId : undefined,
          },
        }
      );

      if (response.status === 200) {
        return response.data.map(contentItemFromJSON);
      } else {
        console.error(response);
        return [];
      }
    },
    async search(
      searchTerm: string,
      typeFilter: Array<ContentType>,
      tagIds: Array<string>
    ): Promise<Array<ContentItem>> {
      const response: AxiosResponse<Array<ContentItemJSON>> = await axios.get(
        `${Config.getApiUrl()}/content-items/search`,
        {
          params: {
            searchTerm: searchTerm ? searchTerm : undefined,
            type: typeFilter.length > 0 ? typeFilter : undefined,
            searchTags: tagIds,
          },
        }
      );

      if (response.status === 200) {
        return response.data.map(contentItemFromJSON);
      } else {
        console.error(response);
        return [];
      }
    },
    async createContentItem(contentItem: ContentItem): Promise<AxiosResponse<ContentItemJSON>> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<ContentItemJSON> = await axios.post(
          `${Config.getApiUrl()}/content-items`,
          contentItem.toNewJSON()
        );
        if (response.status !== 200) {
          console.error(response);
        }
        this.isActionPending = false;
        return response;
      } catch (e: unknown) {
        logAxiosError(e);
        this.isActionPending = false;
        throw e;
      }
    },
    async removeContentItemByIdInStore(contentItemId: string): Promise<void> {
      const index: number = this.contentItems.findIndex(
        (contentItemIterator: ContentItem) => contentItemIterator._id === contentItemId
      );
      if (index < 0) {
        console.warn(`Tried to delete a non-existing content item with id ${contentItemId}`);
        return;
      }
      delete this.contentItemsOriginalNamesMap[this.contentItems[index].name];
      this.contentItems.splice(index, 1);
    },
    async addContentItemToStore(contentItem: ContentItem): Promise<void> {
      const indexOfContentItemInStore: number = this.contentItems.findIndex(
        (item: ContentItem) => item._id === contentItem._id
      );
      if (indexOfContentItemInStore >= 0) {
        console.warn(`Tried to add already existing content item with id ${contentItem._id}`);
      } else {
        this.contentItems.push(contentItem);
        this.updateContentItemMapName(contentItem);
      }
    },
    updateContentItemMapName(contentItem: ContentItem): void {
      if (contentItem.isMediaFile()) {
        this.contentItemsOriginalNamesMap[contentItem.name] = contentItem.originalName;
      } else if (contentItem.isPlaylist()) {
        this.contentItemsOriginalNamesMap[contentItem.name] = contentItem.name;
      } else {
        // In case it's a folder map it to the id
        this.contentItemsOriginalNamesMap[contentItem._id] = contentItem.name;
      }
    },
    async updateContentItemInStore(contentItem: ContentItem): Promise<void> {
      const indexOfContentItemInStore: number = this.contentItems.findIndex(
        (item: ContentItem) => item._id === contentItem._id
      );
      if (indexOfContentItemInStore >= 0) {
        this.updateContentItemMapName(contentItem);
        Vue.set(this.contentItems, indexOfContentItemInStore, contentItem);
      } else {
        console.warn(`Could not find content item with id ${contentItem._id} in store`);
      }
    },
    async updateContentItem(contentItem: ContentItem): Promise<void> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<void> = await axios.post(
          `${Config.getApiUrl()}/content-items/${contentItem._id}`,
          contentItem.toJSON(),
          {
            params: {
              hash: contentItem.hash,
            },
          }
        );
        this.isActionPending = false;
        if (response.status !== 200) {
          console.error(response);
        }
      } catch (e: unknown) {
        logAxiosError(e);
        this.isActionPending = false;
        throw e;
      }
    },
    async updateContentItemTags(contentItem: ContentItem, selectedTags: Array<TagReference>): Promise<void> {
      try {
        const tagsStore: TagsStore = useTagsStore();
        const tagUpdates: EntityUpdateReference[] = [];
        selectedTags.forEach((tagReference: TagReference) => {
          const tag: Optional<Tag> = tagsStore.getTagById(tagReference.id);
          if (!tag) {
            return;
          }
          tagUpdates.push({ id: tag._id, hash: tag.hash });
        });
        this.isActionPending = true;
        const response: AxiosResponse<void> = await axios.post(
          `${Config.getApiUrl()}/content-items/${contentItem._id}/tags`,
          tagUpdates,
          {
            params: {
              hash: contentItem.hash,
            },
          }
        );
        this.isActionPending = false;
        if (response.status !== 200) {
          console.error(response);
        }
      } catch (e: unknown) {
        logAxiosError(e);
        this.isActionPending = false;
        throw e;
      }
    },

    async bulkMoveContentItems(contentItems: Array<ContentItem>, targetFolder: string): Promise<void> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<void> = await axios.put(
          `${Config.getApiUrl()}/content-items/move`,
          contentItems.map((item: ContentItem) => item.toJSON()),
          {
            params: {
              targetFolder: targetFolder,
            },
          }
        );
        this.isActionPending = false;
        if (response.status !== 200) {
          console.error(response);
        }
      } catch (e: unknown) {
        const error: AxiosError = e as AxiosError;
        console.error(error);
        this.isActionPending = false;
      }
    },
    async updateContentItemDimensions(contentItem: ContentItem): Promise<void> {
      this.isActionPending = true;
      this.setMetadataLoadingStatus(contentItem._id);
      const response: AxiosResponse<void> = await axios.post(
        `${Config.getApiUrl()}/content-items/${contentItem._id}/metadata`,
        contentItem,
        {
          params: {
            hash: contentItem.hash,
          },
        }
      );
      this.isActionPending = false;
      if (response.status !== 200) {
        console.error(response);
      }
      this.removeMetadataLoadingStatus(contentItem._id);
    },
    async recomputeContentItemPreview(contentItem: ContentItem): Promise<void> {
      this.isActionPending = true;
      const response: AxiosResponse<void> = await axios.post(
        `${Config.getApiUrl()}/content-items/${contentItem._id}/preview`,
        contentItem,
        {
          params: {
            hash: contentItem.hash,
          },
        }
      );
      this.isActionPending = false;
      if (response.status !== 200) {
        console.error(response);
      }
    },
    async deleteContentItem(contentItem: ContentItem): Promise<void> {
      this.isActionPending = true;
      const response: AxiosResponse<void> = await axios.delete(
        `${Config.getApiUrl()}/content-items/${contentItem._id}`,
        {
          params: {
            hash: contentItem.hash,
          },
        }
      );
      this.isActionPending = false;
      if (response.status !== 200) {
        console.error(response);
      }
    },
    async bulkDeleteContentItems(contentItems: Array<ContentItem>): Promise<void> {
      this.isActionPending = true;
      const response: AxiosResponse<void> = await axios.post(
        `${Config.getApiUrl()}/content-items/delete/bulk`,
        contentItems.map((contentItem: ContentItem) => contentItem.toJSON())
      );
      this.isActionPending = false;
      if (response.status !== 200) {
        console.error(response);
      }
    },
    async fetchPlaylistsContainingContentItem(contentItemId: string): Promise<Optional<Array<ContentItem>>> {
      const response: AxiosResponse<Array<ContentItem>> = await axios.get(
        `${Config.getApiUrl()}/content-items/${contentItemId}/playlists`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.error(response);
      }
    },
    async fetchReferencesContainingContentItem(contentItemId: string): Promise<Optional<ContentReferences>> {
      const response: AxiosResponse<ContentReferencesJSON> = await axios.get(
        `${Config.getApiUrl()}/content-items/${contentItemId}/references`
      );
      if (response.status !== 200) {
        console.error(response);
        return;
      }
      return ContentReferences.fromJSON(response.data);
    },
    async bulkFetchReferencesContainingContentItem(
      contentItems: Array<ContentItem>
    ): Promise<Optional<ContentReferences>> {
      const response: AxiosResponse<ContentReferencesJSON> = await axios.post(
        `${Config.getApiUrl()}/content-items/references/bulk`,
        contentItems.map((item: ContentItem) => item.toJSON())
      );
      if (response.status !== 200) {
        console.error(response);
        return;
      }
      return ContentReferences.fromJSON(response.data);
    },
    async createFolderHierarchy(): Promise<number> {
      if (!this.upload.pendingFiles.length) {
        return 0;
      }
      this.isActionPending = true;
      // selected target folder is target folder of any file, using first one
      const selectedTargetFolder: string = this.upload.pendingFiles[0].targetFolder;
      const webKitPaths: Array<string> = this.upload.pendingFiles.map(
        (queueFile: QueueFile) => queueFile.file.webkitRelativePath
      );
      const response: AxiosResponse<Array<FolderJSON>> = await axios.post(
        `${Config.getApiUrl()}/content-items/hierarchy/create`,
        webKitPaths,
        {
          params: {
            parentFolder: selectedTargetFolder ? selectedTargetFolder : undefined,
          },
        }
      );
      if (response.status !== 200) {
        console.error(response);
        return 0;
      }
      const createdFolders: Array<FolderJSON> = response.data;
      // update target folder based on file path and newly created folders
      this.upload.pendingFiles.forEach((file: QueueFile) => {
        const relativeFilePath: string = file.file.webkitRelativePath;
        const subPaths: string[] = relativeFilePath.split('/');
        subPaths.pop(); // remove file name
        const destinationFolderName: Optional<string> = subPaths.pop();
        if (!destinationFolderName) {
          console.debug('New destination folder not found, saving file to selected folder');
          return;
        }
        const normalizedName: string = normalizeString(destinationFolderName);
        const destinationFolder: Optional<FolderJSON> = createdFolders.find(
          (folder: FolderJSON) => folder.name === normalizedName
        );
        if (!destinationFolder) {
          console.debug('New destination folder not found, saving file to selected folder');
        }
        file.targetFolder = destinationFolder?._id ?? file.targetFolder;
      });
      this.isActionPending = false;
      return createdFolders.length;
    },
    clearContentItems(): void {
      Vue.set(this, 'contentItems', new Array<ContentItem>());
      this.contentItemsOriginalNamesMap = {};
      this.fetched = false;
    },
    setPendingFiles(files: Array<QueueFile>, isFolderUpload: boolean): void {
      this.upload.pendingFiles = files;
      this.upload.isFolderUpload = isFolderUpload;
    },
    clearPendingFiles(): void {
      this.upload.pendingFiles = [];
    },
    queuePendingFiles(): void {
      const queue: Array<QueueItem> = this.upload.pendingFiles.map((file: QueueFile) => {
        return {
          fileData: file,
          loadedBytes: 0,
          uploadState: UploadState.queued,
          targetFolder: file.targetFolder,
        };
      });
      this.upload.uploadQueue = this.upload.uploadQueue.concat(queue);
      this.clearPendingFiles();
    },
    addFileToQueue(file: QueueFile): void {
      this.upload.uploadQueue = this.upload.uploadQueue.concat({
        fileData: file,
        loadedBytes: 0,
        uploadState: UploadState.queued,
      });
    },
    clearUploadQueue(): void {
      this.upload.uploadQueue = this.upload.uploadQueue.filter(
        (queueItem: QueueItem) => queueItem.uploadState !== UploadState.queued
      );
    },
    removeItemFromQueue(index: number): void {
      this.upload.uploadQueue.splice(index, 1);
    },
    setMetadataLoadingStatus(contentItemId: string): void {
      this.metadataLoading.push(contentItemId);
    },
    removeMetadataLoadingStatus(contentItemId: string): void {
      this.metadataLoading = this.metadataLoading.filter((id: string) => id !== contentItemId);
    },
  },
});
