import { render, staticRenderFns } from "./QRCodeCopyButton.vue?vue&type=template&id=36ee7bc9&scoped=true"
import script from "./QRCodeCopyButton.vue?vue&type=script&lang=ts"
export * from "./QRCodeCopyButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36ee7bc9",
  null
  
)

export default component.exports