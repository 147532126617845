
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import {
  BackgroundContent,
  BaseLayer,
  ForegroundContent,
  GondolaTemplate as GondolaTemplateModel,
  LayerPlaylistItem,
  DeviceTemplate,
  ScheduledContent,
  ScheduleLayer,
} from '@client/models';
import ContentPreview from './ContentPreview.vue';
import ModalDialog from '../../ModalDialog/ModalDialog.vue';
import {
  GondolaTemplatesStore,
  DeviceContentSelection,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { ContentType, LayerVisibility } from '@common/enums';
import { Optional } from '@common/types';
import { ContentItemSelectionTarget } from '@client/enums';
import { EMPTY_LABEL_PLACEHOLDER, HIDDEN_SCHEDULE_URL_VALUE } from '@client/models/ContentModels/types';
import ContentItemsList from '@client/components/ContentItems/ContentItemsList.vue';

/**
 * Shows the currently selected content (background or foreground) for a specific device of a template.
 * Contains the action buttons to edit or delete the device template content.
 * The set of available action buttons depends on whether a foreground or background content,
 * or whether a schedule is selected.
 */
@Component({
  components: {
    ContentItemsList,
    ModalDialog,
    ContentPreview,
  },
})
export default class DeviceLayerSelectionContentDetails extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  private currentSelectedContentURL: string = '';
  private playlistItemToPreview?: LayerPlaylistItem = LayerPlaylistItem.init();
  private showPlaylistItemPreview: boolean = false;
  private showContentItemDialog: boolean = false;
  private showDeleteAllScheduledBackgroundDialog: boolean = false;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();

  async created(): Promise<void> {
    await this.contentItemsStore.fetchContentItems();
    await this.setCurrentSelectedContentURL();
  }

  async openPreviewVideo(layerPlaylistItem: LayerPlaylistItem): Promise<void> {
    this.playlistItemToPreview = layerPlaylistItem;
    this.showPlaylistItemPreview = true;
  }

  closePreviewVideo(): void {
    this.playlistItemToPreview = undefined;
    this.showPlaylistItemPreview = false;
  }

  closeContentItemDialog(): void {
    this.showContentItemDialog = false;
  }

  closeDeleteAllScheduledBackgroundModal(): void {
    this.showDeleteAllScheduledBackgroundDialog = false;
  }

  @Watch('deviceContentSelection', { deep: true })
  @Watch('playlistItemToPreview', { deep: true })
  @Watch('selectedBaseLayer', { deep: true })
  @Watch('selectedLayer', { deep: true })
  async setCurrentSelectedContentURL(): Promise<void> {
    if (this.showPlaylistItemPreview && this.playlistItemToPreview) {
      this.currentSelectedContentURL = await this.playlistItemToPreview.getBlobUrl();
    } else if (this.selectedLayer) {
      if (this.selectedLayer.url === HIDDEN_SCHEDULE_URL_VALUE && this.selectedBaseLayer) {
        this.currentSelectedContentURL = await this.selectedBaseLayer.getBlobUrl();
      } else {
        this.currentSelectedContentURL = await this.selectedLayer.getBlobUrl();
      }
    }
  }

  toggleLayerVisibilityStatus(): void {
    const newContent: ForegroundContent = this.selectedForegroundContent.clone();
    if (newContent.scheduledContent && this.selectedSchedule) {
      const indexOfScheduledContent: number = newContent.scheduledContent.findIndex(
        (content: ScheduledContent) => content.scheduleId === this.selectedSchedule
      );
      if (indexOfScheduledContent >= 0) {
        // If scheduled content is found then toggle its visibility status
        if (newContent.scheduledContent[indexOfScheduledContent].layer.url === HIDDEN_SCHEDULE_URL_VALUE) {
          // We end up here, if the scheduled content was only used to hide the baselayer, which implies visibility=hidden (and none of the other fields matter).
          // Making it visible again removes the need to have this ScheduledContent at all, thus we remove it here.
          newContent.scheduledContent = newContent.scheduledContent.filter(
            (content: ScheduledContent) => content.scheduleId !== this.selectedSchedule
          );
        } else {
          newContent.scheduledContent[indexOfScheduledContent].layer.visibility =
            newContent.scheduledContent[indexOfScheduledContent].layer.visibility === LayerVisibility.VISIBLE
              ? LayerVisibility.HIDDEN
              : LayerVisibility.VISIBLE;
        }
      } else {
        // No content is found, so we create new content from the base layer!
        const scheduledContent: ScheduledContent = new ScheduledContent(
          this.selectedSchedule,
          new ScheduleLayer(
            newContent.baseLayer.name,
            HIDDEN_SCHEDULE_URL_VALUE,
            newContent.baseLayer.previewUrl,
            newContent.baseLayer.type,
            newContent.baseLayer.height,
            newContent.baseLayer.width,
            newContent.baseLayer.duration,
            newContent.baseLayer.fps,
            newContent.baseLayer.offsetX,
            newContent.baseLayer.offsetY,
            newContent.baseLayer.zIndex,
            newContent.baseLayer.checksum,
            newContent.baseLayer.layerId,
            LayerVisibility.HIDDEN,
            newContent.baseLayer.playlist
          )
        );
        newContent.scheduledContent.push(scheduledContent);
      }
    }
    this.gondolaTemplatesStore.updateDeviceForegroundSlot(
      this.$route.params.id,
      Number(this.$route.params.row),
      Number(this.$route.params.col),
      this.deviceTemplate,
      newContent,
      this.deviceContentSelection.index
    );
  }

  deleteDeviceContent(): void {
    if (this.deviceContentSelection.isForeground) {
      const newContent: ForegroundContent = this.selectedForegroundContent.clone();
      if (newContent.scheduledContent) {
        newContent.scheduledContent = newContent.scheduledContent.filter(
          (content: ScheduledContent) => content.scheduleId !== this.selectedSchedule
        );
      }
      this.gondolaTemplatesStore.updateDeviceForegroundSlot(
        this.$route.params.id,
        Number(this.$route.params.row),
        Number(this.$route.params.col),
        this.deviceTemplate,
        newContent,
        this.deviceContentSelection.index
      );
    } else {
      this.onDeleteBackground();
    }
  }

  /**
   * Method used to delete some background content of a device.
   * Will remove either the base or one of the scheduled content
   */
  async onDeleteBackground(): Promise<void> {
    if (this.hasScheduledBackgroundContent) {
      this.showDeleteAllScheduledBackgroundDialog = true;
    } else {
      const currentDeviceTemplate: DeviceTemplate = this.deviceTemplate;
      const updatedBackground: BackgroundContent = this.deviceTemplate.backgroundContent[0];
      await this.gondolaTemplatesStore.updateDeviceBackground(
        currentDeviceTemplate,
        this.selectedSchedule === ''
          ? []
          : [
              new BackgroundContent(
                updatedBackground.baseLayer,
                updatedBackground.scheduledContent?.filter(
                  (content: ScheduledContent) => content.scheduleId !== this.selectedSchedule
                )
              ),
            ],
        this.$route.params.id,
        Number(this.$route.params.row),
        Number(this.$route.params.col)
      );
    }
  }

  /**
   * Method used to delete ALL the background content of a device.
   * Will remove the base and the scheduled content
   */
  async onDeleteAllBackgroundContent(): Promise<void> {
    const currentDeviceTemplate: DeviceTemplate = this.deviceTemplate;
    await this.gondolaTemplatesStore.updateDeviceBackground(
      currentDeviceTemplate,
      [new BackgroundContent(undefined, undefined)],
      this.$route.params.id,
      Number(this.$route.params.row),
      Number(this.$route.params.col)
    );
    this.showDeleteAllScheduledBackgroundDialog = false;
  }

  get selectedLayer(): ScheduleLayer | BaseLayer | undefined {
    if (this.deviceContentSelection.isForeground) {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined = this.deviceTemplate.foregroundContent[
          this.deviceContentSelection.index
        ]?.scheduledContent?.find(
          (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
        );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.foregroundContent[this.deviceContentSelection.index]?.baseLayer;
    } else {
      if (this.selectedSchedule) {
        const scheduleContent: ScheduledContent | undefined =
          this.deviceTemplate.backgroundContent?.[0]?.scheduledContent?.find(
            (schedule: ScheduledContent) => schedule.scheduleId === this.schedulesStore.getSelectedSchedule()
          );
        if (scheduleContent) {
          return scheduleContent?.layer;
        }
      }
      return this.deviceTemplate.backgroundContent?.[0]?.baseLayer;
    }
  }

  get selectedLayerSwitchValue(): boolean {
    return (
      (this.selectedLayer as ScheduleLayer)?.visibility === undefined ||
      (this.selectedLayer as ScheduleLayer)?.visibility === LayerVisibility.VISIBLE
    );
  }

  get selectedForegroundContent(): ForegroundContent {
    return this.deviceTemplate.foregroundContent[this.deviceContentSelection.index];
  }

  /**
   * Returns the content items preview title
   * E.g: "Background: MyExample.png" | "Label 3: My Video.mp4" | "Label 1: my playlist"
   */
  get selectedContentTitle(): string {
    // Will be either "Background" or "Label {index}"
    const descriptionPrefix: string = this.deviceContentSelection.isForeground
      ? `${this.$t(this.$i18nTranslationKeys.deviceView.label.$path, {
          index: this.deviceContentSelection.index + 1,
        })}`
      : `${this.$t(this.$i18nTranslationKeys.deviceView.background.$path)}`;

    const originalContentItemName: string = this.selectedLayer?.name
      ? this.contentItemsStore.getOriginalNameByAzureBlobName(this.selectedLayer.name)
      : '';
    return `<h3 class="d-inline">${descriptionPrefix}</h3> ${originalContentItemName}`;
  }

  /**
   * Always returns the base layer of the current selection : Either the background base layer or the selected label base layer
   */
  get selectedBaseLayer(): BaseLayer | undefined {
    if (this.deviceContentSelection.isForeground) {
      return this.deviceTemplate.foregroundContent[this.deviceContentSelection.index]?.baseLayer;
    } else {
      return this.deviceTemplate.backgroundContent?.[0]?.baseLayer;
    }
  }

  get selectedBaseLayerLabelHasContent(): boolean {
    return (
      this.deviceTemplate.foregroundContent?.[this.deviceContentSelection.index]?.baseLayer?.name !==
      EMPTY_LABEL_PLACEHOLDER
    );
  }

  get contentItemSelectionTarget(): ContentItemSelectionTarget {
    return ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND;
  }

  /**
   * Check if a schedule is currently selected and the selected label (or background) is empty/unassigned
   */
  get isEmptyContent(): boolean {
    return (
      !!this.selectedSchedule &&
      ((!this.gondolaTemplatesStore.deviceContentSelection.isForeground && !this.deviceBackgroundBaseLayer) ||
        (this.gondolaTemplatesStore.deviceContentSelection.isForeground && !this.selectedBaseLayerLabelHasContent))
    );
  }

  get currentlyActiveContentItemName(): string {
    const currentlySelectedLayer: ScheduleLayer | BaseLayer | undefined = this.selectedLayer;
    if (!currentlySelectedLayer) {
      return '';
    }
    return currentlySelectedLayer.getName();
  }

  get deviceBackgroundBaseLayer(): BaseLayer | undefined {
    return this.gondolaTemplate?.railGrid[parseInt(this.$route.params.row)][parseInt(this.$route.params.col)]
      .backgroundContent?.[0]?.baseLayer;
  }

  get hasScheduledBackgroundContent(): boolean {
    return !!this.deviceTemplate.backgroundContent[0]?.scheduledContent?.length && !this.selectedSchedule;
  }

  get gondolaTemplate(): Optional<GondolaTemplateModel> {
    return this.gondolaTemplatesStore.getById(this.$route.params.id);
  }

  get isDeleteContentButtonDisabled(): boolean {
    if (!this.selectedSchedule) {
      return !this.deviceTemplate.backgroundContent[0]?.baseLayer;
    }
    const isDefaultSelectedLabelOverridden: boolean =
      (this.selectedLayer as ScheduleLayer)?.visibility === undefined ||
      (this.selectedLayer?.name === EMPTY_LABEL_PLACEHOLDER &&
        (this.selectedLayer as ScheduleLayer)?.visibility === LayerVisibility.VISIBLE);
    const isScheduledContentSet: boolean = this.deviceContentSelection.isForeground
      ? this.deviceTemplate.foregroundContent[this.deviceContentSelection.index].scheduledContent?.findIndex(
          (schedule: ScheduledContent) => schedule.scheduleId === this.selectedSchedule
        ) === -1
      : this.deviceTemplate.backgroundContent[0]?.scheduledContent?.findIndex(
          (schedule: ScheduledContent) => schedule.scheduleId === this.selectedSchedule
        ) === -1;
    return isScheduledContentSet || isDefaultSelectedLabelOverridden;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get deviceContentSelection(): DeviceContentSelection {
    return this.gondolaTemplatesStore.deviceContentSelection;
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }

  /**
   * Hide the create-playlist-button for foreground items, since they cannot interact with playlists
   */
  get showAddPlaylistButton(): boolean {
    return !this.gondolaTemplatesStore.deviceContentSelection.isForeground;
  }

  get selectableContentType(): Array<ContentType> {
    const allTypes: Array<ContentType> = Object.values(ContentType) as Array<ContentType>;
    return this.deviceContentSelection.isForeground
      ? allTypes.filter((type: string) => type !== ContentType.Playlist)
      : allTypes;
  }
}
