import { defineStore, Store, StoreDefinition } from 'pinia';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import Vue from 'vue';
import { ReleaseManagementJSON } from '@common/config/release-management/types';
import { logAxiosError } from '@client/utils/logger';
import { NewReleaseManagement } from '@client/models/SettingsModels/NewReleaseManagement';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Store as StoreModel } from '@client/models';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { TranslateResult } from 'vue-i18n';

export interface ReleaseManagementState {
  releaseConfigurations: Array<ReleaseManagement>;
  fetched: boolean;
  isFetching: boolean;
  isActionPending: boolean;
}
export interface ReleaseManagementGetters {
  getReleaseConfigurationById: (state: ReleaseManagementState) => (id: string) => Optional<ReleaseManagement>;
  getReleaseConfigurations: (state: ReleaseManagementState) => () => Array<ReleaseManagement>;
  getIsFetching: (state: ReleaseManagementState) => () => boolean;
  getDefaultReleaseManagement: (state: ReleaseManagementState) => () => Optional<ReleaseManagement>;
  getReleaseManagementByNameCaseInsensitive: (
    state: ReleaseManagementState
  ) => (name: string) => Optional<ReleaseManagement>;
}

export interface ReleaseManagementActions {
  updateReleaseConfigurationHash(releaseManagementId: string, hash: string): Promise<void>;
  deleteReleaseConfiguration(releaseManagementId: string): Promise<void>;
  setFetched(fetched: boolean): void;
  setIsFetching(isFetching: boolean): void;
  createReleaseManagement(newReleaseManagement: NewReleaseManagement): Promise<void>;
  updateReleaseManagement(
    releaseManagementId: string,
    newReleaseManagement: NewReleaseManagement,
    hash: string
  ): Promise<void>;
  fetch(): Promise<void>;
}

export type ReleaseManagementStoreDefinition = StoreDefinition<
  'releaseManagement',
  ReleaseManagementState,
  ReleaseManagementGetters,
  ReleaseManagementActions
>;

export type ReleaseManagementStore = Store<
  'releaseManagement',
  ReleaseManagementState,
  ReleaseManagementGetters,
  ReleaseManagementActions
>;

export const useReleaseManagementStore: ReleaseManagementStoreDefinition = defineStore('releaseManagement', {
  state: (): ReleaseManagementState => ({
    releaseConfigurations: new Array<ReleaseManagement>(),
    fetched: false,
    isFetching: false,
    isActionPending: false,
  }),
  getters: {
    getReleaseConfigurationById: (state: ReleaseManagementState) => (releaseManagementId: string) => {
      return state.releaseConfigurations.find(
        (releaseManagement: ReleaseManagement): boolean => releaseManagement._id === releaseManagementId
      );
    },
    getReleaseConfigurations: (state: ReleaseManagementState) => () => {
      return state.releaseConfigurations;
    },
    getIsFetching: (state: ReleaseManagementState) => () => {
      return state.isFetching;
    },
    getDefaultReleaseManagement: (state: ReleaseManagementState) => () => {
      return state.releaseConfigurations.find((releaseManagement: ReleaseManagement) => releaseManagement.isDefault);
    },
    getReleaseManagementByNameCaseInsensitive: (state: ReleaseManagementState) => (name: string) => {
      return state.releaseConfigurations.find(
        (releaseManagement: ReleaseManagement): boolean => releaseManagement.name.toUpperCase() === name.toUpperCase()
      );
    },
  },
  actions: {
    async updateReleaseConfigurationHash(releaseManagementId: string, releaseManagementHash: string): Promise<void> {
      const releaseManagement: Optional<ReleaseManagement> = this.getReleaseConfigurationById(releaseManagementId);

      if (releaseManagement) {
        releaseManagement.hash = releaseManagementHash;
      }
    },
    async deleteReleaseConfiguration(releaseManagementId: string): Promise<void> {
      try {
        this.isActionPending = true;
        const releaseManagement: Optional<ReleaseManagement> = this.getReleaseConfigurationById(releaseManagementId);
        if (!releaseManagement) {
          console.error('Release management for deleting not found');
          return;
        }
        const response: AxiosResponse<Array<string>> = await axios.delete(
          `${Config.getApiUrl()}/release-management/${releaseManagementId}`,
          {
            params: {
              hash: releaseManagement.hash,
            },
          }
        );
        if (response.status !== 200) {
          console.error(response);
        }

        const defaultReleaseManagement: Optional<ReleaseManagement> = this.getDefaultReleaseManagement();
        if (!defaultReleaseManagement) {
          console.error('default release management not found');
          return;
        }
        defaultReleaseManagement.appliedStores = defaultReleaseManagement.appliedStores.concat(response.data);

        const storesStore: StoresStore = useStoresStore();
        response.data.forEach((storeId: string) => {
          const currentStore: Optional<StoreModel> = storesStore.getStoreById(storeId);
          if (!currentStore) {
            console.error(`store with id :${storeId} not found`);
            return;
          }
          if (currentStore.releaseManagementReference) {
            currentStore.releaseManagementReference.id = defaultReleaseManagement._id;
            currentStore.releaseManagementReference.name = defaultReleaseManagement.name;
          }
        });

        const indexToBeDeleted: number = this.releaseConfigurations.findIndex(
          (releaseManagement: ReleaseManagement) => releaseManagement._id === releaseManagementId
        );
        if (indexToBeDeleted > -1) {
          this.releaseConfigurations.splice(indexToBeDeleted, 1);
        }
        this.isActionPending = false;
      } catch (e: unknown) {
        logAxiosError(e);
        const axiosError: AxiosError<ErrorResponse> = e as AxiosError<ErrorResponse>;
        let errorMessage: TranslateResult = '';
        if (ErrorType.HASH_CONFLICT.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.concurrentModification.$path);
        }
        if (ErrorType.NOT_FOUND.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.genericNotFound.$path);
        }
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: errorMessage,
        });
        this.isActionPending = false;
      }
    },
    setFetched(fetched: boolean): void {
      this.fetched = fetched;
    },
    setIsFetching(isFetching: boolean): void {
      this.isFetching = isFetching;
    },
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.setIsFetching(true);
        const response: AxiosResponse<Array<ReleaseManagementJSON>> = await axios.get(
          `${Config.getApiUrl()}/release-management`
        );
        const releaseConfigurations: Array<ReleaseManagement> = response.data.map((value: ReleaseManagementJSON) => {
          return ReleaseManagement.fromJSON(value);
        });
        Vue.set(this, 'releaseConfigurations', releaseConfigurations);
        this.setFetched(true);
        this.setIsFetching(false);
      }
    },
    async createReleaseManagement(newReleaseManagement: NewReleaseManagement): Promise<void> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<ReleaseManagementJSON> = await axios.post(
          `${Config.getApiUrl()}/release-management`,
          newReleaseManagement
        );
        if (response.status !== 200) {
          console.error(response);
        }
        this.isActionPending = false;
        await this.fetch();
      } catch (e: unknown) {
        logAxiosError(e);
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: i18n.t(TranslationKeys.error.genericDuplicate.$path),
        });
        this.isActionPending = false;
      }
    },
    async updateReleaseManagement(
      releaseManagementId: string,
      newReleaseManagement: NewReleaseManagement,
      hash: string
    ): Promise<void> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<ReleaseManagementJSON> = await axios.post(
          `${Config.getApiUrl()}/release-management/${releaseManagementId}`,
          newReleaseManagement,
          {
            params: {
              hash: hash,
            },
          }
        );
        if (response.status !== 200) {
          console.error(response);
        }
        this.isActionPending = false;
        await this.fetch();
      } catch (e: unknown) {
        logAxiosError(e);
        const axiosError: AxiosError<ErrorResponse> = e as AxiosError<ErrorResponse>;
        let errorMessage: TranslateResult = '';
        if (ErrorType.HASH_CONFLICT.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.concurrentModification.$path);
        }
        if (ErrorType.DUPLICATE.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.genericDuplicate.$path);
        }
        if (ErrorType.NOT_FOUND.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.genericNotFound.$path);
        }
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: errorMessage,
        });
        this.isActionPending = false;
      }
    },
  },
});
