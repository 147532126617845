import { ScheduledContent, ScheduleLayer } from '@client/models';
import { PublishingScheduledContentModelJSON } from '@common/schedule/types';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { Optional } from '@common/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';

export default class DevicePublishingScheduledContentModel {
  schedule?: ScheduleModel;
  layer: ScheduleLayer;

  constructor(layer: ScheduleLayer, schedule?: ScheduleModel) {
    this.schedule = schedule;
    this.layer = layer;
  }

  public static fromJSON(
    publishingScheduledContentModelJSON: PublishingScheduledContentModelJSON
  ): DevicePublishingScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = ScheduleLayer.fromJSON(publishingScheduledContentModelJSON.layer);
    return new DevicePublishingScheduledContentModel(
      scheduleLayerModel,
      publishingScheduledContentModelJSON.schedule
        ? ScheduleModel.fromJSON(publishingScheduledContentModelJSON.schedule)
        : undefined
    );
  }

  public toJSON(): PublishingScheduledContentModelJSON {
    return {
      schedule: this.schedule,
      layer: this.layer.toJSON(),
    };
  }

  public clone(): DevicePublishingScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = this.layer.clone();
    return new DevicePublishingScheduledContentModel(scheduleLayerModel, this.schedule);
  }

  /**
   * Returns an array of {@link DevicePublishingScheduledContentModel} from an array of {@link ScheduledContent}
   * @param scheduledContent array of scheduled content to parse
   */
  public static fromScheduledContent(
    scheduledContent?: Array<ScheduledContent>
  ): Optional<Array<DevicePublishingScheduledContentModel>> {
    const schedulesStore: SchedulesStore = useSchedulesStore();
    return (
      scheduledContent
        ?.map((content: ScheduledContentModel) => {
          // Populate the schedules in the scheduled content
          const schedule: Optional<ScheduleModel> = schedulesStore.getById(content.scheduleId);
          if (!schedule) {
            return;
          }
          return new DevicePublishingScheduledContentModel(content.layer, schedule);
        })
        // Filter empty objects from the array
        // This is just handling type correctness and the edge case where the schedule is not found, which shouldn't happen
        .filter(
          (
            content: Optional<DevicePublishingScheduledContentModel>
          ): content is DevicePublishingScheduledContentModel => !!content
        )
    );
  }
}
