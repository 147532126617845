
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class FileNameField extends Vue {
  /* DECLARATIONS */
  @Prop()
  name!: string;
  @Prop({ default: '' })
  azureBlobName!: string;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
