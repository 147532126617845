
import { Vue, Component, Prop } from 'vue-property-decorator';
import NotificationTimeDisplay from '@client/components/NotificationPanel/NotificationTimeDisplay.vue';
import NotificationTitle from '@client/components/NotificationPanel/NotificationTitle.vue';
import { FirmwareChangedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareChangedEventLog.modal';
import { TranslateResult } from 'vue-i18n';
import { getDeviceDetailPath } from '@client/router/utils';
import { Optional } from '@common/types';
import NotificationToggle from '@client/components/NotificationPanel/NotificationToggle.vue';
import { Store } from '@client/models';

@Component({
  components: { NotificationToggle, NotificationTitle, NotificationTimeDisplay },
})
export default class FirmwareChangedNotification extends Vue {
  /* DECLARATIONS */
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: FirmwareChangedEventLog;
  @Prop()
  private store: Optional<Store>;

  private isNotificationContentExpanded: boolean = false;

  /* METHODS */
  toggleExpanded(): void {
    this.isNotificationContentExpanded = !this.isNotificationContentExpanded;
  }

  onNotificationClick(): void {
    if (!this.isNotificationContentExpanded) {
      this.isNotificationContentExpanded = true;
    }
  }

  /* GETTERS */
  get notificationTitleIdentifier(): Optional<string> {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get linkToDeviceDetail(): string {
    if (!this.eventLog.device) {
      return '';
    }
    return getDeviceDetailPath(this.eventLog.device.longId);
  }

  get notificationTitle(): TranslateResult {
    return this.$t(this.$i18nTranslationKeys.notifications.firmwareChanged.title.$path, {
      device: this.eventLog.device?.longId,
    });
  }

  get deviceLongId(): string {
    return this.eventLog.device?.longId || '';
  }
}
