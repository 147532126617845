import { TranslateResult } from 'vue-i18n';
import {
  PublishingSectionValidationError,
  PublishingValidationError,
  ValidatedPublishingJSON,
  ValidatedSectionJSON,
} from '@common/publishing/types';
import { Optional } from '@common/types';
import { Gondola, Store } from '@client/models';
import { StoresStore } from '@client/stores/stores';
import { Vue } from 'vue-property-decorator';

/**
 * Transform validated object to specific error message for end user
 * @param validatedPublishingJSON list of objects that contain some kind of validation error
 * @param storesStore for loading section and store name
 * @param component instead of this
 * @param publishingToMultipleSections true when we are publishing to multiple sections
 */
export const extractPublishValidationError = (
  validatedPublishingJSON: ValidatedPublishingJSON,
  storesStore: StoresStore,
  component: Vue,
  publishingToMultipleSections: boolean = false
): TranslateResult => {
  const errorMessage: TranslateResult = component.$t(
    component.$i18nTranslationKeys.rePublishView.validationFailed.$path
  );
  let specificErrorMessage: TranslateResult = '';
  if (validatedPublishingJSON.validationState === PublishingValidationError.INVALID_HASH) {
    specificErrorMessage = component.$t(component.$i18nTranslationKeys.rePublishView.invalidHashTemplate.$path);
  }
  if (validatedPublishingJSON.validationState === PublishingValidationError.MISSING_TEMPLATE) {
    specificErrorMessage = component.$t(component.$i18nTranslationKeys.rePublishView.missingTemplate.$path);
  }
  if (validatedPublishingJSON.validationState === PublishingValidationError.BACKGROUND_MISSING) {
    specificErrorMessage = component.$t(component.$i18nTranslationKeys.rePublishView.backgroundMissing.$path);
  }
  if (validatedPublishingJSON.validationState === PublishingValidationError.BACKGROUND_BASE_LAYER_MISSING) {
    specificErrorMessage = component.$t(component.$i18nTranslationKeys.rePublishView.backgroundBaseLayerMissing.$path);
  }
  if (validatedPublishingJSON.validationState === PublishingValidationError.CONTAINS_EMPTY_LABELS) {
    specificErrorMessage = component.$t(component.$i18nTranslationKeys.rePublishView.templateContainsEmptyLabels.$path);
  }
  if (validatedPublishingJSON.validationState === PublishingValidationError.SECTION_ERROR) {
    specificErrorMessage = publishingToMultipleSections
      ? component.$t(component.$i18nTranslationKeys.rePublishView.sectionError.$path)
      : '';
    validatedPublishingJSON.validatedSections?.forEach((validatedSection: ValidatedSectionJSON) => {
      if (validatedSection.validationState === PublishingSectionValidationError.INVALID_HASH) {
        const store: Optional<Store> = storesStore.getStoreById(validatedSection.storeId);
        const section: Optional<Gondola> = storesStore.getGondolaById(
          validatedSection.storeId,
          validatedSection.sectionId
        );
        if (store && section) {
          specificErrorMessage = `${specificErrorMessage} ${component.$t(
            component.$i18nTranslationKeys.rePublishView.invalidHashStore.$path,
            {
              section: section.positionInAisle,
              store: store.name,
            }
          )}`;
        }
      }
      if (validatedSection.validationState === PublishingSectionValidationError.DIMENSION_MISMATCH) {
        const store: Optional<Store> = storesStore.getStoreById(validatedSection.storeId);
        const section: Optional<Gondola> = storesStore.getGondolaById(
          validatedSection.storeId,
          validatedSection.sectionId
        );
        if (store && section) {
          specificErrorMessage = `${specificErrorMessage} ${component.$t(
            component.$i18nTranslationKeys.rePublishView.dimensionMismatch.$path,
            {
              section: section.positionInAisle,
              store: store.name,
            }
          )}`;
        }
      }
      if (validatedSection.validationState === PublishingSectionValidationError.MISSING_TIMEZONE) {
        const store: Optional<Store> = storesStore.getStoreById(validatedSection.storeId);
        if (store) {
          specificErrorMessage = `${specificErrorMessage} ${component.$t(
            component.$i18nTranslationKeys.rePublishView.missingTimezone.$path,
            {
              store: store.name,
            }
          )}`;
        }
      }
      if (validatedSection.validationState === PublishingSectionValidationError.MISSING_DEVICE_ID) {
        const store: Optional<Store> = storesStore.getStoreById(validatedSection.storeId);
        const section: Optional<Gondola> = storesStore.getGondolaById(
          validatedSection.storeId,
          validatedSection.sectionId
        );
        if (store && section) {
          specificErrorMessage = `${specificErrorMessage} ${component.$t(
            component.$i18nTranslationKeys.rePublishView.missingDeviceId.$path,
            {
              section: section.positionInAisle,
              store: store.name,
            }
          )}`;
        }
      }
      if (validatedSection.validationState === PublishingSectionValidationError.MISSING_SECTION) {
        specificErrorMessage = `${specificErrorMessage}  ${component.$t(
          component.$i18nTranslationKeys.rePublishView.missingSection.$path,
          {
            id: validatedSection.sectionId,
          }
        )} `;
      }
      if (validatedSection.validationState === PublishingSectionValidationError.MISSING_STORE) {
        specificErrorMessage = `${specificErrorMessage}${component.$t(
          component.$i18nTranslationKeys.rePublishView.missingStore.$path,
          {
            id: validatedSection.storeId,
          }
        )}`;
      }
    });
  }
  return `${errorMessage} ${specificErrorMessage}`;
};
