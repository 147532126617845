
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import ContentType from '@common/enums/ContentType';

@Component({})
export default class HeaderFileTypeFilter extends Vue {
  @Prop()
  private headerText!: TranslateResult;

  @Prop()
  private defaultValue!: ContentType[];

  @Prop({ default: false })
  private useChips!: boolean;

  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType!: Array<ContentType>;

  private filterValue: ContentType[] = [...this.defaultValue];

  // used to force rerender component when we clear all filters from other component
  private typesRerenderKey: number = 0;

  private filterTypesOptions = (): Array<{ text: TranslateResult; value: ContentType }> => [
    {
      text: this.$t(this.$i18nTranslationKeys.deviceView.image.$path),
      value: ContentType.Image,
    },
    {
      text: this.$t(this.$i18nTranslationKeys.deviceView.video.$path),
      value: ContentType.Video,
    },
    {
      text: this.$t(this.$i18nTranslationKeys.deviceView.playlist.$path),
      value: ContentType.Playlist,
    },
    {
      text: this.$t(this.$i18nTranslationKeys.contentManagement.folder.$path),
      value: ContentType.Folder,
    },
  ];

  updateValue(value: ContentType) {
    if (this.filterValue.includes(value)) {
      this.filterValue.splice(this.filterValue.indexOf(value), 1);
      return;
    }
    this.filterValue.push(value);
  }

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    if (this.defaultValue !== this.filterValue) {
      this.filterValue = [...this.defaultValue];
      this.typesRerenderKey++;
    }
  }

  clearFilter(): void {
    this.filterValue = [];
  }

  get filterButtonColor(): string {
    return !this.useChips && this.filterValue.length ? 'primary' : '';
  }

  get filterTypes(): Array<{ text: TranslateResult; value: ContentType }> {
    return this.filterTypesOptions();
  }
}
