'use strict';
import { MSALBasic, Options } from './src/types';
import { MSAL } from './src/main';
import { AuthenticationParameters } from 'msal';
import { VueConstructor } from 'vue';

export default class MSALPlugin {
  static install(Vue: VueConstructor, options: Options): void {
    Vue.prototype.$msal = new MSALPlugin(options);
  }

  constructor(options: Options) {
    const msal: MSAL = new MSAL(options);
    const exposed: MSALBasic = {
      data: msal.data,
      signIn() {
        msal.signIn();
      },
      async signOut() {
        await msal.signOut();
      },
      editProfile() {
        msal.editProfile();
      },
      isAuthenticated() {
        return msal.isAuthenticated();
      },

      async acquireToken(request: AuthenticationParameters | undefined, retries: number | undefined) {
        return msal.acquireToken(request, retries);
      },
    };
    return exposed;
  }
}
