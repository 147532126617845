import { ContentType } from '@common/enums';
import LayerPlaylistItem from './LayerPlaylistItem.model';
import AzureBlobService from '@client/plugins/azureblobservice';

export default abstract class ContentLayerModel {
  name: string;
  url: string;
  previewUrl: string;
  type: ContentType;
  height: number;
  width: number;
  duration: number;
  fps: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum?: string;
  /**
   * The layer id represents the internal id in the APIM device.
   * We need this to link the published content to the real content on the device.
   * It is not set initially, it's only set after we receive the APIM response.
   */
  layerId?: string;
  playlist: Array<LayerPlaylistItem>;

  protected constructor(
    name: string,
    url: string,
    previewUrl: string,
    type: ContentType,
    height: number,
    width: number,
    duration: number,
    fps: number,
    offsetX: number,
    offsetY: number,
    zIndex: number,
    checksum: string | undefined,
    layerId: string | undefined,
    playlist: Array<LayerPlaylistItem>
  ) {
    this.name = name;
    this.url = url;
    this.previewUrl = previewUrl;
    this.type = type;
    this.height = height;
    this.width = width;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.zIndex = zIndex;
    this.checksum = checksum;
    this.playlist = playlist;
    this.layerId = layerId;
    this.duration = duration;
    this.fps = fps;
  }

  public getName(): string {
    return this.name;
  }

  /**
   * Returns the original content URL wrapped with the azure authorization token
   * If the item is a playlist, it will return the URL of the first item in the playlist
   */
  public async getBlobUrl(): Promise<string> {
    if (this.type === ContentType.Playlist) {
      return AzureBlobService.wrapUrlWithReadToken(this.playlist?.[0]?.url);
    }
    return AzureBlobService.wrapUrlWithReadToken(this.url);
  }

  /**
   * Returns the preview content URL wrapped with the azure authorization token
   * returns the original URL as a fallback
   */
  public async getPreviewBlobUrl(): Promise<string> {
    if (this.type === ContentType.Playlist) {
      if (!this.playlist.length) {
        return ''; //when all content items from playlists are deleted
      }
      if (!this.playlist?.[0].previewUrl) {
        return AzureBlobService.wrapUrlWithReadToken(this.playlist?.[0].url);
      }
      return AzureBlobService.wrapUrlWithReadToken(this.playlist?.[0].previewUrl);
    }
    if (!this.previewUrl) {
      return AzureBlobService.wrapUrlWithReadToken(this.url);
    }
    return AzureBlobService.wrapUrlWithReadToken(this.previewUrl);
  }

  public getHeight(): number {
    if (this.type === ContentType.Playlist) {
      return this.playlist?.[0]?.height || 0;
    }
    return this.height;
  }

  public getWidth(): number {
    if (this.type === ContentType.Playlist) {
      return this.playlist?.[0]?.width || 0;
    }
    return this.width;
  }
}
