import WebSocket from 'ws';

export interface ExtWebSocket extends WebSocket {
  id: string;
  key: string;
}

/**
 * Common websocket payload
 */
export interface WsPayload {
  timestamp: string;
  customerId: string;
}

/**
 * Enumeration of all known websocket message types
 */
export enum WsMessageType {
  Device = 'device',
  EventLog = 'eventlog',
  Publishing = 'publishing',
  Powersave = 'powersave',
  ContentItem = 'contentitem',
  GondolaTemplate = 'gondolatemplate',
  StoreSync = 'store-sync',
}

/**
 * Common websocket message
 */
export interface WsMessage {
  type: WsMessageType;
  payload: WsPayload;
}

export interface RegisterWebsocket {
  user: string;
  customer: string;
}
