
import { Component, Vue } from 'vue-property-decorator';

/**
 * Wrapper component.
 * Component to be used to wrap other components, use it when setting the default margins and padding on a component and add a background color
 */
@Component({})
export default class Wrapper extends Vue {
  /* DECLARATIONS */

  private defaultClasses: string = 'rounded-lg wrapper-defaults shadowed white';
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get classes(): string {
    return this.defaultClasses;
  }
}
