import { DeviceJSON } from '@common/device/types';
import { WsMessage, WsMessageType, WsPayload } from '@common/websocket/types';

export enum DeviceActions {
  Update = 'Update',
  BulkUpdate = 'BulkUpdate',
  Delete = 'Delete',
  OnlineStatus = 'OnlineStatus',
  Registered = 'Registered',
  Unregistered = 'Unregistered',
}

export class WsDeviceMessage implements WsMessage {
  type: WsMessageType;
  payload: WsPayload;
  action: DeviceActions;

  constructor(payload: WsPayload, type: WsMessageType, action: DeviceActions) {
    this.type = type;
    this.payload = payload;
    this.action = action;
  }
}

export interface WsDeviceDeletePayload extends WsPayload {
  deviceId: string;
}

export class WsDeviceDeleteMessage extends WsDeviceMessage {
  type: WsMessageType = WsMessageType.Device;
  payload: WsDeviceDeletePayload;
  action: DeviceActions = DeviceActions.Delete;

  constructor(payload: WsDeviceDeletePayload) {
    super(payload, WsMessageType.Device, DeviceActions.Delete);
    this.payload = payload;
  }
}

export interface WsDeviceUpdatePayload extends WsPayload {
  updatedDevice: DeviceJSON;
}

export class WsDeviceUpdateMessage extends WsDeviceMessage {
  type: WsMessageType = WsMessageType.Device;
  payload: WsDeviceUpdatePayload;
  action: DeviceActions = DeviceActions.Update;

  constructor(payload: WsDeviceUpdatePayload) {
    super(payload, WsMessageType.Device, DeviceActions.Update);
    this.payload = payload;
  }
}

export interface WsDeviceBulkUpdatePayload extends WsPayload {
  updatedDevices: Array<DeviceJSON>;
}

export class WsDeviceBulkUpdateMessage extends WsDeviceMessage {
  type: WsMessageType = WsMessageType.Device;
  payload: WsDeviceBulkUpdatePayload;
  action: DeviceActions = DeviceActions.BulkUpdate;

  constructor(payload: WsDeviceBulkUpdatePayload) {
    super(payload, WsMessageType.Device, DeviceActions.BulkUpdate);
    this.payload = payload;
  }
}

export class WsDeviceOnlineStatusMessage extends WsDeviceMessage {
  type: WsMessageType = WsMessageType.Device;
  payload: WsDeviceUpdatePayload;
  action: DeviceActions = DeviceActions.OnlineStatus;

  constructor(payload: WsDeviceUpdatePayload) {
    super(payload, WsMessageType.Device, DeviceActions.OnlineStatus);
    this.payload = payload;
  }
}

export class WsDeviceUnregisteredMessage extends WsDeviceMessage {
  payload: WsDeviceUpdatePayload;
  constructor(payload: WsDeviceUpdatePayload) {
    super(payload, WsMessageType.Device, DeviceActions.Unregistered);
    this.payload = payload;
  }
}

export interface WsDeviceRegisteredPayload extends WsPayload {
  storeIdAzure: string;
  longId: string;
}

export class WsDeviceRegisteredMessage extends WsDeviceMessage {
  payload: WsDeviceRegisteredPayload;

  constructor(payload: WsDeviceRegisteredPayload) {
    super(payload, WsMessageType.Device, DeviceActions.Registered);
    this.payload = payload;
  }
}
