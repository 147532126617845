
import { Component, Prop, Vue } from 'vue-property-decorator';
import Device from '@client/components/Device/Device.vue';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import { DeviceTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({
  components: {
    Device,
    ModalDialog,
  },
})
export default class GondolaTemplateContent extends Vue {
  @Prop() private editable!: boolean;
  @Prop() private id!: string;
  @Prop() private loading!: boolean;
  @Prop({ default: false }) private showCurrentlyDisplayedSchedule!: boolean;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private showDeleteDeviceDialog: boolean = false;
  private cancelHandler = () => {
    // Will be overridden later
  };
  private confirmHandler = () => {
    // Will be overridden later
  };

  checkAndSetLoading(): boolean {
    if (this.loading) {
      return true;
    }
    this.$emit('loading', true);
    return false;
  }

  async addDeviceRow(): Promise<void> {
    if (this.checkAndSetLoading()) {
      return;
    }

    await this.gondolaTemplatesStore.addDeviceRow(new DeviceTemplate(), this.id);
    this.$emit('loading', false);
  }

  addDeviceCol(rowIndex: number): void {
    if (this.checkAndSetLoading()) {
      return;
    }

    this.gondolaTemplatesStore.addDeviceCol(new DeviceTemplate(), this.id, rowIndex).then(() => {
      this.$emit('loading', false);
    });
  }

  async deleteDevice(rowIndex: number, colIndex: number): Promise<void> {
    if (this.checkAndSetLoading()) {
      return;
    }

    if (this.railGrid?.[rowIndex]?.[colIndex]?.backgroundContent?.length) {
      this.confirmHandler = async () => {
        await this.dispatchDeleteDevice(rowIndex, colIndex);
        this.showDeleteDeviceDialog = false;
      };
      this.cancelHandler = () => {
        this.showDeleteDeviceDialog = false;
      };
      this.showDeleteDeviceDialog = true;
    } else {
      await this.dispatchDeleteDevice(rowIndex, colIndex);
    }
  }

  async dispatchDeleteDevice(row: number, col: number): Promise<void> {
    await this.gondolaTemplatesStore.deleteDevice(this.id, row, col);
    this.$emit('loading', false);
  }

  get railGrid(): Array<Array<DeviceTemplate>> | null {
    return this.gondolaTemplatesStore.getById(this.id)?.railGrid || null;
  }
}
