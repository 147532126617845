import { EventLogFilters } from '@client/stores/eventLogs';
import { EventLog } from '@client/models';
import { defineStore, Store, StoreDefinition } from 'pinia';
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import { EventLogSearchResult, EventType } from '@common/eventlog/types';
import Config from '@client/utils/config';
import { SearchResponse } from '@client/stores/eventLogs/types';

import { eventLogFromJSON } from '@client/models/EventLogModels/EventLog.common';

export interface EventLogsState {
  eventLogs: Array<EventLog>;
  /**
   * A set containing all the eventLog ids that are already loaded in the state.
   * This is needed for when we receive an event, we don't have to run a .find to know if the event log exists already or not.
   * Since that would take some execution time, and we can stumble on a concurrency problem
   */
  loadedEventLogIds: Set<string>;
  /**
   * Filters used for the event logs
   */
  filters: EventLogFilters;
  currentPage: number;
  totalCount: number;
  isSearching: boolean;
  isSearchingDevice: boolean;
}

export interface EventLogsGetters {
  isTypeSelectedInFilters: (state: EventLogsState) => (type: EventType) => boolean;
  isAzureIdSelectedInFilters: (state: EventLogsState) => (storeAzureId: string) => boolean;
}
export interface EventLogsActions {
  setEventLogs(eventLogs: Array<EventLog>): void;
  setFilters(eventLogFilters: EventLogFilters): void;
  add(newEventLog: EventLog): void;
  fetch(): Promise<void>;
  search(eventLogFilters: Partial<EventLogFilters>, page?: number): Promise<SearchResponse>;
}

export type EventLogsStoreDefinition = StoreDefinition<'eventLogs', EventLogsState, EventLogsGetters, EventLogsActions>;

export type EventLogsStore = Store<'eventLogs', EventLogsState, EventLogsGetters, EventLogsActions>;

export const useEventLogsStore: EventLogsStoreDefinition = defineStore('eventLogs', {
  state: (): EventLogsState => ({
    eventLogs: new Array<EventLog>(),
    loadedEventLogIds: new Set<string>(),
    filters: {
      stores: new Array<string>(),
      dateRange: new Array<number>(),
      searchTerm: '',
      types: new Array<string>(),
    },
    currentPage: 0,
    totalCount: 0,
    isSearching: false,
    isSearchingDevice: false,
  }),
  getters: {
    isTypeSelectedInFilters:
      (state: EventLogsState) =>
      (type: EventType): boolean => {
        // Create a copy of the selected types
        const filterTypes: Array<string> = [...state.filters.types];
        /**
         * If the active hours type is selected, add the other active hours types
         * We have 3 active hours types in the backend, but we use only one on the FE which the user can select as a type filter
         */
        if (filterTypes.includes(EventType.STORE_ACTIVE_HOURS)) {
          filterTypes.push(EventType.SECTION_ACTIVE_HOURS, EventType.ACTIVE_HOURS_CONFIG);
        }
        if (filterTypes.includes(EventType.FIRMWARE_RELEASED)) {
          filterTypes.push(EventType.FIRMWARE_CHANGED);
        }
        return filterTypes.includes(type);
      },
    isAzureIdSelectedInFilters:
      (state: EventLogsState) =>
      (storeAzureId: string): boolean => {
        return state.filters.stores.length === 0 || state.filters.stores.includes(storeAzureId);
      },
  },
  actions: {
    setEventLogs(eventLogs: Array<EventLog>): void {
      this.loadedEventLogIds = new Set<string>();
      eventLogs.forEach((eventLog: EventLog) => this.loadedEventLogIds.add(eventLog._id));
      Vue.set(this, 'eventLogs', eventLogs);
    },
    setFilters(eventLogFilters: EventLogFilters): void {
      const { stores, dateRange, searchTerm, types }: EventLogFilters = eventLogFilters;
      this.filters = { stores, dateRange, searchTerm, types };
      this.setEventLogs([]);
      this.totalCount = 0;
      this.currentPage = 0;
    },
    add(newEventLog: EventLog): void {
      if (this.isSearching) {
        return;
      }
      // check for existing event log and if it exists, override it

      if (this.loadedEventLogIds.has(newEventLog._id)) {
        const existingEventLogIndex: number = this.eventLogs.findIndex(
          (eventLog: EventLog) => eventLog._id === newEventLog._id
        );
        if (existingEventLogIndex < 0) {
          return;
        }
        Vue.set(this.eventLogs, existingEventLogIndex, newEventLog);
      } else {
        this.totalCount += 1;
        this.loadedEventLogIds.add(newEventLog._id);
        this.eventLogs.unshift(newEventLog);
      }
    },
    async fetch(): Promise<void> {
      if (this.isSearching) {
        return;
      }
      this.isSearching = true;
      const response: AxiosResponse<EventLogSearchResult> = await axios.get(`${Config.getApiUrl()}/notifications`, {
        params: {
          startDate: this.filters.dateRange[0],
          endDate: this.filters.dateRange[1],
          stores: this.filters.stores.join(','),
          types: this.filters.types.join(','),
          searchTerm: this.filters.searchTerm,
          page: this.currentPage,
        },
      });
      this.isSearching = false;
      if (response.status !== 200) {
        console.error(response);
        return;
      }
      const newEventLogSearchResult: Array<EventLog> = response.data.eventLogs
        .map(eventLogFromJSON)
        .filter((eventLog: EventLog) => !this.loadedEventLogIds.has(eventLog._id));
      newEventLogSearchResult.forEach((eventLog: EventLog) => this.loadedEventLogIds.add(eventLog._id));
      this.eventLogs.push(...newEventLogSearchResult);
      this.totalCount = response.data.count;
      this.currentPage += 1;
    },
    async search(eventLogFilters: Partial<EventLogFilters>, page: number = 0): Promise<SearchResponse> {
      this.isSearchingDevice = true;
      const response: AxiosResponse<EventLogSearchResult> = await axios.get(`${Config.getApiUrl()}/notifications`, {
        params: {
          startDate: eventLogFilters.dateRange?.[0],
          endDate: eventLogFilters.dateRange?.[1],
          stores: eventLogFilters.stores?.join(','),
          types: eventLogFilters.types?.join(','),
          searchTerm: eventLogFilters.searchTerm,
          page: page,
          resultsPerPage: 10,
        },
      });
      this.isSearchingDevice = false;
      if (response.status !== 200) {
        console.error(response);
        return {
          eventLogs: [],
          totalCount: 0,
        };
      } else {
        return {
          eventLogs: response.data.eventLogs.map(eventLogFromJSON),
          totalCount: response.data.count,
        };
      }
    },
  },
});
