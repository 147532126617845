
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContentItemsList from './ContentItemsList.vue';
import { ContentItemSelectionTarget } from '@client/enums';
import { ContentType } from '@common/enums';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import VideoModel from '@client/models/ContentModels/Video.model';

/**
 * This component renders the content items list, wrapped with the actions toolbar
 */
@Component({
  components: {
    ContentItemsList,
  },
})
export default class ContentItems extends Vue {
  @Prop({ default: '' })
  private selectedSchedule!: string;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget?: ContentItemSelectionTarget;
  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType?: Array<ContentType>;
  @Prop({
    default: () => {
      // Will be overridden later
    },
  })
  private selectVideo!: (item: VideoModel) => void;
  @Prop({ default: false })
  private isModalEmbedded!: boolean;
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  async created(): Promise<void> {
    await this.contentItemsStore.fetchContentItems();
  }
}
