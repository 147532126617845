
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItem } from '@client/models/ContentModels/types';

@Component({})
export default class MoveButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private item!: ContentItem;
  @Prop()
  private openMoveDialog!: (contentItemToMove: ContentItem) => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  onOpenMoveDialog(): void {
    this.openMoveDialog(this.item);
  }
  /* GETTERS */
}
