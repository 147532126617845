import { Gondola, GondolaDevice } from './Gondola';
import { GondolaTemplateContent, GondolaTemplatePlaceholder, GondolaTemplate } from './GondolaTemplate';
import {
  Device,
  DeviceActiveStatusSwitch,
  ScheduleActiveStatusSwitch,
  DeviceCanvas,
  DeviceContentDetails,
  ContentPreview,
} from './Device';
import { ScaleControl } from './ScaleControl';
import { Preview, ForegroundPreview, BackgroundPreview } from './Preview';
import { InlineEditTextField } from './InlineEditTextField';
import { LocaleSwitch } from './LocaleSwitch';
import { VisualTimer } from './VisualTimer';
import { ModalDialog } from './ModalDialog';
import { NavigationWrapper } from './Layouts';
import { TimeSpanCalendar } from './TimeSpanCalendar';
import { ContentItemsList, ContentItems } from './ContentItems';
import { DeleteScheduleButton } from './DeleteScheduleButton';
import { ScheduleTabItem } from './ScheduleTabItem';
import { ScheduleDialog } from './ScheduleDialog';
import { NotificationPanel } from './NotificationPanel';
import { SmartPreview } from './SmartPreview';
import { OpenLastPublishedTemplateForSection } from './OpenLastPublishedTemplateForSection';

/* Components folder exports */
export {
  Device,
  DeviceCanvas,
  Gondola,
  GondolaDevice,
  GondolaTemplate,
  GondolaTemplateContent,
  GondolaTemplatePlaceholder,
  ScaleControl,
  Preview,
  ForegroundPreview,
  BackgroundPreview,
  InlineEditTextField,
  LocaleSwitch,
  VisualTimer,
  ModalDialog,
  NavigationWrapper,
  ScheduleDialog,
  TimeSpanCalendar,
  ContentItemsList,
  ContentItems,
  ScheduleActiveStatusSwitch,
  DeviceActiveStatusSwitch,
  DeleteScheduleButton,
  ScheduleTabItem,
  DeviceContentDetails,
  ContentPreview,
  NotificationPanel,
  SmartPreview,
  OpenLastPublishedTemplateForSection,
};
