
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { Device } from '@client/models';

@Component({
  components: { ModalHeader },
})
export default class WizardDevices extends Vue {
  /* DECLARATIONS */
  @Prop()
  private azureIdRelatedDevices!: Device[];

  private showDialog: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  closeDialog(): void {
    this.showDialog = false;
  }
  /* GETTERS */
}
