
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device, Store } from '@client/models';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';
import TimeSpanCalendar from '@client/components/TimeSpanCalendar/TimeSpanCalendar.vue';
import Schedule from '@client/models/ScheduleModels/Schedule.model';
import { Success } from '@client/styles/colors';
import { ScheduleVisibility } from '@common/schedule/types';
import { activeHoursScheduleToScheduleSpans } from '@client/utils/ActiveHoursUtils';
import WizardDevices from '@client/components/Store/StoreCreationWizard/WizardDevices.vue';

@Component({
  components: { WizardDevices, TimeSpanCalendar, TagsList },
})
export default class WizardReview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  @Prop()
  private releaseManagement!: ReleaseManagement;
  @Prop()
  private hasActiveHoursError!: boolean;
  @Prop()
  private azureIdRelatedDevices!: Array<Device>;

  private isInitialized: boolean = false;

  private activeHours: Schedule = new Schedule(
    '',
    'storeActiveHours',
    Success._500,
    '',
    [],
    ScheduleVisibility.PUBLIC,
    [],
    true
  );
  /* LIFECYCLE EVENTS */
  mounted(): void {
    this.updateStoreActiveHours();
  }

  created() {
    setTimeout(() => {
      this.isInitialized = true;
    }, 400);
  }

  /* METHODS */
  @Watch('store.activeHours', { deep: true })
  updateStoreActiveHours(): void {
    this.activeHours.spans = activeHoursScheduleToScheduleSpans(this.store.activeHours);
  }
  /* GETTERS */
}
