
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component
export default class ScaleControl extends Vue {
  @Prop() private gondolaId!: string;
  private zoomStep: number = 5;
  private minZoom: number = 25;
  private maxZoom: number = 200;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  handleChange(newZoom: number): void {
    this.gondolaTemplatesStore.updateGondolaZoomLevelById(this.gondolaId, newZoom);
    this.resize();
  }

  zoomOut(): void {
    const zoom: number = Math.max(this.gondolaZoomLevel - this.zoomStep, this.minZoom);
    this.gondolaTemplatesStore.updateGondolaZoomLevelById(this.gondolaId, zoom);
    this.resize();
  }

  zoomIn(): void {
    const zoom: number = Math.min(this.gondolaZoomLevel + this.zoomStep, this.maxZoom);
    this.gondolaTemplatesStore.updateGondolaZoomLevelById(this.gondolaId, zoom);
    this.resize();
  }

  mounted(): void {
    this.resize();
  }

  resizeToRatio(ratio: string): void {
    const elem: HTMLElement | null = document.getElementById(this.gondolaId);
    if (elem) {
      elem.style.setProperty('--device-scale', ratio);
    }
  }

  get gondolaZoomLevel(): number {
    return this.gondolaTemplatesStore.getZoomLevelById(this.gondolaId);
  }

  resize(): void {
    this.resizeToRatio((this.gondolaZoomLevel / 100).toString());
  }
}
