
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { ContentType } from '@common/enums';
import TypeFilter from '@client/components/ContentItems/Search/TypeFilter.vue';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import TagReference from '@client/models/SettingsModels/TagReference';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';

export type SearchParams = {
  typeFilter: Array<ContentType>;
  searchTerm: string;
  tags: Array<TagReference>;
};

@Component({
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateTextFieldLength,
  },
  components: { SelectTag, TypeFilter },
})
export default class Search extends Vue {
  /* DECLARATIONS */
  @Prop({ default: () => Object.values(ContentType) })
  private selectableContentType?: Array<ContentType>;
  @Prop({ default: () => [] })
  private defaultSelectedValues!: Array<ContentType>;
  @Prop({ default: () => [] })
  private searchTags!: Array<TagReference>;

  private searchTypes: Array<ContentType> = [];
  private searchTerm: string = '';
  private selectedTags: Array<TagReference> = [];
  private isSearchDisabled: boolean = true;

  /* LIFECYCLE EVENTS */
  created(): void {
    if (this.defaultSelectedValues.length) {
      this.searchTypes = this.defaultSelectedValues;
    }
    if (this.searchTags) {
      this.selectedTags = this.searchTags;
    }
  }
  /* METHODS */
  updateTagFilter(selectedTags: Array<TagReference>) {
    this.selectedTags = selectedTags;
    this.triggerSearch();
  }

  onSelectedFilterChange(filters: Array<ContentType>): void {
    this.searchTypes = filters;
    this.triggerSearch();
  }

  onSearch(): void {
    if (this.isSearchDisabled) {
      return;
    }
    this.triggerSearch();
  }

  @Emit('onSearch')
  triggerSearch(): SearchParams {
    return {
      typeFilter: this.searchTypes,
      searchTerm: this.searchTerm,
      tags: this.selectedTags,
    };
  }

  @Watch('searchTerm')
  onSearchTermChanged(): void {
    if (this.searchTerm.length === 0 && this.searchTerm.length > DEFAULT_COMMON_STRING_MAX_LENGTH) {
      this.isSearchDisabled = true;
      return;
    } else {
      this.isSearchDisabled = false;
    }
  }
}
