import HardwareModel from './HardwareModel.model';
import DevicePublishInformation from './DevicePublishInformation.model';
import DeviceGroup from '@client/models/DeviceModels/DeviceGroup.model';
import { DevicePublishingJSON } from '@common/publishing/types';
import ClientPublishState from '@client/enums/ClientPublishState';
import { PublishingBackgroundContentModelJSON, PublishingForegroundContentModelJSON } from '@common/schedule/types';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';

export default class DevicePublishing {
  shortId: string;
  longId: string;
  hardwareModel: HardwareModel;
  publishInformation: DevicePublishInformation = new DevicePublishInformation(ClientPublishState.INITIALIZING);
  backgroundContent: Array<DevicePublishingBackgroundContentModel> =
    new Array<DevicePublishingBackgroundContentModel>();
  foregroundContent: Array<DevicePublishingForegroundContentModel> =
    new Array<DevicePublishingForegroundContentModel>();
  railGroup: DeviceGroup = DeviceGroup.DeviceGroupUnlinked;
  previewUrl?: string;

  constructor(shortId: string, longId: string, hardwareModel: HardwareModel) {
    this.shortId = shortId;
    this.longId = longId;
    this.hardwareModel = hardwareModel;
    if (this.shortId === '') {
      this.publishInformation.state = ClientPublishState.MISSING_DEVICE_ID;
    } else {
      this.publishInformation.state = ClientPublishState.MATCHED;
    }
  }

  toJSON(): DevicePublishingJSON {
    return {
      shortId: this.shortId,
      longId: this.longId,
      hardwareModel: this.hardwareModel.identifier,
      publishInformation: this.publishInformation.toJSON(),
      backgroundContent: this.backgroundContent,
      foregroundContent: this.foregroundContent,
      railGroup: this.railGroup,
      previewUrl: this.previewUrl,
    };
  }

  static fromJSON(source: DevicePublishingJSON): DevicePublishing {
    const devicePublishing: DevicePublishing = new DevicePublishing(
      source.shortId,
      source.longId,
      HardwareModel.getByIdentifier(source.hardwareModel)
    );
    devicePublishing.publishInformation = DevicePublishInformation.fromJSON(source.publishInformation);
    devicePublishing.previewUrl = source.previewUrl;

    if (source.railGroup) {
      devicePublishing.railGroup = DeviceGroup.fromJSON(source.railGroup);
    }

    devicePublishing.backgroundContent = source.backgroundContent.map(
      (backgroundContent: PublishingBackgroundContentModelJSON) => {
        return DevicePublishingBackgroundContentModel.fromJSON(backgroundContent);
      }
    );
    devicePublishing.foregroundContent = source.foregroundContent.map(
      (foregroundContent: PublishingForegroundContentModelJSON) => {
        return DevicePublishingForegroundContentModel.fromJSON(foregroundContent);
      }
    );
    return devicePublishing;
  }

  /**
   * Checks if any {@link ScheduledContent} is set for a {@link BackgroundContent} that has no  {@link BaseLayer} set.
   * This is currently not supported in the backend, since the apim requires an existing baselayer in order to publish
   * scheduled content.
   */
  public hasInvalidBackgroundScheduledContentSet(): boolean {
    for (const currentBackground of this.backgroundContent) {
      if (!currentBackground.baseLayer && currentBackground.scheduledContent?.length) {
        return true;
      }
    }
    return false;
  }
}
