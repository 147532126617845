
import { Vue, Component, Prop } from 'vue-property-decorator';
import AffectedStoresModal from '@client/components/Settings/ReleaseManagement/AffectedStoresModal.vue';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';

@Component({
  components: { AffectedStoresModal },
})
export default class SaveButton extends Vue {
  @Prop()
  private isValid!: boolean;
  @Prop()
  private isSameAsInitValue!: boolean;
  @Prop()
  private releaseManagement!: ReleaseManagement;
  @Prop()
  private isEditMode!: boolean;
  @Prop()
  private isNewDefault!: boolean;

  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();

  private showDialog: boolean = false;

  confirmSave(): void {
    this.$emit('save-changes');
  }

  closeDialog(): void {
    this.showDialog = false;
  }

  get hasStoresApplied(): boolean {
    return !!this.releaseManagement.appliedStores.length || this.isNewDefault;
  }

  get isDisabled(): boolean {
    return !this.isValid || this.isSameAsInitValue;
  }

  get hasAffectedStores(): boolean {
    return this.isEditMode && this.hasStoresApplied;
  }

  get isLoading(): boolean {
    return this.releaseManagementStore.isFetching || this.releaseManagementStore.isActionPending;
  }
}
