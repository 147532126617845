
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import ActiveHours from '@client/components/ActiveHours/ActiveHours.vue';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';

@Component({
  components: {
    ActiveHours,
  },
})
export default class StoreActiveHours extends Vue {
  @Prop()
  private storeId!: string;
  @Prop({ default: false })
  private small!: boolean;
  @Prop()
  private activeHoursSchedule!: ActiveHoursSchedule;
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private isMissingAzureId!: boolean;
  @Prop()
  private activeHoursJobFailed!: boolean;
  @Prop({ default: 'mdi-clock' })
  private defaultIcon!: string;
  @Prop({ default: 'mdi-clock-check' })
  private configSetIcon!: string;
  @Prop({ default: 'mdi-clock-alert' })
  private errorIcon!: string;
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();

  async onSaveHandler(submittedActiveHoursSchedule: ActiveHoursSchedule): Promise<void> {
    await this.activeHoursStore.submitActiveHours(this.storeId, submittedActiveHoursSchedule);
    this.$emit('onChange');
  }
}
