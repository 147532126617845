
import ScheduleDialog from '../../ScheduleDialog/ScheduleDialog.vue';
import ScheduleTabItem from '../../ScheduleTabItem/ScheduleTabItem.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { GondolaTemplate as GondolaTemplateModel, Schedule, ScheduleSpan } from '@client/models';
import Moment from 'moment/moment';
import { ScheduleDay } from '@common/enums';
import { getMinutesFromTimeString } from '@client/utils/DateTimeUtils';
import { Optional } from '@common/types';

/**
 * Renders the list of available schedules for the currently edited template as vertical tabs.
 * Each schedule item includes a context menu that provides features to manage the schedule item.
 * Additionally, also contains the add schedule button.
 */
@Component({
  components: {
    ScheduleTabItem,
    ScheduleDialog,
  },
})
export default class DeviceLayerSelectionScheduleList extends Vue {
  private activeTab: string = 'tab-base';
  private schedulesFilterOptions: { isActiveOnly: boolean; isCurrentWeekOnly: boolean } = {
    isActiveOnly: false,
    isCurrentWeekOnly: true,
  };

  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  async created(): Promise<void> {
    this.activeTab = this.selectedSchedule ? `tab-${this.selectedSchedule}` : 'tab-base';
  }

  /**
   * Applies the selected filters to the schedules array
   * isCurrentWeekOnly: Will return the schedules available in the current selected week in the TimeSpanCalendar component
   * isActiveOnly: Will return the active schedules only
   * @param schedules Array of schedules to filter
   */
  applyFilters(schedules: Array<Schedule>): Array<Schedule> {
    if (this.schedulesFilterOptions.isActiveOnly) {
      schedules = schedules.filter((schedule: Schedule) => schedule.active);
    }
    const selectedTimespanCalendarDateRange: Array<string> = this.schedulesStore.getSelectedTimespanCalendarDateRange();
    if (this.schedulesFilterOptions.isCurrentWeekOnly && selectedTimespanCalendarDateRange) {
      schedules = schedules.filter((schedule: Schedule) => {
        if (
          Moment(selectedTimespanCalendarDateRange[0]).isSameOrBefore(Moment(schedule.spans[0].validityTo)) &&
          Moment(selectedTimespanCalendarDateRange[1]).isSameOrAfter(Moment(schedule.spans[0].validityFrom))
        ) {
          // Check if the schedule has recurrence days in the selected time range
          return schedule.spans.some((span: ScheduleSpan) =>
            span.recurrenceDays.some((day: ScheduleDay) => {
              const weekStart: Moment.Moment = Moment(selectedTimespanCalendarDateRange[0]).startOf('isoWeek');
              const currentDayMoment: Moment.Moment = weekStart.isoWeekday(day);
              return currentDayMoment.isBetween(
                Moment(span.validityFrom).startOf('day'),
                Moment(span.validityTo).endOf('day'),
                undefined,
                '[]'
              );
            })
          );
        }
        return false;
      });
    }
    return schedules;
  }

  @Watch('schedulesStore.selectedSchedule')
  onSchedulesChanged(): void {
    this.activeTab = this.selectedSchedule ? `tab-${this.selectedSchedule}` : 'tab-base';
  }

  /**
   * Sorts the array of schedules by start time, closest to start of day (00:00).
   * And prioritizes active schedules
   * @param schedules Array of schedules to sort
   */
  sortSchedules(schedules: Array<Schedule>): Array<Schedule> {
    return schedules.sort(function (a: Schedule, b: Schedule) {
      const firstScheduleStarTime: number = getMinutesFromTimeString(a.spans[0].recurrenceStart);
      const secondScheduleStarTime: number = getMinutesFromTimeString(b.spans[0].recurrenceStart);
      if (a.active === b.active) {
        return Math.abs(firstScheduleStarTime) - Math.abs(secondScheduleStarTime);
      }
      return a.active ? -1 : 1;
    });
  }

  setActive(selectedSchedule?: string): void {
    if (!selectedSchedule) {
      this.schedulesStore.updateSelectedSchedule('');
      return;
    }
    this.schedulesStore.updateSelectedSchedule(selectedSchedule);
  }

  /**
   * Get schedules to render in the schedules tabs.
   * Schedules will be filtered based on isActiveOnly and isCurrentWeekOnly.
   * Schedules will be sorted by start time, the closest to start of day 00:00.
   */
  get schedulesToRender(): Array<Schedule> {
    let schedulesToRender: Array<Schedule> = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
    schedulesToRender = this.applyFilters(schedulesToRender);
    schedulesToRender = this.sortSchedules(schedulesToRender);
    if (!schedulesToRender.find((schedule: Schedule) => schedule._id === this.selectedSchedule)) {
      this.schedulesStore.updateSelectedSchedule('');
    }
    return schedulesToRender;
  }

  get gondolaSchedules(): Array<Schedule> {
    return this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }

  get selectedSchedule(): string | undefined {
    return this.schedulesStore.selectedSchedule;
  }

  get gondolaTemplate(): Optional<GondolaTemplateModel> {
    return this.gondolaTemplatesStore.getById(this.$route.params.id);
  }
}
