
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Breadcrumb, BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';

@Component({})
export default class Breadcrumbs extends Vue {
  /* DECLARATIONS */
  @Prop()
  toggleDrawer!: () => void;

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();

  /* LIFECYCLE EVENTS */
  /* METHODS */
  /**
   * Used to check if the path is an exception
   * The only exception is the assets page as it uses the id just to navigate without changing the page
   * @param path - The path to check
   */
  checkForException(path: string): boolean {
    return path !== '/assets';
  }

  /**
   * Used to get the class for the breadcrumb
   * The last breadcrumb will have a different class to disable the ellipsis effect
   * @param item - The breadcrumb item
   */
  getBreadcrumbClass(item: Breadcrumb): string {
    const defaultClass: string = 'primary--text breadcrumb';
    return item.path === this.breadcrumbs[this.breadcrumbs.length - 1].path ? `${defaultClass} last` : defaultClass;
  }
  /* GETTERS */
  get breadcrumbs(): Breadcrumb[] {
    return this.breadcrumbsStore.breadcrumbs;
  }

  get areBreadcrumbsVisible(): boolean {
    return this.breadcrumbs.length > 0;
  }
}
