
import { Prop, Vue, Component, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

/**
 * Custom time picker
 * We use this component since we have some specifications in our app:
 *  - Our default value for start time is always 00:00
 *  - We always auto close the modal when the user selects the minutes of the time
 *  - We are always using some validation logic, this component makes the validation agnostic from the actual picker
 */
@Component({})
export default class TimePicker extends Vue {
  /**
   * The icon to prepend to the input
   * @default mdi-clock-time-four-outline
   * @private
   */
  @Prop({ default: 'mdi-clock-time-four-outline' })
  private prependIcon!: string | (() => string);
  /**
   * The initial value for the time picker
   * @default 00:00
   * @private
   */
  @Prop({ default: '00:00' })
  private initialValue!: string;
  /**
   * The label for the input
   * @default empty string
   * @private
   */
  @Prop({ default: '' })
  private label!: TranslateResult | string;
  /**
   * Function that takes the value returned by the time picker and returns an array of errors
   * Expects an empty array if the value is valid
   * @private
   */
  @Prop()
  private validate!: (value: string) => Array<string | TranslateResult>;
  /**
   * Callback function to execute after a valid user input
   * Only triggered if the value is validated
   * @private
   */
  @Prop({
    default: () => {
      // By default, we do nothing
    },
  })
  private callback?: (value: string) => void;
  /**
   * Renders the component as disabled
   * @private
   */
  @Prop({ default: false })
  private isDisabled!: boolean;
  /**
   * Data cy prop used for testing purposes
   * Will be bound to the text input
   * @private
   */
  @Prop({ default: '' })
  private dataCyId?: string;
  /**
   * Max width of the component (e.g. 100% 50%, 150px, 3em)
   * @private
   */
  @Prop()
  private maxWidth?: string;

  private isOpen: boolean = false;
  private value: string = '';
  private errors: Array<string | TranslateResult> = [];

  /* LIFECYCLE EVENTS */

  created(): void {
    this.initialize();
  }

  /* METHODS */

  onChangeOrInput(value: string): void {
    let errors: Array<string | TranslateResult> = [];
    if (this.validate) {
      errors = this.validate(value);
    }
    if (this.callback) {
      this.callback(value);
    }
    this.value = value;
    this.errors = errors;
  }

  onBlur(event: InputEvent): void {
    const value: string = (event.target as HTMLInputElement).value;
    if (value !== this.value) {
      this.onChangeOrInput(value);
    }
  }

  initializeValues(): void {
    this.value = this.initialValue || '00:00';
  }

  initializeErrors(): void {
    this.errors = [];
  }

  initialize(): void {
    this.initializeValues();
    this.initializeErrors();
  }

  onMinuteClick(): void {
    this.isOpen = false;
  }

  @Watch('initialValue', { immediate: true })
  onInitialValueChanged(): void {
    this.initializeValues();
  }
  /* GETTERS */
}
