
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import spacetime from 'spacetime';
import { formatTimezone } from '@client/utils/timezone';
import { TimezoneAutoCompleteEntry } from '@client/components/StoreDetail/Buttons/EditStoreSettingsButton.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import TagReference from '@client/models/SettingsModels/TagReference';
import { Store } from '@client/models';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';

@Component({
  methods: {
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateTextFieldLength,
  },
  components: { SelectTag },
})
export default class WizardStoreSettings extends Vue {
  /* DECLARATIONS */
  @Prop({ type: Object })
  private store!: Store;

  private storesStore: StoresStore = useStoresStore();

  private storeName: string = '';
  private timezone: string = '';
  private currentTags: TagReference[] = [];
  /* LIFECYCLE EVENTS */
  created() {
    this.storeName = this.store.name;
    this.timezone = this.store.timezone;
    this.currentTags = this.store.tags;
  }

  mounted(): void {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
  }

  @Watch('store')
  onStoreChanged(): void {
    this.storeName = this.store.name;
    this.timezone = this.store.timezone;
    this.currentTags = this.store.tags;
  }

  /* METHODS */
  private onTagListChanged(tags: TagReference[]): void {
    this.currentTags = tags;
  }

  @Watch('storeName')
  @Watch('timezone')
  @Watch('currentTags')
  onChange(): void {
    this.$emit('onChange', {
      storeName: this.storeName,
      timezone: this.timezone,
      tags: this.currentTags,
    });
  }

  /* GETTERS */
  get timezones(): TimezoneAutoCompleteEntry[] {
    return Object.keys(spacetime.timezones()).map((timezone: string) => {
      // get GMT offset and add offset as prefix to display text (note that the GMT offset is DST-aware and uses the timezone based on the current datetime)
      const offset: number = spacetime().goto(timezone).timezone().current.offset;
      const offsetPrefix: string = (offset >= 0 && `(GMT +${offset})`) || `(GMT ${offset})`;

      const timezoneFormatted: string = formatTimezone(timezone);
      return {
        displayText: `${offsetPrefix} ${timezoneFormatted}`,
        iana: timezoneFormatted,
      };
    });
  }

  get isLoading(): boolean {
    return this.storesStore.isFetching || this.storesStore.loadingIndicator.update;
  }
}
