
import { Component, Prop, Vue } from 'vue-property-decorator';
import { downloadAsset } from '@client/components/ContentItems/ListItems/utils';
import { MediaFile } from '@client/models/ContentModels/types';

@Component({
  methods: {
    downloadAsset,
  },
})
export default class DownloadButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private mediaFile!: MediaFile;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  onDownload(): void {
    if (!this.mediaFile.downloadFailed && !this.mediaFile.isDownloading && this.mediaFile.percentCompleted === 0) {
      downloadAsset(this.mediaFile);
    }
  }

  /* GETTERS */
}
