
import { Vue, Component, Prop } from 'vue-property-decorator';
import Tag from '@client/models/SettingsModels/Tag.model';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import AffectedEntitiesModal from '@client/components/Settings/Tags/AffectedEntitiesModal.vue';
import { TagReferenceType } from '@common/config/tags/types';

@Component({
  components: { AffectedEntitiesModal, ModalHeader },
})
export default class ReferenceNumberButton extends Vue {
  @Prop()
  private type!: TagReferenceType;
  @Prop()
  private tag!: Tag;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
  get isDisabled(): boolean {
    switch (this.type) {
      case 'store': {
        return this.tag.stores.length === 0;
      }
      case 'section': {
        return this.tag.sections.length === 0;
      }
      case 'template': {
        return this.tag.templates.length === 0;
      }
      case 'contentItems': {
        return this.tag.contentItems.length === 0;
      }
    }
    return false;
  }
  get displayNumber(): number {
    switch (this.type) {
      case 'store': {
        return this.tag.stores.length;
      }
      case 'section': {
        return this.tag.sections.length;
      }
      case 'template': {
        return this.tag.templates.length;
      }
      case 'contentItems': {
        return this.tag.contentItems.length;
      }
    }
    return 0;
  }
  get title(): TranslateResult {
    let referenceType: TranslateResult = '';
    switch (this.type) {
      case 'store': {
        referenceType = this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.items.store.$path);
        break;
      }
      case 'template': {
        referenceType = this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.items.template.$path);
        break;
      }
      case 'section': {
        referenceType = this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.items.section.$path);
        break;
      }
      case 'contentItems': {
        referenceType = this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.items.contentItem.$path);
        break;
      }
    }
    return this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.title.$path, {
      item: referenceType,
    });
  }
}
