
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class HeaderTextFilter extends Vue {
  @Prop()
  private headerText!: string;

  @Prop()
  private defaultValue!: string;

  private filterValue: string = this.defaultValue;

  /**
   * This property indicates that clearing of a filter value will be done through chip displayed above the table
   * If not set, clearing of value will be done inside the v-menu where value is entered.
   */
  @Prop({ default: false })
  private useChips!: boolean;

  clearFilter(): void {
    this.filterValue = '';
  }

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  defaultValueChanged(): void {
    this.filterValue = this.defaultValue;
  }

  get filterButtonColor(): string {
    return !this.useChips && this.filterValue ? 'primary' : '';
  }
}
