import ClientPublishState, { fromJSON, toJSON } from '@client/enums/ClientPublishState';
import { GondolaPublishInformationJSON } from '@common/publishing/types';

/**
 * Gondola publish details
 */
export default class GondolaPublishInformation {
  timestamp: Date;
  message: string;
  state: ClientPublishState;
  railsSucceeded: number;
  railsPending: number;
  railsFailed: number;
  railsAccepted: number;

  constructor(
    state: ClientPublishState,
    railsSucceeded: number = 0,
    railsPending: number = 0,
    railsFailed: number = 0,
    railsAccepted: number = 0,
    timestamp: Date = new Date(),
    message: string = ''
  ) {
    this.timestamp = timestamp;
    this.message = message;
    this.state = state;
    this.railsSucceeded = railsSucceeded;
    this.railsPending = railsPending;
    this.railsFailed = railsFailed;
    this.railsAccepted = railsAccepted;
  }

  toJSON(): GondolaPublishInformationJSON {
    return {
      timestamp: this.timestamp,
      message: this.message,
      state: toJSON(this.state),
      railsSucceeded: this.railsSucceeded,
      railsPending: this.railsPending,
      railsAccepted: this.railsAccepted,
      railsFailed: this.railsFailed,
    };
  }

  static fromJSON(source: GondolaPublishInformationJSON): GondolaPublishInformation {
    return new GondolaPublishInformation(
      fromJSON(source.state),
      source.railsSucceeded,
      source.railsPending,
      source.railsFailed,
      source.railsAccepted,
      source.timestamp,
      source.message
    );
  }
}
