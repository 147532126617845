
import { Component, Vue } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { DeviceSearchResult, DeviceTableItem, Gondola, GondolaTemplate, Store } from '@client/models';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Optional } from '@common/types';
import DeviceDataTable from '@client/components/DeviceOverview/DeviceDataTable.vue';
import { DeviceCSV } from '@client/models/DeviceOverviewModels';
import { convertToCSV } from '@client/utils/CSVUtils';
import { DownloadFormat, downloadFileFromUrl } from '@client/utils/DownloadUtils';

@Component({
  components: {
    DeviceDataTable,
  },
})
export default class DevicesOverviewView extends Vue {
  private items: DeviceTableItem[] = [];
  private selectedItems: DeviceTableItem[] = [];

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private devicesStore: DevicesStore = useDevicesStore();
  private storesStore: StoresStore = useStoresStore();
  private gondolaTemplateStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  async created(): Promise<void> {
    this.breadcrumbsStore.replace({
      path: `${this.$route.fullPath}`,
      title: { key: this.$i18nTranslationKeys.devices.overviewBreadcrumb.$path },
    });
    this.breadcrumbsStore.setCurrentPage(
      this.$t(this.$i18nTranslationKeys.devices.overviewBreadcrumb.$path),
      'mdi-devices'
    );
    await this.storesStore.fetch();
    await this.gondolaTemplateStore.fetch();
    await this.devicesStore.search();
    const devices: DeviceSearchResult[] = this.devicesStore.searchResults;
    this.items = devices.map((device: DeviceSearchResult) => DeviceTableItem.fromDeviceSearchResult(device));
    this.items.forEach((item: DeviceTableItem) => {
      this.populateDeviceStoreAndTemplateInfo(item);
    });
  }

  private populateDeviceStoreAndTemplateInfo(item: DeviceTableItem) {
    if (item.storeId) {
      const store: Optional<Store> = this.storesStore.getStoreById(item.storeId);
      item.storeName = store ? store.name : undefined;
      if (item.sectionId) {
        const section: Optional<Gondola> = this.storesStore.getGondolaById(item.storeId, item.sectionId);
        item.section = section ? section.positionInAisle : undefined;
        item.aisle = section ? section.aisle : undefined;
      }
    }
    if (item.publishTemplateId) {
      const gondolaTemplate: Optional<GondolaTemplate> = this.gondolaTemplateStore.getById(item.publishTemplateId);
      item.publishTemplateName = gondolaTemplate ? gondolaTemplate.name : undefined;
    }
  }

  handleSelectedItems(selectedItems: DeviceTableItem[]) {
    this.selectedItems = selectedItems;
  }

  exportDataToCSV(): void {
    const longIds: Array<string> = this.selectedItems.map((item: DeviceTableItem) => item.longId);
    const deviceCSVs: Array<DeviceCSV> = this.devicesStore.getDeviceCSVByLongIds(longIds);
    const csvContent: string = convertToCSV(deviceCSVs);
    const blob: Blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl: string = URL.createObjectURL(blob);
    const now: Date = new Date();
    const fileName: string = `Devices-${now.toDateString()}`;
    downloadFileFromUrl(objUrl, fileName, DownloadFormat.CSV);
  }
}
