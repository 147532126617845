
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import ReleaseManagementModel from '@client/models/SettingsModels/ReleaseManagement.model';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { Optional } from '@common/types';
import EditButton from '@client/components/Settings/ReleaseManagement/Actions/EditButton.vue';
import DeleteButton from '@client/components/Settings/ReleaseManagement/Actions/DeleteButton.vue';
import AffectedStoresNumberButton from '@client/components/Settings/ReleaseManagement/Actions/AffectedStoresNumberButton.vue';
import AssignButton from '@client/components/Settings/ReleaseManagement/Actions/AssignButton.vue';

@Component({
  components: { AssignButton, AffectedStoresNumberButton, DeleteButton, EditButton },
})
export default class ReleaseManagementTable extends Vue {
  @Prop()
  private releaseConfigurations!: Array<ReleaseManagementModel>;
  @Prop()
  private currentReleaseManagementId: Optional<string>;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private isTableEmbedded!: boolean;

  changeReleaseManagement(releaseManagement: ReleaseManagement) {
    this.$emit('release-management-changed', releaseManagement);
  }

  isAppliedReleaseManagementConfig(item: ReleaseManagement): boolean {
    return this.currentReleaseManagementId === item._id;
  }

  get headers(): CustomDataTableHeader[] {
    if (this.isTableEmbedded) {
      return this.filteredHeaders;
    }
    return this.allHeaders;
  }

  get filteredHeaders(): CustomDataTableHeader[] {
    return this.allHeaders.filter((header: CustomDataTableHeader) => header.value !== 'affectedStores');
  }

  get allHeaders(): CustomDataTableHeader[] {
    return [
      {
        text: '',
        value: 'indicatorOfDefaultReleaseManagement',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.name.$path),
        value: 'name',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.description.$path),
        value: 'description',
        sortable: false,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.email.$path),
        value: 'email',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.qaDelay.$path),
        value: 'qaDelay',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.releaseDelay.$path),
        value: 'releaseDelay',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.affectedStores.$path),
        value: 'affectedStores',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.tableHeaders.actions.$path),
        value: 'actions',
        sortable: false,
      },
    ];
  }
}
