
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class PublishingNextRetryCountdown extends Vue {
  /* DECLARATIONS */
  @Prop()
  private targetTime!: Date;

  countDownText: TranslateResult = '';
  /* LIFECYCLE EVENTS */
  mounted() {
    this.updateCountDownText();
    setInterval(() => {
      this.updateCountDownText();
    }, 1000);
  }

  /* METHODS */
  updateCountDownText() {
    const now: Date = new Date();
    const timeDifferenceInMs: number = this.targetTime.getTime() - now.getTime();
    const minutes: number = Math.floor(timeDifferenceInMs / 1000 / 60);
    const seconds: number = Math.floor(timeDifferenceInMs / 1000 - minutes * 60);
    // If the time is less than 5 seconds, show 'Starting soon...'
    if (seconds <= 5) {
      this.countDownText = this.$t(this.$i18nTranslationKeys.notifications.publishingRetry.startingSoon.$path);
      return;
    }
    this.countDownText = this.$t(this.$i18nTranslationKeys.notifications.publishingRetry.startingIn.$path, {
      minutes: minutes.toString(),
      seconds: seconds.toString(),
    });
  }
  /* GETTERS */
}
