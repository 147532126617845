
import { Component, Vue } from 'vue-property-decorator';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';

@Component({})
export default class NoNotifications extends Vue {
  private eventLogsStore: EventLogsStore = useEventLogsStore();

  get isSearching(): boolean {
    return this.eventLogsStore.isSearching;
  }
}
