import { PublishingDeviceEventUpdate } from '@client/models';
import { EventState, PublishingDeviceEventJSON, PublishingDeviceEventUpdateJSON } from '@common/eventlog/types';

/**
 * notification (event) specific to a device
 */
export default class PublishingDeviceEvent {
  longId: string;
  shortId: string;
  state: EventState;
  transactionIds: Array<string>;
  events: Array<PublishingDeviceEventUpdate>;
  numberOfExpectedSuccessfulContentEvents: number;

  constructor(
    longId: string,
    shortId: string,
    state: EventState,
    transactionIds: Array<string>,
    numberOfExpectedSuccessfulContentEvents: number,
    events: Array<PublishingDeviceEventUpdate>
  ) {
    this.longId = longId;
    this.shortId = shortId;
    this.state = state;
    this.transactionIds = transactionIds;
    this.numberOfExpectedSuccessfulContentEvents = numberOfExpectedSuccessfulContentEvents;
    this.events = events;
  }

  public static fromJSON(source: PublishingDeviceEventJSON): PublishingDeviceEvent {
    const deviceEventUpdates: PublishingDeviceEventUpdate[] = source.events.map(
      (eventUpdate: PublishingDeviceEventUpdateJSON) => PublishingDeviceEventUpdate.fromJSON(eventUpdate)
    );
    return new PublishingDeviceEvent(
      source.longId,
      source.shortId,
      source.state,
      source.transactionIds,
      source.numberOfExpectedSuccessfulContentEvents,
      deviceEventUpdates
    );
  }
}
