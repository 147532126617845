import { EventType, FirmwareReleasedEventLogJSON } from '@common/eventlog/types';
import { BaseEventLog } from '@client/models/EventLogModels/EventLog.model';
import { Device } from '@client/models';
import { DeviceJSON } from '@common/device/types';

export class FirmwareReleasedEventLog extends BaseEventLog {
  build: string;
  version: string;
  branch: string;
  releasedNote: string;
  releasedDatetime: string;
  executionDatetime: string;
  supportedDevices: Array<Device>;
  constructor(
    _id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,

    build: string,
    version: string,
    branch: string,
    releasedNote: string,
    releasedDatetime: string,
    executionDatetime: string,
    supportedDevices: Array<Device>
  ) {
    super(_id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.build = build;
    this.version = version;
    this.branch = branch;
    this.releasedNote = releasedNote;
    this.releasedDatetime = releasedDatetime;
    this.executionDatetime = executionDatetime;
    this.supportedDevices = supportedDevices;
  }

  public static fromJSON(eventLog: FirmwareReleasedEventLogJSON): FirmwareReleasedEventLog {
    const {
      _id,
      azureStoreId,
      storeId,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      build,
      version,
      branch,
      releasedNote,
      releasedDatetime,
      executionDatetime,
      supportedDevices,
    }: FirmwareReleasedEventLogJSON = eventLog;
    return new FirmwareReleasedEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      build,
      version,
      branch,
      releasedNote,
      releasedDatetime,
      executionDatetime,
      supportedDevices.map((supportedDeviceJSON: DeviceJSON) => Device.fromJSON(supportedDeviceJSON))
    );
  }
}
