import { ActiveHoursEventType, StoreActiveHoursDeviceEventJSON } from '@common/eventlog/types';

export class StoreActiveHoursDeviceEvent {
  longId: string;
  shortId: string;
  dimmingLevel: number;
  activeHoursStatus: ActiveHoursEventType;
  dump: string;
  eventExecutionTimestamp: string;
  constructor(
    longId: string,
    shortId: string,
    dimmingLevel: number,
    activeHoursStatus: ActiveHoursEventType,
    dump: string,
    eventExecutionTimestamp: string
  ) {
    this.activeHoursStatus = activeHoursStatus;
    this.longId = longId;
    this.shortId = shortId;
    this.dimmingLevel = dimmingLevel;
    this.dump = dump;
    this.eventExecutionTimestamp = eventExecutionTimestamp;
  }

  public static fromJSON(
    storeActiveHoursDeviceEventJSON: StoreActiveHoursDeviceEventJSON
  ): StoreActiveHoursDeviceEvent {
    const {
      eventExecutionTimestamp,
      activeHoursStatus,
      dump,
      dimmingLevel,
      shortId,
      longId,
    }: StoreActiveHoursDeviceEventJSON = storeActiveHoursDeviceEventJSON;
    return new StoreActiveHoursDeviceEvent(
      longId,
      shortId,
      dimmingLevel,
      activeHoursStatus,
      dump,
      eventExecutionTimestamp
    );
  }
}
