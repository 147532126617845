
import { Component, Vue } from 'vue-property-decorator';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { DisplayedMessage } from '@client/stores/messages/messages';

@Component({})
export default class SnackBar extends Vue {
  /* DECLARATIONS */
  private messagesStore: MessagesStore = useMessagesStore();

  /* LIFECYCLE EVENTS */
  /* METHODS */
  removeSnackBarMessage(messageId: string): void {
    this.messagesStore.removeMessageById(messageId);
  }
  /* GETTERS */
  get pushNotifications(): DisplayedMessage[] {
    return this.messagesStore.displayedMessages;
  }
}
