
import { Vue, Component, Watch } from 'vue-property-decorator';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { PageTab } from '@client/stores/breadcrumbs/types';
import { TABS_HEIGHT } from '@client/components/Layouts/variables';
import { PortalTarget } from 'portal-vue';

@Component({
  components: {
    PortalTarget,
  },
})
export default class PageTitle extends Vue {
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();

  created() {
    this.onEditModeChange();
  }

  @Watch('breadcrumbsStore.currentPage.isEditMode', { immediate: true, deep: true })
  onEditModeChange() {
    const extensionBar: Element = document.getElementsByClassName('v-toolbar__extension')[0];
    if (!extensionBar) {
      return;
    }
    if (this.isEditMode) {
      extensionBar.classList.add('primary', 'darken-1');
    } else {
      extensionBar.classList.remove('primary', 'darken-1');
    }
  }

  get icon(): string {
    return this.breadcrumbsStore.currentPage.icon;
  }

  get title(): TranslateResult {
    return this.breadcrumbsStore.getPageTitle();
  }

  get tabs(): Optional<Array<PageTab>> {
    return this.breadcrumbsStore.currentPage.tabs;
  }

  get tabsHeight(): string {
    return `${TABS_HEIGHT}px`;
  }

  get isEditMode(): boolean {
    return this.breadcrumbsStore.currentPage.isEditMode;
  }

  get iconChipColor(): string {
    return this.breadcrumbsStore.currentPage.isEditMode ? 'white' : 'primary lighten-5';
  }

  get titleColor(): string {
    return this.breadcrumbsStore.currentPage.isEditMode
      ? 'white--text font-weight-regular'
      : 'accentVariant--text text--darken-2';
  }
}
