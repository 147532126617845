import _ from 'lodash';
import { UserAgentApplicationExtended } from './UserAgentApplicationExtended';
import {
  Auth,
  AuthEventFunctions,
  CacheOptions,
  CallbackQueueObject,
  DataObject,
  Graph,
  MSALBasic,
  Options,
} from './types';
import { AuthenticationParameters, AuthError, AuthResponse, ClientAuthError, Configuration } from 'msal';

export class MSAL implements MSALBasic {
  private lib: UserAgentApplicationExtended;
  private passwordResetActive: boolean = false;
  private tokenExpirationTimers: { [key: string]: undefined | number } = {};
  public data: DataObject = {
    isAuthenticated: false,
    accessToken: '',
    idToken: '',
    user: {},
    graph: {},
  };
  public callbackQueue: CallbackQueueObject[] = [];
  private readonly auth: Auth = {
    clientId: '',
    authority: '',
    tenantId: 'common',
    tenantName: 'login.microsoftonline.com',
    validateAuthority: true,
    redirectUri: window.location.href,
    postLogoutRedirectUri: window.location.href,
    navigateToLoginRequestUrl: true,
    requireAuthOnInitialize: false,
    autoRefreshToken: true,
    // onAuthentication: (error: object, response: AuthError) => {},
    // onToken: (error: object, response: AuthError | null) => {},
    // beforeSignOut: () => {},
  };
  private readonly cache: CacheOptions = {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  };
  private readonly request: AuthenticationParameters = {
    scopes: ['user.read'],
  };
  private readonly graph: Graph = {
    callAfterInit: false,
    endpoints: { profile: '/me' },
    baseUrl: 'https://graph.microsoft.com/v1.0',
  };

  constructor(private readonly options: Options) {
    if (!options.auth.clientId) {
      throw new Error('auth.clientId is required');
    }
    this.auth = Object.assign(this.auth, options.auth);
    this.cache = Object.assign(this.cache, options.cache);
    this.request = Object.assign(this.request, options.request);
    this.graph = Object.assign(this.graph, options.graph);

    this.lib = new UserAgentApplicationExtended({
      auth: {
        clientId: this.auth.clientId,
        authority: this.auth.authority || `https://${this.auth.tenantName}/${this.auth.tenantId}`,
        validateAuthority: this.auth.validateAuthority,
        redirectUri: this.auth.redirectUri,
        postLogoutRedirectUri: this.auth.postLogoutRedirectUri,
        navigateToLoginRequestUrl: this.auth.navigateToLoginRequestUrl,
      },
      cache: this.cache,
      system: options.system,
    });

    this.getSavedCallbacks();
    this.executeCallbacks().then(() => {
      // Executed callbacks
    });

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const backRef: this = this;
    // Register Callbacks for redirect flow
    this.lib.handleRedirectCallback((authErr: AuthError, response?: AuthResponse) =>
      backRef.handleRedirectCallback(backRef, authErr, response)
    );

    if (this.auth.requireAuthOnInitialize) {
      this.signIn();
    }
    this.data.isAuthenticated = this.isAuthenticated();
    if (this.data.isAuthenticated) {
      this.data.user = this.lib.getAccount();
      this.acquireToken().then(() => {
        // Token acquired
      });
    }
  }

  handleRedirectCallback(backRef: this, authErr: AuthError, response?: AuthResponse): void {
    // Check for forgot password error
    // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
    if (authErr && authErr.errorMessage.indexOf('AADB2C90118') > -1) {
      try {
        // follow up with routing...
        backRef.passwordResetActive = true;
      } catch (err) {
        console.error(err);
      }
    } else if (
      response &&
      response.tokenType === 'id_token' &&
      response.idToken.claims['acr'] === `${process.env.VUE_APP_B2C_AUTHORITY_POLICY_EDITPROFILE}`.toLocaleLowerCase()
    ) {
      // we have to logout.. the editprofile token can not be used.
      backRef.lib.logout();
    } else if (
      response &&
      response.tokenType === 'id_token' &&
      response.idToken.claims['acr'] === `${process.env.VUE_APP_B2C_AUTHORITY_POLICY_PASSWORDRESET}`.toLocaleLowerCase()
    ) {
      // we have to logout.. the password reset token can not be used.
      backRef.lib.logout();
    } else {
      if (!this.isAuthenticated()) {
        this.saveCallback('auth.onAuthentication', authErr, response);
      } else {
        this.acquireToken().then(() => {
          // Token acquired
        });
      }
    }
  }

  signIn() {
    if (!this.lib.isCallback(window.location.hash) && !this.lib.getAccount()) {
      // request can be used for login or token request, however in more complex situations this can have diverging options
      //
      if (this.passwordResetActive) {
        this.passwordResetActive = false;
        this.lib.loginRedirect({
          authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_PASSWORDRESET}`,
        });
      } else {
        this.lib.loginRedirect(this.request);
      }
    }
  }

  editProfile() {
    this.lib.loginRedirect({
      authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_EDITPROFILE}`,
    });
  }

  async signOut() {
    if (this.options.auth.beforeSignOut) {
      this.options.auth.beforeSignOut(this);
    }
    this.lib.logout();
  }

  isAuthenticated() {
    return !this.lib.isCallback(window.location.hash) && !!this.lib.getAccount();
  }

  async acquireToken(
    request: AuthenticationParameters = this.request,
    retries: number = 0
  ): Promise<AuthResponse | boolean> {
    try {
      //Always start with acquireTokenSilent to obtain a token in the signed in user from cache
      const response: AuthResponse = await this.lib.acquireTokenSilent(request);
      this.handleTokenResponse(null, response);
      return response;
    } catch (e: unknown) {
      const error: ClientAuthError = e as ClientAuthError;
      // Upon acquireTokenSilent failure (due to consent or interaction or login required ONLY)
      // Call acquireTokenRedirect
      if (this.requiresInteraction(error.errorCode)) {
        this.lib.acquireTokenRedirect(request);
      } else if (retries > 0) {
        return new Promise((resolve: (value: boolean | AuthResponse | PromiseLike<boolean | AuthResponse>) => void) => {
          setTimeout(async () => {
            const res: boolean | AuthResponse = await this.acquireToken(request, retries - 1);
            resolve(res);
          }, 60 * 1000);
        });
      }
      return false;
    }
  }

  private handleTokenResponse(error: null, response: AuthResponse) {
    if (error) {
      this.saveCallback('auth.onToken', error, null);
      return;
    }
    let setCallback: boolean = false;
    if (response.tokenType === 'access_token' && this.data.accessToken !== response.accessToken) {
      this.setToken('accessToken', response.accessToken, response.expiresOn, response.scopes);
      setCallback = true;
    }
    if (this.data.idToken !== response.idToken.rawIdToken) {
      const expiration: number = Number.parseInt(response.idToken.expiration);
      this.setToken('idToken', response.idToken.rawIdToken, new Date(expiration * 1000), [this.auth.clientId]);
      setCallback = true;
    }
    if (setCallback) {
      this.saveCallback('auth.onToken', null, response);
    }
  }

  private setTokenOnDataObject(tokenType: string, token: string) {
    if (tokenType == 'accessToken') {
      this.data.accessToken = token;
    } else if (tokenType == 'idToken') {
      this.data.idToken = token;
    }
  }

  private setToken(tokenType: string, token: string, expiresOn: Date, scopes: string[]) {
    const config: Configuration = this.lib.getCurrentConfiguration();
    const tokenRenewalOffsetSeconds: number = config.system?.tokenRenewalOffsetSeconds || 0;
    const expirationOffset: number = tokenRenewalOffsetSeconds * 1000;
    const expiration: number = expiresOn.getTime() - new Date().getTime() - expirationOffset;
    if (expiration >= 0) {
      this.setTokenOnDataObject(tokenType, token);
    }
    if (this.tokenExpirationTimers[tokenType]) clearTimeout(this.tokenExpirationTimers[tokenType]);
    this.tokenExpirationTimers[tokenType] = window.setTimeout(async () => {
      if (this.auth.autoRefreshToken) {
        await this.acquireToken({ scopes }, 3);
      } else {
        this.setTokenOnDataObject(tokenType, '');
      }
    }, expiration);
  }

  private requiresInteraction(errorCode: string) {
    if (!errorCode || !errorCode.length) {
      return false;
    }
    return errorCode === 'consent_required' || errorCode === 'interaction_required' || errorCode === 'login_required';
  }

  // CALLBACKS
  private saveCallback(
    callbackPath: string,
    ...args: ((AuthError | null | undefined) | (AuthResponse | null | undefined))[]
  ) {
    if (_.get(this.options, callbackPath)) {
      const callbackQueueObject: CallbackQueueObject = {
        id: _.uniqueId(`cb-${callbackPath}`),
        callback: callbackPath,
        arguments: args,
      };
      _.remove(this.callbackQueue, (obj: CallbackQueueObject) => obj.id === callbackQueueObject.id);
      this.callbackQueue.push(callbackQueueObject);
      this.storeCallbackQueue();
      this.executeCallbacks([callbackQueueObject]).then(() => {
        // Executed callbacks
      });
    }
  }

  private getSavedCallbacks() {
    const callbackQueueStr: string = this.lib.store.getItem('msal.callbackqueue');
    if (callbackQueueStr) {
      this.callbackQueue = [...this.callbackQueue, ...JSON.parse(callbackQueueStr)];
    }
  }

  private async executeCallbacks(callbacksToExec: CallbackQueueObject[] = this.callbackQueue) {
    if (callbacksToExec.length) {
      for (const i in callbacksToExec) {
        const cb: CallbackQueueObject = callbacksToExec[i];
        const callback: AuthEventFunctions = _.get(this.options, cb.callback);
        try {
          await callback(this, ...cb.arguments);
          _.remove(this.callbackQueue, function (currentCb: { id: string }) {
            return cb.id === currentCb.id;
          });
          this.storeCallbackQueue();
        } catch (e: unknown) {
          const error: Error = e as Error;
          console.warn(`Callback '${cb.id}' failed with error: `, error.message);
        }
      }
    }
  }

  private storeCallbackQueue() {
    if (this.callbackQueue.length) {
      this.lib.store.setItem('msal.callbackqueue', JSON.stringify(this.callbackQueue));
    } else {
      this.lib.store.removeItem('msal.callbackqueue');
    }
  }
}
