
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  ContentItemFilters,
  ContentItemFiltersKeys,
  ContentItemFiltersValues,
} from '@client/models/ContentModels/types';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { ContentType } from '@common/enums';
import Moment from 'moment';
import { DateFilterPrefill } from '@client/components/Filters/DatePicker/types';
import { PrefillOption } from '@client/definitions/dateselector';
import { datePrefillOptions } from '@client/components/Filters/DatePicker/DatePicker.vue';
import { contentItemTypes } from '@client/components/ContentItems/utils';
import TagReference from '@client/models/SettingsModels/TagReference';

/**
 * Component responsible for rendering chips for each active filter
 * If there are more than two active filters, button for clearing all filters is rendered too
 */
@Component({})
export default class ContentItemFiltersChips extends Vue {
  @Prop()
  private contentItemFilters!: ContentItemFilters;
  @Prop()
  private headers!: Array<CustomDataTableHeader>;

  getPropertyTranslation(key: string): Optional<TranslateResult> {
    const headerDefinition: Optional<CustomDataTableHeader> = this.headers.find(
      (header: CustomDataTableHeader) => header.value === key
    );
    return headerDefinition?.text;
  }

  isFilterActive(key: ContentItemFiltersKeys, filterValue: ContentItemFiltersValues): boolean {
    // we use datePrefillOption instead createdAtAsTime for predefined cases so that is not active filter
    if (key === 'datePrefillOption') {
      return false;
    }
    if (!filterValue) {
      return false;
    }
    if (Array.isArray(filterValue) && !filterValue.length) {
      return false;
    }
    return !!this.getDisplayValue(key, filterValue);
  }

  clearFiltersVisible(): boolean {
    let count: number = 0;
    let key: ContentItemFiltersKeys; //can not be const due to implicit any error
    for (key in this.contentItemFilters) {
      const value: ContentItemFiltersValues = this.contentItemFilters[key];
      if (this.isFilterActive(key, value)) {
        count++;
      }
    }
    return count >= 2;
  }

  getDisplayValue(key: ContentItemFiltersKeys, value: ContentItemFiltersValues): string | TranslateResult {
    switch (key) {
      case 'type': {
        const values: ContentType[] = value as ContentType[];
        let output: string = '';
        values.forEach((type: ContentType) => {
          const translatedType: Optional<{ text: TranslateResult; value: ContentType }> = contentItemTypes(this).find(
            (option: { text: TranslateResult; value: ContentType }) => option.value === type
          );
          if (translatedType) {
            output += `${translatedType.text}, `;
          }
        });
        return output.slice(0, output.length - 2);
      }
      case 'originalName': {
        return value as string;
      }
      case 'dimensions': {
        return value as string;
      }
      case 'createdAtAsTime': {
        const values: [string?, string?] = value as [string?, string?];
        if (this.contentItemFilters?.datePrefillOption !== DateFilterPrefill.CUSTOM) {
          return (
            datePrefillOptions().find(
              (prefillOption: PrefillOption) => prefillOption.value === this.contentItemFilters.datePrefillOption
            )?.text || ''
          );
        }
        return `${Moment(values[0]).format('lll')} - ${Moment(values[1]).format('lll')}`;
      }
      case 'fps': {
        return `${value} fps`;
      }
      case 'duration': {
        const values: [number?, number?] = value as [number?, number?];
        if (!values[0] && !values[1]) {
          return '';
        }
        if (values[0] && !values[1]) {
          return this.$tc(this.$i18nTranslationKeys.filtersAndPagination.longerSeconds.$path, values[0] as number);
        }
        if (!values[0] && values[1]) {
          return this.$tc(this.$i18nTranslationKeys.filtersAndPagination.shorterSeconds.$path, values[1] as number);
        }
        return this.$t(this.$i18nTranslationKeys.filtersAndPagination.betweenSeconds.$path, [values[0], values[1]]);
      }
      case 'tags': {
        const values: TagReference[] = value as TagReference[];
        return values.map((tag: TagReference) => tag.name).join(', ');
      }
      default: {
        return value as string;
      }
    }
  }

  clearAll(): void {
    this.$emit('filter-cleared', 'all');
  }

  clearFilter(key: string): void {
    this.$emit('filter-cleared', key);
  }
}
