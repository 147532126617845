import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { GondolaTemplateWithIdJSON } from '@common/gondola-template/types';
import {
  GondolaTemplateAction,
  WsBulkUpdateGondolaTemplateMessage,
  WsGondolaTemplateMessage,
} from '@common/websocket/GondolaTemplate';

/**
 * Handle a websocket message containing a gondola template message
 */
const handleGondolaTemplateMessage = (wsGondolaTemplateMessage: WsGondolaTemplateMessage): void => {
  switch (wsGondolaTemplateMessage.action) {
    case GondolaTemplateAction.BulkUpdate:
      handleBulkUpdateGondolaTemplateMessage(wsGondolaTemplateMessage as WsBulkUpdateGondolaTemplateMessage);
      break;
    case GondolaTemplateAction.Add:
    case GondolaTemplateAction.Delete:
    case GondolaTemplateAction.Update:
    default:
      break;
  }
};

/**
 * Handle a websocket message the updates multiple gondola templates
 */
const handleBulkUpdateGondolaTemplateMessage = (
  wsBulkUpdateGondolaTemplateMessage: WsBulkUpdateGondolaTemplateMessage
): void => {
  const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  wsBulkUpdateGondolaTemplateMessage.payload.gondolaTemplates.forEach(
    (gondolaTemplateJson: GondolaTemplateWithIdJSON) => {
      gondolaTemplatesStore.update(gondolaTemplateJson);
    }
  );
};

export { handleBulkUpdateGondolaTemplateMessage, handleGondolaTemplateMessage };
