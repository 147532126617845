import { ErrorResponse, ErrorType } from '@common/error/types';
import { AxiosError } from 'axios';
import { ErrorStore, useErrorStore } from '@client/stores/error';

/**
 * Function interface for an error handler
 */
export interface ErrorHandler<T = unknown> {
  (error: ErrorResponse<T>): void;
}

/**
 * Class that defines an error observer. Error observers can be registered to observe specific errors.
 * Whenever the backend returns an error with a specific error type all handlers registered to that error type will be triggered.
 * Note: As long as the error observer is not registered, no handlers will be invoked. Call the method {@link register} to register the error observer.
 */
export class ErrorObserver {
  public readonly key: string;
  private errorHandlerMap: Map<string, ErrorHandler<never>[]> = new Map<string, ErrorHandler[]>();

  constructor(key: string) {
    this.key = key;
  }

  /**
   * Attach a new error handler. The handler will be called whenever the middleware detects an error matching the {@param errorType}.
   * @param errorType the error type
   * @param errorHandler the handler function
   */
  attachHandler<T = void>(errorType: ErrorType, errorHandler: ErrorHandler<T>): ErrorObserver {
    const isAlreadyAttached: boolean = this.errorHandlerMap.get(errorType.name)?.includes(errorHandler) || false;
    if (!isAlreadyAttached) {
      this.errorHandlerMap.set(errorType.name, [...(this.errorHandlerMap.get(errorType.name) || []), errorHandler]);
    }
    return this;
  }

  /**
   * Notify all registered handlers that an error with the given error type has occurred.
   * @param errorType the error type
   * @param error the error that was received
   */
  notifyHandler(errorType: ErrorType, error: AxiosError<ErrorResponse>): boolean {
    if (!error || !error.response || !error.response.data) {
      console.warn('Received unhandleable error');
      console.warn(error);
      return true;
    }
    const responseData: ErrorResponse = error.response.data;
    const errorHandlers: ErrorHandler[] | undefined = this.errorHandlerMap.get(errorType.name) as
      | ErrorHandler[]
      | undefined;
    errorHandlers?.forEach((observer: ErrorHandler) => observer(responseData));
    return errorHandlers !== undefined;
  }

  /**
   * Create new error observer.
   * @param key used to identify the error observer
   */
  public static create(key: string): ErrorObserver {
    return new ErrorObserver(key);
  }

  /**
   * Register the error observer to the global registry
   */
  register(): void {
    const errorStore: ErrorStore = useErrorStore();
    errorStore.register(this);
  }
}
