import PublishState from '@common/publishing/PublishState';

/**
 * Enumeration of all publish state used on the client side.
 */
enum ClientPublishState {
  INITIALIZING = 'INITIALIZING',
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
  MISSING_DEVICE_ID = 'MISSING_DEVICE_ID',
  MATCHED = 'MATCHED',
  DIMENSION_MISMATCH = 'DIMENSION_MISMATCH',
  INVALID_HASH = 'INVALID_HASH',
  BACKGROUND_MISSING = 'BACKGROUND_MISSING',
  BACKGROUND_BASELAYER_MISSING = 'BACKGROUND_BASELAYER_MISSING',
  BACKGROUND_SET = 'BACKGROUND_SET',
  PUBLISHING = 'PUBLISHING',
  PUBLISHING_FAILED = 'PUBLISHING_FAILED',
  PUBLISHED = 'PUBLISHED',
  PUBLISHING_DENIED = 'PUBLISHING_DENIED',
  EMPTY_PUBLISHING = 'EMPTY_PUBLISHING',
  DEVICES_NOT_COMPATIBLE = 'DEVICES_NOT_COMPATIBLE',
}

export const fromJSON = (source: PublishState): ClientPublishState => {
  switch (source) {
    case PublishState.ACCEPTED:
      return ClientPublishState.ACCEPTED;
    case PublishState.DIMENSION_MISMATCH:
      return ClientPublishState.DIMENSION_MISMATCH;
    case PublishState.PUBLISHED:
      return ClientPublishState.PUBLISHED;
    case PublishState.PUBLISHING:
      return ClientPublishState.PUBLISHING;
    case PublishState.PUBLISHING_FAILED:
      return ClientPublishState.PUBLISHING_FAILED;
    case PublishState.CANCELLED:
      return ClientPublishState.CANCELLED;
    case PublishState.INITIALIZING:
      return ClientPublishState.INITIALIZING;
    default:
      return ClientPublishState.PUBLISHING_DENIED;
  }
};

export const toJSON = (source: ClientPublishState): PublishState => {
  switch (source) {
    case ClientPublishState.DIMENSION_MISMATCH:
      return PublishState.DIMENSION_MISMATCH;
    case ClientPublishState.PUBLISHED:
      return PublishState.PUBLISHED;
    case ClientPublishState.PUBLISHING:
      return PublishState.PUBLISHING;
    case ClientPublishState.PUBLISHING_FAILED:
      return PublishState.PUBLISHING_FAILED;
    case ClientPublishState.ACCEPTED:
      return PublishState.ACCEPTED;
    case ClientPublishState.INITIALIZING:
      return PublishState.INITIALIZING;
    default:
      return PublishState.INITIALIZING;
  }
};

export default ClientPublishState;
