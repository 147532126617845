import { RouteConfig } from 'vue-router/types/router';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

const DeviceOverviewPage = () => import('@client/views/DevicePages/DevicesOverviewView.vue');
const DeviceDetailPage = () => import('@client/views/DevicePages/DeviceDetailView.vue');

export const deviceRoutes: Array<RouteConfig> = [
  {
    path: '/devices',
    name: 'devices',
    alias: '/',
    component: DeviceOverviewPage,
    meta: {
      title: i18n.t(TranslationKeys.devices.overviewPageTitle.$path),
    },
  },
  {
    path: '/devices/:longId',
    name: 'device detail',
    alias: '/',
    component: DeviceDetailPage,
    meta: {
      title: i18n.t(TranslationKeys.deviceDetail.title.$path),
    },
  },
];
