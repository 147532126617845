
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, DevicePublishing, Gondola as GondolaModel, HardwareModel } from '@client/models';
import { TranslateResult } from 'vue-i18n';
import Moment from 'moment';
import { OnlineStatus } from '@common/enums';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { SectionValidationResult } from '@client/stores/stores/types';
import { SectionDeviceValidationStatusJSON, ValidationErrorType } from '@common/stores/error';
import { ValidationStatus } from '@common/error/types';
import { Optional } from '@common/types';

@Component({})
export default class DevicesGrid extends Vue {
  @Prop()
  private section!: GondolaModel;
  @Prop({ type: Boolean, default: false })
  private isValidating!: boolean;
  @Prop({ type: Object, required: true })
  private displayedSectionValidationResult!: SectionValidationResult;
  @Prop({ type: Array, default: [] })
  private filteredDeviceIds!: Array<string>;

  private sizes: HardwareModel[] = HardwareModel.getAll();
  private messagesStore: MessagesStore = useMessagesStore();
  private devicesStore: DevicesStore = useDevicesStore();

  getContainerClass(device: Device): string {
    if (!device.shortId) {
      return this.getDeviceSize(device);
    }
    return `${this.getDeviceSize(device)} device-info-margin`;
  }

  getDeviceSize(device: Device): string {
    return `device-${device.hardwareModel.identifier}`;
  }

  showOnlineStatus(device: Device): boolean {
    return !!device.onlineStatus;
  }

  getOnlineStatusClass(device: Device): string {
    return device.onlineStatus === OnlineStatus.ONLINE ? 'success' : 'error';
  }

  getDevicePublishing(rowIndex: number, colIndex: number): DevicePublishing | undefined {
    if (this.section.lastPublishing?.railGrid?.[rowIndex]) {
      return this.section.lastPublishing?.railGrid?.[rowIndex][colIndex];
    }
    return undefined;
  }

  showRailGroupIcon(rowIndex: number, colIndex: number): boolean {
    const devicePublishing: DevicePublishing | undefined = this.getDevicePublishing(rowIndex, colIndex);
    return (devicePublishing?.railGroup && !devicePublishing.railGroup.isUnlinked) || false;
  }

  getRailGroupColor(rowIndex: number, colIndex: number): string | undefined {
    const devicePublishing: DevicePublishing | undefined = this.getDevicePublishing(rowIndex, colIndex);
    return devicePublishing?.railGroup.color;
  }

  formatOnlineStatus(device: Device): TranslateResult {
    if (!device.onlineStatus) {
      return '';
    }
    return this.$t(this.$i18nTranslationKeys.device.status[device.onlineStatus].$path, {
      date: Moment(device.onlineStatusLastChanged || new Date()).format('llll'),
    });
  }

  copyLongId(device: Device): void {
    navigator.clipboard.writeText(device.longId);
    this.messagesStore.showMessage(
      this.$t(this.$i18nTranslationKeys.device.copyLongId.$path, { shortId: device.shortId })
    );
  }

  addDeviceRow(): void {
    this.section.railGrid.push([new Device()]);
    this.$emit('change', this.section);
  }

  addDeviceCol(rowIndex: number): void {
    this.section.railGrid[rowIndex].push(new Device());
    this.$emit('change', this.section);
  }

  deleteDevice(rowIndex: number, colIndex: number): void {
    this.section.railGrid[rowIndex].splice(colIndex, 1);
    if (!this.section.railGrid[rowIndex].length) {
      this.section.railGrid.splice(rowIndex, 1);
    }
    this.$emit('change', this.section);
  }

  onChangeSize(rowIndex: number, colIndex: number, value: string): void {
    this.section.railGrid[rowIndex][colIndex].hardwareModel = HardwareModel.getByIdentifier(value);
    this.$emit('change', this.section);
  }

  onDeviceSelected(
    selectedDevice: Device | string | null,
    oldDevice: Device,
    rowIndex: number,
    colIndex: number
  ): void {
    let device: Device = new Device();
    if (!selectedDevice) {
      device.shortId = '';
      device.longId = '';
    } else if (typeof selectedDevice === 'string') {
      const deviceFromStore: Optional<Device> = this.storeDevices.find(
        (device: Device) => device.shortId === selectedDevice
      );
      if (deviceFromStore) {
        device = Device.clone(deviceFromStore);
      } else {
        device.shortId = selectedDevice;
      }
    } else {
      device = selectedDevice;
    }
    this.section.railGrid[rowIndex][colIndex] = device;
    this.$emit('change', this.section);
  }

  /**
   *  Check if the device on the given position has any validation errors set by the server
   */
  getSectionValidationError(row: number, col: number): Array<SectionDeviceValidationStatusJSON> | undefined {
    const sectionValidationResult: Array<SectionDeviceValidationStatusJSON> | undefined =
      this.displayedSectionValidationResult?.errors?.filter(
        (error: SectionDeviceValidationStatusJSON) => error.column === col && error.row === row
      );
    if (
      !sectionValidationResult ||
      !sectionValidationResult.length ||
      sectionValidationResult.every(
        (result: SectionDeviceValidationStatusJSON) => result.severity === ValidationStatus.VALID
      )
    ) {
      return undefined;
    }

    return sectionValidationResult;
  }

  getDeviceValidationError(row: number, col: number): string | undefined {
    const sectionValidationResult: Array<SectionDeviceValidationStatusJSON> | undefined =
      this.getSectionValidationError(row, col);
    if (!sectionValidationResult) {
      return undefined;
    }

    return `${sectionValidationResult
      .map(
        (error: SectionDeviceValidationStatusJSON) =>
          `<li>${
            error.message
              ? this.getValidationErrorTranslatedMessage(error.message.type, error.message.arguments || [])
              : ''
          }</li>`
      )
      .join('')}`;
  }

  /**
   * returns a function, which can be used to determine if a given rail grid slot has an active validation message
   */
  getGondolaValidationErrorMessageEvaluator(
    row: number,
    col: number
  ): [() => string | Array<string> | boolean] | Array<TranslateResult> {
    const validation: Array<SectionDeviceValidationStatusJSON> | undefined = this.getSectionValidationError(row, col);
    if (!validation) {
      return [() => false];
    }
    const validationMessage: Array<TranslateResult> = validation
      .filter((error: SectionDeviceValidationStatusJSON) => !!error.message)
      .map((error: SectionDeviceValidationStatusJSON) =>
        error.message ? this.getValidationErrorTranslatedMessage(error.message.type, error.message.arguments || []) : ''
      );
    return [...validationMessage];
  }

  getValidationErrorTranslatedMessage(type: ValidationErrorType, messageArguments: Array<string>): TranslateResult {
    switch (type) {
      case ValidationErrorType.INVALID_DEVICE_ID:
        return this.$t(
          this.$i18nTranslationKeys.storeDetail.createOrEditSections.validationErrors.invalidDeviceId.$path
        );
      case ValidationErrorType.DEVICE_NOT_FOUND:
        return this.$t(
          this.$i18nTranslationKeys.storeDetail.createOrEditSections.validationErrors.deviceNotFound.$path,
          {
            deviceId: messageArguments[0],
          }
        );
      case ValidationErrorType.LOCAL_DUPLICATE_DEVICE:
        return this.$t(
          this.$i18nTranslationKeys.storeDetail.createOrEditSections.validationErrors.localDuplicateDevice.$path,
          {
            deviceId: messageArguments[0],
          }
        );
      case ValidationErrorType.DEVICE_DUPLICATE:
        return this.$t(
          this.$i18nTranslationKeys.storeDetail.createOrEditSections.validationErrors.deviceDuplicate.$path,
          {
            deviceId: messageArguments[0],
            storeId: messageArguments[1],
            gondolaId: messageArguments[2],
            aisle: messageArguments[3],
            sectionName: messageArguments[4],
          }
        );
      default:
        return '';
    }
  }

  hasValidDeviceLongId(device: Device): boolean {
    return !!device.longId;
  }

  customDeviceFilter(item: Device, queryText: string): boolean {
    return (
      item.shortId.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
      item.hardwareModel.identifier.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  customDeviceValueComparator(itemA: Optional<Device>, itemB: Optional<Device>): boolean {
    if (!itemA || !itemB) {
      return false;
    }
    return itemA.longId === itemB.longId;
  }

  /**
   * Check if the device is missing shortId or longId
   */
  getIsDeviceMissingIds(device: Device): boolean {
    return !device.shortId || !device.longId;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get storeDevices(): Array<Device> {
    return this.devicesStore
      .getDevicesByStoreId(this.storeId)
      .filter((device: Device) => !this.filteredDeviceIds.includes(device.shortId));
  }
}
