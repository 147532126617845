
import { Component, Prop, Vue } from 'vue-property-decorator';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { Wrapper },
})
export default class VisualTimer extends Vue {
  @Prop() timeoutInSeconds!: number;
  @Prop() onTimerEnd!: () => Promise<void> | void;
  @Prop() messageKey!: string;

  private componentVisible: boolean = false;
  private timer: ReturnType<typeof setTimeout> = setTimeout(() => {
    // Empty timer
  }, 1000);
  private time: number = this.timeoutInSeconds;

  public startTimer(): void {
    this.componentVisible = true;
    if (this.time > 0) {
      this.timer = setTimeout(() => {
        this.time -= 1;
        this.startTimer();
      }, 1000);
    } else {
      this.componentVisible = false;
      this.onTimerEnd();
    }
  }

  isComponentVisible(): boolean {
    return this.componentVisible;
  }

  cancelTimer(): void {
    this.componentVisible = false;
    clearTimeout(this.timer);
    this.time = this.timeoutInSeconds;
  }
}
