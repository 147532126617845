import { TranslateResult } from 'vue-i18n';
import { getComputedTranslationString } from '@client/plugins/i18n/TypedI18nPlugin';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { Optional } from '@common/types';

/**
 * Checks whether the passed value lies within the passed min and max values.
 * @param value value to be checked
 * @param min minimum of range
 * @param max maximum of range
 * @param isRequired if checked field is mandatory
 */
export const validateNumberInput = (
  value: Optional<number>,
  min: number,
  max: number,
  isRequired: boolean = true
): boolean | TranslateResult => {
  if (!value) {
    return isRequired ? getComputedTranslationString(TranslationKeys.error.requiredField.$path) : true;
  }
  if (value >= min && value <= max) {
    return true;
  }
  return getComputedTranslationString(TranslationKeys.error.numberOutOfRange.$path, { min: min, max: max });
};
