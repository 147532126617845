
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Device, DeviceTemplate, Gondola, GondolaTemplate, Store } from '@client/models';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import DevicesGrid from '@client/components/Gondola/DevicesGrid.vue';
import { TranslateResult } from 'vue-i18n';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import { SectionValidationError, SectionValidationResultJSON } from '@common/stores/error';
import { AxiosError } from 'axios';
import { SectionValidationResult } from '@client/stores/stores/types';
import { ValidationStatus } from '@common/error/types';
import { getStoreDetailPath } from '@client/router/utils';
import { Portal } from 'portal-vue';
import _ from 'lodash';
import { SectionValidation, validateSections } from '@client/utils/validateSections';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import AssignTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';

@Component({
  components: { AssignTag, SelectTag, Wrapper, DevicesGrid, Portal },
})
export default class EditSection extends Vue {
  /* DECLARATIONS */
  @Prop({ type: Store, required: true })
  private store!: Store;
  @Prop({ type: Object, required: true })
  private sectionToEdit!: Gondola;

  private section: Gondola = new Gondola(
    this.sectionToEdit.aisle,
    this.sectionToEdit.positionInAisle,
    this.sectionToEdit.railGrid
  );

  private sectionValidation: SectionValidationResult = {
    errors: [],
    hasError: false,
    generalSeverity: ValidationStatus.VALID,
  };
  private rerenderKey: number = 0;
  private areSectionsValid: boolean = true;
  private showForceMoveModal: boolean = false;
  private devicesToMoveFromOtherSections: Array<Device> = [];
  private sectionsUsedDeviceIds: Array<string> = [];

  private storesStore: StoresStore = useStoresStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  /* LIFECYCLE EVENTS */
  created(): void {
    this.section = Gondola.clone(this.sectionToEdit);
    this.sectionsUsedDeviceIds = this.sectionToEdit.railGrid.flat().map((device: Device) => device.shortId);
    this.sectionValidation = { errors: [], hasError: false, generalSeverity: ValidationStatus.VALID };
    this.section.tags = this.sectionToEdit.tags.map(
      (tag: TagReference) => new TagReference(tag.id, tag.name, tag.color)
    );
  }

  mounted() {
    this.validateForm();
  }

  @Watch('section', { deep: true })
  onSectionChange(): void {
    const isFormDirty: boolean =
      this.section.positionInAisle !== this.sectionToEdit.positionInAisle ||
      !_.isEmpty(_.xorWith(this.section.railGrid, this.sectionToEdit.railGrid, _.isEqual)) ||
      !_.isEmpty(_.xorWith(this.section.tags, this.sectionToEdit.tags, _.isEqual));
    this.$emit('change', isFormDirty);
    this.$emit('validation', !this.areSectionsInvalid);
  }

  @Watch('areSectionsInvalid')
  onAreSectionsInvalidChange(): void {
    this.$emit('validation', !this.areSectionsInvalid);
  }

  /* METHODS */

  updateSection(section: Gondola): void {
    this.section.railGrid = section.railGrid;
    this.sectionsUsedDeviceIds = this.section.railGrid.flat().map((device: Device) => device.shortId);
    this.validateSections();
    this.onSectionChange();
    this.rerenderKey++;
  }

  getDisplayNameForTemplate(template: GondolaTemplate): string | undefined {
    if (template.name && template.name !== '') {
      return template.name;
    }
    return template._id;
  }

  onTemplateSelected(selectedTemplateId: string, section: Gondola): void {
    const selectedTemplate: Optional<GondolaTemplate> = this.gondolaTemplatesStore.getById(selectedTemplateId);
    if (!selectedTemplate) {
      return;
    }
    section.railGrid = selectedTemplate.railGrid.map((row: Array<DeviceTemplate>) =>
      row.map((cell: DeviceTemplate) => new Device(cell.hardwareModel))
    );
  }

  onTagListChanged(selectedTags: Array<TagReference>) {
    this.section.tags = selectedTags;
  }

  checkSectionNameValidity(value: string): TranslateResult | boolean {
    const valueToCheck: string = value.trim().toLocaleLowerCase();
    if (!valueToCheck) {
      return this.$t(this.$i18nTranslationKeys.error.requiredField.$path);
    }
    const sectionNames: string[] = [];
    this.store.gondolas.forEach((gondola: Gondola) => {
      if (
        gondola.aisle !== this.sectionToEdit.aisle ||
        gondola.positionInAisle.toLocaleLowerCase() === this.sectionToEdit.positionInAisle.toLocaleLowerCase()
      ) {
        return;
      }
      sectionNames.push(gondola.positionInAisle.toLocaleLowerCase());
    });

    if (sectionNames.includes(valueToCheck)) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.createOrEditSections.duplicateSection.$path);
    }
    return true;
  }

  validateSections(): void {
    this.areSectionsValid = true;
    this.devicesToMoveFromOtherSections = [];
    const { devicesToMoveFromOtherSections, areSectionsValid, shouldRerender }: SectionValidation = validateSections(
      [this.section],
      [this.sectionValidation],
      this.store,
      true
    );
    this.devicesToMoveFromOtherSections = devicesToMoveFromOtherSections;
    this.areSectionsValid = areSectionsValid;
    if (shouldRerender) {
      this.rerenderKey++;
    }
  }

  isValidDeviceId(deviceId: string): boolean {
    if (!deviceId) {
      return false;
    }

    return !!deviceId.match(/^[0-9]{5}$/);
  }

  async onSave(): Promise<void> {
    if (this.areSectionsInvalid) {
      return;
    }
    if (this.isValidationForcibleError) {
      this.showForceMoveModal = true;
      return;
    }
    await this.callSave();
    return;
  }

  public async callSave(isForced: boolean = false): Promise<boolean> {
    this.$emit('change', false);
    try {
      const sectionToEdit: Gondola = Gondola.clone(this.section);
      await this.storesStore.editGondola(sectionToEdit, this.store._id, isForced);
      await this.$router.push(getStoreDetailPath(this.store._id, this.section.aisle, this.section.positionInAisle));
      return true;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        this.showForceMoveModal = false;
        if (error.response?.status === 422) {
          this.devicesToMoveFromOtherSections = [];
          const validationError: SectionValidationError = error.response.data as SectionValidationError;
          validationError.details?.validationResult.forEach((validationResult: SectionValidationResultJSON) => {
            this.devicesToMoveFromOtherSections.push(...validationResult.duplicateDevices.map(Device.fromJSON));
            this.sectionValidation = {
              errors: validationResult.errors,
              hasError: validationResult.hasError,
              generalSeverity: validationResult.generalSeverity,
            };
            this.rerenderKey++;
          });
        }
      }
      this.$emit('validation', !this.areSectionsInvalid);
      console.error(error);
      return false;
    }
  }

  onForceMoveAbort(): void {
    this.showForceMoveModal = false;
  }

  async onForceMove(): Promise<void> {
    await this.callSave(true);
  }

  navigateToStore(): void {
    this.$router.push(getStoreDetailPath(this.store._id));
  }

  /**
   * This is used to force the validation of the form
   */
  validateForm(): void {
    this.$nextTick(() => {
      (this.$refs.sections as unknown as { validate: () => void }).validate();
    });
  }

  /* GETTERS */
  get storeName(): string {
    return this.store.name || '';
  }

  get templates(): GondolaTemplate[] {
    return this.gondolaTemplatesStore.gondolaTemplates;
  }

  get isSectionDuplicate(): boolean {
    const storeSectionNames: string[] = this.store.gondolas
      .filter(
        (section: Gondola) => section.aisle === this.sectionToEdit.aisle && section._id !== this.sectionToEdit._id
      )
      .map((section: Gondola) => section.positionInAisle.trim().toLocaleLowerCase());
    return storeSectionNames.includes(this.section.positionInAisle.toLocaleLowerCase());
  }

  get isSectionEmpty(): boolean {
    return this.section.positionInAisle === '';
  }

  get isValidationForcibleError(): boolean {
    return (
      this.sectionValidation.generalSeverity === ValidationStatus.FORCIBLE_ERROR && this.sectionValidation.hasError
    );
  }

  get areSectionsInvalid(): boolean {
    if (this.sectionValidation.hasError && this.sectionValidation.generalSeverity === ValidationStatus.CRITICAL_ERROR) {
      return true;
    }
    return this.isSectionDuplicate || this.isSectionEmpty || !this.areSectionsValid;
  }

  get isLoading(): boolean {
    return this.storesStore.loadingIndicator.update;
  }
}
