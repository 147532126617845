import Vue, { CreateElement } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import MSALPlugin from './plugins/vue-msal/plugin';
import i18n from './plugins/i18n/i18n';
import Component from 'vue-class-component';
import { MSALBasic } from './plugins/vue-msal/src/types';
import { PiniaVuePlugin } from 'pinia';
import pinia from './stores';
import VueRouter from 'vue-router';
import VueTour from 'vue-tour';
import TypedI18nPlugin from './plugins/i18n/TypedI18nPlugin';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from './stores/gondolaTemplates';
import { AppGlobalStore, useAppGlobalStore } from './stores/app-global';
import './styles/__theme.scss';

Vue.config.productionTip = false;

// Install the authentication plugin
Vue.use(MSALPlugin, {
  auth: {
    // https://${process.env.VUE_APP_B2C_TENANT}.b2clogin.com/${process.env.VUE_APP_B2C_TENANT}.onmicrosoft.com/${process.env.VUE_APP_B2C_SIGNUPIN_POLICY}
    authority: `${process.env.VUE_APP_B2C_AUTHORITY_BASE}${process.env.VUE_APP_B2C_AUTHORITY_POLICY_SIGNUP_SIGNIN}`,
    clientId: `${process.env.VUE_APP_B2C_CLIENTID}`,
    redirectUri: `${process.env.VUE_APP_B2C_REDIRECTURI}`,
    validateAuthority: false,
  },
  request: {
    scopes: ['openid'],
  },
  framework: {
    globalMixin: true,
  },
});

// Install the vue tour plugin
Vue.use(VueTour);

// Install pinia plugin
Vue.use(PiniaVuePlugin);

// Install router plugin
Vue.use(VueRouter);

// Install Typed I18n plugin
Vue.use(TypedI18nPlugin);

// Register the router hooks with their names
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave']);

// Create Vue App
export const EventBus: Vue = new Vue({
  i18n,
  pinia,
  router,
  vuetify,
  render: (h: CreateElement) => h(App),
  created() {
    if (!this.$msal.isAuthenticated()) {
      this.$msal.signIn();
    }
    const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
    const globalStore: AppGlobalStore = useAppGlobalStore();
    gondolaTemplatesStore.loadGondolaZoomFromLocalStorage();
    const authService: MSALBasic = this.$msal;

    axios.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
      await authService.acquireToken({ scopes: [`${process.env.VUE_APP_B2C_SCOPE}`] }, 1);

      if (!config.headers) {
        config.headers = new AxiosHeaders();
      }
      config.headers.Authorization = `Bearer ${authService.data.accessToken}`;
      config.headers['x-customer-id'] = globalStore.customer;
      config.params = config.params || {};
      return config;
    });
  },
}).$mount('#app');
