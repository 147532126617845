
import { Component, Vue, Watch } from 'vue-property-decorator';
import { v4 } from 'uuid';

const ACTIVE_CLASS: string = 'button-group-item--active';

@Component({})
export default class ButtonGroup extends Vue {
  /* DECLARATIONS */
  private instanceId: string = v4();
  private activeElementId: string = '';
  private timeoutIds: Array<null | ReturnType<typeof setTimeout>> = [];

  /* LIFECYCLE EVENTS */
  mounted() {
    this.initializeEvent();
  }

  updated(): void {
    this.initializeEvent();
  }

  /* METHODS */
  initializeEvent(): void {
    const defaultClass: Array<string> = ['button-group-item', 'elevation-0'];
    const buttonGroup: HTMLElement = this.$el as HTMLElement;
    const buttons: HTMLCollection = buttonGroup.getElementsByClassName('v-btn');
    // Loop through the buttons and add the default class
    for (let i: number = 0; i < buttons.length; i++) {
      const button: HTMLElement = buttons[i] as HTMLElement;
      button.id = `${this.instanceId}-button-group-item-${i}`;
      const buttonContent: HTMLElement = button.getElementsByClassName('v-btn__content')[0] as HTMLElement;

      for (const childNode of buttonContent.childNodes) {
        // If the child node is a text node, wrap it in a span
        if (childNode.nodeType === 3) {
          const span: HTMLElement = document.createElement('span');
          span.appendChild(childNode);
          buttonContent.appendChild(span);
        }
      }

      button.classList.add(...defaultClass);

      // If the button is the first one, set it as active
      if (i === 0) {
        this.activeElementId = button.id;
        button.classList.add(ACTIVE_CLASS);
      }
      // Add the event listener for the mouseenter event
      button.addEventListener('mouseenter', () => {
        this.removeActiveClass();
        button.classList.add(ACTIVE_CLASS);
        this.cancelTimeout();
      });
      button.addEventListener('mouseleave', () => {
        this.initializeActiveElement();
      });
      button.addEventListener('click', () => {
        button.classList.remove(ACTIVE_CLASS);
        this.initializeActiveElement();
      });
    }
  }

  @Watch('activeElementId')
  onActiveElementIdChange() {
    this.addActiveClass(this.activeElementId);
  }

  /**
   * Removes the active class from the element with the active class
   */
  removeActiveClass(): void {
    const buttonGroup: HTMLElement = this.$el as HTMLElement;
    const activeElements: HTMLCollection = buttonGroup.getElementsByClassName(ACTIVE_CLASS);
    for (let i: number = 0; i < activeElements.length; i++) {
      activeElements[i].classList.remove(ACTIVE_CLASS);
    }
  }

  /**
   * Adds the active class to the element with the given id
   * @param elementId The id of the element to add the active class to
   */
  addActiveClass(elementId: string): void {
    document.getElementById(elementId)?.classList.add(ACTIVE_CLASS);
  }

  /**
   * Initializes the active element after a timeout, setting the first element as active
   */
  initializeActiveElement(): void {
    this.timeoutIds.push(
      setTimeout(() => {
        this.removeActiveClass();
        this.activeElementId = `${this.instanceId}-button-group-item-0`;
        this.addActiveClass(this.activeElementId);
      }, 1000)
    );
  }

  cancelTimeout(): void {
    if (this.timeoutIds.length === 0) {
      return;
    }
    this.timeoutIds.forEach((timeoutId: null | ReturnType<typeof setTimeout>) => {
      if (!timeoutId) {
        return;
      }
      clearTimeout(timeoutId);
    });
    this.timeoutIds = this.timeoutIds.filter((timeoutId: null | ReturnType<typeof setTimeout>) => timeoutId !== null);
  }

  /* GETTERS */
}
