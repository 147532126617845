
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Store } from '@client/models';
import { StoreActiveHours } from '@client/components/ActiveHours';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import TimeSpanCalendar from '@client/components/TimeSpanCalendar/TimeSpanCalendar.vue';
import Schedule from '@client/models/ScheduleModels/Schedule.model';
import { Success } from '@client/styles/colors';
import { ScheduleVisibility } from '@common/schedule/types';
import { activeHoursScheduleToScheduleSpans } from '@client/utils/ActiveHoursUtils';
import EditStoreSettingsButton from '@client/components/StoreDetail/Buttons/EditStoreSettingsButton.vue';

@Component({
  components: { EditStoreSettingsButton, TimeSpanCalendar, StoreActiveHours },
})
export default class ActiveHoursSettings extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  // This key is used to force the TimeSpanCalendar to rerender when the active hours are updated
  private rerenderKey: number = 0;

  private activeHours: Schedule = new Schedule(
    '',
    'activeHoursSchedule',
    Success._500,
    '',
    [],
    ScheduleVisibility.PUBLIC,
    [],
    true
  );

  private storesStore: StoresStore = useStoresStore();
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    this.updateStoreActiveHours();
  }
  /* METHODS */
  updateStoreActiveHours(): void {
    this.activeHours.spans = activeHoursScheduleToScheduleSpans(this.store.activeHours);
    this.rerenderKey++;
  }

  @Watch('store.activeHours', { deep: true })
  updateActiveHoursAfterFetching(): void {
    this.updateStoreActiveHours();
  }

  /* GETTERS */
  get storeActiveHourJobFailed(): boolean {
    const currentStore: Optional<Store> = this.storesStore.getStoreById(this.store._id);
    if (!currentStore) {
      return false;
    }
    return currentStore.activeHoursJobFailed || false;
  }
}
