import Aisle from './Aisle.model';
import Gondola from '../GondolaModels/Gondola.model';
import { AisleJSON, StoreResponseJSON } from '@common/stores/types';
import { Device } from '@client/models';
import { OnlineStatus, ScheduleDay } from '@common/enums';
import { ActiveHourItemJSON } from '@common/active-hours/types';
import { ActiveHourDay, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import ActiveHourItem from '../ActiveHoursModels/ActiveHourItem.model';
import ConfigReference from '@client/models/SettingsModels/ConfigReference';
import TagReference from '@client/models/SettingsModels/TagReference';

export interface StoreDeviceStatistics {
  numberOfOnlineDevices: number;
  numberOfOfflineDevices: number;
  numberOfUnassignedDevices: number;
}

export default class Store {
  _id: string;
  name: string;
  hash: string;
  customerId: string;
  aisles: Aisle[];
  idAzure: string;
  timezone: string;
  activeHours: ActiveHoursSchedule;
  numberOfOnlineDevices: number = 0;
  numberOfOfflineDevices: number = 0;
  numberOfUnassignedDevices: number = 0;
  activeHoursJobFailed?: boolean;
  releaseManagementReference?: ConfigReference;
  activeHoursTransactionId?: string;
  isStoreSyncing: boolean = false;
  useEmailSubscription: boolean = false;
  tags: Array<TagReference> = [];

  constructor(
    _id: string,
    name: string,
    customerId: string,
    timezone: string = '',
    aisles: Aisle[] = [],
    activeHours: ActiveHoursSchedule = [],
    idAzure: string = '',
    hash: string = '',
    activeHoursJobFailed: boolean = false,
    releaseManagementReference: ConfigReference = { id: '', name: '' },
    activeHoursTransactionId: string = '',
    useEmailSubscription: boolean = false,
    tags: Array<TagReference> = []
  ) {
    this.initializeActiveHours();
    this.name = name;
    this._id = _id;
    this.customerId = customerId;
    this.timezone = timezone;
    this.aisles = aisles;
    this.activeHours = activeHours;
    if (this.activeHours.length === 0) {
      this.initializeActiveHours();
    }
    this.idAzure = idAzure;
    this.hash = hash;
    const devices: Array<Device> = this.gondolas.map((gondola: Gondola) => gondola.railGrid.flat(1)).flat(1);
    this.numberOfUnassignedDevices = devices.filter((device: Device) => !device.gondolaId).length;
    this.numberOfOnlineDevices = devices.filter(
      (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.ONLINE && device.shortId
    ).length;
    this.numberOfOfflineDevices = devices.filter(
      (device: Device) => device.gondolaId && device.onlineStatus === OnlineStatus.OFFLINE && device.shortId
    ).length;
    this.activeHoursJobFailed = activeHoursJobFailed;
    this.releaseManagementReference = releaseManagementReference;
    this.activeHoursTransactionId = activeHoursTransactionId;
    this.useEmailSubscription = useEmailSubscription;
    this.tags = tags;
  }

  setStoreSyncing(isStoreSyncing: boolean): void {
    this.isStoreSyncing = isStoreSyncing;
  }

  setDevicesStatistics({
    numberOfUnassignedDevices,
    numberOfOfflineDevices,
    numberOfOnlineDevices,
  }: StoreDeviceStatistics): void {
    this.numberOfOnlineDevices = numberOfOnlineDevices;
    this.numberOfOfflineDevices = numberOfOfflineDevices;
    this.numberOfUnassignedDevices = numberOfUnassignedDevices;
  }

  initializeActiveHours(): void {
    if (!this.activeHours || this.activeHours.length === 0) {
      this.activeHours = [];
    }
    Object.values(ScheduleDay).forEach((day: ScheduleDay) => {
      this.activeHours.push(new ActiveHourItem(day, [new ActiveHourDay('00:00', '24:00', 100)]));
    });
  }

  get numberOfAisles(): number {
    return this.aisles.length;
  }

  get numberOfGondolas(): number {
    return this.gondolas.length;
  }
  /**
   * Return a list of all gondolas inside the aisles to easily have access
   */
  get gondolas(): Gondola[] {
    return this.aisles.flatMap((aisle: Aisle) => aisle.gondolas);
  }

  /**
   * Creates a deep copy of the store
   * @param source
   */
  static clone(source: Store): Store {
    const clonedAisles: Aisle[] = source.aisles.map((value: Aisle) => {
      return Aisle.clone(value);
    });

    return new Store(
      source._id,
      source.name,
      source.customerId,
      source.timezone,
      clonedAisles,
      source.activeHours,
      source.idAzure,
      source.hash,
      source.activeHoursJobFailed,
      source.releaseManagementReference,
      source.activeHoursTransactionId,
      source.useEmailSubscription,
      source.tags
    );
  }

  static fromJSON(source: StoreResponseJSON): Store {
    const newAisles: Aisle[] = source.aisles.map((value: AisleJSON) => {
      return Aisle.fromJSON(value);
    });

    const newActiveHours: ActiveHoursSchedule = source.activeHours.map((item: ActiveHourItemJSON) =>
      ActiveHourItem.fromJSON(item)
    );

    return new Store(
      source._id,
      source.name,
      source.customerId,
      source.timezone,
      newAisles,
      newActiveHours,
      source.idAzure,
      source.hash,
      source.activeHoursJobFailed,
      source.releaseManagementReferenceJSON,
      source.activeHoursTransactionId,
      source.useEmailSubscription,
      source.tagsReference.map((tag: TagReference) => TagReference.fromJSON(tag))
    );
  }
}
