
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola } from '@client/models';
import router from '@client/router';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class PublishButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondola!: Gondola;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private isDisabled!: boolean;
  @Prop()
  private isMissingAzureId!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  publishGondola(gondolaId: string): void {
    router.push(`/publish/${this.storeId}/${gondolaId}`);
  }
  /* GETTERS */
  get storeId(): string {
    return this.$route.params.storeid;
  }

  get buttonTooltip(): TranslateResult {
    if (this.isMissingAzureId) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.missingAzureId.$path);
    }
    if (this.isDisabled) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.apimActionsDisabled.$path);
    }
    return this.$t(this.$i18nTranslationKeys.action.publish.$path);
  }
}
