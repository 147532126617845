/**
 * Code taken from https://github.com/bsalex/typed-path
 * This is an updated version that we adapted to our code
 */
export type TypedPathKey = string | symbol | number;

function appendStringPathChunk(path: string, chunk: TypedPathKey): string {
  if (typeof chunk === 'number') {
    return `${path}[${chunk}]`;
  } else {
    return appendStringSymbolChunkToPath(path, chunk);
  }
}

function appendStringSymbolChunkToPath(path: string, chunk: string | symbol) {
  return `${path}${path === '' ? '' : '.'}${chunk.toString()}`;
}

/**
 * Returns the path as a valid object string
 * Example : ['foregroundContent', 3, 'scheduledContent', 2, 'baseLayer', 'name'] => `foregroundContent[3].scheduledContent[2].baseLayer.name`
 * @param path properties path as an array Example: for a call with `this.is.a.property.in.an.object` ['this','is','a','property','in','an','object']
 * It works also with arrays `foregroundContent[3].scheduledContent[2].baseLayer.name` => ['foregroundContent', 3, 'scheduledContent', 2, 'baseLayer', 'name']
 */
function pathToString(path: TypedPathKey[]): string {
  return path.reduce<string>((current: string, next: TypedPathKey) => {
    return appendStringPathChunk(current, next);
  }, '');
}

export type TypedPathFunction<ResultType> = (...args: unknown[]) => ResultType;

export type TypedPathHandlersConfig = Record<string, (path: TypedPathKey[]) => unknown>;

const defaultHandlersConfig: {
  $rawPath: (path: TypedPathKey[]) => TypedPathKey[];
  [Symbol.toStringTag]: (path: TypedPathKey[]) => string;
  $path: (path: TypedPathKey[]) => string;
  valueOf: (path: TypedPathKey[]) => () => string;
  toString: (path: TypedPathKey[]) => () => string;
} = {
  $path: (path: TypedPathKey[]) => pathToString(path),
  $rawPath: (path: TypedPathKey[]) => path,
  toString: (path: TypedPathKey[]) => () => pathToString(path),
  [Symbol.toStringTag]: (path: TypedPathKey[]) => pathToString(path),
  valueOf: (path: TypedPathKey[]) => () => pathToString(path),
};

export type DefaultHandlers = typeof defaultHandlersConfig;

export type TypedPathHandlers<ConfigType extends TypedPathHandlersConfig> = {
  [key in keyof ConfigType]: ReturnType<ConfigType[key]>;
};

/**
 * This type "adds" the handlers to any property passed from the OriginalType (Only to the type, this is basically faking it since these new handlers can only be called on the proxy)
 * This basically adds the properties of {@link DefaultHandlers} to all properties of the OriginalType
 */
export type TypedPathWrapper<
  OriginalType,
  HandlersType extends TypedPathHandlers<Record<never, never>>
> = (OriginalType extends Array<infer OriginalArrayItemType>
  ? {
      [index: number]: TypedPathWrapper<OriginalArrayItemType, HandlersType>;
    }
  : OriginalType extends TypedPathFunction<infer OriginalFunctionResultType>
  ? {
      (): TypedPathWrapper<OriginalFunctionResultType, HandlersType>;
    } & {
      [P in keyof Required<OriginalFunctionResultType>]: TypedPathWrapper<OriginalFunctionResultType[P], HandlersType>;
    }
  : {
      [P in keyof Required<OriginalType>]: TypedPathWrapper<OriginalType[P], HandlersType>;
    }) &
  TypedPathHandlers<HandlersType>;

/**
 * Returns the array key to number
 * @param key object property key as {@link TypedPathKey}
 */
function convertNumericKeyToNumber(key: TypedPathKey): TypedPathKey {
  if (typeof key === 'string') {
    const keyAsNumber: number = +key;
    if (keyAsNumber === keyAsNumber) {
      return keyAsNumber;
    }
  }

  return key;
}

/**
 * Searches for a handler by name in the called property
 * @param name name of the handler
 */
function getHandlerByNameKey(name: TypedPathKey) {
  if (defaultHandlersConfig[name as keyof typeof defaultHandlersConfig]) {
    return defaultHandlersConfig[name as keyof typeof defaultHandlersConfig];
  }
}

const emptyObject: object = {};

/**
 * Recursive function that returns the called object as a string path
 * This has to be called with one of the methods defined in {@link DefaultHandlers} or else it will return a proxy instead of a string path
 * This is using a proxy, a proxy allows us to change the normal behavior of any javascript object, in this case we want to change the get property of any property given
 * @param path properties path as an array Example: for a call with `this.is.a.property.in.an.object` ['this','is','a','property','in','an','object']
 * It works also with arrays `foregroundContent[3].scheduledContent[2].baseLayer.name` => ['foregroundContent', 3, 'scheduledContent', 2, 'baseLayer', 'name']
 */
export function typedPath<OriginalObjectType, HandlersType extends TypedPathHandlersConfig = Record<never, never>>(
  path: TypedPathKey[] = []
): TypedPathWrapper<OriginalObjectType, HandlersType & DefaultHandlers> {
  return <TypedPathWrapper<OriginalObjectType, HandlersType & DefaultHandlers>>new Proxy(emptyObject, {
    get(_target: unknown, name: TypedPathKey) {
      const handler:
        | ((path: TypedPathKey[]) => TypedPathKey[])
        | ((path: TypedPathKey[]) => string)
        | ((path: TypedPathKey[]) => () => string)
        | undefined = getHandlerByNameKey(name);
      /**
       If no handler is called See {@link DefaultHandlers} keep adding to the path array
       */
      return handler ? handler(path) : typedPath([...path, convertNumericKeyToNumber(name)]);
    },
  });
}
