
import { Component, Prop, Vue } from 'vue-property-decorator';
import router from '@client/router';

@Component({})
export default class AddSectionsButton extends Vue {
  /* DECLARATIONS */
  @Prop({ type: String, required: true })
  aisle!: string;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  navigateToAddSections(): void {
    router.push(`/stores/${this.storeId}/${this.aisle}/sections/create`);
  }
  /* GETTERS */
  get storeId(): string {
    return this.$route.params.storeid;
  }
}
