import { Device, Gondola, GondolaPublishing } from '@client/models';
import { ClientPublishState } from '@client/enums';
import isGondolaPublishingUpToDate from '@client/utils/isGondolaPublishingUpToDate';
import { Optional } from '@common/types';

/**
 * Possible states of publishing for one specific section
 */
export enum PublishedStateFilter {
  PUBLISHED = 'PUBLISHED',
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  FAILED = 'FAILED',
  NOT_SET = 'NOT_SET',
}

/**
 * Filter object for querying {@link SectionForPublishing}
 */
export type SectionForPublishingFilter = {
  storeName?: string;
  storeAzureId?: string;
  aisle?: string;
  section?: string;
  lastPublishedOn: [string?, string?];
  lastPublishedTemplateName?: string;
  lastPublishedTemplateState?: PublishedStateFilter | null;
};

/**
 * Object which collects data of section and its latest publishing if publishing occurred
 * Boolean selected is used in table, while selecting element for publish/republish action
 */
export default class SectionForPublishing {
  storeName: string;
  storeAzureId: string;
  storeId: string;
  aisle: string;
  section: string;
  gondolaId: string;
  lastPublishedOn?: Date;
  lastPublishedTemplateId?: string;
  lastPublishedTemplateName?: string;
  lastPublishedTemplateState?: ClientPublishState;
  isPublishUpToDate?: boolean;
  selected: boolean;
  isSelectable: boolean;

  private constructor(
    storeName: string,
    storeAzureId: string,
    storeId: string,
    aisle: string,
    section: string,
    gondolaId: string,
    isSelectable: boolean
  ) {
    this.storeName = storeName;
    this.storeAzureId = storeAzureId;
    this.storeId = storeId;
    this.aisle = aisle;
    this.section = section;
    this.gondolaId = gondolaId;
    this.selected = false;
    this.isSelectable = isSelectable;
  }

  setPublishingInformation(gondolaPublishing: GondolaPublishing, templateName: string): void {
    this.lastPublishedOn = gondolaPublishing.publishInformation.timestamp;
    this.lastPublishedTemplateId = gondolaPublishing.gondolaTemplateId;
    this.lastPublishedTemplateName = templateName;
    this.lastPublishedTemplateState = gondolaPublishing.publishInformation.state;
    this.isPublishUpToDate = isGondolaPublishingUpToDate(gondolaPublishing);
  }

  setSelected(newValue: boolean): void {
    this.selected = newValue;
  }

  /**
   * Check whether section has all elements with assigned short ID
   * @param optionalSection section to be checked
   */
  static canSectionBePublished(optionalSection: Optional<Gondola>): boolean {
    if (!optionalSection) {
      return false;
    }
    return optionalSection.railGrid.flat(1).every((device: Device) => device.shortId);
  }

  static fromGondolaPublishing(
    gondolaPublishing: GondolaPublishing,
    storeName: string,
    storeId: string,
    templateName: string,
    isSelectable: boolean
  ): SectionForPublishing {
    const sectionForPublishing: SectionForPublishing = new SectionForPublishing(
      storeName,
      gondolaPublishing.storeIdAzure,
      storeId,
      gondolaPublishing.aisle,
      gondolaPublishing.positionInAisle,
      gondolaPublishing.gondolaId,
      isSelectable
    );
    sectionForPublishing.setPublishingInformation(gondolaPublishing, templateName);
    return sectionForPublishing;
  }

  static fromGondola(
    gondola: Gondola,
    storeName: string,
    storeAzureId: string,
    storeId: string,
    isSelectable: boolean
  ): SectionForPublishing {
    return new SectionForPublishing(
      storeName,
      storeAzureId,
      storeId,
      gondola.aisle,
      gondola.positionInAisle,
      gondola._id,
      isSelectable
    );
  }
}
