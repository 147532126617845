import { PublishingEventLog } from '@client/models/EventLogModels/PublishingEventLog/PublishingEventLog.model';
import { DeviceStatusEventLog } from '@client/models/EventLogModels/DeviceStatusEventLog/DeviceStatusEventLog.model';
import { StoreActiveHoursEventLog } from '@client/models/EventLogModels/StoreActiveHoursEventLog/StoreActiveHoursEventLog.model';
import { SectionActiveHoursEventLog } from '@client/models/EventLogModels/SectionActiveHoursEventLog/SectionActiveHoursEventLog.model';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayer from '@client/models/ScheduleModels/ScheduleLayer.model';
import { LayerPlaylistItem } from '@client/models';
import { EventLogJSON } from '@common/eventlog/types';
import {
  isActiveHoursConfigEventLogJSON,
  isDeviceStatusEventLogJSON,
  isFirmwareChangedEventLogJSON,
  isFirmwareReleasedEventLogJSON,
  isPublishEventLogJSON,
  isSectionActiveHoursEventLogJSON,
  isStoreActiveHoursEventLogJSON,
  isStoreSyncEventLogJSON,
} from '@common/eventlog/utils';
import { BaseEventLog } from '@client/models/EventLogModels/EventLog.model';
import { ActiveHoursConfigEventLog } from '@client/models/EventLogModels/ActiveHoursConfigEventLog/ActiveHoursConfigEventLog.model';
import { FirmwareReleasedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareReleasedEvent.modal';
import { FirmwareChangedEventLog } from '@client/models/EventLogModels/ReleaseManagementEventLog/FirmwareChangedEventLog.modal';
import { StoreSyncEventLog } from '@client/models/EventLogModels/StoreSyncEventLog/StoreSyncEventLog.model';

export const eventLogFromJSON = (eventLogJSON: EventLogJSON): EventLog => {
  if (isPublishEventLogJSON(eventLogJSON)) {
    return PublishingEventLog.fromJSON(eventLogJSON);
  } else if (isStoreActiveHoursEventLogJSON(eventLogJSON)) {
    return StoreActiveHoursEventLog.fromJSON(eventLogJSON);
  } else if (isSectionActiveHoursEventLogJSON(eventLogJSON)) {
    return SectionActiveHoursEventLog.fromJSON(eventLogJSON);
  } else if (isDeviceStatusEventLogJSON(eventLogJSON)) {
    return DeviceStatusEventLog.fromJSON(eventLogJSON);
  } else if (isActiveHoursConfigEventLogJSON(eventLogJSON)) {
    return ActiveHoursConfigEventLog.fromJSON(eventLogJSON);
  } else if (isStoreSyncEventLogJSON(eventLogJSON)) {
    return StoreSyncEventLog.fromJSON(eventLogJSON);
  } else if (isFirmwareReleasedEventLogJSON(eventLogJSON)) {
    return FirmwareReleasedEventLog.fromJSON(eventLogJSON);
  } else if (isFirmwareChangedEventLogJSON(eventLogJSON)) {
    return FirmwareChangedEventLog.fromJSON(eventLogJSON);
  } else {
    return BaseEventLog.fromJSON(eventLogJSON);
  }
};

export type EventLog =
  | BaseEventLog
  | PublishingEventLog
  | DeviceStatusEventLog
  | StoreActiveHoursEventLog
  | SectionActiveHoursEventLog;

export type NotificationLayer = BaseLayerModel | ScheduleLayer | LayerPlaylistItem | undefined;

export interface NotificationEntryMessage {
  message: string;
  layer: NotificationLayer;
}
