import Device from './Device.vue';
import DeviceCanvas from './DeviceCanvas.vue';
import DeviceActiveStatusSwitch from './DeviceActiveStatusSwitch.vue';
import ScheduleActiveStatusSwitch from './DeviceActiveStatusSwitch.vue';
import { DeviceContentDetails, ContentPreview } from './CurrentSelectedContentDetails';

export {
  Device,
  DeviceCanvas,
  DeviceActiveStatusSwitch,
  ScheduleActiveStatusSwitch,
  DeviceContentDetails,
  ContentPreview,
};
