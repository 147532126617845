import { BaseEventLog } from '@client/models/EventLogModels/EventLog.model';
import { Device } from '@client/models';
import { EventType, StoreSyncEventLogJSON } from '@common/eventlog/types';

export class StoreSyncEventLog extends BaseEventLog {
  // Whether the sync was manual or triggered from a sync job
  manual: boolean;
  // The devices that were added in the sync
  addedDevices: Array<Device>;
  // The devices that were removed in the sync
  removedDevices: Array<Device>;
  // The devices that were updated in the sync
  updatedDevices: Array<Device>;
  // Whether the webhook has been recreated after the sync
  isWebhookSynced: boolean;

  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    manual: boolean,
    addedDevices: Array<Device>,
    removedDevices: Array<Device>,
    updatedDevices: Array<Device>,
    isWebhookSynced: boolean
  ) {
    super(id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.manual = manual;
    this.addedDevices = addedDevices;
    this.removedDevices = removedDevices;
    this.updatedDevices = updatedDevices;
    this.isWebhookSynced = isWebhookSynced;
  }

  public static fromJSON(eventLogJSON: StoreSyncEventLogJSON): StoreSyncEventLog {
    const {
      _id,
      azureStoreId,
      storeId,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      manual,
      addedDevices,
      removedDevices,
      updatedDevices,
      isWebhookSynced,
    }: StoreSyncEventLogJSON = eventLogJSON;
    return new StoreSyncEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      manual,
      addedDevices.map(Device.fromJSON),
      removedDevices.map(Device.fromJSON),
      updatedDevices.map(Device.fromJSON),
      isWebhookSynced
    );
  }
}
