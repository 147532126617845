
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Preview as DevicePreview } from '@client/components';
import { Optional } from '@common/types';
import { GondolaPublishing, Device, DevicePublishing } from '@client/models';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';

@Component({
  components: {
    DevicePreview,
  },
})
export default class Preview extends Vue {
  /* DECLARATIONS */
  @Prop()
  private lastPublishing!: GondolaPublishing;
  @Prop()
  private device!: Device;

  private schedulesStore: SchedulesStore = useSchedulesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get devicePublishing(): Optional<DevicePublishing> {
    return this.lastPublishing.railGrid
      ?.flat<DevicePublishing[][]>(1)
      .find((device: DevicePublishing) => device.longId === this.device.longId);
  }
  get getSize(): string {
    return `device-${this.device.hardwareModel.identifier}`;
  }

  get deviceSelectedSchedule(): string {
    return this.schedulesStore.selectedSchedule;
  }
}
