import { defineStore, Store, StoreDefinition } from 'pinia';

export interface WebsocketActions {
  setSocket(socket: WebSocket): void;
  setReconnectTimeout(timeout: ReturnType<typeof setTimeout>): void;
  clearReconnectTimeout(): void;
}

export interface WebsocketState {
  socket?: WebSocket;
  reconnectTimeout?: ReturnType<typeof setTimeout>;
}

export type WebsocketStore = Store<'websocket', WebsocketState, Record<string, never>, WebsocketActions>;

export const useWebsocketStore: StoreDefinition<
  'websocket',
  WebsocketState,
  Record<string, never>,
  WebsocketActions
> = defineStore('websocket', {
  state: (): WebsocketState => ({
    socket: undefined,
    reconnectTimeout: undefined,
  }),
  actions: {
    setSocket(socket: WebSocket) {
      this.socket = socket;
    },
    setReconnectTimeout(timeout: ReturnType<typeof setTimeout>) {
      this.reconnectTimeout = timeout;
    },
    clearReconnectTimeout() {
      this.reconnectTimeout = undefined;
    },
  },
});
