
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceTemplate } from '@client/models';
import DeviceLayerList from '@client/components/Device/CurrentSelectedContentDetails/DeviceLayerList.vue';
import DeviceLayerSelectionScheduleList from '@client/components/Device/CurrentSelectedContentDetails/DeviceLayerSelectionScheduleList.vue';
import DeviceLayerSelectionContentDetails from '@client/components/Device/CurrentSelectedContentDetails/DeviceLayerSelectionContentDetails.vue';

/**
 * Wraps the three main components that show and allow editing the content of the selected gondola template:
 * {@link DeviceLayerList} List of labels
 * {@link DeviceLayerSelectionScheduleList} List of schedules & Add schedule action
 * {@link DeviceLayerSelectionContentDetails} Currently selected content (by layer and schedule) / Actions to edit the selected content
 */
@Component({
  components: {
    DeviceLayerSelectionContentDetails,
    DeviceLayerSelectionScheduleList,
    DeviceLayerList,
  },
})
export default class DeviceContentDetails extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
}
