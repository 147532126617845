
import { Vue, Component, Prop } from 'vue-property-decorator';
import QRCode from '@client/components/QRCode/QRCode.vue';
import { Store } from '@client/models';
import {
  copyQRCodeToClipboard,
  downloadQRCodeAsPNG,
  downloadQRCodeAsSVG,
  getQRCodeFileName,
  getStoreQRCodeData,
  isClipboardAPICompatibleWithBrowser,
} from '@client/components/QRCode/utils';
import QRCodeDownloadButton from '@client/components/Store/Actions/QRCodeDownloadButton.vue';
import QRCodeCopyButton from '@client/components/Store/Actions/QRCodeCopyButton.vue';

@Component({
  methods: {
    getQRCodeFileName,
    downloadQRCodeAsPNG,
    downloadQRCodeAsSVG,
    copyQRCodeToClipboard,
    isClipboardAPICompatibleWithBrowser,
  },
  components: { QRCodeCopyButton, QRCodeDownloadButton, QRCode },
})
export default class QRCodeOverlay extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  /* LIFECYCLE EVENTS */

  /* METHODS */

  /* GETTERS */
  get isDataAvailable(): boolean {
    return !!this.storeQRCodeData;
  }

  get storeQRCodeData(): string | undefined {
    return getStoreQRCodeData(this.store, this);
  }
}
