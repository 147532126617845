import { ActiveHoursEventType, SectionActiveHoursDeviceEventJSON } from '@common/eventlog/types';

export class SectionActiveHoursDeviceEvent {
  longId: string;
  shortId: string;
  dimmingLevel: number;
  activeHoursStatus: ActiveHoursEventType;
  dump: string;
  eventExecutionTimestamp: string;
  transactionId: string;

  constructor(
    longId: string,
    shortId: string,
    dimmingLevel: number,
    activeHoursStatus: ActiveHoursEventType,
    dump: string,
    eventExecutionTimestamp: string,
    transactionId: string
  ) {
    this.activeHoursStatus = activeHoursStatus;
    this.longId = longId;
    this.shortId = shortId;
    this.dimmingLevel = dimmingLevel;
    this.dump = dump;
    this.eventExecutionTimestamp = eventExecutionTimestamp;
    this.transactionId = transactionId;
  }

  public static fromJSON(deviceEvent: SectionActiveHoursDeviceEventJSON): SectionActiveHoursDeviceEvent {
    const {
      longId,
      shortId,
      activeHoursStatus,
      dump,
      dimmingLevel,
      eventExecutionTimestamp,
      transactionId,
    }: SectionActiveHoursDeviceEventJSON = deviceEvent;
    return new SectionActiveHoursDeviceEvent(
      longId,
      shortId,
      dimmingLevel,
      activeHoursStatus,
      dump,
      eventExecutionTimestamp,
      transactionId
    );
  }
}
