
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItem } from '@client/models/ContentModels/types';
import { getAncestorName, getContentItemParent } from './utils';
import { Ancestor, ROOT_NODE } from '@common/content-item/types';
import { TranslateResult } from 'vue-i18n';

@Component({
  methods: { getAncestorName },
})
export default class ParentFolderColumn extends Vue {
  /* DECLARATIONS */
  @Prop()
  contentItem!: ContentItem;
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  onAncestorClick(ancestor: Ancestor): void {
    if (ancestor.id === ROOT_NODE) {
      this.setSelectedFolder('');
    } else {
      this.setSelectedFolder(ancestor.id);
    }
  }
  /* GETTERS */
  get parent(): Ancestor {
    return getContentItemParent(this.contentItem);
  }

  get ancestorsTooltipMessage(): TranslateResult {
    return (
      this.contentItem.ancestors
        ?.slice(0, -1)
        .map((ancestor: Ancestor) => getAncestorName(ancestor))
        .join(' / ') ?? this.$t(this.$i18nTranslationKeys.contentManagement.root.$path)
    );
  }
}
