export default class Config {
  private static apiUrl: string | null = null;

  public static getApiUrl(): string {
    if (this.apiUrl !== null) {
      return this.apiUrl;
    }
    // check if full url has to be used
    if (
      process.env.VUE_APP_BACKEND_PORT !== process.env.VUE_APP_FRONTEND_PORT ||
      process.env.VUE_APP_BACKEND_HOSTNAME !== process.env.VUE_APP_FRONTEND_HOSTNAME ||
      process.env.VUE_APP_BACKEND_PROTOCOL !== process.env.VUE_APP_FRONTEND_PROTOCOL
    ) {
      const port: string = process.env.VUE_APP_BACKEND_PORT === '80' ? '' : `:${process.env.VUE_APP_BACKEND_PORT}`;
      this.apiUrl = `${process.env.VUE_APP_BACKEND_PROTOCOL}://${process.env.VUE_APP_BACKEND_HOSTNAME}${port}${process.env.VUE_APP_BACKEND_PATH}${process.env.VUE_APP_BACKEND_API_PATH}`;
    } else {
      // relative path is enough!
      this.apiUrl = `${process.env.VUE_APP_BACKEND_PATH}${process.env.VUE_APP_BACKEND_API_PATH}`;
    }
    return this.apiUrl;
  }
}
