import { ContentItem, MediaFile } from '@client/models/ContentModels/types';
import axios, { AxiosError, AxiosInstance, AxiosProgressEvent, AxiosResponse } from 'axios';
import { downloadFileFromUrl } from '@client/utils/DownloadUtils';
import { TranslateResult } from 'vue-i18n';
import { Ancestor, DEFAULT_ANCESTORS, ROOT_NODE } from '@common/content-item/types';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

/**
 * Downloads a content item of type Image or Video
 * @param item item to download
 */
export const downloadAsset = async (item: MediaFile): Promise<void> => {
  /**
   * Azure authentication needs to be omitted here since we already send a parsed URL with access key
   * Otherwise, it will reject the request.
   * For that we create a new axios instance, using .create() it will ignore our interceptors.
   */
  const uninterceptedAxiosInstance: AxiosInstance = axios.create();
  item.isDownloading = true;
  uninterceptedAxiosInstance
    .get(await item.getBlobUrl(), {
      responseType: 'blob',
      onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
        item.percentCompleted = Math.floor((progressEvent.loaded / (progressEvent.total || 1)) * 100);
        item.isDownloading = item.percentCompleted !== 100;
      },
    })
    .then((response: AxiosResponse) => {
      const blob: Blob = new Blob([response.data]);
      downloadFileFromUrl(URL.createObjectURL(blob), item.originalName);
    })
    .catch((e: AxiosError) => {
      item.downloadFailed = true;
      console.error(e);
    });
};

/**
 * Returns the parent name from the list of ancestors
 */
export const getAncestorName = (ancestor: Ancestor): TranslateResult => {
  const contentItemsStore: ContentItemsStore = useContentItemsStore();
  const rootName: TranslateResult = i18n.t(TranslationKeys.contentManagement.root.$path);
  if (ancestor.id === ROOT_NODE) {
    return rootName;
  }
  return contentItemsStore.getOriginalNameByAzureBlobName(ancestor.id) || ancestor.name;
};

/**
 * Returns the parent of the content item from the list of ancestors
 */
export const getContentItemParent = (contentItem: ContentItem): Ancestor => {
  if (!contentItem.ancestors) {
    return DEFAULT_ANCESTORS[0];
  }

  return contentItem.ancestors[contentItem.ancestors.length - 1];
};
