import { ActiveHourDayJSON } from '@common/active-hours/types';

export default class ActiveHourDay {
  start: string;
  end: string;
  dimmingLevel: number;
  constructor(start: string, end: string, dimmingLevel: number) {
    this.start = start;
    this.end = end;
    this.dimmingLevel = dimmingLevel;
  }

  static fromJSON(activeHourDayJSON: ActiveHourDayJSON): ActiveHourDay {
    return new ActiveHourDay(activeHourDayJSON.start, activeHourDayJSON.end, activeHourDayJSON.dimmingLevel);
  }

  toJSON(): ActiveHourDayJSON {
    return { start: this.start, end: this.end, dimmingLevel: this.dimmingLevel };
  }
}
