
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ForegroundContent, DeviceTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import ImageModel from '@client/models/ContentModels/Image.model';

/**
 * Contains the action buttons that stick to the canvas
 * Add foreground item button
 * Delete foreground item button
 * Delete all foreground items button
 * The buttons are only rendered when the base content is selected
 */
@Component({
  components: { ModalDialog: () => import('../ModalDialog/ModalDialog.vue') },
})
export default class DeviceTemplateButtons extends Vue {
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop()
  private enableEditForeground!: boolean;

  private showDeleteAllForegroundItemsDialog: boolean = false;
  private showNoMoreDevices: boolean = false;
  private MAX_FOREGROUND_ITEMS: number = 12;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  closeDeleteAllForegroundItemsModal(): void {
    this.showDeleteAllForegroundItemsDialog = false;
  }

  onDeleteAllForeground(): void {
    this.showDeleteAllForegroundItemsDialog = true;
  }

  async deleteAllForeground(): Promise<void> {
    if (!this.deviceTemplate) {
      return;
    }
    await this.gondolaTemplatesStore.deleteAllDeviceForegroundSlots(
      this.$route.params.id,
      Number(this.$route.params.row),
      Number(this.$route.params.col),
      this.deviceTemplate
    );
    this.closeDeleteAllForegroundItemsModal();
  }

  async onAddForeground(): Promise<void> {
    if (this.deviceTemplate.foregroundContent.length >= this.MAX_FOREGROUND_ITEMS) {
      this.showNoMoreDevices = true;
    } else {
      await this.gondolaTemplatesStore.addDeviceForegroundSlot(
        this.$route.params.id,
        Number(this.$route.params.row),
        Number(this.$route.params.col),
        this.deviceTemplate,
        ForegroundContent.fromContentItem(ImageModel.getDefaultPlaceHolderContentItem())
      );
    }
  }

  async onDeleteForeground(): Promise<void> {
    if (this.isDeleteButtonEnabled) {
      await this.gondolaTemplatesStore.deleteDeviceForegroundSlot(
        this.$route.params.id,
        Number(this.$route.params.row),
        Number(this.$route.params.col),
        this.deviceTemplate,
        this.selectedForegroundIndex
      );
    }
  }

  get isDeleteButtonEnabled(): boolean {
    return this.selectedForegroundIndex !== -1;
  }

  get selectedForegroundIndex(): number {
    if (
      this.gondolaTemplatesStore.gondolaTemplates &&
      this.gondolaTemplatesStore.deviceContentSelection &&
      this.gondolaTemplatesStore.deviceContentSelection.index != undefined
    ) {
      return this.gondolaTemplatesStore.deviceContentSelection.index;
    }
    return -1;
  }

  get deleteButtonClass(): string {
    return this.isDeleteButtonEnabled ? '' : 'disable-events grey';
  }

  get deleteAllForegroundButtonClass(): string {
    return this.deviceTemplate?.foregroundContent && this.deviceTemplate.foregroundContent.length > 0
      ? ''
      : 'disable-events grey';
  }

  get deleteButtonColor(): string {
    return this.isDeleteButtonEnabled ? 'error' : 'accent';
  }

  get deleteAllForegroundButtonColor(): string {
    return this.deviceTemplate?.foregroundContent && this.deviceTemplate.foregroundContent.length > 0
      ? 'error'
      : 'accent';
  }

  get isLoading(): boolean {
    return this.gondolaTemplatesStore.loadingIndicator.update;
  }
}
