var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:({
    height: 'calc(40px * var(--device-scale))',
    position: 'relative',
    left: _vm.offsetX,
    top: _vm.offsetY,
    visibility: _vm.layerVisibility,
  })},[(_vm.isImage)?_c('v-img',{style:({
      border: _vm.foregroundContentBorder,
    }),attrs:{"src":_vm.previewURL,"width":_vm.width,"height":_vm.height}}):_c('div',{staticClass:"video"},[_c('video',{style:({
        width: _vm.width,
        height: _vm.height,
        border: _vm.foregroundContentBorder,
      }),attrs:{"preload":"auto","autoplay":"","loop":"","muted":"","src":_vm.previewURL},domProps:{"muted":true}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }