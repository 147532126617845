
import { Vue, Component, Prop } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';

@Component({})
export default class ModalHeader extends Vue {
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private entityName: Optional<string>;

  emitCloseAction(): void {
    this.$emit('close-button-pressed');
  }
}
