
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Moment, * as MomentExt from 'moment';
import { Schedule } from '@client/models';
import spacetime from 'spacetime';
import ScheduleModel, {
  END_OF_DAY_TIME,
  END_OF_DAY_TIME_WITH_SECONDS,
  START_OF_DAY_TIME,
} from '@client/models/ScheduleModels/Schedule.model';
import ModalDialog from '../ModalDialog/ModalDialog.vue';
import { SelectableScheduleDay } from '@client/definitions/schedule';
import { getShortDayName, isEndTimeBeforeStartTime, timeValidationRegex } from '@client/utils/DateTimeUtils';
import { extendMoment, MomentRange } from 'moment-range';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { TranslateResult } from 'vue-i18n';
import { ScheduleDay } from '@common/enums/ScheduleDay';
import TimePicker from '@client/components/TimePicker/TimePicker.vue';
import { ScheduleModelJSON, ScheduleVisibility } from '@common/schedule/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { Optional } from '@common/types';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import ScheduleActiveStatusSwitch from '@client/components/Device/DeviceActiveStatusSwitch.vue';
import { VuetifyParsedThemeItem } from 'vuetify/types/services/theme';

/**
 * Used either as component for creating a new schedule, or as a component for editing existing schedule.
 */
@Component({
  components: {
    DeviceActiveStatusSwitch: ScheduleActiveStatusSwitch,
    TimePicker,
    ModalDialog,
  },
  methods: {
    getShortDayName,
    validateTextFieldLength,
  },
})
export default class ScheduleDialog extends Vue {
  @Prop()
  private gondolaTemplateId!: string;
  @Prop()
  private schedule: Schedule | undefined;
  @Prop({ default: '' })
  private buttonWrapperClass?: string;
  private showConfirmOverrideSchedule: boolean = false;
  private overrideSchedulesText: string = '';
  private validityPeriod: string[] = ['', ''];
  private name: string = '';
  private color: string = '';
  private startTime: string = '';
  private endTime: string = '';
  private initialStartTime: string = '';
  private initialEndTime: string = '';
  private isFetching: boolean = false;
  private scheduleDays: SelectableScheduleDay[] = [];
  private allowedScheduleDays: Map<string, string> = new Map<string, string>();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  errors: {
    name: boolean;
    dates: boolean;
    scheduleDays: boolean;
    color: boolean;
    startTime: boolean;
    endTime: boolean;
    endTimeBigger: boolean;
  } = {
    name: false,
    dates: false,
    scheduleDays: false,
    color: false,
    startTime: false,
    endTime: false,
    endTimeBigger: false,
  };
  showDialog: boolean = false;
  openStartTime: boolean = false;
  openEndTime: boolean = false;
  openDates: boolean = false;
  openColorPicker: boolean = false;
  moment: MomentRange = extendMoment(MomentExt);

  initValues(initialSchedule?: Schedule | undefined): void {
    if (initialSchedule) {
      //Edit mode
      this.validityPeriod = [
        initialSchedule.spans[0].validityFrom.toString(),
        initialSchedule.spans[0].validityTo.toString(),
      ];
      this.name = initialSchedule.name;
      this.color = initialSchedule.color;
      this.startTime = initialSchedule.spans[0].recurrenceStart;
      this.initialStartTime = initialSchedule.spans[0].recurrenceStart;
      this.endTime = initialSchedule.spans[0].recurrenceEnd;
      this.initialEndTime = initialSchedule.spans[0].recurrenceEnd;
      this.scheduleDays = this.getSelectedScheduleDays(initialSchedule);
    } else {
      this.name = '';
      this.color = (this.$vuetify.theme.themes.light.primary as VuetifyParsedThemeItem).base;
      this.initialStartTime = '00:00';
      this.initialEndTime = '00:00';
      this.startTime = '00:00';
      this.endTime = '00:00';
      this.validityPeriod = [new Date().toISOString(), new Date().toISOString()];
      this.scheduleDays.forEach((day: SelectableScheduleDay) => (day.selected = false));
    }
    (this.$refs.endTime as TimePicker | undefined)?.initialize();
    (this.$refs.startTime as TimePicker | undefined)?.initialize();
    this.checkDates(this.validityPeriod);
    this.errors = {
      name: false,
      dates: false,
      scheduleDays: false,
      color: false,
      startTime: false,
      endTime: false,
      endTimeBigger: false,
    };
    this.showDialog = false;
    this.openStartTime = false;
    this.openEndTime = false;
    this.openDates = false;
    this.openColorPicker = false;
    this.isFetching = false;
  }

  /**
   * Helper method to filter out the days available to the user,
   * E.g. the user chooses a date that goes from monday -> friday => saturday and sunday will be locked
   * @param dates : Array of two dates as ISO string, start date and end date
   */
  checkDates(dates: Array<string>): void {
    // Clear the allowed schedule days map
    this.allowedScheduleDays.clear();
    // GO through the available days in the date range e.g. (Monday, Tuesday, Wednesday...Ect.)
    for (const day of this.moment.range(Moment(dates[0]), Moment(dates[1])).by('days')) {
      const dayToSetAvailable: string = day.format('dddd').toLocaleUpperCase();
      this.allowedScheduleDays.set(dayToSetAvailable, dayToSetAvailable);
      if (this.allowedScheduleDays.size === 7) {
        // If number of days = 7 it means that all days are available and no need to keep looping through the remaining days
        break;
      }
    }
    for (const scheduleDay of this.scheduleDays) {
      if (!this.allowedScheduleDays.get(scheduleDay.day.toLocaleUpperCase())) {
        scheduleDay.selected = false;
      }
    }
    if (new Date(dates[1]) < new Date(dates[0])) {
      this.validityPeriod = [this.validityPeriod[1], this.validityPeriod[0]];
    }
  }

  validateName(value: string): boolean | TranslateResult {
    return validateTextFieldLength(
      value,
      DEFAULT_COMMON_STRING_MAX_LENGTH,
      true,
      this.$t(this.$i18nTranslationKeys.schedules.errors.name.$path)
    );
  }

  AddScheduleCancelHandler(): void {
    this.showDialog = false;
    this.initValues(this.schedule);
  }

  /**
   * On date range component close, check if the user selected only one date.
   * If only one date is selected create a valid date range from said date.
   */
  @Watch('openDates')
  setValidPeriodFromSingleDate(): void {
    if (this.validityPeriod.length === 1) {
      this.validityPeriod = [this.validityPeriod[0], this.validityPeriod[0]];
      this.validateAndSetDateRange(`${Moment(this.validityPeriod[0])} - ${Moment(this.validityPeriod[1])}`);
    }
  }

  setAllScheduleDaysCheckedValue(checkValue: boolean = false): void {
    for (const scheduleDay of this.scheduleDays) {
      if (this.allowedScheduleDays.get(scheduleDay.day.toLocaleUpperCase())) {
        scheduleDay.selected = checkValue;
      }
    }
  }

  validateStartTime(value: string): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    this.startTime = value;
    (this.$refs.endTime as TimePicker | undefined)?.onChangeOrInput(this.endTime);
    if (isValid) {
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.startTime.$path)];
  }

  validateEndTime(value: string): Array<string | TranslateResult> {
    const isValidValue: boolean = timeValidationRegex.test(value);
    // Only validate if end time is a valid time string else it's an error anyway
    const isEndTimeBigger: boolean = isValidValue ? isEndTimeBeforeStartTime(this.startTime, value) : true;
    this.endTime = value;
    if (isValidValue && !isEndTimeBigger) {
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.endTime.$path)];
  }

  validateAndSetDateRange(value: string): void {
    if (!value) {
      this.errors.dates = true;
      this.validityPeriod = [];
      return;
    }
    const dates: Array<string> = value.split('-');
    const startDate: Moment.Moment = Moment(dates[0]);
    const endDate: Moment.Moment = Moment(dates[1]);
    if (dates.length === 1) {
      if (!startDate.isValid()) {
        this.validityPeriod = [];
        this.errors.dates = true;
        return;
      }
      this.validityPeriod = [startDate.format('YYYY-MM-DD')];
      this.setValidPeriodFromSingleDate();
    } else if (dates.length === 2) {
      // Try to format the dates with moment
      try {
        if (!startDate.isValid() || !endDate.isValid()) {
          this.errors.dates = true;
          this.validityPeriod = [];
          return;
        }
        const startDateString: string = startDate.format('YYYY-MM-DD');
        const endDateString: string = endDate.format('YYYY-MM-DD');
        this.errors.dates = false;
        // If the end date is smaller than the start date, reverse them
        if (endDate.isBefore(startDate)) {
          this.validityPeriod = [endDateString, startDateString];
          this.checkDates(this.validityPeriod);
          return;
        }
        this.validityPeriod = [startDateString, endDateString];
        this.checkDates(this.validityPeriod);
      } catch {
        this.errors.dates = true;
        this.validityPeriod = [];
      }
    } else {
      this.errors.dates = true;
    }
  }

  validateAndSetDateRangeComponent(dates: Array<string>): void {
    if (dates.length !== 2) {
      this.errors.dates = true;
      this.validityPeriod = [];
    } else {
      this.validateAndSetDateRange(`${Moment(dates[0])} - ${Moment(dates[1])}`);
    }
  }

  saveHandler(): void {
    // If only one day is selected, set the same date as start and end date
    if (this.validityPeriod.length > 0) {
      this.setValidPeriodFromSingleDate();
      this.validateAndSetDateRange(`${Moment(this.validityPeriod[0])} - ${Moment(this.validityPeriod[1])}`);
    }
    this.errors.dates = this.validityPeriod.length !== 2;
    this.errors.name = this.name.length === 0 || this.name.length > DEFAULT_COMMON_STRING_MAX_LENGTH;
    this.errors.scheduleDays = this.scheduleDays.filter((day: SelectableScheduleDay) => day.selected).length === 0;
    this.errors.color = !/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(this.color);
    this.errors.startTime = !timeValidationRegex.test(this.startTime);
    this.errors.endTime = !timeValidationRegex.test(this.endTime);
    this.errors.endTimeBigger = Moment(
      this.endTime === START_OF_DAY_TIME ? END_OF_DAY_TIME_WITH_SECONDS : this.endTime,
      'HH:mm'
    ).isSameOrBefore(Moment(this.startTime, 'HH:mm'));
    if (
      this.errors.scheduleDays ||
      this.errors.name ||
      this.errors.dates ||
      this.errors.color ||
      this.errors.startTime ||
      this.errors.endTime ||
      this.errors.endTimeBigger
    ) {
      return;
    }

    const recurrenceDaysFilter = (day: string | false): day is ScheduleDay => {
      return !!day;
    };

    const recurrenceDays: Array<ScheduleDay> = this.scheduleDays
      .map((day: SelectableScheduleDay) => day.selected && day.day.toLocaleLowerCase())
      .filter(recurrenceDaysFilter);

    const scheduleModel: ScheduleModelJSON = {
      color: this.color,
      name: this.name,
      gondolaTemplates: [this.gondolaTemplateId],
      visibility: ScheduleVisibility.PRIVATE,
      customerId: this.appGlobalStore.customer,
      // explicitly specify UTC for spans, since this is assumed for the conversion logic when publishing
      spans: [
        {
          recurrenceDays: recurrenceDays,
          validityFrom: spacetime(this.validityPeriod[0], 'UTC').toNativeDate(),
          validityTo: spacetime(this.validityPeriod[1], 'UTC').toNativeDate(),
          recurrenceStart: this.startTime,
          recurrenceEnd: this.endTime === START_OF_DAY_TIME ? END_OF_DAY_TIME : this.endTime,
        },
      ],
      active: true,
    };

    if (this.schedule) {
      // If this is an edit action, assign the schedule _id, gondola templates and active status
      scheduleModel._id = this.schedule._id;
      scheduleModel.gondolaTemplates = this.schedule.gondolaTemplates;
      scheduleModel.active = this.schedule.active;
      this.editSchedule(scheduleModel);
    } else {
      this.addSchedule(scheduleModel);
    }
  }

  created(): void {
    Object.keys(ScheduleDay).forEach((day: string) => this.scheduleDays.push({ day: day, selected: false }));
    this.initValues(this.schedule);
  }

  get formatSelectedDate(): string {
    if (this.validityPeriod.length === 0) {
      return '';
    }
    return `${Moment(this.validityPeriod[0]).toDate().toLocaleDateString(this.$i18n.locale, {})} - ${Moment(
      this.validityPeriod[1]
    )
      .toDate()
      .toLocaleDateString(this.$i18n.locale, {})}`;
  }

  confirmHandler(): void {
    this.showConfirmOverrideSchedule = false;
  }

  cancelHandler(): void {
    this.initValues();
    this.isFetching = false;
    this.showConfirmOverrideSchedule = false;
  }

  async editSchedule(scheduleToEdit: ScheduleModelJSON): Promise<void> {
    if (!this.schedule) {
      return;
    }
    const scheduleHash: string | undefined = this.schedule.hash;
    this.isFetching = true;
    const result: Optional<{ schedule: Schedule; conflicts: Array<Schedule> }> = await this.schedulesStore.editSchedule(
      ScheduleModel.fromJSON(scheduleToEdit),
      scheduleHash,
      false
    );
    if (!result) {
      return;
    }
    const { conflicts, schedule }: { schedule: Schedule; conflicts: Array<Schedule> } = result;
    if (conflicts.length > 0) {
      const conflictsListAsHtml: Array<string> = conflicts.map(
        (conflict: ScheduleModel) => `<li><strong>${conflict.name}</strong></li>`
      );
      this.overrideSchedulesText = `${this.$t(
        this.$i18nTranslationKeys.schedules.conflictWarning.$path
      )} <ul>${conflictsListAsHtml.join('')}</ul>`;
      this.confirmHandler = () => {
        this.schedulesStore
          .editSchedule(schedule, scheduleHash, true)
          .then((forcedResult: Optional<{ schedule: Schedule; conflicts: Array<Schedule> }>) => {
            if (!forcedResult) {
              return;
            }
            this.schedulesStore.edit(ScheduleModel.setEndOfDayTime(ScheduleModel.clone(forcedResult.schedule)));
            this.schedulesStore.setSchedulesInactive(forcedResult.conflicts);
            this.showConfirmOverrideSchedule = false;
            this.initValues(forcedResult.schedule);
            this.showDialog = false;
          });
      };
      this.showConfirmOverrideSchedule = true;
    } else {
      const scheduleResult: ScheduleModel = ScheduleModel.setEndOfDayTime(ScheduleModel.clone(schedule));
      this.schedulesStore.edit(scheduleResult);
      this.initValues(scheduleResult);
      this.isFetching = false;
      this.showDialog = false;
    }
  }

  async addSchedule(scheduleToAdd: ScheduleModelJSON): Promise<void> {
    this.isFetching = true;
    const result: Optional<{ schedule: Schedule; conflicts: Array<Schedule> }> = await this.schedulesStore.addSchedule(
      scheduleToAdd,
      false
    );
    if (!result) {
      return;
    }
    const { conflicts, schedule }: { schedule: Schedule; conflicts: Array<Schedule> } = result;
    if (conflicts.length > 0) {
      const conflictsListAsHtml: Array<string> = conflicts.map(
        (conflict: ScheduleModel) => `<li><strong>${conflict.name}</strong></li>`
      );
      this.overrideSchedulesText = `${this.$t(
        this.$i18nTranslationKeys.schedules.conflictWarning.$path
      )} <ul>${conflictsListAsHtml.join('')}</ul>`;
      this.confirmHandler = async () => {
        const forcedResult: Optional<{ schedule: Schedule; conflicts: Array<Schedule> }> =
          await this.schedulesStore.addSchedule(scheduleToAdd, true);
        if (!forcedResult) {
          return;
        }
        this.schedulesStore.add(ScheduleModel.setEndOfDayTime(ScheduleModel.clone(forcedResult.schedule)));
        this.schedulesStore.setSchedulesInactive(forcedResult.conflicts);
        this.showConfirmOverrideSchedule = false;
        this.showDialog = false;
        this.initValues();
        this.isFetching = false;
      };
      this.showConfirmOverrideSchedule = true;
    } else {
      this.schedulesStore.add(ScheduleModel.setEndOfDayTime(ScheduleModel.clone(schedule)));
      this.initValues();
      this.isFetching = false;
      this.showDialog = false;
    }
  }

  /**
   * Returns an array of {@link SelectableScheduleDay} that contains an entry for all existing keys of enum {@link ScheduleDay}.
   * Whether a {@link SelectableScheduleDay} is selected or not is determined by the given {@link Schedule}.
   * More specifically - as we currently only support {@link Schedule} objects with a single entry in {@link Schedule#spans} -
   * each {@link ScheduleDay} that is present in {@link ScheduleSpan#recurrenceDays} is treated as a selected schedule day.
   */
  getSelectedScheduleDays(schedule: Schedule): SelectableScheduleDay[] {
    const selectedScheduleDays: SelectableScheduleDay[] = [];
    // iterate over values of enum => they are both type ScheduleDay and are strings representing the keys, e.g. "monday", ...
    for (const scheduleDay of Object.values(ScheduleDay)) {
      const recurrenceDayForScheduleDay: ScheduleDay | undefined = schedule.spans[0].recurrenceDays.find(
        (recurrenceDay: ScheduleDay) => {
          // as ScheduleSpanModel.recurrenceDays is of type ScheduleDay[], we're properly comparing
          // - types ScheduleDay with ScheduleDay
          // - values "monday" with "monday"
          return recurrenceDay === scheduleDay;
        }
      );
      const isSetInSchedule: boolean = recurrenceDayForScheduleDay !== undefined;
      selectedScheduleDays.push({ day: scheduleDay, selected: isSetInSchedule });
    }
    return selectedScheduleDays;
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get scheduleActionText(): TranslateResult {
    return this.schedule
      ? this.$t(this.$i18nTranslationKeys.action.edit.$path)
      : this.$t(this.$i18nTranslationKeys.action.createEntity.$path, {
          entity: this.$tc(this.$i18nTranslationKeys.schedules.schedule.$path),
        });
  }
}
