import {
  DeviceStatusEventLogJSON,
  BaseEventLogJSON,
  EventType,
  PublishingEventLogJSON,
  SectionActiveHoursEventLogJSON,
  StoreActiveHoursEventLogJSON,
  ActiveHoursConfigEventLogJSON,
  StoreSyncEventLogJSON,
} from '@common/eventlog/types';
import { FirmwareChangedEventLogJSON, FirmwareReleasedEventLogJSON } from './types';

export const isPublishEventLogJSON = (eventLog: BaseEventLogJSON): eventLog is PublishingEventLogJSON => {
  return eventLog.type === EventType.PUBLISHING;
};

export const isStoreActiveHoursEventLogJSON = (
  eventLog: BaseEventLogJSON
): eventLog is StoreActiveHoursEventLogJSON => {
  return eventLog.type === EventType.STORE_ACTIVE_HOURS;
};

export const isSectionActiveHoursEventLogJSON = (
  eventLog: BaseEventLogJSON
): eventLog is SectionActiveHoursEventLogJSON => {
  return eventLog.type === EventType.SECTION_ACTIVE_HOURS;
};

export const isDeviceStatusEventLogJSON = (eventLog: BaseEventLogJSON): eventLog is DeviceStatusEventLogJSON => {
  return eventLog.type === EventType.ONLINE_STATUS || eventLog.type === EventType.REGISTERED_STATUS;
};

export const isActiveHoursConfigEventLogJSON = (
  eventLog: BaseEventLogJSON
): eventLog is ActiveHoursConfigEventLogJSON => {
  return eventLog.type === EventType.ACTIVE_HOURS_CONFIG;
};

export const isStoreSyncEventLogJSON = (eventLog: BaseEventLogJSON): eventLog is StoreSyncEventLogJSON => {
  return eventLog.type === EventType.STORE_SYNC;
};

export const isFirmwareReleasedEventLogJSON = (
  eventLog: BaseEventLogJSON
): eventLog is FirmwareReleasedEventLogJSON => {
  return eventLog.type === EventType.FIRMWARE_RELEASED;
};

export const isFirmwareChangedEventLogJSON = (eventLog: BaseEventLogJSON): eventLog is FirmwareChangedEventLogJSON => {
  return eventLog.type === EventType.FIRMWARE_CHANGED;
};
