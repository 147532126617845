import { ContentType } from '@common/enums';
import { BaseLayerModelJSON, LayerPlaylistItemJSON } from '@common/schedule/types';
import LayerPlaylistItem from '@client/models/ScheduleModels/LayerPlaylistItem.model';
import ContentLayerModel from '@client/models/ScheduleModels/ContentLayer.model';

export default class BaseLayerModel extends ContentLayerModel {
  _id?: string;

  constructor(
    _id: string | undefined,
    name: string,
    url: string,
    previewUrl: string,
    type: ContentType,
    height: number,
    width: number,
    duration: number = 0,
    fps: number = 0,
    offsetX: number,
    offsetY: number,
    zIndex: number,
    checksum: string | undefined,
    layerId: string | undefined,
    playlist: Array<LayerPlaylistItem>
  ) {
    super(
      name,
      url,
      previewUrl,
      type,
      height,
      width,
      duration,
      fps,
      offsetX,
      offsetY,
      zIndex,
      checksum,
      layerId,
      playlist
    );
    this._id = _id;
  }

  public static fromJSON(baseLayerModelJSON: BaseLayerModelJSON): BaseLayerModel {
    const playlistItems: LayerPlaylistItem[] =
      baseLayerModelJSON.playlist?.map((layerPlaylistItem: LayerPlaylistItemJSON) =>
        LayerPlaylistItem.fromJSON(layerPlaylistItem)
      ) || [];
    return new BaseLayerModel(
      baseLayerModelJSON._id,
      baseLayerModelJSON.name,
      baseLayerModelJSON.url,
      baseLayerModelJSON.previewUrl ?? '',
      baseLayerModelJSON.type,
      baseLayerModelJSON.height,
      baseLayerModelJSON.width,
      baseLayerModelJSON.duration,
      baseLayerModelJSON.fps,
      baseLayerModelJSON.offsetX,
      baseLayerModelJSON.offsetY,
      baseLayerModelJSON.zIndex,
      baseLayerModelJSON.checksum,
      baseLayerModelJSON.layerId,
      playlistItems
    );
  }

  public toJSON(): BaseLayerModelJSON {
    const playlistItems: LayerPlaylistItemJSON[] = this.playlist.map((layerPlaylistItem: LayerPlaylistItem) =>
      layerPlaylistItem.toJSON()
    );
    return {
      _id: this._id,
      name: this.name,
      url: this.url,
      previewUrl: this.previewUrl,
      type: this.type,
      height: this.height,
      width: this.width,
      duration: this.duration,
      fps: this.fps,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      zIndex: this.zIndex,
      checksum: this.checksum,
      layerId: this.layerId,
      playlist: playlistItems,
    };
  }

  public clone(): BaseLayerModel {
    return new BaseLayerModel(
      this._id,
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      this.layerId,
      this.playlist
    );
  }
}
