
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VueTourStep, VueTourSteps } from '@client/definitions/vue-tour';
// Import vue-tour css, we need to import it here or it will override our css rules (if for example called in app.ts)
import 'vue-tour/dist/vue-tour.css';
import {
  TOUR_DEVICE_CONTAINER_TARGET,
  TOUR_DEVICE_DROPDOWN_TARGET,
  TOUR_DEVICE_EDIT_TARGET,
  vueTourConfig,
} from '@client/components/DeviceTemplateEditTour/types';

@Component({
  methods: {
    vueTourConfig,
  },
})
export default class DeviceTemplateEditTour extends Vue {
  /**
   * The first step is only shown when a device already has content assigned
   * @private
   */
  @Prop() private showFirstStep!: boolean;

  get vueTourSteps(): VueTourSteps {
    const steps: Array<VueTourStep> = [
      {
        content: this.$t(this.$i18nTranslationKeys.deviceView.vueTour.stepEditContent.$path),
        target: `#${TOUR_DEVICE_EDIT_TARGET}`,
      },
      {
        content: this.$t(this.$i18nTranslationKeys.deviceView.vueTour.stepEditModel.$path),
        target: `#${TOUR_DEVICE_DROPDOWN_TARGET}`,
      },
    ];
    if (this.showFirstStep) {
      steps.unshift({
        content: this.$t(this.$i18nTranslationKeys.deviceView.vueTour.stepHover.$path),
        target: `#${TOUR_DEVICE_CONTAINER_TARGET}`,
      });
    }
    return {
      steps,
    };
  }
}
