
import { Component, Prop, Vue } from 'vue-property-decorator';
import ContentItemSelectionTarget from '../../enums/ContentItemSelectionTarget';
import { TranslateResult } from 'vue-i18n';
import { Optional } from '@common/types';
import { ContentItem } from '@client/models/ContentModels/types';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { Ancestor, ROOT_NODE } from '@common/content-item/types';

@Component({
  components: {
    ContentItemsList: () => import('./ContentItemsList.vue'),
  },
})
export default class MoveDialog extends Vue {
  /* DECLARATIONS */
  @Prop()
  private showMoveDialog!: boolean;
  @Prop()
  private closeMoveDialog!: () => void;
  @Prop()
  private contentItemsToMove!: Array<ContentItem>;

  private selectedTargetFolder: string = '';
  private defaultParentFolder: string = ROOT_NODE;
  private currentlyDisplayedContentItems: Array<ContentItem> = [];

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  /* LIFECYCLE EVENTS */
  created(): void {
    this.defaultParentFolder = this.contentItemsToMove[0]?.parentFolder || ROOT_NODE;
    this.$root.$on('onSelectedTargetFolderMoveDialogChanged', this.setSelectedTargetFolder);
    this.$root.$on('onCurrentlyDisplayedContentItems', this.setCurrentlyDisplayedContentItems);
  }
  /* METHODS */
  setSelectedTargetFolder(folderId: string): void {
    this.selectedTargetFolder = folderId;
  }

  setCurrentlyDisplayedContentItems(currentlyDisplayedContentItems: Array<ContentItem>): void {
    this.currentlyDisplayedContentItems = currentlyDisplayedContentItems;
  }

  onCloseModal(): void {
    this.selectedTargetFolder = '';
    this.closeMoveDialog();
  }

  async moveItem(): Promise<void> {
    if (!this.isItemMoveTargetValid) {
      return;
    }
    const contentItems: Array<ContentItem> = [];
    for (const contentItem of this.contentItemsToMove) {
      const updatedContentItem: ContentItem = contentItem.clone();
      updatedContentItem.parentFolder = this.selectedTargetFolder;
      contentItems.push(updatedContentItem);
    }
    await this.contentItemsStore.bulkMoveContentItems(contentItems, this.selectedTargetFolder);

    this.closeMoveDialog();
  }
  /* GETTERS */
  get ContentItemSelectionTarget(): typeof ContentItemSelectionTarget {
    return ContentItemSelectionTarget;
  }

  get selectedFolderName(): TranslateResult {
    const selectedFolder: Optional<ContentItem> = this.contentItemsStore.getContentItemById(this.selectedTargetFolder);
    if (!selectedFolder) {
      return this.$t(this.$i18nTranslationKeys.contentManagement.root.$path);
    }
    return selectedFolder.name;
  }

  get contentItemToMoveName(): TranslateResult {
    if (this.contentItemsToMove.length === 1) {
      const onlyItemSelected: ContentItem = this.contentItemsToMove[0];
      if (onlyItemSelected.isMediaFile()) {
        return onlyItemSelected.originalName;
      } else {
        return onlyItemSelected.name;
      }
    } else {
      return this.$t(this.$i18nTranslationKeys.contentManagement.movingItems.$path, {
        count: this.contentItemsToMove.length,
      });
    }
  }

  get isItemDuplicateNameInTargetFolder(): boolean {
    const contentItemsToCheck: Array<ContentItem> = this.contentItemsToMove.filter(
      (item: ContentItem) => !item.isMediaFile()
    );
    if (!contentItemsToCheck.length) {
      return false;
    }
    const nameTypePairs: Array<string> = contentItemsToCheck.map((item: ContentItem) => `${item.name}-${item.type}`);

    return this.currentlyDisplayedContentItems.some((contentItem: ContentItem) =>
      nameTypePairs.includes(`${contentItem.name}-${contentItem.type}`)
    );
  }

  get isItemAlreadyInSelectedFolder(): boolean {
    return this.contentItemsToMove.some(
      (item: ContentItem) =>
        (!item.parentFolder && !this.selectedTargetFolder) || item.parentFolder === this.selectedTargetFolder
    );
  }

  get isFolderMovingToSelf(): boolean {
    const contentItemsToCheck: Array<ContentItem> = this.contentItemsToMove.filter((item: ContentItem) =>
      item.isFolder()
    );
    if (!contentItemsToCheck.length) {
      return false;
    }
    return contentItemsToCheck.some((item: ContentItem) => item._id === this.selectedTargetFolder);
  }

  get isFolderMovingToChild(): boolean {
    const contentItemsToCheck: Array<ContentItem> = this.contentItemsToMove.filter((item: ContentItem) =>
      item.isFolder()
    );
    if (!contentItemsToCheck.length) {
      return false;
    }
    if (this.isFolderMovingToSelf) {
      return false;
    }
    if (this.selectedTargetFolder) {
      const selectedFolder: Optional<ContentItem> = this.contentItemsStore.getContentItemById(
        this.selectedTargetFolder
      );
      if (!selectedFolder?.ancestors) {
        return false;
      }
      const contentItemsIdsToCheck: Array<string> = contentItemsToCheck.map((item: ContentItem) => item._id);
      return selectedFolder.ancestors.some((ancestor: Ancestor) => contentItemsIdsToCheck.includes(ancestor.id));
    }
    return false;
  }

  get isItemMoveTargetValid(): boolean {
    return (
      !this.isItemAlreadyInSelectedFolder &&
      !this.isFolderMovingToSelf &&
      !this.isFolderMovingToChild &&
      !this.isItemDuplicateNameInTargetFolder
    );
  }

  get isLoading(): boolean {
    return this.contentItemsStore.isActionPending;
  }
}
