/**
 * Helper method to check the brightness level of a color
 * > (>128) light; use dark text/contrast color
 * > (<128) dark; use light text/contrast color
 * @param hex color to check
 */
const getBrightness = (hex: string) => {
  // Remove '#' if it's included in the hex code
  const cleanHexValue: string = hex.replace('#', '');

  // Convert hex to RGB
  const r: number = parseInt(cleanHexValue.substring(0, 2), 16);
  const g: number = parseInt(cleanHexValue.substring(2, 4), 16);
  const b: number = parseInt(cleanHexValue.substring(4, 6), 16);

  // Calculate and return brightness using YIQ formula
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export { getBrightness };
