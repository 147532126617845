/**
 * Return true if input string contains only ASCII characters
 * @param input
 */
export const isInputOnlyAscii = (input: string): boolean => {
  for (let i: number = 0; i < input.length; i++) {
    if (input.charCodeAt(i) > 127) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if a string is pure of symbols, accepts all characters, including other language characters.
 */
export const isStringSymbolsPure = (inputString: string): boolean => {
  // Define a regular expression pattern that matches non-ASCII characters except symbols
  const symbolsOnlyPattern: RegExp = /^[^\x00-\x1F\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+$/;
  // Test the input string against the pattern
  return symbolsOnlyPattern.test(inputString);
};

/**
 * Sanitize folder name, removes special characters and trims string
 * @param input folder name
 */
export const normalizeString = (input: string): string => {
  const nonSymbolsRegex: RegExp = /[\x00-\x1F\x21-\x2F\x3A-\x40\x5B-\x60\x7B-\x7E]+/;
  const removedNonSymbols: string = input.replace(nonSymbolsRegex, ' ');
  return removedNonSymbols.trim();
};
