
import { Component, Prop, Vue } from 'vue-property-decorator';
import AssignButton from '@client/components/ContentItems/ListItems/AssignButton.vue';
import DeleteButton from '@client/components/ContentItems/ListItems/DeleteButton.vue';
import FolderModel from '@client/models/ContentModels/Folder.model';
import { ContentItem } from '@client/models/ContentModels/types';
import InlineEditTextField from '@client/components/InlineEditTextField/InlineEditTextField.vue';
import {
  validateNoSymbolsInString,
  validateTextFieldLength,
  DEFAULT_COMMON_STRING_MAX_LENGTH,
} from '@client/utils/validateTextFieldLength';
import { isStringSymbolsPure } from '@common/utils/validation';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItemSelectionTarget } from '@client/enums';
import { getAncestorName } from '@client/components/ContentItems/ListItems/utils';
import ParentFolderColumn from '@client/components/ContentItems/ListItems/ParentFolderColumn.vue';
import FileNameField from '@client/components/ContentItems/ListItems/FileNameField.vue';
import MoveButton from '@client/components/ContentItems/ListItems/MoveButton.vue';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import EditTagsButton from '@client/components/ContentItems/ListItems/EditTagsButton.vue';

/**
 * Renders a folder row in the content items table.
 */
@Component({
  components: {
    EditTagsButton,
    Tag,
    FileNameField,
    ParentFolderColumn,
    InlineEditTextField,
    DeleteButton,
    AssignButton,
    MoveButton,
  },
  methods: { validateTextFieldLength, validateNoSymbolsInString, getContentItemParentFolderName: getAncestorName },
})
export default class FolderItem extends Vue {
  /* DECLARATIONS */
  @Prop()
  private folder!: FolderModel;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget!: ContentItemSelectionTarget;
  @Prop()
  private deleteContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  private setSelectedFolder!: (folderId: string) => void;
  @Prop()
  private index!: number;
  @Prop()
  private isSelected!: boolean;
  @Prop()
  private showParentFolder!: boolean;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private setSelectedRow!: (id: string) => void;
  @Prop()
  private isModalEmbedded!: boolean;
  @Prop()
  private openMoveDialog!: (contentItemToMove: ContentItem) => void;
  @Prop()
  private openEditTagsDialog!: (contentItemToEdit: ContentItem) => void;
  @Prop()
  private onToggleSelection!: (contentItem: ContentItem) => void;
  @Prop()
  private isChecked!: boolean;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  private isEditMode: boolean = false;
  private folderNameEdit: string = '';
  private isEditPending: boolean = false;

  /* LIFECYCLE EVENTS */
  created(): void {
    this.folderNameEdit = this.folder.name;
  }
  /* METHODS */
  openEditMode(): void {
    this.folderNameEdit = this.folder.name;
    this.isEditMode = true;
    const editNameComponent: Vue | undefined = this.$refs['edit-name'] as Vue | undefined;
    if (!editNameComponent) {
      return;
    }
    const editNameInput: HTMLInputElement = editNameComponent.$refs.input as HTMLInputElement;
    editNameInput.focus();
  }

  onRowClick(id: string): void {
    this.setSelectedRow(id);
    this.setSelectedFolder(id);
  }

  onEditFocusOut(): void {
    this.isEditMode = false;
    this.isEditPending = false;
    this.folderNameEdit = this.folder.name;
  }

  async editFolderName(): Promise<void> {
    if (!this.isFolderNameEditValid) {
      return;
    }
    if (this.folderNameEdit === this.folder.name) {
      const editNameComponent: Vue | undefined = this.$refs['edit-name'] as Vue | undefined;
      if (!editNameComponent) {
        return;
      }
      const editNameInput: HTMLInputElement = editNameComponent.$refs.input as HTMLInputElement;
      editNameInput.blur();
    }
    const updatedFolder: FolderModel = FolderModel.fromJSON(this.folder.toJSON());
    updatedFolder.setName(this.folderNameEdit);
    this.isEditPending = true;
    await this.contentItemsStore.updateContentItem(updatedFolder);
    this.isEditPending = false;
  }

  /* GETTERS */
  get selectedRowClass(): string | null {
    return this.isSelected ? 'selected-highlight-row' : null;
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get folderNameTitle(): string {
    return `${this.$t(this.$i18nTranslationKeys.contentManagement.folder.$path)} ${this.$t(
      this.$i18nTranslationKeys.deviceView.name.$path
    )}`;
  }

  get isFolderNameEditValid(): boolean {
    return (
      isStringSymbolsPure(this.folderNameEdit) &&
      validateTextFieldLength(this.folderNameEdit, DEFAULT_COMMON_STRING_MAX_LENGTH, true) === true
    );
  }

  get hideDetailColumns(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.MOVE_ITEM;
  }
}
