import i18n from '@client/plugins/i18n/i18n';
import { RouteConfig } from 'vue-router/types/router';
import { TranslationKeys } from '@client/plugins/i18n/locales';

const AddSectionsToAisleView = () => import('@client/views/StorePages/AddSectionsToAisleView.vue');
const StoreListView = () => import('@client/views/StorePages/StoreListView.vue');
const StoreDetailView = () => import('@client/views/StorePages/StoreDetailView.vue');
const EditSectionView = () => import('@client/views/StorePages/EditSectionView.vue');
const PublishDetailView = () => import('@client/views/StorePages/PublishDetailView.vue');
const PublishSectionsView = () => import('@client/views/StorePages/PublishSectionsView.vue');

export const storeRoutes: Array<RouteConfig> = [
  {
    path: '/stores',
    name: 'stores',
    alias: '/',
    component: StoreListView,
    meta: {
      title: i18n.t(TranslationKeys.storeOverview.title.$path),
    },
  },
  {
    path: '/stores/:storeid',
    name: 'store',
    component: StoreDetailView,
    meta: {
      title: i18n.t(TranslationKeys.storeDetail.title.$path),
    },
  },
  {
    path: '/stores/:storeid/gondola/:gondolaid?',
    name: 'gondola',
    component: EditSectionView,
    meta: {
      title: i18n.t(TranslationKeys.gondolaView.title.$path),
    },
  },
  {
    path: '/stores/:storeid/:aisle/sections/create',
    name: 'sections',
    component: AddSectionsToAisleView,
    meta: {
      title: i18n.t(TranslationKeys.storeDetail.createOrEditSections.addSection.$path),
    },
  },
  {
    path: '/publish/:storeid/:gondolaid',
    name: 'publish',
    component: PublishDetailView,
  },
  {
    path: '/republish/:templateid',
    name: 'republish',
    component: PublishSectionsView,
  },
  {
    path: '/publish/:templateid',
    name: 'publishToSections',
    component: PublishSectionsView,
  },
];
