
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ValidationFunctionType } from '@client/utils/validateTextFieldLength';
import { TranslateResult } from 'vue-i18n';

@Component
export default class InlineEditTextField extends Vue {
  @Prop() value!: string;
  @Prop() onUpdate!: (value: string) => void;
  @Prop({ default: true }) toggleToEdit!: boolean;
  @Prop({ default: false }) displayOnly!: boolean;
  @Prop({ default: false }) isLoading!: boolean;
  @Prop({ default: '' }) displayOnlyText!: string | TranslateResult;
  @Prop({ default: [] }) rules?: Array<string | boolean | ValidationFunctionType>;
  @Prop() dataCy: string | undefined;
  /**
   * If this is set, then a confirmation logic is set to the component
   * This expects a boolean value to be returned
   * if true it will trigger the toggle edit logic, otherwise it will stop the current user action
   */
  @Prop() confirmationCallback?: () => Promise<boolean>;

  private isReadonly: boolean = true;
  private isDirty: boolean = false;
  private initialValue: string = '';

  created(): void {
    this.isReadonly = this.toggleToEdit;
  }

  /**
   * on update hook
   * focus the input element after the readonly state changed
   */
  async updated(): Promise<void> {
    if (!this.isReadonly && this.toggleToEdit) {
      (this.$refs.textField as HTMLElement).focus();
    }
  }

  /**
   * Updates propagates the input change of v-text-field to this component
   * so the assigned model is updated correctly
   * @param newValue  changed input value
   */
  update(newValue: string): void {
    this.$emit('input', newValue);
  }

  onChange(newValue: string): void {
    this.isDirty = newValue !== this.initialValue;
    this.update(newValue);
  }

  onSubmit(e: FocusEvent): void {
    if (this.isDirty) {
      this.onUpdate(this.value);
      this.isDirty = false;
    }
    if (this.toggleToEdit) {
      const editToggle: Vue | Element | (Vue | Element)[] | undefined = this.$refs?.editToggle;
      if (e.relatedTarget !== (editToggle as Vue)?.$el) {
        this.isReadonly = true;
      }
    }
  }

  async enqueueToggleEditAction(): Promise<void> {
    // If no confirmation logic is set, toggle the button edit
    if (!this.confirmationCallback || !this.isReadonly) {
      this.performToggleEditAction();
    } else {
      // Wait for the user confirmation and perform the adequate action
      const isConfirmed: boolean = await this.confirmationCallback();
      if (!isConfirmed) {
        return;
      }
      this.performToggleEditAction();
    }
  }

  performToggleEditAction(): void {
    this.isReadonly = !this.isReadonly;
    if (!this.isReadonly) {
      this.initialValue = this.value;
    }
  }

  cancelEdit(): void {
    if (this.isDirty) {
      this.isReadonly = true;
      this.isDirty = false;
      this.update(this.initialValue);
    }
  }

  /**
   * Boolean value to indicate if all the rules have been validated!
   * Returns false if at least one rule fails.
   */
  get validateRules(): boolean {
    let isValid: boolean = true;
    if (!this.rules) {
      return isValid;
    }
    for (const rule of this.rules) {
      if (typeof rule === 'string') {
        // If the rule type is a string it should be valid if the string is empty
        isValid = !rule;
      } else if (typeof rule === 'boolean') {
        // If the rule type is boolean return the rule as is
        isValid = rule;
      } else {
        // If the rule is a validation function, return the result
        const result: boolean | TranslateResult = rule(this.value);
        isValid = typeof result === 'boolean' ? result : !result;
      }
      if (!isValid) {
        break;
      }
    }

    return isValid;
  }
}
