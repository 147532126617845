
import { Vue, Component, Prop } from 'vue-property-decorator';
import { scrollToNotification } from '@client/components/NotificationPanel/utils';

@Component({})
export default class NotificationToggle extends Vue {
  @Prop()
  private isExpanded!: boolean;
  @Prop()
  private id!: string;
  @Prop()
  private dataCyAttribute!: string;

  onExpandToggleClick(): void {
    this.$emit('toggle', !this.isExpanded);
    if (!this.isExpanded) {
      scrollToNotification(this.id);
    }
  }

  get chevronIcon(): string {
    return this.isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }
}
