import { IImageOptions, Image, IObjectOptions } from 'fabric/fabric-impl';
import DeviceCanvasItem from './DeviceCanvasItem.model';
import { BaseLayer } from '../ScheduleModels';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { createImageConfig } from '@client/utils/CanvasUtils';

export default class DeviceCanvasBackgroundItem extends DeviceCanvasItem {
  protected setupImage(image: Image, isSelected?: boolean, _isHidden?: boolean, color?: string): void {
    const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
    const config: IObjectOptions & IImageOptions = createImageConfig(
      this.baseLayerModel.offsetX,
      this.baseLayerModel.offsetY,
      color
    );
    image.set(config);
    const onBackgroundSelected = function () {
      gondolaTemplatesStore.updateDeviceContentSelection({
        isForeground: false,
        index: -1,
      });
    };
    if (isSelected != null && isSelected) {
      this.canvas.setActiveObject(image);
    }
    image.on('selected', onBackgroundSelected);
    image.canvas?.requestRenderAll();
  }

  public async render(
    isSelected?: boolean,
    baseLayerModel?: BaseLayer,
    isHidden?: boolean,
    color?: string
  ): Promise<void> {
    await super.render(isSelected, baseLayerModel, isHidden, color);
    // background items must always be at the very back!
    // since background item is not deletable, it could potentially prevent selection of foreground items otherwise
    this.renderedImage.sendToBack();
  }
}
