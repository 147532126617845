
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, Gondola as GondolaModel, Store } from '@client/models';
import { Gondola, ModalDialog, OpenLastPublishedTemplateForSection } from '@client/components';
import { validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { ErrorType } from '@common/error/types';
import SectionActiveHours from '@client/components/ActiveHours/SectionActiveHours.vue';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { equals } from '@client/utils/ActiveHoursUtils';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ErrorObserver, ErrorStore, useErrorStore } from '@client/stores/error';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import SearchTextField from '@client/components/Filters/SearchTextField/SearchTextField.vue';
import DoubleIcon from '@client/components/DoubleIcon/DoubleIcon.vue';
import PublishButton from './Buttons/PublishButton.vue';
import EditGondolaButton from '@client/components/StoreDetail/Buttons/EditGondolaButton.vue';
import DeleteGondolaButton from '@client/components/StoreDetail/Buttons/DeleteGondolaButton.vue';
import CreateTemplateFromSectionButton from '@client/components/StoreDetail/Buttons/CreateTemplateFromSectionButton.vue';
import AislePublishingBadge from '@client/components/StoreDetail/AislePublishingBadge.vue';
import { TranslateResult } from 'vue-i18n';
import ButtonGroup from '@client/components/ButtonGroup/ButtonGroup.vue';
import QuickActionsButton from '@client/components/StoreDetail/Buttons/QuickActionsButton.vue';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import AddSectionsButton from '@client/components/StoreDetail/Buttons/AddSectionsButton.vue';
import AisleButton, { AisleOperation } from '@client/components/StoreDetail/Buttons/AisleButton.vue';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import DeleteAisleButton from '@client/components/StoreDetail/Buttons/DeleteAisleButton.vue';
import CloneSectionButton from '@client/components/StoreDetail/Buttons/CloneSectionButton.vue';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';
import TagReference from '@client/models/SettingsModels/TagReference';
import { includesSelectedTags } from '@client/utils/TagUtils';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import { Dictionary } from 'vue-router/types/router';

type ShownSection = {
  isShown: boolean;
  section: GondolaModel;
};

type ShownAisle = {
  isShown: boolean;
  name: string;
  shownSections: Array<ShownSection>;
};

@Component({
  computed: {
    AisleOperation() {
      return AisleOperation;
    },
  },
  components: {
    SelectTag,
    TagsList,
    CloneSectionButton,
    DeleteAisleButton,
    AisleButton,
    AddSectionsButton,
    Wrapper,
    QuickActionsButton,
    ButtonGroup,
    AislePublishingBadge,
    CreateTemplateFromSectionButton,
    DeleteGondolaButton,
    EditGondolaButton,
    PublishButton,
    SearchTextField,
    SectionActiveHours,
    Gondola,
    ModalDialog,
    OpenLastPublishedTemplateForSection,
    DoubleIcon,
  },
  methods: {
    validateTextFieldLength,
  },
})
export default class StoreDetailGondolas extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreDetailGondolas';
  @Prop()
  private store!: Store;
  private aisleSearchQuery: string = '';
  private sectionSearchQuery: string = '';
  private showDeleteGondolaDialog: boolean = false;
  private VUE_ACCORDION_ANIMATION_DELAY: number = 700;

  private readonly activeHoursSetFilter: string = 'activeHours';
  private readonly hideEmptyAislesFilter: string = 'hideEmptyAisles';
  private selectedFilters: string[] = [];
  private selectedTags: Array<TagReference> = [];

  private cancelHandler: () => void = () => {
    // Will be overridden later
  };
  private confirmHandler: () => void = () => {
    // Will be overridden later
  };

  private actionQueue: Promise<void> = Promise.resolve();

  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private errorStore: ErrorStore = useErrorStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();
  private messagesStore: MessagesStore = useMessagesStore();

  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    this.registerObserver();
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreDetailGondolas.ERROR_OBSERVER_KEY);
  }

  registerObserver(): void {
    // register error handler when azureId is already assigned to another store
    ErrorObserver.create(StoreDetailGondolas.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
        this.storesStore.setUpdatingStoreLoadingIndicator(false);
        this.storesStore.setDeletingStoreLoadingIndicator(false);
      })
      .register();
  }

  openPreselectedAisleAndScroll(): void {
    if (!this.activeAisleName) {
      // No aisle is set, no need to scroll
      return;
    }
    const aisles: Array<string> = this.filteredAisles.map((aisle: ShownAisle) => aisle.name);
    // Find the index of the active aisle
    const index: number = aisles.findIndex((aisle: string) => aisle === this.activeAisleName);
    if (index === -1) {
      // If the aisle is not found, do not scroll
      return;
    }
    const anchorSelector: string = `aisle-${this.activeAisleName}-anchor`;
    let sectionAnchorSelector: string = '';
    if (this.activePositionInAisleName) {
      // If a position in aisle is set, scroll to it
      sectionAnchorSelector = `section-${aisles[index]}-${this.activePositionInAisleName}-anchor`;
    }
    setTimeout(() => {
      const aisleHeader: HTMLElement | null = document.getElementById(anchorSelector);
      if (!aisleHeader?.parentElement?.classList.contains('v-expansion-panel-header--active')) {
        aisleHeader?.parentElement?.click();
      }
      if (sectionAnchorSelector) {
        const sectionHeader: HTMLElement | null = document.getElementById(sectionAnchorSelector);
        sectionHeader?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      } else {
        aisleHeader?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      }
      const query: Dictionary<string | (string | null)[]> = Object.assign({}, this.$route.query);
      delete query.aisle;
      delete query.position;
      this.$router.replace({ query });
    }, this.VUE_ACCORDION_ANIMATION_DELAY);
  }

  /* METHODS */

  onTagListChanged(selectedTags: Array<TagReference>) {
    this.selectedTags = selectedTags;
  }

  isAnyFilterActive(): boolean {
    return this.selectedFilters.length > 0 || this.selectedTags.length > 0;
  }

  filterSections(gondolas?: GondolaModel[]): ShownSection[] {
    if (!gondolas) {
      return [];
    }
    let filteredGondolas: ShownSection[] = gondolas.map((gondola: GondolaModel) => {
      return { isShown: true, section: gondola };
    });
    // No filters are set
    if (
      !this.selectedFilters.includes(this.activeHoursSetFilter) &&
      !this.sectionSearchQuery &&
      !this.selectedFilters.length &&
      !this.selectedTags.length
    ) {
      return filteredGondolas;
    }
    // Apply active hours filter
    if (this.selectedFilters.includes(this.activeHoursSetFilter)) {
      filteredGondolas = filteredGondolas.map((shownSection: ShownSection) => {
        shownSection.isShown =
          shownSection.isShown &&
          shownSection.section.activeHours?.length > 0 &&
          !equals(this.store.activeHours, shownSection.section.activeHours);
        return shownSection;
      });
    }
    // Apply tag filter
    if (this.selectedTags.length) {
      filteredGondolas = filteredGondolas.map((shownSection: ShownSection) => {
        shownSection.isShown =
          shownSection.isShown && includesSelectedTags(shownSection.section.tags, this.selectedTags);
        return shownSection;
      });
    }
    if (!this.sectionSearchQuery) {
      return filteredGondolas;
    }
    // Apply search query filter on gondolas
    return filteredGondolas.map((shownSection: ShownSection) => {
      shownSection.isShown =
        shownSection.isShown &&
        shownSection.section.positionInAisle.toLowerCase().includes(this.sectionSearchQuery.toLowerCase());
      return shownSection;
    });
  }

  getShownSections(shownSections: Array<ShownSection>): Array<GondolaModel> {
    return shownSections
      .filter((shownSection: ShownSection) => shownSection.isShown)
      .map((shownSection: ShownSection) => shownSection.section);
  }

  deleteGondola(gondolaId: string): void {
    this.showDeleteGondolaDialog = true;
    this.confirmHandler = () => {
      this.actionQueue = this.actionQueue.then(() => {
        return this.storesStore.deleteGondola(gondolaId, this.storeId);
      });
      this.showDeleteGondolaDialog = false;
    };
    this.cancelHandler = () => {
      this.showDeleteGondolaDialog = false;
    };
  }

  getSectionActiveHours(gondola: GondolaModel): ActiveHoursSchedule {
    if (gondola.activeHours?.length > 0) {
      return gondola.activeHours;
    }
    return this.store.activeHours;
  }

  /**
   * Checks if the section has at least one valid device id set, otherwise no action can be performed
   * @param railGrid rail grid of the section to check
   */
  isSectionOperable(railGrid: Array<Array<Device>>): boolean {
    return !railGrid.flat(1).some((device: Device) => device.shortId);
  }

  doesAisleContainShownSections(aisle: ShownAisle): boolean {
    return aisle.shownSections.filter((shownSection: ShownSection) => shownSection.isShown).length > 0;
  }

  onAisleCreatedOrUpdated(): void {
    this.$nextTick(() => {
      this.openPreselectedAisleAndScroll();
    });
  }

  copyNameToClipboard(name: string, isSection: boolean = false): void {
    navigator.clipboard.writeText(name);
    if (isSection) {
      this.messagesStore.showMessage(
        this.$t(this.$i18nTranslationKeys.storeDetail.copySectionName.$path, { section: name })
      );
      return;
    }
    this.messagesStore.showMessage(this.$t(this.$i18nTranslationKeys.storeDetail.copyAisleName.$path, { aisle: name }));
  }

  /* GETTERS */

  get isStoreAzureIdSet(): boolean {
    return !this.store.idAzure;
  }

  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update ||
      this.gondolaTemplatesStore.loadingIndicator.update
    );
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get activeAisleName(): string {
    return this.$route.query.aisle as string;
  }

  get activePositionInAisleName(): string {
    return this.$route.query.position as string;
  }

  /**
   * Get all gondolas for the currently selected store, grouped by aisle
   */
  get filteredAisles(): Array<ShownAisle> {
    const result: Array<ShownAisle> = new Array<ShownAisle>();
    if (!this.store) {
      return result;
    }
    for (const aisle of this.store.aisles) {
      const gondolasInAisle: Array<ShownSection> = this.filterSections(aisle.gondolas);
      const newAisle: ShownAisle = {
        isShown: true,
        name: aisle.name,
        shownSections: gondolasInAisle.sort((a: ShownSection, b: ShownSection) =>
          a.section.positionInAisle.toLowerCase() < b.section.positionInAisle.toLowerCase() ? -1 : 1
        ),
      };
      // Apply the set filters
      if (!aisle.name.toLowerCase().includes(this.aisleSearchQuery.toLowerCase())) {
        newAisle.isShown = false;
      }
      // If this is empty and hide empty is active, or search is active, do not show it
      if (
        newAisle.isShown &&
        this.selectedFilters.includes(this.hideEmptyAislesFilter) &&
        this.getShownSections(gondolasInAisle).length === 0
      ) {
        newAisle.isShown = false;
      }
      // Add the final result to the set
      result.push(newAisle);
    }
    setTimeout(() => {
      this.onAisleCreatedOrUpdated();
    }, this.VUE_ACCORDION_ANIMATION_DELAY);
    return result;
  }

  get filters(): Array<{ text: TranslateResult; value: string }> {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.activeHours.section.filterMessage.$path),
        value: this.activeHoursSetFilter,
      },
      {
        text: this.$t(this.$i18nTranslationKeys.activeHours.section.hideEmptyAisles.$path),
        value: this.hideEmptyAislesFilter,
      },
    ];
  }
}
