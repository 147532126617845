import i18n from '@client/plugins/i18n/i18n';
import { FilterOption } from '@client/definitions/dateselector';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { OrderBy } from '@common/enums/Search';

const sortOptions = (): Array<FilterOption> => [
  {
    value: OrderBy.createdAt,
    label: i18n.t(TranslationKeys.filtersAndPagination.sortOrder.createdAt.$path),
  },
  {
    value: OrderBy.updatedAt,
    label: i18n.t(TranslationKeys.filtersAndPagination.sortOrder.updatedAt.$path),
  },
  {
    value: OrderBy.name,
    label: i18n.t(TranslationKeys.filtersAndPagination.sortOrder.name.$path),
  },
];

export { sortOptions };
