
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VIntersectConfig } from '@client/definitions/vue-intersect';

/**
 * Allows infinite scroll.
 * Will check if this is in the user viewport,
 * This will then trigger the callback event.
 */
@Component({})
export default class InfiniteScroll extends Vue {
  @Prop()
  private intersectionCallback?: (isIntersecting: boolean) => void;
  @Prop()
  private isLoading?: boolean;
  @Prop()
  private additionalClasses?: string;
  /**
   * See {@link Threshold}
   */
  @Prop({ default: 0.25 })
  private threshold!: number | Array<number>;

  /* LIFECYCLE EVENTS */
  /* METHODS */

  /**
   * Fired event on intersection state changed
   * @param _entries contains information about current intersections
   * @param _observer The observer object
   * @param isIntersecting State of the component intersection
   * @private
   */
  private onIntersect(
    _entries: Array<IntersectionObserverEntry>,
    _observer: IntersectionObserver,
    isIntersecting: boolean
  ) {
    if (!this.intersectionCallback) {
      return;
    }
    this.intersectionCallback(isIntersecting);
  }

  /* GETTERS */

  /**
   * Returns the "intersect" component config
   */
  get intersectConfig(): VIntersectConfig {
    return {
      handler: this.onIntersect,
      threshold: this.threshold,
    };
  }
}
