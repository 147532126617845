import { GondolaPublishing } from '@client/models';
import { WsPublishingMessage } from '@common/websocket/Publishing';
import { StoresStore, useStoresStore } from '@client/stores/stores';

/**
 * Handle a websocket message containing a publishing update
 * @param wsPublishingMessage the publishing websocket message
 */
const handlePublishingMessage = async (wsPublishingMessage: WsPublishingMessage): Promise<void> => {
  const publishing: GondolaPublishing = GondolaPublishing.fromJSON(wsPublishingMessage.payload.publishing);
  console.debug(
    `Received websocket message; Updating publishing of ${publishing.gondolaId} for store ${publishing.storeIdAzure}`
  );
  const storesStore: StoresStore = useStoresStore();
  await storesStore.updatePublishing(publishing);
};

export { handlePublishingMessage };
