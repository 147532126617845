import { PiniaPluginContext } from 'pinia';
import { AppGlobalStore } from '@client/stores/app-global';
import { RegisterWebsocket, WsMessage } from '@common/websocket/types';
import { handleWebsocketMessage } from '@client/utils/WebsocketMessageHandler/WsMessageHandler';
import i18n from '@client/plugins/i18n/i18n';
import { useWebsocketStore, WebsocketStore } from '@client/stores/websocket';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { watch } from 'vue';
import { TranslationKeys } from '@client/plugins/i18n/locales';

/**
 * The Plugin takes the data received from the websocket to the pinia store and creates the lifecycle bindings
 */
export default function createWebSocketPlugin({ store }: PiniaPluginContext): void {
  // Only listen to the global config store
  if (store.$id === 'appGlobal') {
    let socketInitialized: boolean = false;
    watch(
      () => store.$state.customer,
      () => {
        socketInitialized = checkAndInitWebsocket(socketInitialized, store as AppGlobalStore);
      }
    );
    watch(
      () => store.$state.user,
      () => {
        socketInitialized = checkAndInitWebsocket(socketInitialized, store as AppGlobalStore);
      }
    );
  }
}

export function checkAndInitWebsocket(socketInitialized: boolean, store: AppGlobalStore): boolean {
  if (!socketInitialized && store.customer != null && store.user != null) {
    bindWebsocket(store);
    return true;
  }
  return false;
}

export function retryConnection(store: WebsocketStore, appGlobalStore: AppGlobalStore): void {
  const DEFAULT_RETRY_INTERVAL_MS: number = 10000;

  if (store.reconnectTimeout != null) {
    return;
  }

  const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
    bindWebsocket(appGlobalStore);
    store.clearReconnectTimeout();
  }, DEFAULT_RETRY_INTERVAL_MS);

  store.setReconnectTimeout(timeout);
}

export function bindWebsocket(store: AppGlobalStore): void {
  const websocketStore: WebsocketStore = useWebsocketStore();
  const messagesStore: MessagesStore = useMessagesStore();
  try {
    const websocketProtocol: string = location.protocol === 'https:' ? 'wss' : 'ws';
    const socket: WebSocket = new WebSocket(
      `${websocketProtocol}://${process.env.VUE_APP_BACKEND_HOSTNAME}:${process.env.VUE_APP_BACKEND_PORT}/path`
    );

    websocketStore.setSocket(socket);

    // Connection opened
    socket.addEventListener('open', function () {
      const userData: RegisterWebsocket = {
        customer: store.customer,
        user: store.user || '',
      };

      socket.send(JSON.stringify(userData));
    });

    // Listen for messages
    socket.addEventListener('message', async (event: MessageEvent) => {
      try {
        const parsedMessageObj: WsMessage = JSON.parse(event.data);
        await handleWebsocketMessage(parsedMessageObj);
      } catch (error: unknown) {
        console.error(`could not process message event for data`, event, error);
      }
    });

    socket.addEventListener('error', function () {
      messagesStore.showMessage(i18n.t(TranslationKeys.webSocketMessages.connectionError.$path));
      socket.close();
      retryConnection(websocketStore, store);
    });

    socket.addEventListener('close', function () {
      messagesStore.showMessage(i18n.t(TranslationKeys.webSocketMessages.connectionClose.$path));
      socket.close();
      retryConnection(websocketStore, store);
    });
  } catch (e) {
    messagesStore.showMessage(i18n.t(TranslationKeys.webSocketMessages.connectionFail.$path));
  }
}
