import ActiveHourItem, { ActiveHoursSchedule } from '@client/models/ActiveHoursModels/ActiveHourItem.model';
import { ActiveHourItemJSON, SectionActiveHoursJSON, StoreAndSectionActiveHoursJSON } from '@common/active-hours/types';
import SectionActiveHoursSchedule from '@client/models/ActiveHoursModels/SectionActiveHoursSchedule';

/**
 * This class is responsible for holding data about store active hours and each active hours of each section.
 */
export default class StoreAndSectionActiveHours {
  sectionActiveHours: SectionActiveHoursSchedule[];
  storeActiveHours: ActiveHoursSchedule;

  /**
   * @param sectionActiveHours
   * @param storeActiveHours
   */
  constructor(sectionActiveHours: SectionActiveHoursSchedule[], storeActiveHours: ActiveHoursSchedule) {
    this.sectionActiveHours = sectionActiveHours;
    this.storeActiveHours = storeActiveHours;
  }

  static fromJSON(storeAndSectionActiveHoursJSON: StoreAndSectionActiveHoursJSON): StoreAndSectionActiveHours {
    const sectionActiveHours: SectionActiveHoursSchedule[] = storeAndSectionActiveHoursJSON.sectionActiveHours.map(
      (item: SectionActiveHoursJSON) => SectionActiveHoursSchedule.fromJSON(item)
    );
    const storeActiveHours: ActiveHoursSchedule = storeAndSectionActiveHoursJSON.storeActiveHours.activeHours.map(
      (item: ActiveHourItemJSON) => ActiveHourItem.fromJSON(item)
    );
    return new StoreAndSectionActiveHours(sectionActiveHours, storeActiveHours);
  }
}
