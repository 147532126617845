
import { Component, Vue, Watch } from 'vue-property-decorator';
import { GondolaTemplateContent, TimeSpanCalendar } from '@client/components';
import { GondolaTemplate, Schedule } from '@client/models';
import SchedulesModalDialog from '@client/components/SchedulesModalDialog/SchedulesModalDialog.vue';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { ErrorStore, useErrorStore } from '@client/stores/error';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Optional } from '@common/types';
import TemplateNotFound from '@client/components/NotFound/TemplateNotFound.vue';
import GondolaTemplateLoader from '@client/components/GondolaTemplate/GondolaTemplateOverviewLoader.vue';
import RepublishButton from '@client/components/GondolaTemplate/Buttons/RepublishButton.vue';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';

@Component({
  components: {
    SelectTag,
    Wrapper,
    RepublishButton,
    TemplateNotFound,
    SchedulesModalDialog,
    GondolaTemplateContent,
    TimeSpanCalendar,
    GondolaTemplateLoader,
  },
  methods: {
    validateTextFieldLength,
  },
})
export default class GondolaTemplateDetailView extends Vue {
  private static readonly ERROR_OBSERVER_KEY: string = 'GondolaTemplateDetailView';
  private name: string = '';
  private loading: boolean = false;
  private schedules: Array<Schedule> = [];
  private selectedSchedule: string = '';
  private showListDialog: boolean = false;
  private isDataLoaded: boolean = false;

  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private errorStore: ErrorStore = useErrorStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  async created(): Promise<void> {
    this.schedulesStore.clearSelectedSchedule();
    await this.gondolaTemplatesStore.fetch();
    await this.schedulesStore.fetch();
    this.isDataLoaded = true;
  }

  beforeDestroy(): void {
    this.errorStore.deregister(GondolaTemplateDetailView.ERROR_OBSERVER_KEY);
  }

  @Watch('schedulesStore.schedules', { deep: true })
  @Watch('schedulesStore.selectedSchedule')
  onSchedulesChanged(): void {
    this.selectedSchedule = this.schedulesStore.selectedSchedule;
    this.schedules = this.schedulesStore.getSchedulesForTemplate(this.id);
  }

  @Watch('gondolaTemplate')
  onGondolaTemplateFetched() {
    if (this.gondolaTemplate) {
      this.name = this.gondolaTemplate.name;
      this.breadcrumbsStore.replace({
        path: `/templates`,
        title: { key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path },
      });
      this.breadcrumbsStore.push({
        path: `${this.$route.fullPath}`,
        title: {
          key: this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path,
          params: {
            template: this.name || this.id,
          },
        },
      });
      this.breadcrumbsStore.setCurrentPage(
        this.$t(this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path, {
          template: this.name || this.id,
        }),
        'mdi-clipboard'
      );
      this.schedules = this.schedulesStore.getSchedulesForTemplate(this.id);
    }
  }

  checkAndSetLoading(): boolean {
    if (this.loading) {
      return true;
    }
    this.loading = true;
    return false;
  }

  onLoadingChange(loading: boolean): void {
    this.loading = loading;
  }

  cancelHandler(): void {
    this.showListDialog = false;
  }

  openDialog(): void {
    this.showListDialog = true;
  }

  updateName(): void {
    if (this.name.length > DEFAULT_COMMON_STRING_MAX_LENGTH) {
      return;
    }
    if (this.checkAndSetLoading()) {
      return;
    }
    this.gondolaTemplatesStore.updateName(this.id, this.name).then(() => {
      this.loading = false;
    });
  }

  updateTags(selectedTags: TagReference[]): void {
    this.gondolaTemplatesStore.updateTags(this.id, selectedTags);
  }

  startTour(): void {
    this.$tours['deviceEditHelp']?.start();
  }

  get gondolaTemplate(): Optional<GondolaTemplate> {
    return this.gondolaTemplatesStore.getById(this.id);
  }

  get id(): string {
    return this.$route.params.id;
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH(): number {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get isGondolaTemplateFetching() {
    return this.gondolaTemplatesStore.isFetching;
  }
}
