
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Schedule } from '@client/models';
import { AxiosError } from 'axios';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';

@Component({
  components: {
    ModalDialog: () => import('../ModalDialog/ModalDialog.vue'),
  },
})
export default class DeleteScheduleButton extends Vue {
  @Prop()
  private schedule!: Schedule;
  private isDeleteScheduleDeployed: boolean = false;
  private showConfirmDialog: boolean = false;

  private schedulesStore: SchedulesStore = useSchedulesStore();

  async handleDeleteSchedule(): Promise<void> {
    // Set the selected schedule to the current schedule to delete to force trigger the @Watch event in GondolaTemplateDetailView
    // Else the table won't update if the base layer is already selected
    if (!this.schedule._id) {
      return;
    }
    this.schedulesStore.updateSelectedSchedule(this.schedule._id);
    try {
      await this.schedulesStore.deleteSchedule(this.schedule._id, this.$route.params.id, false, this.schedule.hash);
    } catch (e: unknown) {
      const error: AxiosError = e as AxiosError;
      if (error.response?.status == 403 || error.response?.status == 423) {
        this.showConfirmDialog = true;
        this.isDeleteScheduleDeployed = error.response.status == 403;
      } else {
        console.warn('unexpected error');
        console.warn(e);
      }
    }
  }

  async confirmDialogHandler(): Promise<void> {
    if (this.schedule?._id) {
      await this.schedulesStore.deleteSchedule(this.schedule._id, this.$route.params.id, true, this.schedule.hash);
      this.showConfirmDialog = false;
    }
  }

  cancelConfirmDialogHandler(): void {
    this.showConfirmDialog = false;
  }
}
