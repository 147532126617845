import { WsMessage, WsMessageType } from '@common/websocket/types';
import { handleContentItemMessage } from '@client/utils/WebsocketMessageHandler/ContentItem';
import { handleDeviceMessage } from '@client/utils/WebsocketMessageHandler/Device';
import { handleEventLogMessage } from '@client/utils/WebsocketMessageHandler/EventLog';
import { handlePublishingMessage } from '@client/utils/WebsocketMessageHandler/Publishing';
import { handleGondolaTemplateMessage } from '@client/utils/WebsocketMessageHandler/GondolaTemplate';
import { WsContentItemMessage } from '@common/websocket/ContentItem';
import { WsPublishingMessage } from '@common/websocket/Publishing';
import { WsEventLogMessage } from '@common/websocket/EventLog';
import { WsDeviceMessage } from '@common/websocket/Devices';
import { WsGondolaTemplateMessage } from '@common/websocket/GondolaTemplate';
import { WsStoreSyncMessage } from '@common/websocket/Store';
import { handleStoreSyncMessage } from '@client/utils/WebsocketMessageHandler/Store';

/**
 * Handle a received websocket message
 * @param wsMessage the websocket message
 */
const handleWebsocketMessage = async (wsMessage: WsMessage): Promise<void> => {
  const type: WsMessageType = wsMessage?.type;
  if (!type) {
    console.warn('received invalid websocket message', wsMessage);
    return;
  }
  switch (type) {
    case WsMessageType.EventLog:
      await handleEventLogMessage(wsMessage as WsEventLogMessage);
      break;
    case WsMessageType.Device:
      await handleDeviceMessage(wsMessage as WsDeviceMessage);
      break;
    case WsMessageType.Publishing:
      await handlePublishingMessage(wsMessage as WsPublishingMessage);
      break;
    case WsMessageType.ContentItem:
      await handleContentItemMessage(wsMessage as WsContentItemMessage);
      break;
    case WsMessageType.GondolaTemplate:
      await handleGondolaTemplateMessage(wsMessage as WsGondolaTemplateMessage);
      break;
    case WsMessageType.StoreSync:
      await handleStoreSyncMessage(wsMessage as WsStoreSyncMessage);
      break;
    default:
      console.warn(`Received Websocket message of unrecognized type ${type}`);
  }
};
export { handleWebsocketMessage };
