
import { Vue, Component, Prop } from 'vue-property-decorator';
import AffectedStoresModal from '@client/components/Settings/ReleaseManagement/AffectedStoresModal.vue';
import Tag from '@client/models/SettingsModels/Tag.model';
import AffectedEntitiesModal from '@client/components/Settings/Tags/AffectedEntitiesModal.vue';
import { Optional } from '@common/types';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { AffectedEntitiesViewOptions } from '@client/models/SettingsModels/AffectedEntitiesViewOptions';

@Component({
  computed: {
    AffectedEntitiesViewOptions() {
      return AffectedEntitiesViewOptions;
    },
  },
  components: { AffectedEntitiesModal, AffectedStoresModal },
})
export default class SaveButton extends Vue {
  @Prop()
  private oldTagValues: Optional<Tag>;
  @Prop()
  private isValid!: boolean;
  @Prop()
  private isSameAsInitValue!: boolean;

  private showDialog: boolean = false;
  private tagsStore: TagsStore = useTagsStore();

  confirmSave(): void {
    this.$emit('save-changes');
  }
  closeDialog(): void {
    this.showDialog = false;
  }
  get isDisabled(): boolean {
    return !this.isValid || this.isSameAsInitValue;
  }
  get hasAffectedEntities(): boolean {
    if (!this.oldTagValues) {
      return false;
    }
    if (
      this.oldTagValues.stores.length ||
      this.oldTagValues.sections.length ||
      this.oldTagValues.templates.length ||
      this.oldTagValues.contentItems.length
    ) {
      return true;
    }
    return false;
  }
  get isLoading(): boolean {
    return this.tagsStore.isFetching || this.tagsStore.isActionPending;
  }
}
