
import { Component, VModel, Vue, Watch } from 'vue-property-decorator';
import TimePicker from '@client/components/TimePicker/TimePicker.vue';
import { ActiveHourDay, ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { isActiveAllDay } from '@client/utils/ActiveHoursUtils';
import { TranslateResult } from 'vue-i18n';
import { isEndTimeBeforeStartTime, timeValidationRegex } from '@client/utils/DateTimeUtils';
import { TimeValidationError } from '@client/components/ActiveHours/ActiveHours.vue';

@Component({
  methods: { isActiveAllDay },
  components: { TimePicker },
})
export default class ActiveHoursTable extends Vue {
  /* DECLARATIONS */
  private static readonly NUMBER_OF_DAYS_IN_WEEK: number = 7;

  @VModel({ required: true, type: Array, default: [] })
  localActiveHoursSchedule!: ActiveHoursSchedule;

  errors: TimeValidationError[] = [];
  /* LIFECYCLE EVENTS */

  created() {
    this.errors = Array.from({ length: ActiveHoursTable.NUMBER_OF_DAYS_IN_WEEK }, () => ({
      startTime: false,
      endTime: false,
      endTimeBigger: false,
    }));
  }

  /* METHODS */

  /**
   * Check if devices are turned off for this day
   * Represented by empty array
   * @param activeHours schedule for specific day
   */
  isTurnedOff(activeHours: ActiveHourDay[]): boolean {
    return !activeHours.length;
  }

  /**
   * Check whether devices are active whole day or only for specific time
   * Based on this check, determine which icon should be used
   * @param activeHours for that specific day
   */
  determineTimeIcon(activeHours: ActiveHourDay[]): string {
    return isActiveAllDay(activeHours) ? 'mdi-hours-24' : 'mdi-clock-time-four-outline';
  }

  getStartTimeFromActiveHourDay(activeHourSchedule: Array<ActiveHourDay>): string {
    return activeHourSchedule[0]?.start || '00:00';
  }

  getEndTimeFromActiveHourDay(activeHourSchedule: Array<ActiveHourDay>): string {
    return activeHourSchedule[0]?.end || '24:00';
  }

  validateStartTime(value: string, activeHourItem: ActiveHourItem, index: number): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    this.errors[index].startTime = !isValid;
    if (isValid) {
      activeHourItem.schedule[0].start = value;
      // We need to re-trigger the end time validation AFTER we set the start time,
      // Since we're using the state start time to compare to the actual end time, else it would compare the old value
      (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.onChangeOrInput(
        activeHourItem.schedule[0].end
      );
      return [];
    }
    (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.onChangeOrInput(
      activeHourItem.schedule[0].end
    );
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.startTime.$path)];
  }

  validateEndTime(value: string, activeHourItem: ActiveHourItem, index: number): Array<string | TranslateResult> {
    const isValid: boolean = timeValidationRegex.test(value);
    const isEndOfDay: boolean = value === '24:00';
    const hasEndTimeError: boolean = this.isEndTimeBeforeStartTime(index, activeHourItem.schedule[0].start, value);
    this.errors[index].endTime = !isValid && (hasEndTimeError || !isEndOfDay);
    if (isValid || isEndOfDay) {
      activeHourItem.schedule[0].end = value;
    }
    if ((isValid && !hasEndTimeError) || isEndOfDay) {
      return [];
    }
    return [this.$t(this.$i18nTranslationKeys.schedules.errors.endTime.$path)];
  }

  private isEndTimeBeforeStartTime(index: number, startTime: string, endTime: string): boolean {
    const hasError: boolean = isEndTimeBeforeStartTime(startTime, endTime);
    this.errors[index].endTimeBigger = hasError;
    return hasError;
  }
  /**
   * Make devices active all day for selected day
   * @param activeHourItem selected day
   * @param index of day, used for error
   */
  resetActiveHours(activeHourItem: ActiveHourItem, index: number): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    const activeAllDayItem: ActiveHourItem = new ActiveHourItem(activeHourItem.day, [
      new ActiveHourDay('00:00', '24:00', 100),
    ]);

    const arrayIndex: number = this.localActiveHoursSchedule.indexOf(activeHourItem);
    if (arrayIndex !== -1) {
      Vue.set(this.localActiveHoursSchedule, arrayIndex, activeAllDayItem);
      this.resetValidationErrors(index);
      (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
      (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    }
  }

  /**
   * Turn off devices for selected day
   * @param activeHourItem selected day
   * @param index of day, used for error
   */
  turnOff(activeHourItem: ActiveHourItem, index: number): void {
    if (!this.localActiveHoursSchedule) {
      return;
    }
    const turnedOffItem: ActiveHourItem = new ActiveHourItem(activeHourItem.day, []);
    const arrayIndex: number = this.localActiveHoursSchedule.indexOf(activeHourItem);
    (this.$refs[`endTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    (this.$refs[`startTime${index}`] as Array<TimePicker> | undefined)?.[0]?.initialize();
    if (arrayIndex !== -1) {
      Vue.set(this.localActiveHoursSchedule, arrayIndex, turnedOffItem);
      this.resetValidationErrors(index);
    }
  }

  private resetValidationErrors(index: number) {
    const errorFreeObject: TimeValidationError = { startTime: false, endTime: false, endTimeBigger: false };
    Vue.set(this.errors, index, errorFreeObject);
  }

  @Watch('errors', { deep: true })
  onErrorsChanged(): void {
    this.$emit('errors-changed', this.errors);
  }
  /* GETTERS */
}
