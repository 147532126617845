
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Device, Gondola, Store } from '@client/models';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import spacetime from 'spacetime';
import { formatTimezone } from '@client/utils/timezone';
import { TranslateResult } from 'vue-i18n';
import { isInputOnlyAscii } from '@common/utils/validation';
import i18n from '@client/plugins/i18n/i18n';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import SelectTag from '@client/components/Settings/Tags/Actions/SelectTag.vue';
import TagReference from '@client/models/SettingsModels/TagReference';

/**
 * Entry of the timezone autocomplete component
 */
export interface TimezoneAutoCompleteEntry {
  displayText: string;
  iana: string;
}

@Component({
  methods: {
    validateTextFieldLength,
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
  },
  components: { AssignTag: SelectTag, ModalHeader },
})
export default class EditStoreSettingsButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  private currentTags: Array<TagReference> = [];

  private storesStore: StoresStore = useStoresStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  private showDialog: boolean = false;

  private storeName: string = '';
  private storeIdAzure: string = '';
  private timezone: string = '';
  /* LIFECYCLE EVENTS */

  created(): void {
    this.init();
  }

  /* METHODS */

  private init(): void {
    this.storeName = this.store.name;
    this.storeIdAzure = this.store.idAzure;
    this.timezone = this.store.timezone;
    this.currentTags = this.store.tags.map((tag: TagReference) => new TagReference(tag.id, tag.name, tag.color));
  }

  cancelEditStore(): void {
    this.init();
    this.showDialog = false;
  }

  isTagListChanged() {
    if (this.currentTags.length !== this.store.tags.length) {
      return true;
    }
    for (const currentTag of this.currentTags) {
      if (this.store.tags.findIndex((tagReference: TagReference) => tagReference.id === currentTag.id) !== -1) {
        return true;
      }
    }
    return false;
  }

  onTagListChanged(selectedTags: Array<TagReference>) {
    this.currentTags = selectedTags;
  }

  async editStoreSettings(): Promise<void> {
    // if no changes, close dialog
    const azureIdChanged: boolean = this.storeIdAzure !== this.store.idAzure;
    if (
      this.storeIdAzure === this.store.idAzure &&
      this.storeName === this.store.name &&
      this.timezone === this.store.timezone &&
      !this.isTagListChanged()
    ) {
      this.showDialog = false;
      return;
    }
    if (this.validateStoreAzureId(this.storeIdAzure) !== true) {
      return;
    }
    if (validateTextFieldLength(this.storeName, DEFAULT_COMMON_STRING_MAX_LENGTH, true) !== true) {
      return;
    }
    if (this.currentTags.length > 5) {
      return;
    }
    if (!this.timezone) {
      return;
    }
    await this.storesStore.updateStoreSettings(
      this.store._id,
      this.storeName,
      this.timezone,
      this.storeIdAzure,
      this.currentTags
    );
    if (azureIdChanged) {
      await this.activeHoursStore.fetchActiveHours(this.store._id);
    }
    this.showDialog = false;
    this.init();
  }

  validateStoreAzureId(value: string): boolean | TranslateResult {
    if (!isInputOnlyAscii(value)) {
      return i18n.t(this.$i18nTranslationKeys.error.nonASCIIInput.$path);
    }
    const maxInternalLength: number = 128;
    const customerIdLength: number = this.appGlobalStore.customer.length;
    // azure id internally on APIM is saved as "{customerId}-{AzureId}" and limited to 128 char
    const maxAzureIdLength: number = maxInternalLength - customerIdLength - 1;
    return validateTextFieldLength(value, maxAzureIdLength, true);
  }

  /* GETTERS */

  get isLoading(): boolean {
    return (
      this.storesStore.isFetching ||
      this.store.isStoreSyncing ||
      this.storesStore.loadingIndicator.update ||
      this.activeHoursStore.loadingIndicator.update
    );
  }

  get timezones(): TimezoneAutoCompleteEntry[] {
    return Object.keys(spacetime.timezones()).map((timezone: string) => {
      // get GMT offset and add offset as prefix to display text (note that the GMT offset is DST-aware and uses the timezone based on the current datetime)
      const offset: number = spacetime().goto(timezone).timezone().current.offset;
      const offsetPrefix: string = (offset >= 0 && `(GMT +${offset})`) || `(GMT ${offset})`;

      const timezoneFormatted: string = formatTimezone(timezone);
      return {
        displayText: `${offsetPrefix} ${timezoneFormatted}`,
        iana: timezoneFormatted,
      };
    });
  }

  get isAzureIdEditable(): boolean {
    const flattenedRailGrid: Array<Device> = this.store.gondolas.flatMap((gondola: Gondola) => gondola.railGrid.flat());
    return flattenedRailGrid.every((device: Device) => {
      return device.longId == undefined || device.longId.length == 0;
    });
  }

  get isAzureIdChanged(): boolean {
    return this.store.idAzure !== this.storeIdAzure && this.store.idAzure !== '';
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.validateStoreAzureId(this.storeIdAzure) !== true ||
      validateTextFieldLength(this.storeName, DEFAULT_COMMON_STRING_MAX_LENGTH, true) !== true ||
      !this.timezone ||
      this.currentTags.length > 5
    );
  }
}
