import { TagReferenceJSON } from '@common/config/tags/types';

/**
 * Represents a reference to a tag.
 */
export default class TagReference {
  id: string;
  name: string;
  color: string;
  constructor(id: string, name: string, color: string) {
    this.id = id;
    this.name = name;
    this.color = color;
  }

  static fromJSON(tagReferenceJSON: TagReferenceJSON): TagReference {
    return new TagReference(tagReferenceJSON.id, tagReferenceJSON.name, tagReferenceJSON.color);
  }

  static toJSON(tagsReference: TagReference): TagReferenceJSON {
    return { id: tagsReference.id, name: tagsReference.name, color: tagsReference.color };
  }
}
