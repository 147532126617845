
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import { Device } from '@client/models';
import { getDeviceDetailPath } from '@client/router/utils';

@Component({
  components: { ModalHeader },
})
export default class FirmwareReleasedAffectedDevicesModal extends Vue {
  @Prop()
  private affectedDevices!: Device[];
  @Prop()
  private closePanel!: () => void;

  private showDialog: boolean = false;
  closeDialog(): void {
    this.showDialog = false;
  }

  navigateToDeviceDetail(longId: string): void {
    const link: string = getDeviceDetailPath(longId);
    if (this.$route.fullPath === link) {
      this.closePanel();
      return;
    }
    this.$router.push(link);
  }

  get title(): TranslateResult {
    return `${this.affectedDevices.length} ${this.$t(
      this.$i18nTranslationKeys.notifications.firmwareReleased.affectedDevicesModal.title.$path
    )}`;
  }
}
