
import axios from 'axios';
import Config from './utils/config';
import { Component, Vue } from 'vue-property-decorator';
import './components/Filters/filters.scss';
import NavigationWrapper from './components/Layouts/NavigationWrapper.vue';
import Header from '@client/components/Layouts/Header.vue';

// Dev commands trigger-able by commandline
declare global {
  interface Window {
    lsdev: {
      listPublishJobs: () => Promise<void>;
      purgePublishJobs: (storeId: string) => Promise<void>;
      recreateStoreWebhook: (azureStoreId: string, customerId: string) => Promise<void>;
      getVersion: () => void;
      getApiUrl: () => void;
    };
  }
}
window.lsdev = window.lsdev || {
  listPublishJobs: async () => {
    console.debug((await axios.get(`${Config.getApiUrl()}/publishing/jobs`)).data);
  },
  purgePublishJobs: async (storeIdAzure: string) => {
    if (!storeIdAzure) {
      return;
    }
    console.debug((await axios.delete(`${Config.getApiUrl()}/publishing/jobs?storeIdAzure=${storeIdAzure}`)).data);
  },
  getVersion: () => {
    console.debug(`${process.env.GIT_SHORT}`);
  },
  recreateStoreWebhook: async (azureStoreId: string, customerId: string) => {
    if (!azureStoreId) {
      return;
    }
    console.debug(
      (
        await axios.post(
          `${Config.getApiUrl()}/stores/recreate-webhook/${azureStoreId}`,
          {},
          {
            params: {
              customerId: customerId,
            },
          }
        )
      ).data
    );
  },
  getApiUrl: () => {
    console.debug(`API url: ${Config.getApiUrl()}`);
  },
};

@Component({
  components: { Header, NavigationWrapper },
})
export default class App extends Vue {}
