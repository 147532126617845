import {
  PublishingDeviceEventState,
  PublishingDeviceEventType,
  PublishingDeviceEventUpdateJSON,
} from '@common/eventlog/types';

/**
 * Details of an occurred event
 * Can be empty in the parent object array in case of simple events
 */
export default class PublishingDeviceEventUpdate {
  id: string;
  message: string;
  state: PublishingDeviceEventState;
  type: PublishingDeviceEventType;
  timestamp: string;

  constructor(
    message: string,
    state: PublishingDeviceEventState,
    type: PublishingDeviceEventType,
    timestamp: string,
    id: string
  ) {
    this.message = message;
    this.state = state;
    this.type = type;
    this.timestamp = timestamp;
    this.id = id;
  }

  public static fromJSON(source: PublishingDeviceEventUpdateJSON): PublishingDeviceEventUpdate {
    return new PublishingDeviceEventUpdate(source.message, source.state, source.type, source.timestamp, source.id);
  }
}
