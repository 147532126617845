import { GondolaTemplateWithIdJSON } from '@common/gondola-template/types';
import { WsMessage, WsMessageType, WsPayload } from '@common/websocket/types';

export enum GondolaTemplateAction {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
  BulkUpdate = 'BulkUpdate',
}

export interface WsBulkUpdateGondolaTemplatePayload extends WsPayload {
  gondolaTemplates: Array<GondolaTemplateWithIdJSON>;
}

export class WsGondolaTemplateMessage implements WsMessage {
  type: WsMessageType = WsMessageType.GondolaTemplate;
  payload: WsPayload;
  action: GondolaTemplateAction;

  constructor(payload: WsPayload, action: GondolaTemplateAction) {
    this.payload = payload;
    this.action = action;
  }
}

export class WsBulkUpdateGondolaTemplateMessage implements WsGondolaTemplateMessage {
  type: WsMessageType = WsMessageType.GondolaTemplate;
  payload: WsBulkUpdateGondolaTemplatePayload;
  action: GondolaTemplateAction = GondolaTemplateAction.BulkUpdate;

  constructor(payload: WsBulkUpdateGondolaTemplatePayload) {
    this.payload = payload;
  }
}
