
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import Tag from '@client/models/SettingsModels/Tag.model';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Gondola, GondolaTemplate, Store } from '@client/models';
import { Optional } from '@common/types';
import { getStoreDetailPath } from '@client/router/utils';
import { TranslateResult } from 'vue-i18n';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItem } from '@client/models/ContentModels/types';
import { AffectedEntitiesViewOptions } from '@client/models/SettingsModels/AffectedEntitiesViewOptions';
import { TagReferenceType } from '@common/config/tags/types';

interface Link {
  value: string;
  text: string;
}
@Component({
  components: { ModalHeader },
})
export default class AffectedEntitiesModal extends Vue {
  @Prop()
  private type: Optional<TagReferenceType>;
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private tag!: Tag;
  @Prop({ default: false })
  private hasSaveAction!: boolean;
  @Prop({ default: false })
  private hasDeleteAction!: boolean;
  @Prop()
  private dialogMessage: Optional<TranslateResult>;
  @Prop({ default: AffectedEntitiesViewOptions.SINGLE_TYPE_TABLE })
  private viewOption!: AffectedEntitiesViewOptions;

  private tagsStore: TagsStore = useTagsStore();
  private storesStore: StoresStore = useStoresStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();

  private activeTab: string = 'store';
  private tagTypes: TagReferenceType[] = ['store', 'template', 'section', 'contentItems'];

  closeDialog(): void {
    this.$emit('dialog-closed');
  }

  saveAndClose(): void {
    this.$emit('save-and-close');
  }

  getLengthForType(type: TagReferenceType): number {
    switch (type) {
      case 'store': {
        return this.tag.stores.length;
      }
      case 'template': {
        return this.tag.templates.length;
      }
      case 'section': {
        return this.tag.sections.length;
      }
      case 'contentItems': {
        return this.tag.contentItems.length;
      }
      default: {
        return 0;
      }
    }
  }

  getTabHeaderFor(type: TagReferenceType): TranslateResult {
    switch (type) {
      case 'store': {
        return this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.stores.$path);
      }
      case 'template': {
        return this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.templates.$path);
      }
      case 'section': {
        return this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.sections.$path);
      }
      case 'contentItems': {
        return this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.contentItems.$path);
      }
      default: {
        return '';
      }
    }
  }

  tableElements(type: Optional<TagReferenceType>): Array<Link> {
    switch (type) {
      case 'store': {
        return this.storeLinks;
      }
      case 'template': {
        return this.templateLinks;
      }
      case 'section': {
        return this.sectionLinks;
      }
      case 'contentItems': {
        return this.contentItemsLinks;
      }
      default: {
        // if no specific type gets passed, all available entities with this tag should be shown
        return [...this.storeLinks, ...this.templateLinks, ...this.sectionLinks, ...this.contentItemsLinks];
      }
    }
  }

  async deleteTag(): Promise<void> {
    await this.tagsStore.deleteTag(this.tag);
    this.closeDialog();
  }

  get storeLinks(): Array<Link> {
    const result: Link[] = [];
    this.tag.stores.map((storeId: string) => {
      const store: Optional<Store> = this.storesStore.getStoreById(storeId);
      if (store) {
        result.push({
          value: getStoreDetailPath(store._id),
          text: store.name,
        });
      }
    });
    return result;
  }

  get templateLinks(): Array<Link> {
    const result: Link[] = [];
    this.tag.templates.map((templateId: string) => {
      const template: Optional<GondolaTemplate> = this.gondolaTemplatesStore.getById(templateId);
      if (template) {
        result.push({
          value: `/template/${template._id}`,
          text: template.name,
        });
      }
    });
    return result;
  }

  get sectionLinks(): Array<Link> {
    const result: Link[] = [];
    this.tag.sections.forEach((sectionId: string) => {
      const sectionStore: Optional<Store> = this.storesStore.getStoreWithGondolaId(sectionId);
      if (!sectionStore) {
        return;
      }
      const gondola: Optional<Gondola> = sectionStore.gondolas.find((gondola: Gondola) => gondola._id === sectionId);
      if (!gondola) {
        return;
      }

      result.push({
        value: getStoreDetailPath(sectionStore._id, gondola.aisle, gondola.positionInAisle),
        text: `${sectionStore.name} - ${this.$t(this.$i18nTranslationKeys.notifications.aisle.$path)} ${
          gondola.aisle
        } - ${this.$t(this.$i18nTranslationKeys.notifications.section.$path)} ${gondola.positionInAisle}`,
      });
    });
    return result;
  }

  get contentItemsLinks(): Array<Link> {
    const result: Link[] = [];
    this.tag.contentItems.forEach((contentItemId: string) => {
      const contentItem: Optional<ContentItem> = this.contentItemsStore.getContentItemById(contentItemId);
      if (!contentItem) {
        return;
      }
      const originalName: string = this.contentItemsStore.getOriginalNameByAzureBlobName(contentItem.name);
      result.push({
        value: `assets/${contentItem.parentFolder ?? ''}`,
        text: originalName ? originalName : contentItem.name,
      });
    });
    return result;
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$t(this.$i18nTranslationKeys.settings.tags.tableHeaders.name.$path),
        value: 'link',
      },
    ];
  }

  get isLoading(): boolean {
    return this.tagsStore.isFetching || this.tagsStore.isActionPending;
  }

  get isListView(): boolean {
    return this.viewOption === AffectedEntitiesViewOptions.LIST;
  }

  get isSingleTypeTableView(): boolean {
    return this.viewOption === AffectedEntitiesViewOptions.SINGLE_TYPE_TABLE;
  }

  get isAllTypesTableView(): boolean {
    return this.viewOption === AffectedEntitiesViewOptions.ALL_TYPES_TABLE;
  }

  get isNotAssigned(): boolean {
    return (
      this.tag.stores.length === 0 &&
      this.tag.templates.length === 0 &&
      this.tag.sections.length === 0 &&
      this.tag.contentItems.length === 0
    );
  }
}
