import { ActiveHourDay, ActiveHourItem, ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import { Optional } from '@common/types';
import ScheduleSpanModel from '@client/models/ScheduleModels/ScheduleSpan.model';

/**
 * Check if devices are active for the whole day
 * @param activeHours schedule for specific day
 */
const isActiveAllDay = (activeHours: ActiveHourDay[]): boolean => {
  if (activeHours.length !== 1) {
    return false;
  }
  return activeHours[0].start === '00:00' && activeHours[0].end === '24:00';
};

const isStoreActiveHoursInDefaultState = (activeHoursSchedule: ActiveHoursSchedule): boolean => {
  return activeHoursSchedule.some((item: ActiveHourItem) => !isActiveAllDay(item.schedule));
};

/**
 * Deep equals check of two {@link ActiveHoursSchedule} objects
 * @param first
 * @param second
 */
const equals = (first: ActiveHoursSchedule, second: ActiveHoursSchedule): boolean => {
  for (const itemFromFirst of first) {
    const itemFromSecond: Optional<ActiveHourItem> = second.find(
      (item: ActiveHourItem) => item.day === itemFromFirst.day
    );
    if (!itemFromSecond) {
      return false;
    }
    if (itemFromSecond.schedule.length !== itemFromFirst.schedule.length) {
      return false;
    }
    for (let i: number = 0; i < itemFromFirst.schedule.length; i++) {
      if (
        itemFromFirst.schedule[i].start !== itemFromSecond.schedule[i].start ||
        itemFromFirst.schedule[i].end !== itemFromSecond.schedule[i].end ||
        itemFromFirst.schedule[i].dimmingLevel !== itemFromSecond.schedule[i].dimmingLevel
      ) {
        return false;
      }
    }
  }
  return true;
};

/**
 * Convert {@link ActiveHoursSchedule} to {@link ScheduleSpanModel}
 * @param activeHorusSchedule active hours schedule to convert
 */
const activeHoursScheduleToScheduleSpans = (activeHorusSchedule: ActiveHoursSchedule): Array<ScheduleSpanModel> => {
  return activeHorusSchedule
    .filter((activeHourItem: ActiveHourItem) => activeHourItem.schedule.length > 0)
    .map((activeHourItem: ActiveHourItem) => activeHourItemToScheduleSpan(activeHourItem));
};

/**
 * Convert {@link ActiveHourItem} to {@link ScheduleSpanModel}
 * @param activeHourItem active hour item to convert
 */
const activeHourItemToScheduleSpan = (activeHourItem: ActiveHourItem): ScheduleSpanModel => {
  const now: Date = new Date();
  const lastWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
  const nextWeek: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7);
  return new ScheduleSpanModel(lastWeek, nextWeek, activeHourItem.schedule[0].start, activeHourItem.schedule[0].end, [
    activeHourItem.day,
  ]);
};

export {
  isActiveAllDay,
  isStoreActiveHoursInDefaultState,
  equals,
  activeHoursScheduleToScheduleSpans,
  activeHourItemToScheduleSpan,
};
