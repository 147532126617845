
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Optional } from '@common/types';
import router from '@client/router';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({})
export default class CloneButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondolaIndex!: number;
  @Prop()
  private gondolaTemplateId?: string;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async cloneGondola(gondolaTemplateId?: string): Promise<void> {
    if (!gondolaTemplateId) {
      return;
    }
    const gondolaTemplateCloneId: Optional<string> = await this.gondolaTemplatesStore.clone(gondolaTemplateId);
    if (!gondolaTemplateCloneId) {
      return;
    }
    await router.push(`/template/${gondolaTemplateCloneId}`);
  }
  /* GETTERS */
}
