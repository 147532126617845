
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Device, DevicePublishing, GondolaPublishing } from '@client/models';
import Preview from '../Preview/Preview.vue';
import { TranslateResult } from 'vue-i18n';
import spacetime from 'spacetime';
import { OnlineStatus } from '@common/enums';
import ClientPublishState from '@client/enums/ClientPublishState';
import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import DevicePublishingBackgroundContentModel from '@client/models/DeviceModels/DevicePublishingBackgroundContentModel';
import PublishingNextRetryCountdown from '@client/components/NotificationPanel/Notifications/PublishingNextRetryCountdown.vue';

@Component({
  components: {
    PublishingNextRetryCountdown,
    Preview,
  },
})
export default class GondolaDevice extends Vue {
  @Prop() private device!: Device;
  @Prop() private row!: number;
  @Prop() private col!: number;
  @Prop() private devicePublishing?: DevicePublishing;
  @Prop() private lastPublishing?: GondolaPublishing;
  @Prop() private selectedSchedule?: string;
  @Prop() private storeTimezone!: string;
  private messagesStore: MessagesStore = useMessagesStore();

  private showLongId: boolean = false;

  get getSize(): string {
    return `device-${this.device.hardwareModel.identifier}`;
  }

  get getContainerClass(): string {
    if (!this.device.shortId) {
      return `${this.getSize} mr-2`;
    }
    return `${this.getSize} device-info-margin`;
  }

  get getRailClass(): string {
    if (!this.device.shortId) {
      return 'device__badges';
    }
    return 'device__badges device__gondola_badges';
  }

  get getRailGroupColor(): string | undefined {
    return this.devicePublishing?.railGroup.color;
  }

  get getBackgroundContentItem(): DevicePublishingBackgroundContentModel | undefined {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHED
      ? this.devicePublishing.backgroundContent[0]
      : undefined;
  }

  get getForegroundContentItems(): Array<DevicePublishingForegroundContentModel> {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHED
      ? this.devicePublishing.foregroundContent
      : new Array<DevicePublishingForegroundContentModel>();
  }

  getOnlineStatusClass(): string {
    return this.device.onlineStatus === OnlineStatus.ONLINE ? 'success' : 'error';
  }

  showOnlineStatus(): boolean {
    return !!this.device.onlineStatus;
  }

  showRailGroupIcon(): boolean {
    return (this.devicePublishing?.railGroup?.color && !this.devicePublishing.railGroup.isUnlinked) || false;
  }

  isPublishingValid(): boolean {
    return !!this.devicePublishing && this.devicePublishing.longId === this.device.longId;
  }

  hasValidContent(): boolean {
    return this.isPublishingValid() && (!!this.getBackgroundContentItem || !!this.getForegroundContentItems.length);
  }

  isPublishingPending(): boolean | undefined {
    return (
      this.devicePublishing?.publishInformation.state !== ClientPublishState.PUBLISHED &&
      this.devicePublishing?.publishInformation.state !== ClientPublishState.DIMENSION_MISMATCH &&
      this.devicePublishing?.publishInformation.state !== ClientPublishState.ACCEPTED
    );
  }

  isPublishingAccepted(): boolean | undefined {
    return this.devicePublishing?.publishInformation.state === ClientPublishState.ACCEPTED;
  }

  isPublishingFailed(): boolean | undefined {
    return (
      this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHING_DENIED ||
      this.devicePublishing?.publishInformation.state === ClientPublishState.PUBLISHING_FAILED
    );
  }

  publishingFailedMessage(): string | undefined {
    return this.devicePublishing?.publishInformation?.message;
  }

  formatOnlineStatus(): TranslateResult {
    if (!this.device.onlineStatus) {
      return '';
    }
    return this.$t(this.$i18nTranslationKeys.device.status[this.device.onlineStatus].$path, {
      date: spacetime(this.device.onlineStatusLastChanged || new Date())
        .goto(this.storeTimezone)
        // e.g. 2021 Jan 5th, 4:59pm
        .format('{year} {nice-short}'),
    });
  }

  copyLongId(device: Device): void {
    navigator.clipboard.writeText(device.longId);
    this.messagesStore.showMessage(
      this.$t(this.$i18nTranslationKeys.device.copyLongId.$path, { shortId: device.shortId })
    );
    this.showLongId = false;
  }

  get isLastAttemptReached(): boolean {
    return (this.lastPublishing?.previousAttempts?.length || 0) > 5;
  }

  get isNextRunValueAvailable(): boolean {
    return !!this.lastPublishing && !!this.lastPublishing.nextRunAt && !this.isLastAttemptReached;
  }
}
