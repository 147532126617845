import { DeviceGroupJSON } from '@common/publishing/types';

export const UNLINKED_GROUP_COLOR: string = '#FFFFFF';

export default class DeviceGroup {
  color?: string;
  isUnlinked?: boolean;

  constructor(color: string, isUnlinked: boolean = true) {
    this.color = color;
    this.isUnlinked = isUnlinked;
  }

  public static DeviceGroupUnlinked: DeviceGroup = new DeviceGroup(UNLINKED_GROUP_COLOR, true);

  public static DeviceGroups: DeviceGroup[] = [
    new DeviceGroup('#CC0001', false),
    new DeviceGroup('#E36101', false),
    new DeviceGroup('#FFCC00', false),
    new DeviceGroup('#009900', false),
    new DeviceGroup('#0066CB', false),
    DeviceGroup.DeviceGroupUnlinked,
  ];

  public static fromJSON(source: DeviceGroupJSON): DeviceGroup {
    return new DeviceGroup(source.color || '', source.isUnlinked);
  }

  public toJSON(): DeviceGroupJSON {
    return {
      color: this.color,
      isUnlinked: this.isUnlinked,
    };
  }
}
