import { LayerPlaylistItemJSON, ScheduleLayerModelJSON } from '@common/schedule/types';
import LayerPlaylistItem from '@client/models/ScheduleModels/LayerPlaylistItem.model';
import { ContentType, LayerVisibility } from '@common/enums';
import ContentLayerModel from '@client/models/ScheduleModels/ContentLayer.model';

export default class ScheduleLayerModel extends ContentLayerModel {
  visibility: LayerVisibility;

  constructor(
    name: string,
    url: string,
    previewUrl: string,
    type: ContentType,
    height: number,
    width: number,
    duration: number = 0,
    fps: number = 0,
    offsetX: number,
    offsetY: number,
    zIndex: number,
    checksum: string | undefined,
    layerId: string | undefined,
    visibility: LayerVisibility,
    playlist: Array<LayerPlaylistItem>
  ) {
    super(
      name,
      url,
      previewUrl,
      type,
      height,
      width,
      duration,
      fps,
      offsetX,
      offsetY,
      zIndex,
      checksum,
      layerId,
      playlist
    );
    this.visibility = visibility;
  }

  public static fromJSON(json: ScheduleLayerModelJSON): ScheduleLayerModel {
    const playlistItems: LayerPlaylistItem[] =
      json.playlist?.map((playlistItemJSON: LayerPlaylistItemJSON) => LayerPlaylistItem.fromJSON(playlistItemJSON)) ||
      [];
    return new ScheduleLayerModel(
      json.name,
      json.url,
      json.previewUrl ?? '',
      json.type,
      json.height,
      json.width,
      json.duration,
      json.fps,
      json.offsetX,
      json.offsetY,
      json.zIndex,
      json.checksum,
      json.layerId,
      json.visibility,
      playlistItems
    );
  }

  public toJSON(): ScheduleLayerModelJSON {
    return {
      name: this.name,
      url: this.url,
      previewUrl: this.previewUrl,
      type: this.type,
      height: this.height,
      width: this.width,
      duration: this.duration,
      fps: this.fps,
      offsetX: this.offsetX,
      offsetY: this.offsetY,
      zIndex: this.zIndex,
      checksum: this.checksum,
      visibility: this.visibility,
      playlist: this.playlist,
    };
  }

  public clone(): ScheduleLayerModel {
    return new ScheduleLayerModel(
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      this.duration,
      this.fps,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      this.layerId,
      this.visibility,
      this.playlist
    );
  }
}
