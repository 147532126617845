import { ReleaseManagementJSON } from '@common/config/release-management/types';

export default class ReleaseManagement {
  _id!: string;
  hash: string;
  customerId: string;
  name: string;
  description: string;
  isDefault: boolean;
  qaDelay: number;
  releaseDelay: number;
  email: string[]; // modeled it as an array, in case in future we need to support multiple emails
  appliedStores: string[]; // ids of stores where this config is applied

  constructor(
    _id: string,
    hash: string,
    customerId: string,
    name: string,
    description: string,
    isDefault: boolean,

    qaDelay: number,
    releaseDelay: number,
    email: string[],
    appliedStores: string[]
  ) {
    this._id = _id;
    this.hash = hash;
    this.customerId = customerId;
    this.name = name;
    this.description = description;
    this.isDefault = isDefault;
    this.qaDelay = qaDelay;
    this.releaseDelay = releaseDelay;
    this.email = email;
    this.appliedStores = appliedStores;
  }

  toJSON(): ReleaseManagementJSON {
    return {
      _id: this._id,
      hash: this.hash,
      customerId: this.customerId,
      name: this.name,
      description: this.description,
      isDefault: this.isDefault,
      qaDelay: this.qaDelay,
      releaseDelay: this.releaseDelay,
      email: this.email,
      appliedStores: this.appliedStores,
    };
  }

  static fromJSON(source: ReleaseManagementJSON): ReleaseManagement {
    return new ReleaseManagement(
      source._id,
      source.hash,
      source.customerId,
      source.name,
      source.description,
      source.isDefault,
      source.qaDelay,
      source.releaseDelay,
      source.email,
      source.appliedStores
    );
  }
}
