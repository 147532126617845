import { defineStore, StoreDefinition, Store } from 'pinia';
import { ErrorObserver } from './types';
import { ErrorResponse, ErrorType } from '@common/error/types';
import i18n from '@client/plugins/i18n/i18n';
import { AxiosError } from 'axios';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { Optional } from '@common/types';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { StoresStore, useStoresStore } from '@client/stores/stores';

export interface ErrorGetters {
  getFallbackErrorObserver: () => () => ErrorObserver;
  getErrorType: (state: ErrorState) => () => Optional<string>;
}

export interface ErrorActions {
  add(errorObserver: ErrorObserver): void;
  delete(data: { key: string }): void;
  register(errorObserver: ErrorObserver): void;
  deregister(key: string): void;
  notify(data: { errorType: ErrorType; error: AxiosError<ErrorResponse> }): void;
  setErrorType(type: Optional<string>): void;
}

export interface ErrorState {
  errorObserverRegistry: Map<string, ErrorObserver>;
  errorPageType: Optional<string>;
}

export type ErrorStoreDefinition = StoreDefinition<'error', ErrorState, ErrorGetters, ErrorActions>;

export type ErrorStore = Store<'error', ErrorState, ErrorGetters, ErrorActions>;

export const useErrorStore: ErrorStoreDefinition = defineStore('error', {
  state: (): ErrorState => ({
    errorObserverRegistry: new Map<string, ErrorObserver>(),
    errorPageType: undefined,
  }),
  getters: {
    /**
     * Definition of all the default fallback error observer.
     * The fallback error observer will be triggered, if there is no registered observer that already handled the specific error type.
     */
    getFallbackErrorObserver: () => (): ErrorObserver => {
      const appGlobal: AppGlobalStore = useAppGlobalStore();
      const storesStore: StoresStore = useStoresStore();
      // initialize global error handles, that will be called in case there is no specific error handler for the component or view
      return ErrorObserver.create('default')
        .attachHandler(ErrorType.HASH_CONFLICT, () => {
          appGlobal.updateInvalidHashErrorModal(true);
          console.debug('tried to issue update with invalid hash');
        })
        .attachHandler(ErrorType.VALIDATION, () => {
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: true,
            genericErrorModalText: i18n.t(TranslationKeys.error.StoreCRUDError.INPUT_ERROR.$path),
            isValidationError: true,
          });
        })
        .attachHandler(ErrorType.INVALID_AUTHENTICATION, () => {
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.backendAuthentication.$path),
          });
        })
        .attachHandler(ErrorType.APIM_MISSING_VRAIL_KEY, (error: ErrorResponse): void => {
          const azureId: string | undefined = error.message.split(':').pop();
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.APIM.MISSING_VRAIL_KEY.$path, { azureId }),
          });
          storesStore.resetLoadingIndicators();
        })
        .attachHandler(ErrorType.APIM_INVALID_STORE, (error: ErrorResponse): void => {
          const azureId: string | undefined = error.message.split(':').pop();
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.APIM.INVALID_STORE.$path, { azureId }),
          });
          storesStore.resetLoadingIndicators();
        })
        .attachHandler(ErrorType.APIM_MISSING_VRAIL_PERMISSIONS, (error: ErrorResponse): void => {
          const azureId: string | undefined = error.message.split(':').pop();
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.APIM.MISSING_VRAIL_PERMISSIONS.$path, { azureId }),
          });
          storesStore.resetLoadingIndicators();
        })
        .attachHandler(ErrorType.APIM_INVALID_VCLOUD_SUBSCRIPTION, (error: ErrorResponse): void => {
          const azureId: string | undefined = error.message.split(':').pop();
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.APIM.MISSING_VCLOUD_SUBSCRIPTION.$path, { azureId }),
          });
          storesStore.resetLoadingIndicators();
        })
        .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
          appGlobal.updateGenericErrorModal({
            showGenericErrorModal: true,
            showGenericErrorModalReloadButton: false,
            genericErrorModalText: i18n.t(TranslationKeys.error.genericUnexpected.$path),
          });
        });
    },
    /**
     * Method for getting errorType field from state.
     * ErrorType is used for recognizing which kind of error occurred on error pages.
     * @param state
     */
    getErrorType: (state: ErrorState) => {
      return () => state.errorPageType;
    },
  },
  actions: {
    add(errorObserver: ErrorObserver): void {
      this.errorObserverRegistry.set(errorObserver.key, errorObserver);
    },
    delete(data: { key: string }): void {
      this.errorObserverRegistry.delete(data.key);
    },
    /**
     * Register an error observer.
     * The error observer can be unregistered using {@link deregister}
     */
    register(errorObserver: ErrorObserver): void {
      this.add(errorObserver);
    },
    /**
     * Unregister the error observer identified by the given key.
     */
    deregister(key: string): void {
      this.delete({ key });
    },
    /**
     * Notify all error observers that an error with the given error type occurred.
     * @param data.errorType the type of the error that occurred
     * @param data.error the error object
     */
    notify(data: { errorType: ErrorType; error: AxiosError<ErrorResponse> }): void {
      const errorObserverRegistry: Map<string, ErrorObserver> = this.errorObserverRegistry;
      const alreadyHandled: boolean = [...errorObserverRegistry.values()].some((errorObserver: ErrorObserver) =>
        errorObserver.notifyHandler(data.errorType, data.error)
      );
      if (!alreadyHandled) {
        this.getFallbackErrorObserver().notifyHandler(data.errorType, data.error);
      }
    },
    /**
     * Updating errorType. Don't forget to do it before destroying the component.
     * @param type
     */
    setErrorType(type: string) {
      this.errorPageType = type;
    },
  },
});
