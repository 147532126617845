import { render, staticRenderFns } from "./SaveButton.vue?vue&type=template&id=37c019ce&scoped=true"
import script from "./SaveButton.vue?vue&type=script&lang=ts"
export * from "./SaveButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c019ce",
  null
  
)

export default component.exports