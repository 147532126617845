import { TagJSON } from '@common/config/tags/types';

export default class Tag {
  _id!: string;
  hash: string;
  customerId: string;
  name: string;
  description: string;
  stores: string[];
  sections: string[];
  templates: string[];
  contentItems: string[];
  color: string;

  constructor(
    _id: string,
    hash: string,
    customerId: string,
    name: string,
    description: string,
    stores: string[],
    sections: string[],
    templates: string[],
    contentItems: string[],
    color: string
  ) {
    this._id = _id;
    this.hash = hash;
    this.customerId = customerId;
    this.name = name;
    this.description = description;
    this.stores = stores;
    this.sections = sections;
    this.templates = templates;
    this.contentItems = contentItems;
    this.color = color;
  }

  toJSON(): TagJSON {
    return {
      name: this.name,
      description: this.description,
      color: this.color,
      _id: this._id,
      hash: this.hash,
      customerId: this.customerId,
      stores: this.stores,
      sections: this.sections,
      templates: this.templates,
      contentItems: this.contentItems,
    };
  }

  static fromJSON(source: TagJSON): Tag {
    return new Tag(
      source._id,
      source.hash,
      source.customerId,
      source.name,
      source.description,
      source.stores,
      source.sections,
      source.templates,
      source.contentItems,
      source.color
    );
  }
}
