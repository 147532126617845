import { QueueItem, UploadState } from '@client/stores/contentItems/store';
import { ContentItem } from '@client/models/ContentModels/types';
import { BackgroundContent, DeviceTemplate, ForegroundContent, ScheduledContent, ScheduleLayer } from '@client/models';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import { ContentType, LayerVisibility } from '@common/enums';
import { Optional } from '@common/types';
import { TranslateResult } from 'vue-i18n';
import { Vue } from 'vue-property-decorator';

/**
 * Returns the amount of megabytes in given bytes
 * @param bytes values of bytes to be converted
 */
export const bytesToMegaBytes = (bytes: number): number => bytes / (1024 * 1024);

/**
 * Array of allowed file extensions to be uploaded
 */
export const acceptedExtensions: Array<string> = ['png', 'apng', 'mp4', 'gif', 'jpg', 'jpeg'];

/**
 * Calculates the queue item priority score, used for sorting the queue array
 * Priority is as following:
 * 1. Items being uploaded first
 * 2. Items waiting to be uploaded
 * 3. Uploaded items
 * 4. Items with errors
 * 5. Cancelled items
 */
export const calculateQueueItemPriorityScore = (queueItem: QueueItem): number => {
  return (
    (queueItem.uploadState === UploadState.uploading ? 8 : 0) +
    (queueItem.loadedBytes === 0 &&
    queueItem.uploadState !== UploadState.error &&
    queueItem.uploadState !== UploadState.cancelled
      ? 6
      : 0) +
    (queueItem.uploadState === UploadState.uploaded ? 4 : 0) +
    (queueItem.uploadState === UploadState.error ? 2 : 0) +
    (queueItem.uploadState === UploadState.cancelled ? 1 : 0)
  );
};

/**
 * Returns the parsed fps value of a content item.
 * @param fps the fps to be displayed.
 */
export const getFPSDisplayValue = (fps?: number): string => {
  if (!fps) {
    return '-';
  }
  return fps.toFixed(2);
};

/**
 * Method to handle the update of the background content of a template.
 * we use backgroundContent[0] because for now the background content supports only one layer,
 * in the future this might change and behave the same way as the foreground content
 * if a background item is updated and a schedule is selected, the incoming {@link ContentItem} needs to be set for the scheduleContent instead of directly to the background baselayer
 */
export const handleBackgroundContentUpdate = async (
  contentItem: ContentItem,
  device: Optional<DeviceTemplate>,
  selectedSchedule: string,
  gondolaTemplateId: string,
  rowIndex: number,
  columnIndex: number
): Promise<void> => {
  if (!device) {
    return;
  }
  const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  // Assume that the background content didn't change and there is a schedule selected
  let backgroundContent: Array<BackgroundContent> = device.backgroundContent.map((content: BackgroundContent) =>
    content.clone()
  );
  if (selectedSchedule) {
    // If a schedule is selected then create scheduled content from the new content item
    const scheduledContent: ScheduledContent = new ScheduledContent(selectedSchedule, contentItem.toScheduledLayer());
    if (!backgroundContent[0]) {
      backgroundContent[0] = new BackgroundContent();
    }
    // try to find a previously existing scheduledContent entry for the currently selected schedule (if it exists)
    const scheduleIndex: number = backgroundContent[0].scheduledContent
      ? backgroundContent[0].scheduledContent.findIndex(
          (schedule: ScheduledContent) => schedule.scheduleId === selectedSchedule
        )
      : -1;

    // If the scheduled doesn't exist yet then create it, else override it
    if (scheduleIndex < 0) {
      backgroundContent[0].scheduledContent?.push(scheduledContent);
    } else if (backgroundContent[0].scheduledContent?.[scheduleIndex]) {
      backgroundContent[0].scheduledContent[scheduleIndex] = scheduledContent;
    }
  } else {
    // Else it's the baseLayer background content, then create the new content for it
    backgroundContent = new Array<BackgroundContent>(
      BackgroundContent.fromContentItem(contentItem, backgroundContent[0]?.scheduledContent)
    );
  }
  await gondolaTemplatesStore.updateDeviceBackground(
    device,
    backgroundContent,
    gondolaTemplateId,
    rowIndex,
    columnIndex
  );
};

/**
 * Method that allow us to set the content of a label depending on if there is a selected schedule or if it's a base layer
 * If a schedule is selected we will create a copy of the label from the base layer
 * if a foreground item is updated and a schedule is selected, the incoming {@link ContentItem} needs to be set for the scheduleContent instead of directly to the background baselayer
 */
export const handleForegroundContentUpdate = async (
  contentItem: ContentItem,
  railContentSelection: DeviceContentSelection,
  device: Optional<DeviceTemplate>,
  selectedSchedule: string,
  gondolaTemplateId: string,
  rowIndex: number,
  columnIndex: number
): Promise<void> => {
  if (!device) {
    return;
  }
  const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  const newContent: ForegroundContent = ForegroundContent.fromContentItem(contentItem);
  newContent.scheduledContent = device.foregroundContent[railContentSelection.index].scheduledContent?.map(
    (content: ScheduledContent) => content.clone()
  );
  let scheduleIndex: number | undefined = device.foregroundContent[
    railContentSelection.index
  ].scheduledContent?.findIndex((schedule: ScheduledContent) => schedule.scheduleId === selectedSchedule);
  // noinspection JSIncompatibleTypesComparison
  if (scheduleIndex === undefined) {
    scheduleIndex = -1;
  }
  if (selectedSchedule && newContent.scheduledContent) {
    // If the override exists then take the overrides value, else set it to Visible
    // Initially the value is Visible by default
    const visibility: LayerVisibility =
      scheduleIndex >= 0 ? newContent.scheduledContent[scheduleIndex].layer.visibility : LayerVisibility.VISIBLE;
    const scheduledContent: ScheduledContent = new ScheduledContent(
      selectedSchedule,
      new ScheduleLayer(
        newContent.baseLayer.name,
        newContent.baseLayer.url,
        newContent.baseLayer.previewUrl,
        newContent.baseLayer.type,
        newContent.baseLayer.height,
        newContent.baseLayer.width,
        newContent.baseLayer.duration,
        newContent.baseLayer.fps,
        device.foregroundContent[railContentSelection.index].baseLayer.offsetX,
        device.foregroundContent[railContentSelection.index].baseLayer.offsetY,
        newContent.baseLayer.zIndex,
        newContent.baseLayer.checksum,
        newContent.baseLayer.layerId,
        visibility,
        newContent.baseLayer.playlist
      )
    );
    newContent.baseLayer = device.foregroundContent[railContentSelection.index].baseLayer.clone();
    // If the schedule index is < 0 then this content doesn't have overridden content yet, thus push it to the array
    if (scheduleIndex < 0) {
      newContent.scheduledContent.push(scheduledContent);
    } else {
      // Else override the current scheduled content
      newContent.scheduledContent[scheduleIndex] = scheduledContent;
    }
  }
  await gondolaTemplatesStore.updateDeviceForegroundSlot(
    gondolaTemplateId,
    rowIndex,
    columnIndex,
    device,
    newContent,
    railContentSelection.index
  );
};

/**
 * Returns the last index of the folder found in the content items array.
 * Assumes that the content items array contains the folders first
 */
export const findLastIndexOfFolder = (contentItems: Array<ContentItem>): number => {
  let lastIndexOfFolder: number = -1;
  for (const contentItem of contentItems) {
    if (contentItem.isFolder()) {
      lastIndexOfFolder += 1;
    } else {
      break;
    }
  }
  return lastIndexOfFolder;
};

/**
 * List of possible content item types with their icon and translation
 * @param component that is using list(usually = this)
 */
export const contentItemTypes = (
  component: Vue
): Array<{ text: TranslateResult; value: ContentType; icon: string }> => {
  return [
    {
      text: component.$t(component.$i18nTranslationKeys.contentManagement.image.$path),
      value: ContentType.Image,
      icon: `mdi-file-image`,
    },
    {
      text: component.$t(component.$i18nTranslationKeys.contentManagement.video.$path),
      value: ContentType.Video,
      icon: `mdi-file-video`,
    },
    {
      text: component.$t(component.$i18nTranslationKeys.contentManagement.folder.$path),
      value: ContentType.Folder,
      icon: `mdi-folder`,
    },
    {
      text: component.$t(component.$i18nTranslationKeys.contentManagement.playlist.$path),
      value: ContentType.Playlist,
      icon: `mdi-playlist-play`,
    },
  ];
};
