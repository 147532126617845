
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola as GondolaModel } from '@client/models';

@Component({})
export default class AislePublishingBadge extends Vue {
  /* DECLARATIONS */
  @Prop()
  private sections!: Array<GondolaModel>;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get publishSucceeded(): number {
    return this.sections.reduce(
      (total: number, section: GondolaModel) =>
        total + (section.lastPublishing?.publishInformation?.railsSucceeded || 0),
      0
    );
  }

  get publishPending(): number {
    return this.sections.reduce(
      (total: number, section: GondolaModel) => total + (section.lastPublishing?.publishInformation?.railsPending || 0),
      0
    );
  }

  get publishAccepted(): number {
    return this.sections.reduce(
      (total: number, section: GondolaModel) =>
        total + (section.lastPublishing?.publishInformation?.railsAccepted || 0),
      0
    );
  }

  get publishFailed(): number {
    return this.sections.reduce(
      (total: number, section: GondolaModel) => total + (section.lastPublishing?.publishInformation?.railsFailed || 0),
      0
    );
  }
}
