/**
 * Scrolls to the header of the notification.
 * This is to avoid the notification to have a "shifting" effect if the user collapses a notification that covers a lot of height
 */
const scrollToNotification = (notificationDOMElementId: string): void => {
  const notification: HTMLElement | null = document.getElementById(notificationDOMElementId);
  const notificationPanel: HTMLElement | null = document.getElementById('notification-panel');
  const notificationTopPosition: number = notification?.offsetTop || 0;
  if (
    notification &&
    notificationPanel &&
    notificationTopPosition &&
    notificationPanel.scrollTop > notificationTopPosition
  ) {
    notificationPanel.scrollTop = notificationTopPosition;
  }
};

export { scrollToNotification };
