import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItem } from '@client/models/ContentModels/types';
import {
  ContentItemAction,
  WsAddContentItemMessage,
  WsContentItemMessage,
  WsDeleteContentItemMessage,
  WsUpdateContentItemMessage,
} from '@common/websocket/ContentItem';
import { contentItemFromJSON } from '@client/models/ContentModels/utils';
import { EventBus } from '@client/main';

/**
 * Handle a websocket message containing a content item update
 * @param wsContentItemMessage the publishing websocket message
 */
const handleContentItemMessage = async (wsContentItemMessage: WsContentItemMessage): Promise<void> => {
  switch (wsContentItemMessage.action) {
    case ContentItemAction.Update:
      await handleUpdateContentItemMessage(wsContentItemMessage as WsUpdateContentItemMessage);
      break;
    case ContentItemAction.Add:
      await handleAddContentItemMessage(wsContentItemMessage as WsAddContentItemMessage);
      break;
    case ContentItemAction.Delete:
      await handleDeleteContentItemMessage(wsContentItemMessage as WsDeleteContentItemMessage);
      break;
    default:
      console.error(`Received unknown event ${wsContentItemMessage}`);
      break;
  }
};

const handleAddContentItemMessage = async (wsAddContentItemMessage: WsAddContentItemMessage): Promise<void> => {
  const contentItemsStore: ContentItemsStore = useContentItemsStore();
  const contentItem: ContentItem = contentItemFromJSON(wsAddContentItemMessage.payload.contentItem);
  console.debug(`Received websocket message; Updating content item ${contentItem._id}`);
  await contentItemsStore.addContentItemToStore(contentItem);
  EventBus.$root.$emit('onContentItemAdded', contentItem);
};

const handleUpdateContentItemMessage = async (
  wsUpdateContentItemMessage: WsUpdateContentItemMessage
): Promise<void> => {
  const contentItemsStore: ContentItemsStore = useContentItemsStore();
  const contentItem: ContentItem = contentItemFromJSON(wsUpdateContentItemMessage.payload.contentItem);
  console.debug(`Received websocket message; Updating content item ${contentItem._id}`);
  await contentItemsStore.updateContentItemInStore(contentItem);
  EventBus.$root.$emit('onContentItemUpdated', contentItem);
};

/**
 * Handle a websocket message containing a content item delete request
 * @param wsDeleteContentItemMessage the publishing websocket message
 */
const handleDeleteContentItemMessage = async (
  wsDeleteContentItemMessage: WsDeleteContentItemMessage
): Promise<void> => {
  const contentItemsStore: ContentItemsStore = useContentItemsStore();
  console.debug(
    `Received websocket message; Deleting content item ${wsDeleteContentItemMessage.payload.contentItemId}`
  );
  await contentItemsStore.removeContentItemByIdInStore(wsDeleteContentItemMessage.payload.contentItemId);
  EventBus.$root.$emit('onContentItemDeleted', wsDeleteContentItemMessage.payload.contentItemId);
};

export {
  handleAddContentItemMessage,
  handleUpdateContentItemMessage,
  handleDeleteContentItemMessage,
  handleContentItemMessage,
};
