
import { Vue, Component } from 'vue-property-decorator';
import Wrapper from '@client/components/Layouts/Wrapper.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import TagsTable from '@client/components/Settings/Tags/TagsTable.vue';
import Tag from '@client/models/SettingsModels/Tag.model';
import AddTagButton from '@client/components/Settings/Tags/Actions/AddTagButton.vue';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({
  components: { AddTagButton, TagsTable, Wrapper },
})
export default class TagsTab extends Vue {
  private tagsStore: TagsStore = useTagsStore();
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  private storesStore: StoresStore = useStoresStore();
  private templateStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  get isLoading(): boolean {
    return (
      this.tagsStore.isFetching ||
      this.tagsStore.isActionPending ||
      this.contentItemsStore.isFetching ||
      this.storesStore.isFetching ||
      this.templateStore.isFetching
    );
  }

  get tags(): Array<Tag> {
    return this.tagsStore.getTags();
  }

  async created(): Promise<void> {
    await this.tagsStore.fetch();
    await this.contentItemsStore.fetchContentItems();
    await this.storesStore.fetch();
    await this.templateStore.fetch();
  }
}
