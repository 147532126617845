
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import DeleteStoreButton from '@client/components/StoreDetail/Buttons/DeleteStoreButton.vue';
import SynchronizeStoreButton from '@client/components/StoreDetail/Buttons/SynchronizeStoreButton.vue';

@Component({
  components: { SynchronizeStoreButton, DeleteStoreButton },
})
export default class StoreActions extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
