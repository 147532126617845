import { BaseEventLog } from '@client/models/EventLogModels/EventLog.model';
import { EventType, FirmwareChangedEventLogJSON } from '@common/eventlog/types';
import { Optional } from '@common/types';
import { Device } from '@client/models';

export class FirmwareChangedEventLog extends BaseEventLog {
  device: Optional<Device>;
  timestamp: string;
  applicationPackageVersion: string;
  applicationSystemVersion: string;

  constructor(
    _id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    device: Optional<Device>,
    timestamp: string,
    applicationPackageVersion: string,
    applicationSystemVersion: string
  ) {
    super(_id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.device = device;
    this.timestamp = timestamp;
    this.applicationPackageVersion = applicationPackageVersion;
    this.applicationSystemVersion = applicationSystemVersion;
  }

  public static fromJSON(eventLog: FirmwareChangedEventLogJSON): FirmwareChangedEventLog {
    const {
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      device,
      timestamp,
      applicationPackageVersion,
      applicationSystemVersion,
    }: FirmwareChangedEventLogJSON = eventLog;
    return new FirmwareChangedEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      device ? Device.fromJSON(device) : undefined,
      timestamp,
      applicationPackageVersion,
      applicationSystemVersion
    );
  }
}
