
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';

@Component({ methods: { getRelativeTimeFromNow } })
export default class NotificationTimeDisplay extends Vue {
  @Prop()
  private dateToDisplay!: Date | string | undefined;

  created(): void {
    setInterval(() => {
      this.$forceUpdate();
    }, 1000);
  }

  get dateAsIsoString(): string {
    if (!this.dateToDisplay) {
      return '';
    }
    return new Date(this.dateToDisplay).toISOString();
  }
}
