
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola, Store } from '@client/models';
import { getCreatedAtFormatted, getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';
import Device from '@client/models/DeviceModels/Device.model';
import { DeviceStatusEventType, EventType } from '@common/eventlog/types';
import NotificationTimeDisplay from '../NotificationTimeDisplay.vue';
import NotificationTitle from '../NotificationTitle.vue';
import { Optional } from '@common/types';
import { DeviceStatusEventLog } from '@client/models/EventLogModels/DeviceStatusEventLog/DeviceStatusEventLog.model';
import { getStoreDetailPath } from '@client/router/utils';

/**
 * Handles Registration notifications (registered/unregistered) and Online status notification (online/offline)
 */
@Component({
  methods: { getCreatedAtFormatted, getRelativeTimeFromNow },
  components: { NotificationTimeDisplay, NotificationTitle },
})
export default class DeviceStatusNotification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: DeviceStatusEventLog;
  @Prop()
  private store: Optional<Store>;
  private showLongId: boolean = false;

  get notificationTitle(): string {
    return `- ${this.getMessageFromDeviceEvent(this.eventLog)}`;
  }

  get storeNameInTitle(): string {
    if (this.store) {
      return this.store.name;
    }
    return this.eventLog.azureStoreId;
  }

  get deviceGondolaLink(): string {
    if (!this.store) {
      return '';
    }
    const device: Optional<Device> = this.eventLog.device;
    if (!device?.gondolaId) {
      return getStoreDetailPath(this.eventLog.storeId);
    }
    const gondola: Optional<Gondola> = this.store.gondolas.find(
      (storeGondola: Gondola) => storeGondola._id === device.gondolaId
    );
    return getStoreDetailPath(this.store._id, gondola?.aisle, gondola?.positionInAisle);
  }

  get notificationIcon(): string {
    return this.eventLog.type === EventType.ONLINE_STATUS ? 'mdi-signal-variant' : 'mdi-note-edit';
  }

  get isOfflineNotification(): boolean {
    return (
      (this.eventLog.type === EventType.ONLINE_STATUS &&
        this.eventLog.deviceStatus === DeviceStatusEventType.OFFLINE) ||
      (this.eventLog.type === EventType.REGISTERED_STATUS &&
        this.eventLog.deviceStatus === DeviceStatusEventType.UNREGISTERED)
    );
  }
  get dateToDisplay(): string | Date {
    return this.eventLog.eventExecutionTimestamp || this.eventLog.createdAt;
  }

  toggleShowLongId(): void {
    this.showLongId = !this.showLongId;
  }

  getMessageFromDeviceEvent(notification: DeviceStatusEventLog): string {
    switch (notification.deviceStatus) {
      case DeviceStatusEventType.REGISTERED:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.registered.$path)} `;
      case DeviceStatusEventType.UNREGISTERED:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.unregistered.$path)} `;
      case DeviceStatusEventType.ONLINE:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.online.$path)}`;
      case DeviceStatusEventType.OFFLINE:
        return ` ${this.$t(this.$i18nTranslationKeys.notifications.eventType.offline.$path)}`;
      default:
        return ` ${notification.deviceStatus}`;
    }
  }
}
