
import { Component, Vue, Watch } from 'vue-property-decorator';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { Device, Store } from '@client/models';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import TagReference from '@client/models/SettingsModels/TagReference';
import WizardStoreSettings from '@client/components/Store/StoreCreationWizard/WizardStoreSettings.vue';
import WizardAzureId from '@client/components/Store/StoreCreationWizard/WizardAzureId.vue';
import { TranslateResult } from 'vue-i18n';
import { ErrorObserver, ErrorStore, useErrorStore } from '@client/stores/error';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { StoreResponseJSON } from '@common/stores/types';
import { TimeValidationError } from '@client/components/ActiveHours/ActiveHours.vue';
import ActiveHoursTable from '@client/components/ActiveHours/ActiveHoursTable.vue';
import ReleaseManagementTable from '@client/components/Settings/ReleaseManagement/ReleaseManagementTable.vue';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import { Optional } from '@common/types';
import WizardReview from '@client/components/Store/StoreCreationWizard/WizardReview.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import Tag from '@client/models/SettingsModels/Tag.model';
import router from '@client/router';
import { getStoreDetailPath } from '@client/router/utils';

interface Step {
  title: TranslateResult;
  icon: string;
  optional: boolean;
  rules?: Array<() => TranslateResult | boolean>;
}

@Component({
  components: {
    WizardReview,
    ReleaseManagementTable,
    ActiveHoursTable,
    WizardAzureId,
    WizardStoreSettings,
    ModalHeader,
  },
})
export default class StoreCreationWizard extends Vue {
  /* DECLARATIONS */
  private static readonly ERROR_OBSERVER_KEY: string = 'StoreCreationWizard';

  private storesStore: StoresStore = useStoresStore();
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();
  private errorStore: ErrorStore = useErrorStore();
  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private tagsStore: TagsStore = useTagsStore();

  private storeToCreate: Store = new Store('', '', this.appGlobalStore.customer);
  private releaseManagement: Optional<ReleaseManagement> = null;
  private activeHoursErrors: TimeValidationError[] = [];
  private showDialog: boolean = false;
  private currentStep: number = 1;
  private maximumStepVisited: number = 1;
  private isAzureIdValid: boolean = false;
  private azureIdValidationMessage: TranslateResult = '';
  private azureIdRelatedDevices: Array<Device> = [];
  private azureIdValidationTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
    // Empty timer
  }, 1000);

  private steps: Array<Step> = [
    {
      title: this.$t(this.$i18nTranslationKeys.storeCreation.steps.basicSettings.$path),
      icon: 'mdi-cog',
      optional: false,
      rules: [() => this.validateStoreSettings()],
    },
    {
      title: this.$t(this.$i18nTranslationKeys.storeCreation.steps.idAzure.$path),
      icon: 'mdi-store-cog',
      optional: false,
      rules: [() => this.isAzureIdStepValid()],
    },
    {
      title: this.$t(this.$i18nTranslationKeys.activeHours.activeHours.$path),
      icon: 'mdi-clock',
      optional: true,
      rules: [() => this.validateActiveHours()],
    },
    {
      title: this.$t(this.$i18nTranslationKeys.settings.releaseManagement.title.$path),
      icon: 'mdi-access-point',
      optional: true,
    },
    { title: this.$t(this.$i18nTranslationKeys.storeCreation.steps.review.$path), icon: 'mdi-check', optional: false },
  ];
  /* LIFECYCLE EVENTS */
  created(): void {
    this.registerObserver();
    this.storeToCreate = new Store('', '', this.appGlobalStore.customer);
    this.releaseManagement = this.releaseManagementStore.getDefaultReleaseManagement();
  }

  beforeDestroy(): void {
    this.errorStore.deregister(StoreCreationWizard.ERROR_OBSERVER_KEY);
  }

  registerObserver(): void {
    ErrorObserver.create(StoreCreationWizard.ERROR_OBSERVER_KEY)
      .attachHandler(ErrorType.DUPLICATE, (errorResponse: ErrorResponse<StoreResponseJSON>): void => {
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(this.$i18nTranslationKeys.error.duplicateAzureId.$path, [
          `<strong>${errorResponse.details?.name || errorResponse.details?._id || ''}</strong>`,
        ]);
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_ERROR, (): void => {
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(
          this.$i18nTranslationKeys.error.StoreCRUDError.WEBHOOK_NOT_CREATED.$path
        );
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_STORE, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(this.$i18nTranslationKeys.error.APIM.INVALID_STORE.$path, { azureId });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_KEY, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_KEY.$path, {
          azureId,
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_MISSING_VRAIL_PERMISSIONS, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(this.$i18nTranslationKeys.error.APIM.MISSING_VRAIL_PERMISSIONS.$path, {
          azureId,
        });
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.APIM_INVALID_VCLOUD_SUBSCRIPTION, (error: ErrorResponse): void => {
        const azureId: string | undefined = error.message.split(':').pop();
        this.azureIdRelatedDevices = [];
        this.isAzureIdValid = false;
        this.azureIdValidationMessage = this.$t(
          this.$i18nTranslationKeys.error.APIM.MISSING_VCLOUD_SUBSCRIPTION.$path,
          {
            azureId,
          }
        );
        this.storesStore.resetLoadingIndicators();
      })
      .attachHandler(ErrorType.UNEXPECTED_ERROR, (): void => {
        this.appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: false,
          genericErrorModalText: this.$t(this.$i18nTranslationKeys.error.genericUnexpected.$path),
        });
        this.storesStore.resetLoadingIndicators();
      })
      .register();
  }
  /* METHODS */
  private nextStep(): void {
    this.currentStep = this.currentStep + 1;
    this.maximumStepVisited = Math.max(this.currentStep, this.maximumStepVisited);
  }

  private previousStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  private onStepChanged(step: number): void {
    this.maximumStepVisited = Math.max(step, this.maximumStepVisited);
  }

  @Watch('shouldCreateStoreButtonBeVisible')
  closeDialog(): void {
    this.storeToCreate = new Store('', '', this.appGlobalStore.customer);
    this.maximumStepVisited = 1;
    this.currentStep = 1;
    this.releaseManagement = this.releaseManagementStore.getDefaultReleaseManagement();
    this.showDialog = false;
  }

  changeReleaseManagement(releaseManagement: ReleaseManagement): void {
    this.releaseManagement = releaseManagement;
  }

  onSettingsChange(data: { storeName: string; timezone: string; tags: Array<TagReference> }): void {
    this.storeToCreate.name = data.storeName;
    this.storeToCreate.timezone = data.timezone;
    this.storeToCreate.tags = data.tags;
  }

  onAzureIdChange(azureId: string): void {
    this.storeToCreate.idAzure = azureId;
    this.isAzureIdValid = false;
    this.azureIdValidationMessage = '';
    this.azureIdRelatedDevices = [];
    clearTimeout(this.azureIdValidationTimer);
    if (this.validateAzureId() !== true) {
      return;
    }
    this.azureIdValidationTimer = setTimeout(() => {
      this.storesStore.validateStoreAzureId(azureId).then((response: Array<Device>) => {
        this.isAzureIdValid = true;
        this.azureIdValidationMessage = this.$t(this.$i18nTranslationKeys.storeCreation.validation.success.$path);

        this.azureIdRelatedDevices = response;
      });
    }, 600);
  }

  onErrorsChanged(errors: TimeValidationError[]): void {
    this.activeHoursErrors = errors;
  }

  /**
   * Validate the store settings
   * @returns {boolean | string} - true if the settings are valid, an error string if the settings are invalid
   */
  validateStoreSettings(): boolean | TranslateResult {
    if (this.storeToCreate.name.length === 0 || this.storeToCreate.timezone.length === 0) {
      return 'Invalid';
    }
    return true;
  }

  validateActiveHours(): boolean | TranslateResult {
    if (
      this.activeHoursErrors.some(
        (error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger
      )
    ) {
      return 'Invalid';
    }
    return true;
  }

  validateAzureId(): boolean | TranslateResult {
    if (this.storeToCreate.idAzure.length === 0) {
      return 'Invalid';
    }
    return true;
  }

  isAzureIdStepValid(): boolean | TranslateResult {
    if (this.maximumStepVisited <= 2) {
      return true;
    }
    return this.validateAzureId() === true && this.isAzureIdValid ? true : 'Invalid';
  }

  async createStore(): Promise<void> {
    if (this.isSaveButtonDisabled) {
      return;
    }
    const tagsToBeAssigned: Array<Tag> = this.storeToCreate.tags
      .map((tagReference: TagReference) => this.tagsStore.getTagById(tagReference.id))
      .filter((tag: Optional<Tag>) => !!tag) as Array<Tag>;
    const createdStoreId: string = await this.storesStore.add(
      this.storeToCreate,
      this.releaseManagement,
      tagsToBeAssigned
    );
    if (createdStoreId) {
      await router.push({
        path: getStoreDetailPath(createdStoreId),
      });
    }
  }

  skipToReview(): void {
    this.currentStep = this.steps.length;
    this.maximumStepVisited = this.steps.length;
  }

  /* GETTERS */

  get isLoading(): boolean {
    return this.storesStore.loadingIndicator.update || !this.shouldCreateStoreButtonBeVisible;
  }

  get isNextButtonDisabled(): boolean {
    if (this.currentStep === 1) {
      return this.isLoading || this.validateStoreSettings() !== true;
    } else if (this.currentStep === 2) {
      return this.isLoading || !this.isAzureIdValid || this.validateAzureId() !== true;
    } else if (this.currentStep === 3) {
      return this.isLoading || this.hasActiveHoursError;
    }
    return this.isLoading;
  }

  get isSkipButtonDisabled(): boolean {
    return (
      this.isLoading ||
      this.validateAzureId() !== true ||
      !this.isAzureIdValid ||
      this.validateAzureId() !== true ||
      this.hasActiveHoursError
    );
  }

  get isSaveButtonDisabled(): boolean {
    return (
      this.isLoading ||
      this.validateStoreSettings() !== true ||
      this.validateAzureId() !== true ||
      !this.isAzureIdValid ||
      this.activeHoursErrors.some(
        (error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger
      )
    );
  }

  get hasActiveHoursError(): boolean {
    return this.activeHoursErrors.some(
      (error: TimeValidationError) => error.startTime || error.endTime || error.endTimeBigger
    );
  }

  get releaseConfigurations(): Array<ReleaseManagement> {
    return this.releaseManagementStore.getReleaseConfigurations();
  }

  get currentReleaseManagement(): string | undefined {
    return this.releaseManagement?._id;
  }

  get shouldCreateStoreButtonBeVisible(): boolean {
    return this.tagsStore.fetched && this.releaseManagementStore.fetched && this.storesStore.fetched;
  }

  get isSkipButtonVisible(): boolean {
    return this.currentStep >= 2 && this.currentStep < this.steps.length;
  }

  get isNextButtonVisible(): boolean {
    return this.currentStep < this.steps.length;
  }

  get isSaveButtonVisible(): boolean {
    return this.currentStep === this.steps.length;
  }

  get isBackButtonVisible(): boolean {
    return this.currentStep > 1;
  }
}
