
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { BaseLayer, HardwareModel, Schedule } from '@client/models';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import { ContentType, LayerVisibility } from '@common/enums';
import { Optional } from '@common/types';
import { OPTIMIZED_PREVIEW_POSTFIX } from '@client/models/DeviceModels/DeviceCanvasItem.model';
import { DEVICE_PREVIEW_SCALE_FACTOR_IN_PIXELS } from '@common/content-item/types';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import DevicePublishingForegroundContentModel from '@client/models/DeviceModels/DevicePublishingForegroundContentModel';
import ForegroundContentModel from '@client/models/ScheduleModels/ForegroundContent.model';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';

@Component({})
export default class ForegroundPreview extends Vue {
  @Prop() private foregroundContentItem!: DevicePublishingForegroundContentModel | ForegroundContentModel;
  @Prop() private foregroundContentIndex!: number;
  @Prop() private hardwareModel!: HardwareModel;
  @Prop() private selectedSchedule!: string;
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private schedules: Array<Schedule> = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  private foregroundContent: Optional<BaseLayer | ScheduleLayerModel> = null;
  private foregroundContentBorder: string = '';
  private previewURL: string = '';

  async created(): Promise<void> {
    await this.setForegroundContentToRender();
  }

  @Watch('schedulesStore.schedules.length')
  @Watch('schedulesStore.selectedSchedule')
  async onSchedulesChanged(): Promise<void> {
    this.schedules = this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
    await this.setForegroundContentToRender();
  }

  /**
   * Returns the preview url to be shown
   * @private
   */
  private async getPreviewUrl(): Promise<string> {
    return this.foregroundContent?.getPreviewBlobUrl() || '';
  }

  @Watch('foregroundContentItem')
  private async setForegroundContentToRender(): Promise<void> {
    const scheduledContent: ScheduledContentModel | undefined = (
      this.foregroundContentItem as ForegroundContentModel
    ).scheduledContent?.find((foreground: ScheduledContentModel) => foreground.scheduleId === this.selectedSchedule);
    if (!this.selectedSchedule || !scheduledContent) {
      this.foregroundContent = this.foregroundContentItem.baseLayer;
      this.setForegroundScheduleContentBorder();
      this.previewURL = await this.getPreviewUrl();
      return;
    }
    if (scheduledContent) {
      this.foregroundContent = scheduledContent.layer;
      this.setForegroundScheduleContentBorder();
      this.previewURL = await this.getPreviewUrl();
    }
  }

  private setForegroundScheduleContentBorder(): void {
    const scheduledContent: ScheduledContentModel | undefined = (
      this.foregroundContentItem as ForegroundContentModel
    ).scheduledContent?.find((foreground: ScheduledContentModel) => foreground.scheduleId === this.selectedSchedule);
    const color: string | undefined = this.schedules.find(
      (schedule: Schedule) => schedule._id === this.selectedSchedule
    )?.color;
    this.foregroundContentBorder = `${color && scheduledContent ? '1px' : '0'} solid ${
      color && scheduledContent ? color : 'transparent'
    }`;
  }

  get layerVisibility(): string {
    if (this.foregroundContent instanceof BaseLayer) {
      return 'visible';
    }
    return this.foregroundContent?.visibility === undefined ||
      this.foregroundContent.visibility === LayerVisibility.VISIBLE
      ? 'visible'
      : 'hidden';
  }

  get offsetX(): string {
    const offset: number = (this.foregroundContent?.offsetX || 0) * this.hardwareModel.railScaleFactor;
    return `calc(${offset}px * var(--device-scale))`;
  }

  get offsetY(): string {
    const offset: number =
      (this.foregroundContent?.offsetY || 0) * this.hardwareModel.railScaleFactor -
      this.foregroundContentIndex * DEVICE_PREVIEW_SCALE_FACTOR_IN_PIXELS;
    return `calc(${offset}px * var(--device-scale))`;
  }

  /**
   * Returns the width style rule of the background item
   * Width: (Background original width in pixels * scaling factor of the hardware model) * Scaling of the preview zoom (if used defaults to 1)
   */
  get width(): string | null {
    if (!this.foregroundContent) {
      return null;
    }
    return `calc(${this.foregroundContent.getWidth() * this.hardwareModel.railScaleFactor}px * var(--device-scale))`;
  }

  /**
   * Returns the height style rule of the background item
   * Height: (Background original height in pixels * scaling factor of the hardware model) * Scaling of the preview zoom (if used defaults to 1)
   */
  get height(): string | null {
    if (!this.foregroundContent) {
      return null;
    }
    return `calc(${this.foregroundContent.getHeight() * this.hardwareModel.railScaleFactor}px * var(--device-scale))`;
  }

  /**
   * Returns a boolean to indicate if the preview is an image or not
   * If the preview URL ends with a '-webp', even if the content is originally a video, an image format has been generated for it
   * @private
   */
  get isImage(): boolean | undefined {
    if (this.foregroundContent?.type === ContentType.Playlist) {
      return this.foregroundContent.playlist?.[0]?.previewUrl.endsWith(OPTIMIZED_PREVIEW_POSTFIX);
    }
    return (
      this.foregroundContent?.type === ContentType.Image ||
      this.foregroundContent?.previewUrl?.endsWith(OPTIMIZED_PREVIEW_POSTFIX)
    );
  }
}
