
import { Component, Vue } from 'vue-property-decorator';
import AppLogo from '@client/components/Layouts/AppLogo.vue';
import UserMenu from '@client/components/User/UserMenu.vue';
import ContentItemsUpload from '@client/components/ContentItems/ContentItemsUpload.vue';
import { NotificationPanel } from '@client/components';
import SnackBar from '@client/components/Layouts/SnackBar.vue';
import Breadcrumbs from '@client/components/Layouts/Breadcrumbs.vue';
import PageTitle from '@client/components/Layouts/PageTitle.vue';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { DEFAULT_HEADER_EXTENSION_HEIGHT, TABS_HEIGHT } from '@client/components/Layouts/variables';

@Component({
  components: { Breadcrumbs, SnackBar, NotificationPanel, ContentItemsUpload, UserMenu, AppLogo, PageTitle },
})
export default class Header extends Vue {
  /* DECLARATIONS */
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private drawer: boolean = false;
  private notificationDrawer: boolean = false;

  /* LIFECYCLE EVENTS */
  /* METHODS */
  toggleNotificationPanel(): void {
    this.notificationDrawer = !this.notificationDrawer;
  }

  toggleDrawer(): void {
    this.drawer = !this.drawer;
  }
  /* GETTERS */

  get extensionHeight(): string {
    if (!this.breadcrumbsStore.currentPage.tabs || !this.breadcrumbsStore.currentPage.title) {
      return `${DEFAULT_HEADER_EXTENSION_HEIGHT}px`;
    }
    return `${DEFAULT_HEADER_EXTENSION_HEIGHT + TABS_HEIGHT}px`;
  }
}
