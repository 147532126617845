
import { Component, Prop, Vue } from 'vue-property-decorator';
import { GondolaTemplate } from '@client/models';
import router from '@client/router';
import { Optional } from '@common/types';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class OpenLastPublishedTemplateForSection extends Vue {
  @Prop()
  private gondolaTemplateId: Optional<string>;
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();

  @Prop()
  private positionInAisle: Optional<string>;

  openTemplate(): void {
    if (!this.gondolaTemplateId) {
      return;
    }
    router.push(`/template/${this.gondolaTemplateId}`);
  }

  get template(): Optional<GondolaTemplate> {
    if (!this.gondolaTemplateId) {
      return undefined;
    }
    return this.gondolaTemplatesStore.getById(this.gondolaTemplateId);
  }

  /**
   * Checks if the published template still exists
   */
  get templateExists(): boolean {
    return !!this.template;
  }

  get tooltipMessage(): TranslateResult {
    if (!this.gondolaTemplateId) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.sectionNotPublished.$path);
    }
    if (!this.templateExists && this.gondolaTemplateId) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.publishedTemplateDeleted.$path);
    }
    return this.$t(this.$i18nTranslationKeys.storeDetail.openTemplate.$path);
  }

  get isButtonDisabled(): boolean {
    return !this.gondolaTemplateId || !this.templateExists;
  }
}
