
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeviceTemplate } from '@client/models';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

@Component({})
export default class LabelItem extends Vue {
  @Prop()
  private index!: number;
  @Prop()
  private deviceTemplate!: DeviceTemplate;
  @Prop()
  private selectedSchedule!: string;
  private menu: boolean = false;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  onDeleteForeground(): void {
    this.gondolaTemplatesStore.deleteDeviceForegroundSlot(
      this.$route.params.id,
      Number(this.$route.params.row),
      Number(this.$route.params.col),
      this.deviceTemplate,
      this.index
    );
    this.menu = false;
  }
}
