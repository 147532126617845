import { defineStore, Store, StoreDefinition } from 'pinia';
import Tag from '@client/models/SettingsModels/Tag.model';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Config from '@client/utils/config';
import { TagJSON } from '@common/config/tags/types';
import Vue from 'vue';
import { NewTag } from '@client/models/SettingsModels/NewTag';
import { logAxiosError } from '@client/utils/logger';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { Optional } from '@common/types';
import { ErrorResponse, ErrorType } from '@common/error/types';
import { TranslateResult } from 'vue-i18n';

export interface TagsState {
  tags: Array<Tag>;
  fetched: boolean;
  isFetching: boolean;
  isActionPending: boolean;
}

export interface TagsGetters {
  getTags: (state: TagsState) => () => Array<Tag>;
  getTagByNameCaseInsensitive: (state: TagsState) => (name: string) => Optional<Tag>;
  getTagByColor: (state: TagsState) => (color: string) => Optional<Tag>;
  getTagById: (state: TagsState) => (id: string) => Optional<Tag>;
}

export interface TagsActions {
  setFetched(fetched: boolean): void;
  setIsFetching(isFetching: boolean): void;
  fetch(): Promise<void>;
  createTag(newTag: NewTag): Promise<void>;
  updateTag(tagId: string, newTag: NewTag): Promise<void>;
  deleteTag(tag: Tag): Promise<void>;
}

export type TagsStoreDefinition = StoreDefinition<'tags', TagsState, TagsGetters, TagsActions>;

export type TagsStore = Store<'tags', TagsState, TagsGetters, TagsActions>;

export const useTagsStore: TagsStoreDefinition = defineStore('tags', {
  state: (): TagsState => ({
    tags: new Array<Tag>(),
    fetched: false,
    isFetching: false,
    isActionPending: false,
  }),
  getters: {
    getTags: (state: TagsState) => () => {
      return state.tags;
    },
    getTagByNameCaseInsensitive: (state: TagsState) => (name: string) => {
      return state.tags.find((tag: Tag): boolean => tag.name.toUpperCase() === name.toUpperCase());
    },
    getTagByColor: (state: TagsState) => (color: string) => {
      return state.tags.find((tag: Tag): boolean => tag.color === color);
    },
    getTagById: (state: TagsState) => (id: string) => {
      return state.tags.find((tag: Tag): boolean => tag._id === id);
    },
  },
  actions: {
    setFetched(fetched: boolean): void {
      this.fetched = fetched;
    },
    setIsFetching(isFetching: boolean): void {
      this.isFetching = isFetching;
    },
    async fetch(): Promise<void> {
      if (!this.isFetching) {
        this.setIsFetching(true);
        const response: AxiosResponse<Array<TagJSON>> = await axios.get(`${Config.getApiUrl()}/tags`);
        const tags: Array<Tag> = response.data.map((value: TagJSON) => Tag.fromJSON(value));
        Vue.set(this, 'tags', tags);
        this.setFetched(true);
        this.setIsFetching(false);
      }
    },
    async createTag(newTag: NewTag): Promise<void> {
      try {
        this.isActionPending = true;
        const response: AxiosResponse<TagJSON> = await axios.post(`${Config.getApiUrl()}/tags`, newTag);
        if (response.status === 200) {
          const newTag: Tag = Tag.fromJSON(response.data);
          this.tags.push(newTag);
        } else {
          console.error(response);
        }
        this.isActionPending = false;
      } catch (e: unknown) {
        logAxiosError(e);
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: i18n.t(TranslationKeys.error.genericDuplicate.$path),
        });
        this.isActionPending = false;
      }
    },
    async updateTag(tagId: string, newTag: NewTag): Promise<void> {
      try {
        const tag: Optional<Tag> = this.getTagById(tagId);
        if (!tag) {
          return;
        }
        this.isActionPending = true;
        const response: AxiosResponse<TagJSON> = await axios.post(`${Config.getApiUrl()}/tags/${tagId}`, newTag, {
          params: {
            hash: tag.hash,
          },
        });
        if (response.status === 200) {
          tag.name = response.data.name;
          tag.color = response.data.color;
          tag.description = response.data.description;
          tag.hash = response.data.hash;
        } else {
          console.error(response);
        }
        this.isActionPending = false;
      } catch (e: unknown) {
        logAxiosError(e);
        let errorMessage: TranslateResult = '';
        const axiosError: AxiosError<ErrorResponse> = e as AxiosError<ErrorResponse>;
        if (ErrorType.HASH_CONFLICT.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.concurrentModification.$path);
        }
        if (ErrorType.NOT_FOUND.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.genericNotFound.$path);
        }
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: errorMessage,
        });
        this.isActionPending = false;
      }
    },
    async deleteTag(tag: Tag): Promise<void> {
      try {
        this.isActionPending = true;
        if (!tag) {
          console.error('Release management for deleting not found');
          return;
        }
        const response: AxiosResponse<Array<string>> = await axios.delete(`${Config.getApiUrl()}/tags/${tag._id}`, {
          params: {
            hash: tag.hash,
          },
        });
        if (response.status !== 200) {
          console.error(response);
        }
        this.tags = this.tags.filter((currenTag: Tag) => currenTag._id !== tag._id);
        this.isActionPending = false;
      } catch (e: unknown) {
        logAxiosError(e);
        const axiosError: AxiosError<ErrorResponse> = e as AxiosError<ErrorResponse>;
        let errorMessage: TranslateResult = '';
        if (ErrorType.HASH_CONFLICT.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.concurrentModification.$path);
        }
        if (ErrorType.NOT_FOUND.equals(axiosError.response?.data.errorType)) {
          errorMessage = i18n.t(TranslationKeys.error.genericNotFound.$path);
        }
        const appGlobalStore: AppGlobalStore = useAppGlobalStore();
        appGlobalStore.updateGenericErrorModal({
          showGenericErrorModal: true,
          showGenericErrorModalReloadButton: true,
          genericErrorModalText: errorMessage,
        });
        this.isActionPending = false;
      }
    },
  },
});
