import { GondolaPublishing, GondolaTemplate } from '@client/models';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { Optional } from '@common/types';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';

/**
 * Check if the current publishing is up-to-date or not
 * This will return false if either:
 * - The gondola template hash doesn't match the same hash at the time when the gondola template was published.
 * - The schedules hashes don't match the schedules hashes at the time when the schedules were published.
 */
function isGondolaPublishingUpToDate(gondolaPublishing: Optional<GondolaPublishing>): boolean {
  const schedulesStore: SchedulesStore = useSchedulesStore();
  const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  if (!gondolaPublishing) {
    // If the template hasn't been published yet, flag it as up to date
    return true;
  }
  // Get the published gondola template
  const gondolaTemplatePublished: Optional<GondolaTemplate> = gondolaTemplatesStore.getById(
    gondolaPublishing.gondolaTemplateId || ''
  );
  // Array to store the updated hashes of the published schedules
  const scheduleHashesPublished: Array<string> = [];
  // Get the published schedules hashes
  gondolaPublishing.scheduleIds?.forEach((scheduleId: string) => {
    const schedule: Optional<ScheduleModel> = schedulesStore.getById(scheduleId);
    if (schedule?.hash) {
      scheduleHashesPublished.push(schedule.hash);
    }
  });
  // Check if the published gondola template hash is up-to-date
  const isGondolaHashMatches: boolean = gondolaPublishing.gondolaTemplateHash === gondolaTemplatePublished?.hash;
  // Check if the published schedules hashes is up-to-date
  const isScheduleHashesMatches: boolean = scheduleHashesPublished.every((scheduleHash: string) =>
    gondolaPublishing.scheduleHashes.find((publishedScheduleHash: string) => publishedScheduleHash === scheduleHash)
  );
  return isGondolaHashMatches && isScheduleHashesMatches;
}

export default isGondolaPublishingUpToDate;
