import Device from '@client/models/DeviceModels/Device.model';
import GondolaPublishing from './GondolaPublishing.model';
import { DeviceJSON } from '@common/device/types';
import { GondolaJSON } from '@common/gondola-template/types';
import { ActiveHourItemJSON } from '@common/active-hours/types';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';
import ActiveHourItem from '../ActiveHoursModels/ActiveHourItem.model';
import TagReference from '@client/models/SettingsModels/TagReference';
import { TagReferenceJSON } from '@common/config/tags/types';
import { Optional } from '@common/types';

// `lastPublishing` can be null in the stores list endpoint for performance reasons.
// Consider using separate data models for stores list and store detail endpoints.
export default class Gondola {
  _id!: string;
  railGrid: Device[][];
  positionInAisle: string;
  aisle: string;
  activeHours: ActiveHoursSchedule;
  lastPublishing: Optional<GondolaPublishing>;
  layoutHash: string;
  activeHoursJobFailed?: boolean;
  activeHoursTransactionIds?: Array<string>;
  tags: Array<TagReference> = [];
  constructor(
    aisle: string,
    positionInAisle: string,
    railGrid: Device[][] = [[new Device()]],
    activeHours: ActiveHoursSchedule = [],
    tags: Array<TagReference> = [],
    lastPublishing: Optional<GondolaPublishing> = null
  ) {
    this.aisle = aisle;
    this.positionInAisle = positionInAisle;
    this.railGrid = railGrid;
    this.layoutHash = '';
    this.activeHours = activeHours;
    this.tags = tags;
    this.lastPublishing = lastPublishing;
  }

  toJSON(): GondolaJSON {
    const newRailGrid: DeviceJSON[][] = this.railGrid.map((value: Device[]) => {
      return value.map((device: Device) => {
        return device.toJSON();
      });
    });

    return {
      _id: this._id,
      positionInAisle: this.positionInAisle,
      aisle: this.aisle,
      activeHours: this.activeHours,
      layoutHash: this.layoutHash,
      railGrid: newRailGrid,
      tagsReferences: this.tags.map((tag: TagReference) => TagReference.toJSON(tag)),
    };
  }

  /**
   * Creates a deep copy of the gondola model
   * @param source the object to be cloned
   */
  static clone(source: Gondola): Gondola {
    const clonedRailGrid: Device[][] = source.railGrid.map((value: Device[]) => {
      return value.map((device: Device) => {
        return Device.clone(device);
      });
    });
    const gondola: Gondola = new Gondola(
      source.aisle,
      source.positionInAisle,
      clonedRailGrid,
      source.activeHours,
      source.tags,
      source.lastPublishing
    );
    gondola._id = source._id;
    gondola.layoutHash = source.layoutHash;
    gondola.activeHoursJobFailed = source.activeHoursJobFailed;
    gondola.lastPublishing = source.lastPublishing;
    return gondola;
  }

  static fromJSON(source: GondolaJSON): Gondola {
    const newRailGrid: Device[][] = source.railGrid.map((value: DeviceJSON[]) => {
      return value.map((device: DeviceJSON) => {
        return Device.fromJSON(device);
      });
    });
    const gondola: Gondola = new Gondola(
      source.aisle,
      source.positionInAisle,
      newRailGrid,
      source.activeHours.map((item: ActiveHourItemJSON) => ActiveHourItem.fromJSON(item)),
      source.tagsReferences.map((tag: TagReferenceJSON) => TagReference.fromJSON(tag))
    );
    if (source._id) {
      gondola._id = source._id;
    }
    gondola.layoutHash = source.layoutHash;
    gondola.activeHoursJobFailed = source.activeHoursJobFailed;
    gondola.activeHoursTransactionIds = source.activeHoursTransactionIds;
    if (source.lastPublishing) {
      gondola.lastPublishing = GondolaPublishing.fromJSON(source.lastPublishing);
    }
    return gondola;
  }
}
