export enum OrderBy {
  name = 'name',
  // If we order by creation date, we need to set the query to sort by _id
  createdAt = '_id',
  updatedAt = 'updatedAt',
}

export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}
