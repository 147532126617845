
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SectionForPublishing, {
  PublishedStateFilter,
  SectionForPublishingFilter,
} from '@client/models/GondolaModels/SectionsForPublishing.model';
import Moment from 'moment';
import { ClientPublishState, PublishSectionsPageType } from '@client/enums';
import HeaderTextFilter from '@client/components/Filters/HeaderFilters/HeaderTextFilter.vue';
import HeaderDateFilter from '@client/components/Filters/HeaderFilters/HeaderDateFilter.vue';
import HeaderPublishStatusFilter from '@client/components/Filters/HeaderFilters/HeaderPublishStatusFilter.vue';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
import { getStoreDetailPath } from '@client/router/utils';

@Component({
  components: { HeaderPublishStatusFilter, HeaderDateFilter, HeaderTextFilter: HeaderTextFilter },
  computed: {
    PublishSectionsPageType() {
      return PublishSectionsPageType;
    },
    Moment() {
      return Moment;
    },
  },
  methods: {
    getStoreDetailPath,
  },
})
export default class SectionsDataTable extends Vue {
  @Prop()
  private sections!: Array<SectionForPublishing>;
  @Prop()
  private pageType!: PublishSectionsPageType;
  private allSelected: boolean = false;
  private sectionForPublishingFilter: SectionForPublishingFilter = {
    storeName: '',
    storeAzureId: '',
    aisle: '',
    section: '',
    lastPublishedOn: [],
    lastPublishedTemplateName: '',
    lastPublishedTemplateState: null,
  };

  emitSelectedItems() {
    const selectedItems: Array<SectionForPublishing> = this.filteredSections.filter(
      (section: SectionForPublishing) => section.selected
    );
    this.$emit('selected-items', selectedItems);
  }

  toggleSelectAll(): void {
    const allSelected: boolean =
      !!this.filteredSections.length &&
      this.filteredSections.filter((item: SectionForPublishing) => item.selected).length ===
        this.filteredSections.filter((item: SectionForPublishing) => item.isSelectable).length;
    this.filteredSections.forEach((section: SectionForPublishing) => {
      if (section.isSelectable) {
        section.setSelected(!allSelected);
      }
    });
    this.emitSelectedItems();
  }

  deselectAll(): void {
    this.sections.forEach((section: SectionForPublishing) => section.setSelected(false));
    this.allSelected = false;
    this.emitSelectedItems();
  }

  initFilters(): void {
    this.sectionForPublishingFilter = {
      storeName: '',
      storeAzureId: '',
      aisle: '',
      section: '',
      lastPublishedOn: [],
      lastPublishedTemplateName: '',
      lastPublishedTemplateState: null,
    };
    this.deselectAll();
  }

  async created(): Promise<void> {
    this.initValues();
  }

  @Watch('sections')
  private initValues() {
    if (this.pageType === PublishSectionsPageType.REPUBLISH) {
      this.sections.forEach((section: SectionForPublishing) => {
        if (!section.isSelectable) {
          section.setSelected(false);
          return;
        }
        section.setSelected(
          !section?.isPublishUpToDate ||
            (section?.lastPublishedTemplateState !== ClientPublishState.PUBLISHING &&
              section?.lastPublishedTemplateState !== ClientPublishState.INITIALIZING &&
              section?.lastPublishedTemplateState !== ClientPublishState.PUBLISHED) ||
            false
        );
      });
    }
    this.emitSelectedItems();
  }

  get isAllSelected(): boolean {
    return (
      !!this.filteredSections.length &&
      this.filteredSections.filter((item: SectionForPublishing) => item.selected).length ===
        this.filteredSections.filter((item: SectionForPublishing) => item.isSelectable).length
    );
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$tc(this.$i18nTranslationKeys.store.$path),
        align: 'start',
        value: 'storeName',
        class: 'text-no-wrap',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.rePublishView.azurStoreId.$path),
        value: 'storeAzureId',
        class: 'text-no-wrap',
      },
      { text: this.$tc(this.$i18nTranslationKeys.aisle.$path), value: 'aisle', class: 'text-no-wrap' },
      { text: this.$tc(this.$i18nTranslationKeys.gondola.$path), value: 'section', class: 'text-no-wrap' },
      {
        text: this.$t(this.$i18nTranslationKeys.rePublishView.lastPublishedTemplate.$path),
        value: 'lastPublishedTemplateName',
        class: 'text-no-wrap',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.rePublishView.lastPublishDate.$path),
        value: 'lastPublishedOn',
        class: 'text-no-wrap',
      },
      {
        text: this.$t(this.$i18nTranslationKeys.rePublishView.publishStatus.$path),
        value: 'lastPublishedTemplateState',
        class: 'text-no-wrap',
      },
    ];
  }

  getTemplateLink(templateId: string) {
    return `/template/${templateId}`;
  }

  get filteredSections(): Array<SectionForPublishing> {
    return this.sections.filter((section: SectionForPublishing) =>
      this.filterSingleSection(section, this.sectionForPublishingFilter)
    );
  }

  private changeStoreNameFilter(storeNameFilter: string) {
    this.sectionForPublishingFilter.storeName = storeNameFilter;
    this.deselectAll();
  }

  private changeStoreAzureIdFilter(storeAzureIdFilter: string) {
    this.sectionForPublishingFilter.storeAzureId = storeAzureIdFilter;
    this.deselectAll();
  }

  private changeAisleFilter(aisleFilter: string) {
    this.sectionForPublishingFilter.aisle = aisleFilter;
    this.deselectAll();
  }

  private changeSectionFilter(sectionFilter: string) {
    this.sectionForPublishingFilter.section = sectionFilter;
    this.deselectAll();
  }

  private changeLastPublishedTemplateNameFilter(lastPublishedTemplateNameFilter: string) {
    this.sectionForPublishingFilter.lastPublishedTemplateName = lastPublishedTemplateNameFilter;
    this.deselectAll();
  }

  private changeLastPublishedOnFilter(lastPublishedOnFilter: [string?, string?]) {
    this.sectionForPublishingFilter.lastPublishedOn = lastPublishedOnFilter;
    this.deselectAll();
  }

  private changeLastPublishedStateFilter(lastPublishedTemplateStateFilter: PublishedStateFilter) {
    this.sectionForPublishingFilter.lastPublishedTemplateState = lastPublishedTemplateStateFilter;
    this.deselectAll();
  }

  private filterSingleSection(
    section: SectionForPublishing,
    sectionForPublishingFilter: SectionForPublishingFilter
  ): boolean {
    if (
      sectionForPublishingFilter.storeName &&
      !section.storeName.toLowerCase().includes(sectionForPublishingFilter.storeName.toLowerCase())
    ) {
      return false;
    }
    if (
      sectionForPublishingFilter.storeAzureId &&
      !section.storeAzureId.toLowerCase().includes(sectionForPublishingFilter.storeAzureId.toLowerCase())
    ) {
      return false;
    }
    if (
      sectionForPublishingFilter.aisle &&
      !section.aisle.toLowerCase().includes(sectionForPublishingFilter.aisle.toLowerCase())
    ) {
      return false;
    }
    if (
      sectionForPublishingFilter.section &&
      !section.section.toLowerCase().includes(sectionForPublishingFilter.section.toLowerCase())
    ) {
      return false;
    }
    if (
      sectionForPublishingFilter.lastPublishedTemplateName &&
      (!section.lastPublishedTemplateName ||
        !section.lastPublishedTemplateName
          .toLowerCase()
          .includes(sectionForPublishingFilter.lastPublishedTemplateName.toLowerCase()))
    ) {
      return false;
    }
    if (sectionForPublishingFilter.lastPublishedOn[0] && sectionForPublishingFilter.lastPublishedOn[1]) {
      if (
        !section.lastPublishedOn ||
        Moment(section.lastPublishedOn).isBefore(Moment(sectionForPublishingFilter.lastPublishedOn[0])) ||
        Moment(section.lastPublishedOn).isAfter(sectionForPublishingFilter.lastPublishedOn[1])
      ) {
        return false;
      }
    }
    if (sectionForPublishingFilter.lastPublishedTemplateState) {
      if (
        sectionForPublishingFilter.lastPublishedTemplateState === PublishedStateFilter.PUBLISHED &&
        section.lastPublishedTemplateState !== ClientPublishState.PUBLISHED
      ) {
        return false;
      }
      if (
        sectionForPublishingFilter.lastPublishedTemplateState === PublishedStateFilter.PENDING &&
        section.lastPublishedTemplateState !== ClientPublishState.PUBLISHING &&
        section.lastPublishedTemplateState !== ClientPublishState.INITIALIZING
      ) {
        return false;
      }
      if (
        sectionForPublishingFilter.lastPublishedTemplateState === PublishedStateFilter.ACCEPTED &&
        section.lastPublishedTemplateState !== ClientPublishState.ACCEPTED
      ) {
        return false;
      }
      if (
        sectionForPublishingFilter.lastPublishedTemplateState === PublishedStateFilter.FAILED &&
        (!section.lastPublishedTemplateState ||
          section.lastPublishedTemplateState === ClientPublishState.PUBLISHED ||
          section.lastPublishedTemplateState === ClientPublishState.PUBLISHING)
      ) {
        return false;
      }
      if (
        sectionForPublishingFilter.lastPublishedTemplateState === PublishedStateFilter.NOT_SET &&
        section.lastPublishedTemplateState
      ) {
        return false;
      }
    }

    return true;
  }
  private isPublishingPending(section: SectionForPublishing) {
    return (
      section.lastPublishedTemplateState === ClientPublishState.PUBLISHING ||
      section.lastPublishedTemplateState === ClientPublishState.INITIALIZING
    );
  }

  private isPublished(section: SectionForPublishing) {
    return section.lastPublishedTemplateState === ClientPublishState.PUBLISHED;
  }

  private isAccepted(section: SectionForPublishing) {
    return section.lastPublishedTemplateState === ClientPublishState.ACCEPTED;
  }
}
