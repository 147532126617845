
import { Component, Vue } from 'vue-property-decorator';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: { Wrapper },
})
export default class MultipleSectionsLoader extends Vue {
  /* DECLARATIONS */
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
