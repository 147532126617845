
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { MediaFile } from '@client/models/ContentModels/types';

/**
 * Renders the expanded content item content.
 */
@Component({})
export default class MediaItemExpanded extends Vue {
  /* DECLARATIONS */
  @Prop()
  private contentItem!: MediaFile;
  @Prop()
  private colSpan!: number;
  private previewItemUrl: string = '';
  /* LIFECYCLE EVENTS */
  async created(): Promise<void> {
    this.previewItemUrl = await this.contentItem.getBlobUrl();
  }
  /* METHODS */

  @Watch('contentItem')
  async onContentItemUpdated(): Promise<void> {
    if (this.contentItem.isMediaFile()) {
      this.previewItemUrl = await this.contentItem.getBlobUrl();
    }
  }

  /* GETTERS */
}
