import { END_OF_DAY_TIME, START_OF_DAY_TIME } from '@client/models/ScheduleModels/Schedule.model';
import { ScheduleDay } from '@common/enums/ScheduleDay';
import { ScheduleSpanModelJSON } from '@common/schedule/types';

export default class ScheduleSpanModel {
  validityFrom: Date;
  validityTo: Date;
  recurrenceStart: string;
  recurrenceEnd: string;
  recurrenceDays: ScheduleDay[];

  constructor(
    validityFrom: Date,
    validityTo: Date,
    recurrenceStart: string,
    recurrenceEnd: string,
    recurrenceDays: ScheduleDay[]
  ) {
    this.validityFrom = validityFrom;
    this.validityTo = validityTo;
    this.recurrenceStart = recurrenceStart;
    this.recurrenceEnd = recurrenceEnd === END_OF_DAY_TIME ? START_OF_DAY_TIME : recurrenceEnd;
    this.recurrenceDays = recurrenceDays;
  }

  public static fromJSON(scheduleSpanModelJSON: ScheduleSpanModelJSON): ScheduleSpanModel {
    return new ScheduleSpanModel(
      scheduleSpanModelJSON.validityFrom,
      scheduleSpanModelJSON.validityTo,
      scheduleSpanModelJSON.recurrenceStart,
      scheduleSpanModelJSON.recurrenceEnd,
      scheduleSpanModelJSON.recurrenceDays
    );
  }

  public static clone(scheduleSpanModel: ScheduleSpanModel): ScheduleSpanModel {
    return new ScheduleSpanModel(
      scheduleSpanModel.validityFrom,
      scheduleSpanModel.validityTo,
      scheduleSpanModel.recurrenceStart,
      scheduleSpanModel.recurrenceEnd === END_OF_DAY_TIME ? START_OF_DAY_TIME : scheduleSpanModel.recurrenceEnd,
      scheduleSpanModel.recurrenceDays
    );
  }

  public toJSON(): ScheduleSpanModelJSON {
    return {
      validityFrom: this.validityFrom,
      validityTo: this.validityTo,
      recurrenceStart: this.recurrenceStart,
      recurrenceEnd: this.recurrenceEnd,
      recurrenceDays: this.recurrenceDays,
    };
  }
}
