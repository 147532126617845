import { EventLogJSON } from '@common/eventlog/types';
import { EventLog } from '@client/models';
import { WsEventLogMessage, WsEventLogPayload } from '@common/websocket/EventLog';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';

import { eventLogFromJSON } from '@client/models/EventLogModels/EventLog.common';

/**
 * Checks if the event log is in the scope of the user selected filters
 */
const isEventLogInSelectedFilters = (wsMessage: WsEventLogMessage): boolean => {
  const eventLogsStore: EventLogsStore = useEventLogsStore();
  const eventLogJson: EventLogJSON = wsMessage.payload.eventLog;
  const [startDate, endDate]: Array<number> = eventLogsStore.filters.dateRange;
  const eventLogDate: Date = new Date(wsMessage.payload.timestamp);
  if (!(startDate - eventLogDate.getTime()) && !(eventLogDate.getTime() - endDate)) {
    return false;
  }
  if (!eventLogsStore.isTypeSelectedInFilters(eventLogJson.type)) {
    return false;
  }
  return eventLogsStore.isAzureIdSelectedInFilters(eventLogJson.azureStoreId);
};

/**
 * Handle a websocket message containing an event log update
 * @param wsEventLogMessage the event log websocket message
 */
const handleEventLogMessage = async (wsEventLogMessage: WsEventLogMessage): Promise<void> => {
  if (!isEventLogInSelectedFilters(wsEventLogMessage)) {
    return;
  }
  const payload: WsEventLogPayload = wsEventLogMessage.payload;
  const eventLog: EventLog = eventLogFromJSON(payload.eventLog);
  console.debug(`Received websocket message; Event log ${eventLog._id} with type ${eventLog.type}`);
  const eventLogsStore: EventLogsStore = useEventLogsStore();
  eventLogsStore.add(eventLog);
};

export { handleEventLogMessage, isEventLogInSelectedFilters };
