
import { Component, Prop, Vue } from 'vue-property-decorator';
import videoDurationParser from '@client/utils/videoDurationParser';
import { getFPSDisplayValue } from '@client/components/ContentItems/utils';
import { PlaylistItem } from '@client/models/ContentModels/Playlist.model';
import VideoModel from '@client/models/ContentModels/Video.model';

@Component({
  methods: {
    videoDurationParser,
    getFPSDisplayValue,
  },
})
export default class PlaylistItemExpanded extends Vue {
  /* DECLARATIONS */
  @Prop()
  private playlistItem!: PlaylistItem;
  @Prop()
  private openPreviewVideo!: (contentItem: VideoModel) => void;
  @Prop()
  private dataCy!: string;
  @Prop()
  private isSearchMode!: boolean;
  @Prop()
  private isMoveMode!: boolean;
  @Prop()
  private isSelectionEnabled!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
