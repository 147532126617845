
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { DEFAULT_COMMON_STRING_MAX_LENGTH, validateTextFieldLength } from '@client/utils/validateTextFieldLength';
import { Aisle, Store } from '@client/models';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

export enum AisleOperation {
  CREATE = 'CREATE',
  RENAME = 'RENAME',
  CLONE = 'CLONE',
}

@Component({
  components: { ModalHeader },
  methods: {
    validateTextFieldLength,
  },
})
export default class AisleButton extends Vue {
  /* DECLARATIONS */
  @Prop({ required: false, type: String, default: '' })
  private aisle!: string;
  @Prop({ required: true, type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ required: true, type: Store })
  private store!: Store;

  @Prop({ required: false, default: AisleOperation.CREATE })
  private operation!: AisleOperation;

  private storesStore: StoresStore = useStoresStore();

  private showDialog: boolean = false;
  private aisleName: string = '';
  private isAisleNameValid: boolean = false;

  /* LIFECYCLE EVENTS */
  created(): void {}

  /* METHODS */
  openAisleModal(): void {
    this.showDialog = true;
    if (this.operation === AisleOperation.RENAME) {
      this.aisleName = this.aisle;
    }
  }

  closeModal(): void {
    this.showDialog = false;
    this.aisleName = '';
    this.validateAisleName();
  }

  async onSave(): Promise<void> {
    this.validateAisleName();
    if (!this.isAisleNameValid) {
      return;
    }
    switch (this.operation) {
      case AisleOperation.RENAME: {
        await this.storesStore.renameAisle(this.storeId, this.aisle, this.aisleName.trim());
        break;
      }
      case AisleOperation.CLONE: {
        await this.storesStore.cloneAisle(this.storeId, this.store.hash, this.aisle, this.aisleName.trim());
        break;
      }
      case AisleOperation.CREATE: {
        await this.storesStore.addAisle(this.storeId, new Aisle(this.aisleName.trim()));
        break;
      }
    }
    this.$router
      .push({
        query: { aisle: this.aisleName },
      })
      .catch((error: Error) => {
        console.error(error);
      });
    this.closeModal();
  }

  validateAisleName(): void {
    let isValid: boolean = true;
    if (validateTextFieldLength(this.aisleName.trim(), DEFAULT_COMMON_STRING_MAX_LENGTH, true) !== true) {
      isValid = false;
    }
    if (this.isAisleUnique(this.aisleName) !== true) {
      isValid = false;
    }
    if (this.aisleName.trim() === this.aisle) {
      isValid = false;
    }
    this.isAisleNameValid = isValid;
  }

  isAisleUnique(aisleName: string): boolean | TranslateResult {
    if (!this.store) {
      return false;
    }
    if (this.aisle === aisleName) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.duplicateAisle.$path);
    }
    return this.storesStore.aisleNameExistsInStore(this.storeId, this.aisleName)
      ? this.$t(this.$i18nTranslationKeys.storeDetail.duplicateAisle.$path)
      : true;
  }

  /* GETTERS */
  get buttonText(): TranslateResult {
    switch (this.operation) {
      case AisleOperation.RENAME:
        return this.$t(this.$i18nTranslationKeys.action.rename.$path);
      case AisleOperation.CLONE:
        return this.$t(this.$i18nTranslationKeys.action.clone.$path);
      default:
        return this.$t(this.$i18nTranslationKeys.storeDetail.createAisle.$path);
    }
  }

  get buttonColor(): string {
    switch (this.operation) {
      case AisleOperation.RENAME:
      case AisleOperation.CLONE: {
        return 'primary lighten-5 primary--text';
      }
      default: {
        return 'primary';
      }
    }
  }

  get inputPlaceholder(): string {
    switch (this.operation) {
      case AisleOperation.CREATE:
      case AisleOperation.CLONE:
        return this.$tc(this.$i18nTranslationKeys.storeDetail.nameOfNewAisle.$path);
      default:
        return this.$tc(this.$i18nTranslationKeys.storeDetail.newAisleName.$path);
    }
  }

  get buttonIcon(): string {
    switch (this.operation) {
      case AisleOperation.RENAME: {
        return 'mdi-pencil-outline';
      }
      case AisleOperation.CLONE: {
        return 'mdi-content-copy';
      }
      default: {
        return 'mdi-plus';
      }
    }
  }

  get isLoading(): boolean {
    return this.storesStore.loadingIndicator.update;
  }

  get DEFAULT_COMMON_STRING_MAX_LENGTH() {
    return DEFAULT_COMMON_STRING_MAX_LENGTH;
  }

  get storeId(): string {
    return this.$route.params.storeid;
  }

  get dataCy(): string {
    switch (this.operation) {
      case AisleOperation.RENAME:
        return `store-rename-${this.aisle}-aisle-button-cy`;
      case AisleOperation.CLONE:
        return `store-clone-${this.aisle}-aisle-button-cy`;
      default:
        return 'store-create-aisle-button-cy';
    }
  }

  get modalTitle(): TranslateResult {
    const title: TranslateResult = this.buttonText;
    switch (this.operation) {
      case AisleOperation.RENAME:
      case AisleOperation.CLONE: {
        return `${title} <strong class="primary--text">${this.aisle}</strong>`;
      }
      default: {
        return title;
      }
    }
  }

  get modalButtonText(): TranslateResult {
    return this.operation == AisleOperation.CLONE
      ? this.$t(this.$i18nTranslationKeys.action.clone.$path)
      : this.$t(this.$i18nTranslationKeys.action.save.$path);
  }
}
