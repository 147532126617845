import { HardwareModelIdentifier } from '@common/device/types';

export default class HardwareModel {
  identifier: HardwareModelIdentifier;
  length: number;
  width: number;
  height: number;
  railScaleFactor: number;

  private constructor(
    identifier: HardwareModelIdentifier,
    length: number,
    width: number,
    height: number,
    railScaleFactor: number
  ) {
    this.identifier = identifier;
    this.length = length;
    this.width = width;
    this.height = height;
    this.railScaleFactor = railScaleFactor;
  }

  static getByIdentifier(identifier: string = HardwareModelIdentifier.VR06001): HardwareModel {
    let returnValue: HardwareModel | undefined;
    returnValue = this.getAll().find((hardwareModel: HardwareModel) => hardwareModel.identifier === identifier);

    if (returnValue === undefined) {
      returnValue = new HardwareModel(HardwareModelIdentifier.VR06001, 60, 100, 200, 0.253125);
    }

    return returnValue;
  }

  static getAll(): HardwareModel[] {
    const all: HardwareModel[] = [];
    all.push(new HardwareModel(HardwareModelIdentifier.VR06001, 60, 1920, 158, 0.253125));
    all.push(new HardwareModel(HardwareModelIdentifier.VR09001, 90, 2880, 158, 0.253125));
    all.push(new HardwareModel(HardwareModelIdentifier.VR12001, 120, 3840, 158, 0.253125));
    all.push(new HardwareModel(HardwareModelIdentifier.VP1080P, 60, 1920, 1080, 0.253125));
    return all;
  }
}
