
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItem, Media } from '@client/models/ContentModels/types';

@Component({})
export default class AssignButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private contentItem!: Media;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private onAssignButtonClick!: (contentItem: ContentItem) => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get contentItemName(): string {
    if (this.contentItem.isMediaFile()) {
      return this.contentItem.originalName;
    } else {
      return this.contentItem.name;
    }
  }
}
