import { RouteConfig } from 'vue-router/types/router';

const ErrorRoute = () => import('@client/views/ErrorPages/ErrorView.vue');

export const errorRoutes: Array<RouteConfig> = [
  {
    path: '/error',
    name: 'error',
    alias: ['/:pathMatch(.*)*'], // match everything that router can't recognize
    component: ErrorRoute,
  },
];
