
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import router from '@client/router';
import { Store } from '@client/models';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { TranslateResult } from 'vue-i18n';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';

@Component({
  components: { ModalHeader },
})
export default class DeleteStoreButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  private storesStore: StoresStore = useStoresStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private devicesStore: DevicesStore = useDevicesStore();

  private showDialog: boolean = false;

  /* LIFECYCLE EVENTS */

  /* METHODS */

  openDeleteStoreConfirmation(): void {
    this.showDialog = true;
  }

  cancelDeleteStore(): void {
    this.showDialog = false;
  }

  async confirmDeleteStore(): Promise<void> {
    this.showDialog = false;
    await this.storesStore.deleteStore(this.store._id);
    await router.push(`/stores`);
  }

  /* GETTERS */

  get isLoading(): boolean {
    return (
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update ||
      this.activeHoursStore.loadingIndicator.update
    );
  }

  get hasStoreAnyDevices(): boolean {
    return this.devicesStore.getDevicesByStoreId(this.store._id).length > 0;
  }

  get disabled(): boolean {
    return this.hasStoreAnyDevices;
  }

  get errorText(): TranslateResult {
    if (this.hasStoreAnyDevices) {
      return this.$t(this.$i18nTranslationKeys.storeDetail.deleteStore.validationErrors.devicesRegistered.$path);
    }
    return '';
  }
}
