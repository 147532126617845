import { VueConstructor } from 'vue';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { TranslateResult } from 'vue-i18n';
import i18n from '@client/plugins/i18n/i18n';

/**
 * Adds typing to i18n translation keys
 * Callable with the global variable: **$i18nTranslationKeys**
 * Any call to this global variable should end with a **.toString()** or **.$path** for it to return a valid value
 */
export default class TypedI18nPlugin {
  static install(Vue: VueConstructor): void {
    Vue.prototype.$i18nTranslationKeys = new TypedI18nPlugin();
  }

  constructor() {
    return TranslationKeys;
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    /*
   Any call to this global variable should end with a **.toString()** or **.$path** for it to return a valid value
    */
    $i18nTranslationKeys: typeof TranslationKeys;
  }
}

/**
 * When calling a translated string in a function, this should be used to get the computed value,
 * Otherwise it will be compiled as a fixed string
 * @param key key of the string
 * @param params optional params if any
 */
export const getComputedTranslationString = (
  key: string,
  params?: { [p: string]: string | number }
): TranslateResult => {
  return i18n.t(key, params);
};
