import ClientPublishState, { fromJSON, toJSON } from '@client/enums/ClientPublishState';
import { DevicePublishInformationJSON } from '@common/publishing/types';

/**
 * Device publishing details
 */
export default class DevicePublishInformation {
  timestamp: Date;
  message: string;
  state: ClientPublishState;

  constructor(state: ClientPublishState, timestamp: Date = new Date(), message: string = '') {
    this.state = state;
    this.timestamp = timestamp;
    this.message = message;
  }

  toJSON(): DevicePublishInformationJSON {
    return {
      timestamp: this.timestamp,
      message: this.message,
      state: toJSON(this.state),
    };
  }

  static fromJSON(source: DevicePublishInformationJSON): DevicePublishInformation {
    return new DevicePublishInformation(fromJSON(source.state), source.timestamp, source.message);
  }
}
