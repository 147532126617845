
import { Vue, Component, Prop } from 'vue-property-decorator';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import ConfigDialog from '@client/components/Settings/ReleaseManagement/ConfigDialog.vue';

@Component({
  components: { ConfigDialog },
})
export default class EditButton extends Vue {
  @Prop()
  private currentConfigValues!: ReleaseManagement;

  private showDialog: boolean = false;

  closeDialog(): void {
    this.showDialog = false;
  }
}
