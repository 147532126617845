import {
  AnonymousCredential,
  BlobClient,
  BlobServiceClient,
  BlobUploadCommonResponse,
  BlockBlobClient,
  ContainerClient,
  RestError,
} from '@azure/storage-blob';
import { TransferProgressEvent } from '@azure/core-http';
import axios from 'axios';
import Config from '@client/utils/config';
import { uint8ArrayToHex } from '@client/utils/uint8converter';
import { ContentType } from '@common/enums';
import { v4 as uuidv4 } from 'uuid';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import ImageModel from '@client/models/ContentModels/Image.model';
import VideoModel from '@client/models/ContentModels/Video.model';
import { ContentItemPreviewStates } from '@common/content-item/types';

export interface Dimensions {
  width: number;
  height: number;
  duration: number;
}

interface FileUploadResponse {
  url: string;
  successful: boolean;
  response: BlobUploadCommonResponse;
}

interface SASWriteToken {
  URL: string;
  CONTAINER: string;
}

interface SASReadToken {
  SAS_STRING: string;
  VALID_UNTIL: Date;
}

interface NamedBlob extends Blob {
  name: string;
}

export default class AzureBlobService {
  // for Nomenclature see
  // https://docs.microsoft.com/en-us/azure/storage/common/storage-sas-overview
  private static readonly DEFAULT_BLOCK_SIZE: number = 4 * 1024 * 1024; // 4MB block size
  private static readonly DEFAULT_CONCURRENCY: number = 20;
  private static readonly BACKEND_AZURE_API_ENDPOINT: string = '/azureblob';
  private static readonly BACKEND_URL: string = `${Config.getApiUrl()}${AzureBlobService.BACKEND_AZURE_API_ENDPOINT}`;
  private static readonly BLOB_CACHE_TIME_SECONDS: number = 7200;
  private static readSASToken: SASReadToken;
  private static abortController: AbortController = new AbortController();

  /**
   * Fetches a SAS token from the backend, which is only valid for uploading data.
   * Then returns a ContainerClient instantiated with the received token..
   */
  private static async getContainerClientForWriting(containerName: string = 'default'): Promise<ContainerClient> {
    const sasWriteToken: SASWriteToken = (await axios.get(`${this.BACKEND_URL}/write-token`)).data.data;
    const defaultCredential: AnonymousCredential = new AnonymousCredential();
    const blobServiceClient: BlobServiceClient = new BlobServiceClient(sasWriteToken.URL, defaultCredential);
    return blobServiceClient.getContainerClient(containerName);
  }

  public static async getUrlReadToken(): Promise<string> {
    if (!this.readSASToken) {
      const token: string | null = window.localStorage.getItem('readSASToken');
      if (token) {
        this.readSASToken = JSON.parse(token);
      }
    }
    if (!this.readSASToken || new Date(this.readSASToken.VALID_UNTIL) <= new Date()) {
      this.readSASToken = (await axios.get(`${AzureBlobService.BACKEND_URL}/read-token`)).data.data;
      window.localStorage.setItem('readSASToken', JSON.stringify(this.readSASToken));
    }

    return this.readSASToken.SAS_STRING;
  }

  /**
   * Wraps a given blob storage blob url with an access token for read access.
   * Caches a read token once its been retrieved and only renews it when its expired
   */
  public static async wrapUrlWithReadToken(url: string): Promise<string> {
    await AzureBlobService.getUrlReadToken();

    return `${url}?${this.readSASToken.SAS_STRING}`;
  }

  /**
   * Cancels the ongoing upload
   */
  public static cancelCurrentUpload(): void {
    this.abortController.abort('Canceled by the user');
    // Reset the abort controller or all the next uploads will be canceled if kept on the same instance.
    this.abortController = new AbortController();
  }

  /**
   * Uploads an image or video file to the blob storage
   */
  private static async uploadFile(
    containerClient: ContainerClient,
    file: NamedBlob,
    onUploadFileProgress: (progress: TransferProgressEvent) => void
  ): Promise<FileUploadResponse> {
    // see https://github.com/Azure/azure-sdk-for-js/blob/master/sdk/storage/storage-blob/samples/typescript/advanced.ts
    const blobClient: BlobClient = containerClient.getBlobClient(file.name);
    const blockBlobClient: BlockBlobClient = blobClient.getBlockBlobClient();
    const response: BlobUploadCommonResponse = await blockBlobClient.uploadData(file, {
      abortSignal: this.abortController.signal,
      blockSize: this.DEFAULT_BLOCK_SIZE,
      concurrency: this.DEFAULT_CONCURRENCY,
      onProgress: (transferProgressEvent: TransferProgressEvent) => {
        onUploadFileProgress(transferProgressEvent);
      },
      blobHTTPHeaders: {
        blobCacheControl: `max-age=${this.BLOB_CACHE_TIME_SECONDS}`,
      },
    });
    return {
      successful: response._response.status === 201,
      response: response,
      url: response._response.request.url.split('?')[0],
    };
  }

  public static async createContentItem(
    contentItemFile: File,
    fileType: string,
    customer: string,
    onUploadFileProgress: (progress: TransferProgressEvent) => void,
    currentlySelectedFolder: string
  ): Promise<void> {
    const containerName: string = this.getValidContainerName(customer);
    const containerClient: ContainerClient = await this.getContainerClientForWriting(containerName);
    const contentItemsStore: ContentItemsStore = useContentItemsStore();
    try {
      await containerClient.create();
      console.info(`Created container ${containerName}`);
    } catch (e: unknown) {
      const error: RestError = e as RestError;
      if (error && error.statusCode === 409) {
        console.warn(`Container ${containerName} already existed`);
      } else {
        console.error(error);
      }
    }
    // in order to set a UUID as filename, we need to create a new File object
    const blob: Blob = contentItemFile.slice(0, contentItemFile.size, contentItemFile.type);
    const uuidFile: NamedBlob = new Blob([blob], {
      type: contentItemFile.type,
    }) as NamedBlob;
    uuidFile.name = uuidv4(); // NOTE: Currently used as cacheId in publishing-job
    const fileUploadResponse: FileUploadResponse = await this.uploadFile(
      containerClient,
      uuidFile,
      onUploadFileProgress
    );

    if (!fileUploadResponse.successful) {
      throw new Error('Could not upload file');
    }
    const appGlobalStore: AppGlobalStore = useAppGlobalStore();
    const md5HashHex: string = uint8ArrayToHex(fileUploadResponse.response.contentMD5);
    let contentItem: ImageModel | VideoModel;
    if (contentItemFile.type.includes('image')) {
      contentItem = ImageModel.getDefaultEmptyImage();
    } else if (contentItemFile.type.includes('video')) {
      contentItem = VideoModel.getDefaultEmptyVideo();
    } else {
      throw new Error('Content type unrecognized');
    }
    contentItem.setUrl(fileUploadResponse.url);
    contentItem.setName(uuidFile.name, contentItemFile.name);
    contentItem.setChecksum(md5HashHex);
    contentItem.setPreviewInfo('', ContentItemPreviewStates.PENDING);
    contentItem.setFileType(fileType as ContentType);
    contentItem.setCustomerId(appGlobalStore.customer);
    if (currentlySelectedFolder) {
      contentItem.setParentFolder(currentlySelectedFolder);
    }
    await contentItemsStore.createContentItem(contentItem);
    return;
  }

  /**
   * Helper function to ensure conformity to container naming rules specified here:
   * https://docs.microsoft.com/en-us/rest/api/storageservices/naming-and-referencing-containers--blobs--and-metadata
   *
   */
  private static getValidContainerName(containerNameCandidate: string): string {
    // 1.  Container names must start with a letter or number, and can contain only letters, numbers, and the dash (-) character.
    containerNameCandidate = containerNameCandidate.replace(/[^a-z0-9-]/gi, '');
    containerNameCandidate = containerNameCandidate.replace(/^-+/g, '');
    // 2. Every dash (-) character must be immediately preceded and followed by a letter or number; consecutive dashes are not permitted in container names.
    containerNameCandidate = containerNameCandidate.replace(/-+/g, '-');
    // 3. All letters in a container name must be lowercase.
    containerNameCandidate = containerNameCandidate.toLowerCase();
    // 4. Container names must be from 3 through 63 characters long.
    containerNameCandidate = containerNameCandidate.padStart(3, '0');
    containerNameCandidate = containerNameCandidate.substring(0, 63);

    if (!containerNameCandidate) {
      throw Error('Azure container name cannot be empty');
    }
    return containerNameCandidate;
  }
}
