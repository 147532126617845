import BaseContentItemModel from '@client/models/ContentModels/BaseContentItem.model';
import { ContentType } from '@common/enums';
import { Ancestors, DEFAULT_ANCESTORS, FolderJSON } from '@common/content-item/types';
import TagReference from '@client/models/SettingsModels/TagReference';

export default class FolderModel extends BaseContentItemModel {
  ancestors: Ancestors;
  constructor(
    id: string,
    customerId: string,
    name: string,
    hash: string,
    type: ContentType,
    ancestors: Ancestors,
    tags: Array<TagReference>,
    parentFolder?: string
  ) {
    super(id, customerId, name, hash, type, parentFolder, ancestors, tags);
    this.ancestors = ancestors;
  }
  public static getDefaultEmptyFolder(): FolderModel {
    return new FolderModel('', '', '', '', ContentType.Folder, DEFAULT_ANCESTORS, [], '');
  }

  public static fromJSON(folderJSON: FolderJSON): FolderModel {
    const contentItem: FolderModel = FolderModel.getDefaultEmptyFolder();
    contentItem.setId(folderJSON._id);
    contentItem.setCustomerId(folderJSON.customerId);
    contentItem.setName(folderJSON.name);
    contentItem.setFileType(folderJSON.type);
    contentItem.setHash(folderJSON.hash);
    contentItem.setParentFolder(folderJSON.parentFolder);
    contentItem.setCreatedAt();
    contentItem.setAncestors(folderJSON.ancestors);
    contentItem.setTags(folderJSON.tags);
    return contentItem;
  }

  public clone(): FolderModel {
    return new FolderModel(
      this._id,
      this.customerId,
      this.name,
      this.hash || '',
      this.type,
      this.ancestors,
      this.tags,
      this.parentFolder
    );
  }
}
