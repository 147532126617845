
import { Component, Prop, Vue } from 'vue-property-decorator';
import GondolaDevice from './GondolaDevice.vue';
import { Gondola as GondolaModel, GondolaPublishing, Device, DevicePublishing } from '@client/models';
import isGondolaPublishingUpToDate from '@client/utils/isGondolaPublishingUpToDate';
import SmartPreview from '@client/components/SmartPreview/SmartPreview.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { StoresStore, useStoresStore } from '@client/stores/stores';

@Component({
  components: {
    SmartPreview,
    GondolaDevice,
  },
})
export default class Gondola extends Vue {
  @Prop() private gondola!: GondolaModel;
  @Prop() private storeId!: string;
  private isPublishingUpToDate: boolean = isGondolaPublishingUpToDate(this.gondola.lastPublishing);
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private storesStore: StoresStore = useStoresStore();
  private timezone: string = this.storesStore.getStoreById(this.storeId)?.timezone || '';
  get railGrid(): Array<Array<Device>> {
    return this.gondola.railGrid;
  }

  getDevicePublishing(rowIndex: number, colIndex: number): DevicePublishing | undefined {
    if (this.gondola.lastPublishing && this.gondola.lastPublishing.railGrid?.[rowIndex]) {
      return this.gondola.lastPublishing?.railGrid?.[rowIndex][colIndex];
    }
    return undefined;
  }

  getCurrentlyDisplayedScheduleForTemplate(gondolaTemplatePublishing?: GondolaPublishing): string {
    if (this.isPublishingUpToDate && gondolaTemplatePublishing?.gondolaTemplateId) {
      return this.schedulesStore.getCurrentlyDisplayedScheduleForTemplate(
        gondolaTemplatePublishing.gondolaTemplateId,
        this.timezone
      );
    }
    return '';
  }

  private getRailClass(device: Device): string {
    return `device-${device.hardwareModel.identifier}`;
  }
}
