import BaseContentItemModel from '@client/models/ContentModels/BaseContentItem.model';
import { ContentType, LayerVisibility } from '@common/enums';
import AzureBlobService from '@client/plugins/azureblobservice';
import { Ancestors, ContentItemPreviewStates, ImageJSON, NewImageJSON, ONE_HOUR } from '@common/content-item/types';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import TagReference from '@client/models/SettingsModels/TagReference';

export default class ImageModel extends BaseContentItemModel {
  originalName: string;
  url: string;
  previewUrl: string;
  previewState: ContentItemPreviewStates;
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
  zIndex: number;
  checksum: string;
  lastPreviewJobRun?: number;

  isDownloading: boolean = false;
  downloadFailed: boolean = false;
  percentCompleted: number = 0;

  constructor(
    id: string,
    customerId: string,
    name: string,
    hash: string,
    type: ContentType,
    originalName: string,
    url: string,
    previewUrl: string,
    previewState: ContentItemPreviewStates,
    height: number,
    width: number,
    tags?: Array<TagReference>,
    offsetX: number = 0,
    offsetY: number = 0,
    zIndex: number = 0,
    checksum: string = '',
    parentFolder?: string,
    ancestors?: Ancestors,
    lastPreviewJobRun?: number
  ) {
    super(id, customerId, name, hash, type, parentFolder, ancestors, tags);
    this.originalName = originalName;
    this.url = url;
    this.previewUrl = previewUrl;
    this.previewState = previewState;
    this.height = height;
    this.width = width;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.zIndex = zIndex;
    this.checksum = checksum;
    this.lastPreviewJobRun = lastPreviewJobRun;
  }

  public setPreviewInfo(
    previewUrl: string,
    previewState: ContentItemPreviewStates = ContentItemPreviewStates.COMPLETED
  ): void {
    this.previewUrl = previewUrl;
    this.previewState = previewState;
  }

  public setDimensions(
    height: number,
    width: number,
    offsetX: number = 0,
    offsetY: number = 0,
    zIndex: number = 0
  ): void {
    this.height = height;
    this.width = width;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.zIndex = zIndex;
  }

  public setUrl(url: string): void {
    this.url = url;
  }

  setChecksum(checksum: string): void {
    this.checksum = checksum;
  }

  public setName(name: string, originalName?: string): void {
    this.name = name;
    this.originalName = originalName ?? name;
  }

  get dimensions(): string {
    if (!this.width && !this.height) {
      return '-';
    }
    return `${this.width}x${this.height}`;
  }

  get isPreviewLoading(): boolean {
    return (
      this.previewState !== ContentItemPreviewStates.COMPLETED && this.previewState !== ContentItemPreviewStates.ERROR
    );
  }

  get isPreviewFailed(): boolean {
    return (
      this.previewState === ContentItemPreviewStates.ERROR ||
      // If the preview hasn't finished in one hour after creation, assume it has failed
      (new Date().getTime() - (this.lastPreviewJobRun || new Date().getTime()) > ONE_HOUR &&
        this.previewState !== ContentItemPreviewStates.COMPLETED)
    );
  }

  /**
   * Returns the original content URL wrapped with the azure authorization token
   */
  public async getBlobUrl(): Promise<string> {
    return AzureBlobService.wrapUrlWithReadToken(this.url);
  }

  public static getDefaultEmptyImage(): ImageModel {
    return new ImageModel('', '', '', '', ContentType.Image, '', '', '', ContentItemPreviewStates.PENDING, 0, 0);
  }

  public static getDefaultPlaceHolderContentItem(): ImageModel {
    return new ImageModel(
      '',
      '',
      'placeholder',
      '/dark.png',
      ContentType.Image,
      'placeholder',
      '/dark.png',
      '',
      ContentItemPreviewStates.COMPLETED,
      40,
      354,
      [],
      0,
      0,
      0,
      ''
    );
  }

  public static fromJSON(imageJSON: ImageJSON): ImageModel {
    const image: ImageModel = ImageModel.getDefaultEmptyImage();
    image.setCustomerId(imageJSON.customerId);
    image.setName(imageJSON.name);
    image.setFileType(imageJSON.type);
    image.setHash(imageJSON.hash);
    image.setParentFolder(imageJSON.parentFolder);
    image.setAncestors(imageJSON.ancestors);
    image.setTags(imageJSON.tags);
    image.originalName = imageJSON.originalName;
    image.previewState = imageJSON.previewState;
    image.url = imageJSON.url;
    image.previewUrl = imageJSON.previewUrl;
    image.height = imageJSON.height;
    image.width = imageJSON.width;
    image.offsetX = imageJSON.offsetX;
    image.offsetY = imageJSON.offsetY;
    image.checksum = imageJSON.checksum;
    image.zIndex = imageJSON.zIndex;
    image._id = imageJSON._id;
    image.setCreatedAt();
    image.lastPreviewJobRun = imageJSON.lastPreviewJobRun || image.createdAtAsTime;
    return image;
  }

  public toJSON(): ImageJSON {
    return {
      ...super.toJSON(),
      originalName: this.originalName,
      url: this.url,
      previewUrl: this.previewUrl,
      previewState: this.previewState,
      height: this.height,
      width: this.width,
      offsetY: this.offsetY,
      offsetX: this.offsetX,
      checksum: this.checksum,
      zIndex: this.zIndex,
    };
  }

  public toNewJSON(): NewImageJSON {
    const { _id, ...newImageJSON }: ImageJSON = this.toJSON();
    return newImageJSON;
  }

  public toBaseLayer(): BaseLayerModel {
    return new BaseLayerModel(
      this._id ? this._id : undefined,
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      0,
      0,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      undefined,
      []
    );
  }

  public toScheduledLayer(): ScheduleLayerModel {
    return new ScheduleLayerModel(
      this.name,
      this.url,
      this.previewUrl,
      this.type,
      this.height,
      this.width,
      0,
      0,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      undefined,
      LayerVisibility.VISIBLE,
      []
    );
  }

  public clone(): ImageModel {
    return new ImageModel(
      this._id,
      this.customerId,
      this.name,
      this.hash || '',
      this.type,
      this.originalName,
      this.url,
      this.previewUrl,
      this.previewState,
      this.height,
      this.width,
      this.tags,
      this.offsetX,
      this.offsetY,
      this.zIndex,
      this.checksum,
      this.parentFolder,
      this.ancestors,
      this.lastPreviewJobRun
    );
  }
}
