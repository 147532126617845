import { DeviceSearchResult, GondolaPublishInformation, HardwareModel } from '@client/models';
import { OnlineStatus } from '@common/enums';
import { PublishedStateFilter } from '@client/models/GondolaModels/SectionsForPublishing.model';
import { HardwareModelIdentifier } from '@common/device/types';

export type DeviceTableFilter = {
  shortId: string;
  longId: string;
  hardwareModels: HardwareModelIdentifier[];
  store: string;
  aisleAndSection: string;
  onlineState: OnlineStatus | null;
  publishStatus: PublishedStateFilter | null;
  lastPublishedTemplate: string;
};

export default class DeviceTableItem {
  shortId: string;
  longId: string;
  hardwareModel: HardwareModel;
  storeName?: string;
  storeId?: string;
  aisle?: string;
  section?: string;
  sectionId?: string;
  onlineStatus?: OnlineStatus;
  onlineStatusChanged?: Date;
  publishInformation?: GondolaPublishInformation;
  publishTemplateName?: string;
  publishTemplateId?: string;

  constructor(shortId: string, longId: string, hardwareModel: HardwareModel) {
    this.shortId = shortId;
    this.longId = longId;
    this.hardwareModel = hardwareModel;
  }

  static fromDeviceSearchResult(deviceSearchResult: DeviceSearchResult): DeviceTableItem {
    const result: DeviceTableItem = new DeviceTableItem(
      deviceSearchResult.device.shortId,
      deviceSearchResult.device.longId,
      deviceSearchResult.device.hardwareModel
    );
    result.storeId = deviceSearchResult.device.storeId;
    result.sectionId = deviceSearchResult.device.gondolaId;
    result.onlineStatus = deviceSearchResult.device.onlineStatus;
    result.onlineStatusChanged = deviceSearchResult.device.onlineStatusLastChanged;
    result.publishInformation = deviceSearchResult.publishing?.publishInformation;
    result.publishTemplateId = deviceSearchResult.publishing?.gondolaTemplateId;
    return result;
  }
}
