
import { Component, Vue } from 'vue-property-decorator';
import { AppGlobalStore, useAppGlobalStore } from '@client/stores/app-global';

@Component({})
export default class GenericErrorDialog extends Vue {
  /* DECLARATIONS */
  private appGlobalStore: AppGlobalStore = useAppGlobalStore();

  /* LIFECYCLE EVENTS */
  /* METHODS */

  hideErrorModal(): void {
    this.appGlobalStore.updateGenericErrorModal({ showGenericErrorModal: false });
  }

  reloadPage(): void {
    window.location.reload();
  }
  /* GETTERS */
  get showErrorModal(): boolean {
    return this.appGlobalStore.showGenericErrorModal;
  }

  get showErrorModalReloadButton(): boolean {
    return this.appGlobalStore.showGenericErrorModalReloadButton;
  }

  get errorModalText(): string {
    return this.appGlobalStore.genericErrorModalText;
  }

  get isValidationError(): boolean {
    return this.appGlobalStore.isValidationError;
  }

  get forceShowIgnoreButton(): boolean {
    return this.appGlobalStore.forceShowIgnoreButton;
  }
}
