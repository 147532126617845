
import { Component, Prop, Vue } from 'vue-property-decorator';
import { WifiConfig } from '@common/device/types';
import DoubleIcon from '@client/components/DoubleIcon/DoubleIcon.vue';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';

@Component({
  components: { ModalHeader, DoubleIcon },
})
export default class WifiConfigDialog extends Vue {
  /* DECLARATIONS */
  @Prop()
  private wifiConfig!: WifiConfig;

  private showDialog: boolean = false;

  /* LIFECYCLE EVENTS */

  /* METHODS */
  closeDialog(): void {
    this.showDialog = false;
  }

  /* GETTERS */
}
