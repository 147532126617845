
import { Component, Vue } from 'vue-property-decorator';
import { ErrorType } from '@common/error/types';
import { ErrorStore, useErrorStore } from '@client/stores/error';

@Component
export default class ErrorView extends Vue {
  private errorStore: ErrorStore = useErrorStore();
  get isAuthError(): boolean {
    return this.errorStore.getErrorType() === ErrorType.INVALID_AUTHENTICATION.name;
  }

  get isNotFoundError(): boolean {
    return !this.errorStore.getErrorType() || this.errorStore.getErrorType() === ErrorType.NOT_FOUND.name;
  }

  beforeDestroy() {
    this.errorStore.setErrorType(undefined);
  }
}
