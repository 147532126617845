/**
 * Currently the following options supported by the APIM are not included here:
 * NONE = 'none',
 * DAILY = 'daily',
 * MONTHLY =  'monthly',
 * YEARLY = 'yearly'
 */
export enum ScheduleDay {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export default ScheduleDay;
