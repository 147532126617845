
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PublishedStateFilter } from '@client/models/GondolaModels/SectionsForPublishing.model';
import { TranslateResult } from 'vue-i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

@Component({})
export default class HeaderPublishStatusFilter extends Vue {
  @Prop()
  private headerText!: string;
  @Prop()
  private defaultValue!: PublishedStateFilter | null;

  private filterTypesOptions = (): Array<{ text: TranslateResult; value: PublishedStateFilter }> => [
    {
      text: this.$t(TranslationKeys.filtersAndPagination.publishStateFilter.published.$path),
      value: PublishedStateFilter.PUBLISHED,
    },
    {
      text: this.$t(TranslationKeys.filtersAndPagination.publishStateFilter.pending.$path),
      value: PublishedStateFilter.PENDING,
    },
    {
      text: this.$t(TranslationKeys.filtersAndPagination.publishStateFilter.accepted.$path),
      value: PublishedStateFilter.ACCEPTED,
    },
    {
      text: this.$t(TranslationKeys.filtersAndPagination.publishStateFilter.failed.$path),
      value: PublishedStateFilter.FAILED,
    },
    {
      text: this.$t(TranslationKeys.filtersAndPagination.publishStateFilter.notSet.$path),
      value: PublishedStateFilter.NOT_SET,
    },
  ];

  private filterValue: PublishedStateFilter | null = this.defaultValue;

  created() {
    this.reassignDefaultValue();
  }

  clearFilter(): void {
    this.filterValue = null;
  }

  @Watch('filterValue')
  emitValueChanged(): void {
    this.$emit('change', this.filterValue);
  }

  @Watch('defaultValue')
  reassignDefaultValue(): void {
    this.filterValue = this.defaultValue;
  }

  get filterButtonColor(): string {
    return this.filterValue ? 'primary' : '';
  }

  get filterTypes(): Array<{ text: TranslateResult; value: PublishedStateFilter }> {
    return this.filterTypesOptions();
  }
}
