import { BaseEventLog } from '../EventLog.model';
import { ActiveHoursEventType, EventType, SectionActiveHoursEventLogJSON } from '@common/eventlog/types';
import { SectionActiveHoursDeviceEvent } from './SectionActiveHoursDeviceEvent.model';

export class SectionActiveHoursEventLog extends BaseEventLog {
  gondolaId: string;
  activeHoursStatus: ActiveHoursEventType;
  devices: Array<SectionActiveHoursDeviceEvent>;
  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    gondolaId: string,
    activeHoursStatus: ActiveHoursEventType,
    devices: Array<SectionActiveHoursDeviceEvent>
  ) {
    super(id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.gondolaId = gondolaId;
    this.activeHoursStatus = activeHoursStatus;
    this.devices = devices;
  }

  public static fromJSON(sectionActiveHoursEventLogJSON: SectionActiveHoursEventLogJSON): SectionActiveHoursEventLog {
    const {
      _id,
      azureStoreId,
      storeId,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      activeHoursStatus,
      devices,
      gondolaId,
    }: SectionActiveHoursEventLogJSON = sectionActiveHoursEventLogJSON;
    return new SectionActiveHoursEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      gondolaId,
      activeHoursStatus,
      devices.map(SectionActiveHoursDeviceEvent.fromJSON)
    );
  }
}
