
import { Component, Prop, Vue } from 'vue-property-decorator';
import videoDurationParser from '@client/utils/videoDurationParser';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';
import LayerPlaylistItem from '@client/models/ScheduleModels/LayerPlaylistItem.model';
import { ContentType } from '@common/enums';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { getFPSDisplayValue } from '@client/components/ContentItems/utils';

@Component({
  components: {},
  methods: {
    videoDurationParser,
    getFPSDisplayValue,
  },
})
export default class ContentPreview extends Vue {
  @Prop()
  private previewUrl!: string;
  @Prop()
  private selectedLayer?: ScheduleLayerModel | BaseLayerModel;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  openPreviewVideo(layerPlaylistItem: LayerPlaylistItem): void {
    this.$emit('openPreviewVideo', layerPlaylistItem);
  }

  get isSelectedLayerAPlaylist(): boolean {
    return this.selectedLayer?.type === ContentType.Playlist;
  }

  get isSelectedLayerAnImage(): boolean {
    return this.selectedLayer?.type === ContentType.Image;
  }

  getContentItemOriginalName(contentItemAzureBlobName: string): string {
    return this.contentItemsStore.getOriginalNameByAzureBlobName(contentItemAzureBlobName);
  }
}
