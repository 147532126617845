
import { Component, Prop, Vue } from 'vue-property-decorator';
import { VIntersectConfig } from '@client/definitions/vue-intersect';

/**
 * This component will help lazy load any subcomponent.
 * It will also unload the component after it leaves the view port (Can be disabled with "enableUnloading" option)
 * @param {Boolean} [enableUnloading=false] <pre>If false it will disable the unloading feature if the component is not in the viewport</pre>
 * @param {String} [parentClass] Wrapper class for the parent div of the lazy loading component. <pre>This will wrap <strong>all</strong> elements in all states loading, hidden and default (When shown).</pre>
 * @param {Boolean} [isLoading=false] Loading state that can be forced by the parent component. <pre>The parent component can still force the smart preview to stay in loading state.</pre>
 * @emits onUnloaded emits this event after unloading the content, this won't fire if {@link enableUnloading} is false
 */
@Component({})
export default class SmartPreview extends Vue {
  @Prop({ default: () => false })
  private enableUnloading!: boolean;
  @Prop({ default: () => null })
  private parentClass!: string | null;
  @Prop({ default: () => false })
  private isLoading!: boolean;

  private isIntersecting: boolean = false;

  /**
   * Fired event on intersection state changed
   * @param _entries contains information about current intersections
   * @param _observer The observer object
   * @param isIntersecting State of the component intersection
   * @private
   */
  private onIntersect(
    _entries: Array<IntersectionObserverEntry>,
    _observer: IntersectionObserver,
    isIntersecting: boolean
  ) {
    this.isIntersecting = isIntersecting;
    if (!this.isIntersecting) {
      // If we're not intersecting with the user view port, emit to the parent component that we unloaded the content
      this.$emit('onUnloaded');
    }
  }

  /**
   * Returns the "intersect" component config
   */
  get intersectConfig(): VIntersectConfig {
    return {
      handler: this.onIntersect,
      threshold: 0.75,
    };
  }
}
