import { ContentItemJSON } from '@common/content-item/types';
import { WsMessage, WsMessageType, WsPayload } from '@common/websocket/types';

export enum ContentItemAction {
  Add = 'Add',
  Update = 'Update',
  Delete = 'Delete',
}

export interface WsAddContentItemPayload extends WsPayload {
  contentItem: ContentItemJSON;
}

export interface WsDeleteContentItemPayload extends WsPayload {
  contentItemId: string;
}

export class WsContentItemMessage implements WsMessage {
  type: WsMessageType = WsMessageType.ContentItem;
  payload: WsPayload;
  action: ContentItemAction;

  constructor(payload: WsPayload, action: ContentItemAction) {
    this.payload = payload;
    this.action = action;
  }
}

export class WsAddContentItemMessage implements WsContentItemMessage {
  action: ContentItemAction = ContentItemAction.Add;
  type: WsMessageType = WsMessageType.ContentItem;
  payload: WsAddContentItemPayload;

  constructor(payload: WsAddContentItemPayload) {
    this.payload = payload;
  }
}

export interface WsUpdateContentItemPayload extends WsPayload {
  contentItem: ContentItemJSON;
}

export class WsUpdateContentItemMessage implements WsContentItemMessage {
  action: ContentItemAction = ContentItemAction.Update;
  type: WsMessageType = WsMessageType.ContentItem;
  payload: WsUpdateContentItemPayload;

  constructor(payload: WsUpdateContentItemPayload) {
    this.payload = payload;
  }
}

export class WsDeleteContentItemMessage implements WsContentItemMessage {
  action: ContentItemAction = ContentItemAction.Delete;
  type: WsMessageType = WsMessageType.ContentItem;
  payload: WsDeleteContentItemPayload;

  constructor(payload: WsDeleteContentItemPayload) {
    this.payload = payload;
  }
}
