
import { Component, Vue } from 'vue-property-decorator';
import { availableLocales, defaultLocale, Locale } from '@client/plugins/i18n/i18n';
import LangFlag from 'vue-lang-code-flags';

@Component({
  components: {
    LangFlag,
  },
})
export default class LocaleSwitch extends Vue {
  private locales: Array<Locale> = availableLocales;
  private selectedLocaleId: string = defaultLocale;

  setLocale(locale: Locale): void {
    this.setLocaleId(locale.id);
  }

  setLocaleId(localeId: string): void {
    this.selectedLocaleId = localeId;
    this.$root.$i18n.locale = localeId;
    this.$i18n.locale = localeId;
    localStorage.localeId = localeId;
  }

  mounted(): void {
    if (localStorage.localeId) {
      this.setLocaleId(localStorage.localeId);
    }
  }

  get selectedLocaleText(): string {
    return this.locales.find((locale: Locale) => locale.id === this.selectedLocaleId)?.text || '';
  }
}
