
import { Component, Vue } from 'vue-property-decorator';
import ContentItemsUpload from '@client/components/ContentItems/ContentItemsUpload.vue';
import ContentItemsUploadConfirmation from '@client/components/ContentItems/ContentItemsUploadConfirmation.vue';
import GenericErrorDialog from '@client/components/Layouts/GenericErrorDialog.vue';

@Component({
  components: {
    GenericErrorDialog,
    ContentItemsUpload,
    ContentItemsUploadConfirmation,
  },
})
export default class NavigationWrapper extends Vue {}
