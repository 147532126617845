
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import TimeSpanCalendar from '@client/components/TimeSpanCalendar/TimeSpanCalendar.vue';

@Component({
  components: { TimeSpanCalendar },
})
export default class Schedules extends Vue {
  /* DECLARATIONS */
  @Prop()
  private schedules!: Array<ScheduleModel>;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
