
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import TagPreview from '@client/components/Settings/Tags/TagPreview.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import Tag from '@client/models/SettingsModels/Tag.model';
import TagReference from '@client/models/SettingsModels/TagReference';
@Component({
  components: { TagPreview },
})
export default class HeaderTagsFilter extends Vue {
  @Prop()
  private headerText!: TranslateResult;

  @Prop()
  private defaultTags!: TagReference[];

  @Prop({ default: false })
  private useChips!: boolean;

  private selectedTags: TagReference[] = [...this.defaultTags];

  private tagsStore: TagsStore = useTagsStore();

  created() {
    this.tagsStore.fetch();
  }

  // used to force rerender component when we clear all filters from other component
  private typesRerenderKey: number = 0;

  updateValue(value: TagReference) {
    if (this.selectedTags.includes(value)) {
      this.selectedTags.splice(this.selectedTags.indexOf(value), 1);
      return;
    }
    this.selectedTags.push(value);
  }

  @Watch('selectedTags')
  emitValueChanged(): void {
    this.$emit('change', this.selectedTags);
  }
  //
  @Watch('defaultTags')
  defaultValueChanged(): void {
    if (this.defaultTags !== this.selectedTags) {
      this.selectedTags = [...this.defaultTags];
      this.typesRerenderKey++;
    }
  }

  clearFilter(): void {
    this.selectedTags = [];
  }

  get filterButtonColor(): string {
    return !this.useChips && this.selectedTags.length ? 'primary' : '';
  }

  get allTags(): Array<TagReference> {
    return this.tagsStore.getTags().map((value: Tag) => {
      return new TagReference(value._id, value.name, value.color);
    });
  }
}
