
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola } from '@client/models';

@Component({})
export default class DeleteGondolaButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private gondola!: Gondola;
  @Prop()
  private deleteGondola!: (gondolaId: string) => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
