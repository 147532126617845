
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Store } from '@client/models';
import EditStoreSettingsButton from '@client/components/StoreDetail/Buttons/EditStoreSettingsButton.vue';
import TagsList from '@client/components/Settings/Tags/TagsList.vue';

@Component({
  components: { TagsList, EditStoreSettingsButton },
})
export default class GeneralSettings extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
