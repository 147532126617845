
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola } from '@client/models';
import router from '@client/router';

@Component({})
export default class EditGondolaButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private gondola!: Gondola;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  editGondola(gondolaId: string): void {
    router.push(`/stores/${this.storeId}/gondola/${gondolaId}`);
  }
  /* GETTERS */

  get storeId(): string {
    return this.$route.params.storeid;
  }
}
