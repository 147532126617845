import { VueTourConfig } from '@client/definitions/vue-tour';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';

/**
 * First device in template edit button id
 * This will be used by vue-tour to prompt the help tooltip targeting the button
 */
export const TOUR_DEVICE_EDIT_TARGET: string = 'tour-device-edit';
/**
 * First device in template container id
 * This will be used by vue-tour to prompt the help tooltip targeting the whole div
 */
export const TOUR_DEVICE_CONTAINER_TARGET: string = 'tour-device-container-target';
/**
 * First device in template container id
 * This will be used by vue-tour to prompt the help tooltip targeting the dropdown
 */
export const TOUR_DEVICE_DROPDOWN_TARGET: string = 'tour-device-dropdown-target';

/**
 * Returns the config used by our vue-tour component
 * https://github.com/pulsardev/vue-tour/wiki/Tour-Configuration
 */
export const vueTourConfig = (): VueTourConfig => {
  return {
    labels: {
      buttonNext: i18n.t(TranslationKeys.vueTour.buttons.buttonNext.$path),
      buttonPrevious: i18n.t(TranslationKeys.vueTour.buttons.buttonPrevious.$path),
      buttonSkip: i18n.t(TranslationKeys.vueTour.buttons.buttonSkip.$path),
      buttonStop: i18n.t(TranslationKeys.vueTour.buttons.buttonStop.$path),
    },
    /**
     * If set to true you may use the ←, → and ESC keys to navigate through your tour.
     */
    useKeyboardNavigation: true,
  };
};
