import { BaseLayer, ScheduledContent } from '@client/models/ScheduleModels';
import BaseLayerModel from '@client/models/ScheduleModels/BaseLayer.model';
import ScheduledContentModel from '@client/models/ScheduleModels/ScheduledContent.model';
import { BackgroundContentModelJSON, ScheduledContentModelJSON } from '@common/schedule/types';
import { ContentItem } from '@client/models/ContentModels/types';

export default class BackgroundContentModel {
  baseLayer?: BaseLayer;
  scheduledContent?: Array<ScheduledContent>;

  constructor(baseLayer?: BaseLayer, scheduledContent?: Array<ScheduledContent>) {
    this.baseLayer = baseLayer;
    this.scheduledContent = scheduledContent ? scheduledContent : [];
  }

  public static fromContentItem(
    contentItem: ContentItem,
    scheduledContent?: Array<ScheduledContent>
  ): BackgroundContentModel {
    return new BackgroundContentModel(contentItem.toBaseLayer(), scheduledContent);
  }

  public static fromJSON(backgroundContentModelJSON: BackgroundContentModelJSON): BackgroundContentModel {
    const baseLayerModel: BaseLayerModel | undefined = backgroundContentModelJSON.baseLayer
      ? BaseLayer.fromJSON(backgroundContentModelJSON.baseLayer)
      : undefined;
    const scheduledContentModel: ScheduledContentModel[] | undefined = backgroundContentModelJSON.scheduledContent?.map(
      (scheduledContentItem: ScheduledContentModelJSON) => ScheduledContent.fromJson(scheduledContentItem)
    );

    return new BackgroundContentModel(baseLayerModel, scheduledContentModel);
  }

  public toJSON(): BackgroundContentModelJSON {
    return {
      baseLayer: this.baseLayer?.toJSON(),
      scheduledContent: this.scheduledContent?.map((scheduledContentItem: ScheduledContentModel) =>
        scheduledContentItem.toJSON()
      ),
    };
  }

  public clone(): BackgroundContentModel {
    return new BackgroundContentModel(
      this.baseLayer?.clone(),
      this.scheduledContent?.map((scheduledContentItem: ScheduledContentModel) => scheduledContentItem.clone())
    );
  }
}
