
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dateToMomentDate, getShortDayName } from '@client/utils/DateTimeUtils';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { TranslateResult } from 'vue-i18n';

@Component({
  methods: {
    dateToMomentDate,
    getShortDayName,
  },
})
export default class ScheduleTabItem extends Vue {
  @Prop() private schedule!: ScheduleModel;

  get activeStatusText(): TranslateResult {
    return this.schedule.active
      ? this.$t(this.$i18nTranslationKeys.schedules.active.$path)
      : this.$t(this.$i18nTranslationKeys.schedules.inactive.$path);
  }
}
