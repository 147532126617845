
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Gondola, Store } from '@client/models';
import { getCreatedAtFormatted, getRelativeTimeFromNow } from '@client/utils/DateTimeUtils';
import NotificationTimeDisplay from '../NotificationTimeDisplay.vue';
import NotificationTitle from '../NotificationTitle.vue';
import { Optional } from '@common/types';
import { EventLogsStore, useEventLogsStore } from '@client/stores/eventLogs';
import { SectionActiveHoursEventLog } from '@client/models/EventLogModels/SectionActiveHoursEventLog/SectionActiveHoursEventLog.model';
import { SectionActiveHoursDeviceEvent } from '@client/models/EventLogModels/SectionActiveHoursEventLog/SectionActiveHoursDeviceEvent.model';
import { getStoreDetailPath } from '@client/router/utils';
import NotificationToggle from '@client/components/NotificationPanel/NotificationToggle.vue';

@Component({
  methods: { getCreatedAtFormatted, getRelativeTimeFromNow },
  components: { NotificationToggle, NotificationTimeDisplay, NotificationTitle },
})
export default class SectionActiveHoursNotification extends Vue {
  @Prop()
  private toggleDrawerVisibility!: () => void;
  @Prop()
  private eventLog!: SectionActiveHoursEventLog;
  @Prop()
  private store: Optional<Store>;

  private devicesExpanded: boolean = false;
  private initialNumberOfDevicesShown: number = 10;
  private maximumNumberOfDevicesToShow: number = this.initialNumberOfDevicesShown;

  private eventLogsStore: EventLogsStore = useEventLogsStore();

  getMessageFromDevice(deviceEvent: SectionActiveHoursDeviceEvent): string {
    return ` ${this.$tc(this.$i18nTranslationKeys.notifications.screenStatusMessage.$path, deviceEvent.dimmingLevel)}`;
  }

  onNotificationClick(): void {
    if (!this.devicesExpanded) {
      this.devicesExpanded = true;
    }
  }

  onExpandToggleClick(): void {
    this.devicesExpanded = !this.devicesExpanded;
  }

  navigateToStore(): void {
    if (this.store) {
      this.$router.push(getStoreDetailPath(this.eventLog.storeId));
    }
  }

  incrementMaximumNumberOfDevices(): void {
    this.maximumNumberOfDevicesToShow += this.initialNumberOfDevicesShown;
  }

  get deviceEvents(): Array<SectionActiveHoursDeviceEvent> {
    if (this.eventLogsStore.filters.searchTerm) {
      return this.eventLog.devices.sort((a: SectionActiveHoursDeviceEvent, b: SectionActiveHoursDeviceEvent) => {
        const aIncludesId: boolean =
          a.longId.includes(this.eventLogsStore.filters.searchTerm) ||
          a.shortId.includes(this.eventLogsStore.filters.searchTerm);
        const bIncludesId: boolean =
          b.longId.includes(this.eventLogsStore.filters.searchTerm) ||
          b.shortId.includes(this.eventLogsStore.filters.searchTerm);

        if (aIncludesId && bIncludesId) {
          // Both elements include the searchByDeviceId, maintain their relative order
          return 0;
        } else if (aIncludesId && !bIncludesId) {
          // Only a includes the searchByDeviceId, move a to a lower index
          return -1;
        } else if (!aIncludesId && bIncludesId) {
          // Only b includes the searchByDeviceId, move b to a lower index
          return 1;
        } else {
          // Neither element includes the searchByDeviceId, maintain their relative order
          return 0;
        }
      });
    }
    return this.eventLog.devices;
  }

  get parsedDeviceEvents(): Array<SectionActiveHoursDeviceEvent> {
    return this.deviceEvents.slice(0, this.maximumNumberOfDevicesToShow);
  }

  get showMoreDeviceVisible(): boolean {
    return this.deviceEvents.length > this.maximumNumberOfDevicesToShow;
  }

  get numberOfDevicesHidden(): number {
    return this.deviceEvents.length - this.maximumNumberOfDevicesToShow;
  }

  get numberOfDevices(): number {
    return this.eventLog.devices.length;
  }

  get activeHoursTargetName(): string {
    if (this.gondola) {
      return `${this.activeHoursStoreName} - ${this.gondola.aisle} - ${this.gondola.positionInAisle}`;
    }
    return this.activeHoursStoreName;
  }

  get activeHoursStoreName(): string {
    return this.store?.name || this.eventLog.azureStoreId;
  }

  get notificationTitle(): string {
    const firstDeviceEvent: SectionActiveHoursDeviceEvent = this.eventLog.devices[0];
    if (!this.store) {
      return `${this.eventLog.azureStoreId} - ${this.getMessageFromDevice(firstDeviceEvent)}`;
    }
    return ` - ${this.getMessageFromDevice(firstDeviceEvent)}`;
  }

  get gondola(): Optional<Gondola> {
    if (!this.store || !this.eventLog.gondolaId) {
      return undefined;
    }
    return this.store.gondolas.find((gondola: Gondola) => gondola._id === this.eventLog.gondolaId);
  }

  get dateToDisplay(): string | Date {
    return this.eventLog.createdAt;
  }

  get notificationBadgesVisible(): boolean {
    return this.devicesExpanded;
  }

  get linkToStore(): string {
    if (!this.store) {
      return '';
    }
    return getStoreDetailPath(this.eventLog.storeId, this.gondola?.aisle, this.gondola?.positionInAisle);
  }

  get titleClass(): string | undefined {
    if (!this.eventLog.gondolaId) {
      return 'ml-8';
    }
    return undefined;
  }
}
