import { BaseEventLog } from '../EventLog.model';
import { GondolaPublishing } from '@client/models';
import PublishingDeviceEvent from './PublishingDeviceEvent.model';
import { EventState, EventType, PublishingEventLogJSON } from '@common/eventlog/types';
import { Optional } from '@common/types';

export class PublishingEventLog extends BaseEventLog {
  publishing: GondolaPublishing;
  gondolaId: string;
  devices: Array<PublishingDeviceEvent>;
  nextRunAt?: Date;
  /**
   * Contains the device events grouped by their state
   */
  deviceEvents: Map<EventState, Array<PublishingDeviceEvent>> = new Map<EventState, Array<PublishingDeviceEvent>>();

  constructor(
    id: string,
    schemaVersion: number,
    type: EventType,
    azureStoreId: string,
    storeId: string,
    customerId: string,
    createdAt: Date,
    updatedAt: Date,
    publishing: GondolaPublishing,
    gondolaId: string,
    devices: Array<PublishingDeviceEvent>,
    nextRunAt?: Date
  ) {
    super(id, schemaVersion, type, azureStoreId, storeId, customerId, createdAt, updatedAt);
    this.publishing = publishing;
    this.gondolaId = gondolaId;
    this.devices = devices;
    this.nextRunAt = nextRunAt;
    this.populateDevicesMap();
  }

  public static fromJSON(eventLog: PublishingEventLogJSON): PublishingEventLog {
    const {
      _id,
      azureStoreId,
      storeId,
      customerId,
      type,
      updatedAt,
      createdAt,
      schemaVersion,
      publishing,
      devices,
      gondolaId,
      nextRunAt,
    }: PublishingEventLogJSON = eventLog;
    return new PublishingEventLog(
      _id,
      schemaVersion,
      type,
      azureStoreId,
      storeId,
      customerId,
      createdAt,
      updatedAt,
      GondolaPublishing.fromJSON(publishing),
      gondolaId,
      devices.map(PublishingDeviceEvent.fromJSON),
      nextRunAt ? new Date(nextRunAt) : undefined
    );
  }

  populateDevicesMap(): void {
    this.devices.forEach((deviceEvent: PublishingDeviceEvent) => {
      const events: Optional<Array<PublishingDeviceEvent>> = this.deviceEvents.get(deviceEvent.state);
      if (!events) {
        this.deviceEvents.set(deviceEvent.state, [deviceEvent]);
      } else {
        events.push(deviceEvent);
        this.deviceEvents.set(deviceEvent.state, events);
      }
    });
  }
}
