
import { Component, Vue } from 'vue-property-decorator';
import { GondolaTemplate as GondolaTemplateModel, DeviceTemplate, Schedule } from '@client/models';
import { DeviceCanvas, TimeSpanCalendar } from '@client/components';
import { BreadcrumbsStore, useBreadcrumbsStore } from '@client/stores/breadcrumbs';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { Optional } from '@common/types';
import TemplateNotFound from '@client/components/NotFound/TemplateNotFound.vue';
import GondolaTemplateDeviceLoader from '@client/components/GondolaTemplate/GondolaTemplateDeviceLoader.vue';
import DeviceContentDetails from '@client/components/Device/CurrentSelectedContentDetails/DeviceContentDetails.vue';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

@Component({
  components: {
    TemplateNotFound,
    TimeSpanCalendar,
    DeviceCanvas,
    DeviceContentDetails,
    GondolaTemplateDeviceLoader,
    Wrapper,
  },
})
export default class TemplateDeviceView extends Vue {
  /* DECLARATIONS */
  private breadcrumbsStore: BreadcrumbsStore = useBreadcrumbsStore();
  private schedulesStore: SchedulesStore = useSchedulesStore();
  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private isDataLoaded: boolean = false;
  /* LIFECYCLE EVENTS */

  async created(): Promise<void> {
    this.gondolaTemplatesStore.updateDeviceContentSelection({
      isForeground: false,
      index: -1,
    });
    await Promise.all([this.schedulesStore.fetch(), this.gondolaTemplatesStore.fetch()]);
    const gondolaTemplateName: string = this.gondolaTemplate?.name || this.$route.params.id;
    this.breadcrumbsStore.replace({
      path: `/templates`,
      title: {
        key: this.$i18nTranslationKeys.gondolaTemplateListView.breadcrumb.$path,
      },
    });
    this.breadcrumbsStore.push({
      path: `/template/${this.$route.params.id}`,
      title: {
        key: this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path,
        params: {
          template: gondolaTemplateName,
        },
      },
    });
    this.breadcrumbsStore.push({
      path: `${this.$route.fullPath}`,
      title: {
        key: this.$i18nTranslationKeys.deviceView.breadcrumb.$path,
        params: {
          row: this.humanReadableRowIndex,
          col: this.humanReadableColumnIndex,
        },
      },
    });
    this.breadcrumbsStore.setCurrentPage(
      this.$t(this.$i18nTranslationKeys.gondolaTemplateDetailView.breadcrumb.$path, {
        template: gondolaTemplateName,
      }),
      'mdi-clipboard-edit'
    );
    this.isDataLoaded = true;
  }
  /* METHODS */
  /* GETTERS */
  get device(): Optional<DeviceTemplate> {
    return this.gondolaTemplatesStore.getById(this.$route.params.id)?.railGrid[Number(this.$route.params.row)][
      Number(this.$route.params.col)
    ];
  }

  get gondolaTemplate(): Optional<GondolaTemplateModel> {
    return this.gondolaTemplatesStore.getById(this.$route.params.id);
  }

  get gondolaTemplateName(): string {
    return this.gondolaTemplate?.name || '';
  }

  get selectedSchedule(): string {
    return this.schedulesStore.selectedSchedule;
  }

  get gondolaSchedules(): Array<Schedule> {
    return this.schedulesStore.getSchedulesForTemplate(this.$route.params.id);
  }

  get humanReadableRowIndex(): string {
    return (Number(this.$route.params.row) + 1).toString();
  }

  get humanReadableColumnIndex(): string {
    return (Number(this.$route.params.col) + 1).toString();
  }
}
