import { MILLISECONDS_PER_HOUR, MILLISECONDS_PER_MINUTE } from '@common/constants';
import { defaultStartTime, defaultEndTime, DateFilterPrefill } from './types';
import Moment from 'moment/moment';

/**
 * Returns an array of two elements containing the range of dates in a YYYY-MM-DD format
 * @param option prefill option to compute
 */
const getDateRangePrefill = (option: DateFilterPrefill): [string, string] => {
  const now: Date = new Date();
  const nowAsString: string = now.toISOString().slice(0, 10);
  const last24hoursAsString: string = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1)
    .toISOString()
    .slice(0, 10);
  const weekAgoAsString: string = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
    .toISOString()
    .slice(0, 10);
  const monthAgoAsString: string = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate())
    .toISOString()
    .slice(0, 10);
  switch (option) {
    case DateFilterPrefill.ONE_WEEK:
      return [weekAgoAsString, nowAsString];
    case DateFilterPrefill.ONE_MONTH:
      return [monthAgoAsString, nowAsString];
    case DateFilterPrefill.ONE_HOUR:
      return [nowAsString, nowAsString];
    case DateFilterPrefill.ONE_DAY:
    default:
      return [last24hoursAsString, nowAsString];
  }
};

/**
 * Returns an array containing the date time prefill values in a HH:mm format
 * @param option prefill option to compute
 */
const getDateTimePrefill = (option: DateFilterPrefill): [string, string] => {
  const now: Date = new Date();
  if (option === DateFilterPrefill.ONE_HOUR) {
    // Include current minute in end time
    const hourAgo: Date = new Date(now.getTime() - MILLISECONDS_PER_HOUR + MILLISECONDS_PER_MINUTE);
    const endTime: string = `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes() + 1).padStart(
      2,
      '0'
    )}`;
    const startTime: string = `${String(hourAgo.getHours()).padStart(2, '0')}:${String(hourAgo.getMinutes()).padStart(
      2,
      '0'
    )}`;
    return [startTime, endTime];
  } else {
    return [defaultStartTime, defaultEndTime];
  }
};

/**
 * Returns an iso string from a date string and a time string
 * @param dateString date string (YYYY-MM-DD)
 * @param timeString time string (HH:mm)
 */
const getIsoDate = (dateString: string, timeString: string): string => {
  const date: Date = new Date(dateString);
  const time: Array<string> = timeString.split(':');
  date.setHours(Number(time[0]));
  date.setMinutes(Number(time[1]));
  return date.toISOString();
};

/**
 * Checks if the end date is not bigger or equals to the end date
 */
const isEndDateBeforeStartDate = (startDate: string, endDate: string): boolean => {
  return Moment(endDate).isSameOrBefore(Moment(startDate));
};

export {
  defaultStartTime,
  defaultEndTime,
  getDateRangePrefill,
  getDateTimePrefill,
  getIsoDate,
  isEndDateBeforeStartDate,
};
