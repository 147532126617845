
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MediaFile } from '@client/models/ContentModels/types';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

@Component({})
export default class RecreatePreviewButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private mediaFile!: MediaFile;

  private isLoading: boolean = false;
  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  async recreatePreview(): Promise<void> {
    this.isLoading = true;
    await this.contentItemsStore.recomputeContentItemPreview(this.mediaFile);
    this.isLoading = false;
  }
  /* GETTERS */
}
