import ScheduleSpanModel from '@client/models/ScheduleModels/ScheduleSpan.model';
import { ScheduleModelJSON, ScheduleSpanModelJSON, ScheduleVisibility } from '@common/schedule/types';

export default class ScheduleModel {
  _id?: string;
  name: string;
  color: string;
  customerId: string;
  gondolaTemplates: string[];
  visibility: ScheduleVisibility;
  spans: ScheduleSpanModel[];
  active: boolean;
  hash?: string;

  constructor(
    id: string,
    name: string,
    color: string,
    customerId: string,
    gondolaTemplates: string[],
    visibility: ScheduleVisibility,
    spans: ScheduleSpanModel[],
    active: boolean,
    hash?: string
  ) {
    this._id = id;
    this.name = name;
    this.color = color;
    this.customerId = customerId;
    this.gondolaTemplates = gondolaTemplates;
    this.visibility = visibility;
    this.spans = spans;
    this.active = active;
    this.hash = hash;
  }

  public static setEndOfDayTime(scheduleModel: ScheduleModel): ScheduleModel {
    scheduleModel.spans.forEach((span: ScheduleSpanModel) => {
      if (span.recurrenceEnd === END_OF_DAY_TIME) {
        span.recurrenceEnd = START_OF_DAY_TIME;
      }
    });
    return scheduleModel;
  }

  public static clone(scheduleModel: ScheduleModel): ScheduleModel {
    const scheduleSpanModels: ScheduleSpanModel[] = scheduleModel.spans.map((scheduleSpan: ScheduleSpanModel) =>
      ScheduleSpanModel.clone(scheduleSpan)
    );
    return new ScheduleModel(
      scheduleModel._id || '',
      scheduleModel.name,
      scheduleModel.color,
      scheduleModel.customerId,
      scheduleModel.gondolaTemplates,
      scheduleModel.visibility,
      scheduleSpanModels,
      scheduleModel.active,
      scheduleModel.hash
    );
  }

  public static fromJSON(scheduleModelJSON: ScheduleModelJSON): ScheduleModel {
    const scheduleSpanModels: Array<ScheduleSpanModel> = scheduleModelJSON.spans.map(
      (scheduleSpanJSON: ScheduleSpanModelJSON) => ScheduleSpanModel.fromJSON(scheduleSpanJSON)
    );
    return new ScheduleModel(
      scheduleModelJSON._id || '',
      scheduleModelJSON.name,
      scheduleModelJSON.color,
      scheduleModelJSON.customerId,
      scheduleModelJSON.gondolaTemplates,
      scheduleModelJSON.visibility,
      scheduleSpanModels,
      scheduleModelJSON.active,
      scheduleModelJSON.hash
    );
  }

  public toJSON(): ScheduleModelJSON {
    return {
      _id: this._id,
      name: this.name,
      color: this.color,
      customerId: this.customerId,
      gondolaTemplates: this.gondolaTemplates,
      visibility: this.visibility,
      spans: this.spans.map((scheduleSpan: ScheduleSpanModel) => scheduleSpan.toJSON()),
      active: this.active,
      hash: this.hash,
    };
  }
}
export const START_OF_DAY_TIME: string = '00:00';
export const END_OF_DAY_TIME: string = '24:00';
export const END_OF_DAY_TIME_WITH_SECONDS: string = '23:59:59';
