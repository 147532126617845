
import { Component, Prop, Vue } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Store } from '@client/models';
import { DevicesStore, useDevicesStore } from '@client/stores/devices';

@Component({
  components: { ModalHeader },
})
export default class SynchronizeStoreButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private store!: Store;

  private storesStore: StoresStore = useStoresStore();
  private devicesStore: DevicesStore = useDevicesStore();

  private showDialog: boolean = false;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  closeSyncStoreDialog(): void {
    this.showDialog = false;
  }

  async syncDevices(): Promise<void> {
    this.closeSyncStoreDialog();
    await this.storesStore.syncDevices(this.store._id);
  }
  /* GETTERS */

  get areDevicesSyncing(): boolean {
    return this.devicesStore.isFetching || this.store.isStoreSyncing;
  }

  get buttonTextClass(): string {
    return this.store.idAzure ? 'primary--text font-weight-medium' : 'font-weight-medium';
  }
}
