
import { Component, Prop, Vue } from 'vue-property-decorator';
import { OpenLastPublishedTemplateForSection } from '@client/components';
import CreateTemplateFromSectionButton from '@client/components/StoreDetail/Buttons/CreateTemplateFromSectionButton.vue';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { ActiveHoursStore, useActiveHoursStore } from '@client/stores/activeHours';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { Gondola as GondolaModel, Gondola, GondolaTemplate } from '@client/models';
import { Optional } from '@common/types';

@Component({
  components: { CreateTemplateFromSectionButton, OpenLastPublishedTemplateForSection },
})
export default class QuickActionsButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private gondola!: Gondola;

  private gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
  private activeHoursStore: ActiveHoursStore = useActiveHoursStore();
  private storesStore: StoresStore = useStoresStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /**
   * Checks if the gondola already has a published template
   * @param gondola gondola to check
   */
  isGondolaAlreadyPublishedWithTemplate(gondola: GondolaModel): boolean {
    return !!gondola.lastPublishing?.gondolaTemplateId;
  }

  /**
   * Checks if the published template still exists
   * @param gondola gondola to check
   */
  isTemplatePresent(gondola: GondolaModel): boolean {
    const templateId: Optional<string> = gondola.lastPublishing?.gondolaTemplateId;
    if (!templateId) {
      return false;
    }
    const template: Optional<GondolaTemplate> = this.gondolaTemplatesStore.getById(templateId);
    return !!template;
  }

  getLastPublishingTemplateId(gondola: GondolaModel): Optional<string> {
    return gondola.lastPublishing?.gondolaTemplateId || undefined;
  }

  /* GETTERS */
  get isLoading(): boolean {
    return (
      this.activeHoursStore.loadingIndicator.update ||
      this.storesStore.loadingIndicator.delete ||
      this.storesStore.loadingIndicator.update ||
      this.gondolaTemplatesStore.loadingIndicator.update
    );
  }
}
