/**
 * format timezone IANA codes to first char upper case (e.g. "europe/vienna" -> "Europe/Vienna")
 * @param timezone the lower case IANA timezone code
 */
export function formatTimezone(timezone: string): string {
  const timezoneParts: string[] = timezone.split('/');
  return timezoneParts
    .map((timezonePart: string) => `${timezonePart.charAt(0).toUpperCase()}${timezonePart.substring(1)}`)
    .join('/');
}
