import { ScheduleLayer } from '@client/models';
import { ScheduledContentModelJSON } from '@common/schedule/types';
import ScheduleLayerModel from '@client/models/ScheduleModels/ScheduleLayer.model';

export default class ScheduledContentModel {
  scheduleId: string;
  layer: ScheduleLayer;

  constructor(scheduleId: string, layer: ScheduleLayer) {
    this.scheduleId = scheduleId;
    this.layer = layer;
  }

  public static fromJson(scheduledContentModelJSON: ScheduledContentModelJSON): ScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = ScheduleLayer.fromJSON(scheduledContentModelJSON.layer);
    return new ScheduledContentModel(scheduledContentModelJSON.scheduleId, scheduleLayerModel);
  }

  public toJSON(): ScheduledContentModelJSON {
    return {
      scheduleId: this.scheduleId,
      layer: this.layer.toJSON(),
    };
  }

  public clone(): ScheduledContentModel {
    const scheduleLayerModel: ScheduleLayerModel = this.layer.clone();
    return new ScheduledContentModel(this.scheduleId, scheduleLayerModel);
  }
}
