import { Canvas, IEvent, IImageOptions, Image, IObjectOptions } from 'fabric/fabric-impl';
import DeviceCanvasItem from './DeviceCanvasItem.model';
import { BaseLayer } from '../ScheduleModels';
import { DeviceTemplate } from '@client/models';
import {
  DeviceContentSelection,
  GondolaTemplatesStore,
  useGondolaTemplatesStore,
} from '@client/stores/gondolaTemplates';
import { createImageConfig } from '@client/utils/CanvasUtils';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';

export default class DeviceCanvasForegroundItem extends DeviceCanvasItem {
  protected setupImage(image: Image, isSelected?: boolean, isHidden?: boolean, color?: string) {
    const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
    const schedulesStore: SchedulesStore = useSchedulesStore();
    const config: IObjectOptions & IImageOptions = createImageConfig(
      this.baseLayerModel.offsetX,
      this.baseLayerModel.offsetY,
      color,
      isHidden,
      this.index,
      !!schedulesStore.selectedSchedule
    );
    image.set(config);
    const foregroundContentIndex: number = this.index;

    const payload: {
      gondolaTemplateId: string;
      row: number;
      col: number;
      device: DeviceTemplate;
      foregroundContentIndex: number;
      offsetX: number;
      offsetY: number;
    } = {
      gondolaTemplateId: this.gondolaTemplateId,
      row: this.row,
      col: this.col,
      device: this.deviceTemplate,
      foregroundContentIndex: this.index,
      offsetX: 0,
      offsetY: 0,
    };
    const theCanvas: Canvas = this.canvas;
    const contentWidth: number = this.baseLayerModel.width;

    image.on('modified', async function (e: IEvent) {
      if (e && e.target && e.target.left != undefined && e.target.top != undefined) {
        if (e.target.left < 0) {
          payload.offsetX = 1; // it's 1 based panel.. so 0 means cancelling the action.
        } else if (e.target.left + contentWidth > theCanvas.getWidth() * (1 / theCanvas.getZoom())) {
          payload.offsetX = theCanvas.getWidth() * (1 / theCanvas.getZoom()) - contentWidth;
        } else {
          payload.offsetX = e.target.left;
        }
        payload.offsetY = e.target.top;
        await gondolaTemplatesStore.updateDeviceForegroundSlotPosition(
          payload.gondolaTemplateId,
          payload.row,
          payload.col,
          payload.device,
          payload.foregroundContentIndex,
          payload.offsetX,
          payload.offsetY
        );
      }
    });

    image.on('mousedown:before', function () {
      const devicePayload: DeviceContentSelection = {
        isForeground: true,
        index: foregroundContentIndex,
        offsetX: theCanvas.getActiveObject()?.left || 0,
        offsetY: theCanvas.getActiveObject()?.top || 0,
      };
      gondolaTemplatesStore.updateDeviceContentSelection(devicePayload);
    });
    if (isSelected != null && isSelected) {
      this.canvas.setActiveObject(image);
    }
  }

  public async render(isSelected?: boolean, baseLayerModel?: BaseLayer, isHidden?: boolean, color?: string) {
    await super.render(isSelected, baseLayerModel, isHidden, color);
    // ensure proper ordering of foreground items
    this.renderedImage.bringToFront();
  }
}
