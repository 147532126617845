import { AisleJSON } from '@common/stores/types';
import Gondola from '../GondolaModels/Gondola.model';
import { GondolaJSON } from '@common/gondola-template/types';

export default class Aisle {
  name: string;
  gondolas: Gondola[];
  railsSucceeded: number = 0;
  railsPending: number = 0;
  railsFailed: number = 0;

  constructor(name: string, gondolas: Gondola[] = []) {
    this.name = name;
    this.gondolas = gondolas;
  }

  static clone(source: Aisle): Aisle {
    return new Aisle(
      source.name,
      source.gondolas.map((gondola: Gondola) => Gondola.clone(gondola))
    );
  }

  static fromJSON(source: AisleJSON): Aisle {
    return new Aisle(
      source.name,
      source.gondolas.map((gondola: GondolaJSON) => Gondola.fromJSON(gondola))
    );
  }

  static toJSON(aisle: Aisle): AisleJSON {
    return {
      name: aisle.name,
      gondolas: aisle.gondolas.map((gondola: Gondola) => gondola.toJSON()),
    };
  }
}
