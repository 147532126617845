import ActiveHourItem, { ActiveHoursSchedule } from '@client/models/ActiveHoursModels/ActiveHourItem.model';
import { ActiveHourItemJSON, SectionActiveHoursJSON } from '@common/active-hours/types';

/**
 * This class is responsible for holding active hours data for one specific section
 */
export default class SectionActiveHoursSchedule {
  gondolaId: string;
  activeHours: ActiveHoursSchedule;

  constructor(gondolaId: string, activeHours: ActiveHoursSchedule) {
    this.gondolaId = gondolaId;
    this.activeHours = activeHours;
  }

  static fromJSON(sectionActiveHoursJSON: SectionActiveHoursJSON): SectionActiveHoursSchedule {
    return new SectionActiveHoursSchedule(
      sectionActiveHoursJSON.gondolaId,
      sectionActiveHoursJSON.activeHours.map((item: ActiveHourItemJSON) => ActiveHourItem.fromJSON(item))
    );
  }
}
