
import { Component, Prop, Vue } from 'vue-property-decorator';
import ScheduleModel from '@client/models/ScheduleModels/Schedule.model';
import { ScheduleVisibility } from '@common/schedule/types';
import TimeSpanCalendar from '@client/components/TimeSpanCalendar/TimeSpanCalendar.vue';
import { Success } from '@client/styles/colors';
import { activeHoursScheduleToScheduleSpans } from '@client/utils/ActiveHoursUtils';
import { ActiveHoursSchedule } from '@client/models/ActiveHoursModels';

@Component({
  components: { TimeSpanCalendar },
})
export default class ActiveHours extends Vue {
  /* DECLARATIONS */
  @Prop()
  private activeHours!: ActiveHoursSchedule;
  @Prop({ default: false })
  private isSectionOverride!: boolean;
  /* LIFECYCLE EVENTS */
  /* METHODS */

  /* GETTERS */
  get currentGondolaActiveHoursAsSchedules(): ScheduleModel {
    const currentDeviceActiveHoursSchedule: ScheduleModel = new ScheduleModel(
      '',
      'activeHoursSchedule',
      Success._500,
      '',
      [],
      ScheduleVisibility.PUBLIC,
      [],
      true
    );
    currentDeviceActiveHoursSchedule.spans = activeHoursScheduleToScheduleSpans(this.activeHours);
    return currentDeviceActiveHoursSchedule;
  }
}
