import TagReference from '@client/models/SettingsModels/TagReference';

/**
 * Checks whether the tagReference array includes at least one of the selected tags
 * @param tags array of tagReferences that need to be checked
 * @param selectedTags array of the currently selected tags
 */
export function includesSelectedTags(tags: Array<TagReference>, selectedTags: Array<TagReference>): boolean {
  return tags.some((tag: TagReference) => selectedTags.some((selectedTag: TagReference) => selectedTag.id === tag.id));
}
