
import { Component, Vue } from 'vue-property-decorator';
import { B2CUser, IdToken } from '@client/models/UserModels';
import { LocaleSwitch } from '@client/components';

@Component({
  components: { LocaleSwitch },
})
export default class UserMenu extends Vue {
  /* DECLARATIONS */
  /* LIFECYCLE EVENTS */
  /* METHODS */
  logout(): void {
    this.$msal.signOut();
  }

  editProfile(): void {
    this.$msal.editProfile();
  }
  /* GETTERS */

  get username(): string {
    const idToken: IdToken = (this.$msal.data.user as B2CUser).idToken;
    return `${idToken.given_name} ${idToken.family_name}`;
  }

  get isAuthenticated(): boolean {
    return this.$msal.isAuthenticated();
  }
}
