/**
 * Format of file that will be downloaded
 */
export enum DownloadFormat {
  PNG = 'png',
  SVG = 'svg',
  CSV = 'csv',
}

/**
 * Download the file
 * @param url generated url of the file
 * @param fileName filename to be downloaded, extension will be added according to the format
 * @param format of type {@link DownloadFormat}, optional,
 * if empty the file name should contain the full name including the extension
 */
export const downloadFileFromUrl: (url: string, fileName: string, format?: DownloadFormat) => void = (
  url: string,
  fileName: string,
  format?: DownloadFormat
): void => {
  // Create an anchor tag to be able to download the image
  const downloadLink: HTMLAnchorElement = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = format ? `${fileName}.${format}` : fileName;
  // Chrome needs the element to be appended to the dom to trigger the download
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
