import { Configuration, UserAgentApplication } from 'msal';
import { AuthCache } from 'msal/lib-commonjs/cache/AuthCache';

export class UserAgentApplicationExtended extends UserAgentApplication {
  public store: AuthCache;

  constructor(configuration: Configuration) {
    super(configuration);
    this.store = this.cacheStorage;
  }
}
