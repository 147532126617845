
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ReleaseManagementStore, useReleaseManagementStore } from '@client/stores/releaseManagement/store';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import ReleaseManagement from '@client/models/SettingsModels/ReleaseManagement.model';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import { getStoreDetailPath } from '@client/router/utils';
import { Store } from '@client/models';
import { CustomDataTableHeader } from '@client/definitions/CustomDataTableHeader';
interface TableElement {
  storeLink: string;
  useEmailSubscriptionIcon: string;
}
@Component({
  components: { ModalHeader },
})
export default class AffectedStoresModal extends Vue {
  @Prop()
  private releaseManagement!: ReleaseManagement;
  @Prop()
  private title!: TranslateResult;
  @Prop({ default: '' })
  private warningText!: TranslateResult;
  @Prop()
  private warningTextStoreReference!: TranslateResult;
  @Prop()
  private hasDeleteAction!: boolean;
  @Prop()
  private hasSaveAction!: boolean;
  @Prop()
  private isNewDefault!: boolean;

  private releaseManagementStore: ReleaseManagementStore = useReleaseManagementStore();
  private storesStore: StoresStore = useStoresStore();

  async deleteReleaseManagement(): Promise<void> {
    await this.releaseManagementStore.deleteReleaseConfiguration(this.releaseManagement._id);
    this.closeDialog();
  }

  extractStoreName(storeId: string): Optional<string> {
    if (!this.storesStore.getStoreById(storeId)) {
      return null;
    }
    return this.storesStore.getStoreById(storeId)?.name;
  }

  closeDialog(): void {
    this.$emit('dialog-closed');
  }
  saveAndClose(): void {
    this.$emit('save-and-close');
  }

  get currentReleaseManagement(): Optional<ReleaseManagement> {
    return this.releaseManagementStore.getReleaseConfigurationById(this.releaseManagement._id);
  }

  get appliedStores(): Array<string> {
    if (this.isNewDefault) {
      if (!this.currentReleaseManagement?.appliedStores.length) {
        return this.releaseManagementStore.getDefaultReleaseManagement()?.appliedStores ?? [];
      }
      return this.currentReleaseManagement.appliedStores.concat(
        this.releaseManagementStore.getDefaultReleaseManagement()?.appliedStores ?? []
      );
    }
    if (!this.currentReleaseManagement?.appliedStores.length) {
      return [];
    }
    return this.currentReleaseManagement.appliedStores;
  }

  get isLoading(): boolean {
    return this.releaseManagementStore.isFetching || this.releaseManagementStore.isActionPending;
  }

  private isUsingEmailSubscription(storeId: string): boolean {
    const store: Optional<Store> = this.storesStore.getStoreById(storeId);
    if (!store) {
      return false;
    }
    return store.useEmailSubscription;
  }
  private getStoreLink(storeId: string): string {
    return `<a href="${getStoreDetailPath(storeId)}">${this.extractStoreName(storeId)}</a>`;
  }

  get tableElements(): Array<TableElement> {
    return this.appliedStores.map((storeId: string) => {
      return {
        storeLink: this.getStoreLink(storeId),
        useEmailSubscriptionIcon: this.isUsingEmailSubscription(storeId) ? 'mdi-check-circle' : '',
      };
    });
  }

  get headers(): CustomDataTableHeader[] {
    return [
      {
        text: this.$tc(this.$i18nTranslationKeys.store.$path, 1),
        align: 'center',
        sortable: true,
        value: 'name',
      },
      {
        text: this.$t(
          this.$i18nTranslationKeys.settings.releaseManagement.affectedStoreDialog.useEmailSubscription.$path
        ),
        value: 'useEmailSubscription',
        align: 'center',
      },
    ];
  }
}
