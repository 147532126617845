import { DevicesStore, useDevicesStore } from '@client/stores/devices';
import { Device, Store } from '@client/models';
import { DeviceJSON } from '@common/device/types';
import {
  DeviceActions,
  WsDeviceUpdatePayload,
  WsDeviceMessage,
  WsDeviceOnlineStatusMessage,
  WsDeviceRegisteredMessage,
  WsDeviceRegisteredPayload,
  WsDeviceUnregisteredMessage,
  WsDeviceUpdateMessage,
  WsDeviceBulkUpdateMessage,
  WsDeviceBulkUpdatePayload,
  WsDeviceDeleteMessage,
  WsDeviceDeletePayload,
} from '@common/websocket/Devices';
import { Optional } from '@common/types';
import { StoresStore, useStoresStore } from '@client/stores/stores';

const handleDeviceMessage = async (wsDeviceMessage: WsDeviceMessage): Promise<void> => {
  switch (wsDeviceMessage.action) {
    case DeviceActions.OnlineStatus:
      handleDeviceOnlineStatusMessage(wsDeviceMessage as WsDeviceOnlineStatusMessage);
      break;
    case DeviceActions.Registered:
      await handleDeviceRegisteredMessage(wsDeviceMessage as WsDeviceRegisteredMessage);
      break;
    case DeviceActions.Unregistered:
      await handleDeviceUnregisteredMessage(wsDeviceMessage as WsDeviceUnregisteredMessage);
      break;
    case DeviceActions.Update:
      handleDeviceUpdateStatusMessage(wsDeviceMessage as WsDeviceUpdateMessage);
      break;
    case DeviceActions.BulkUpdate:
      handleDeviceBulkUpdateStatusMessage(wsDeviceMessage as WsDeviceBulkUpdateMessage);
      break;
    case DeviceActions.Delete:
      handleDeviceDeleteStatusMessage(wsDeviceMessage as WsDeviceDeleteMessage);
      break;
    default:
      console.error(`Received unknown device action ${wsDeviceMessage.action}`);
      break;
  }
};

/**
 * Handle a websocket message containing a device update
 * @param wsDeviceDeleteMessage the device update websocket message
 */
const handleDeviceDeleteStatusMessage = (wsDeviceDeleteMessage: WsDeviceDeleteMessage): void => {
  const devicesStore: DevicesStore = useDevicesStore();
  try {
    const wsWebhookPayload: WsDeviceDeletePayload = wsDeviceDeleteMessage.payload;
    console.debug(`Received websocket message; Delete device ${wsWebhookPayload.deviceId}`);
    devicesStore.deleteFromState(wsWebhookPayload.deviceId);
  } catch (e) {
    console.error(`Could not update local state for incoming bulk update device webhook.`, e);
  }
};

/**
 * Handle a websocket message containing a device bulk update
 * @param wsDeviceBulkUpdateMessage the device array websocket message
 */
const handleDeviceBulkUpdateStatusMessage = (wsDeviceBulkUpdateMessage: WsDeviceBulkUpdateMessage): void => {
  const devicesStore: DevicesStore = useDevicesStore();
  const storesStore: StoresStore = useStoresStore();
  try {
    const wsWebhookPayload: WsDeviceBulkUpdatePayload = wsDeviceBulkUpdateMessage.payload;
    console.debug(`Received websocket message; Bulk update for ${wsWebhookPayload.updatedDevices.length} devices`);
    for (const device of wsWebhookPayload.updatedDevices) {
      const updatedDevice: Device = Device.fromJSON(device);
      const existingStore: Optional<Store> = storesStore.getCurrentStore(updatedDevice.storeId);
      if (!existingStore) {
        return;
      }
      devicesStore.updateDevice(updatedDevice);
      storesStore.populateDevice(existingStore, updatedDevice);
    }
  } catch (e) {
    console.error(`Could not update local state for incoming bulk update device webhook.`, e);
  }
};

/**
 * Handle a websocket message containing a device update
 * @param wsDeviceUpdateMessage the device update websocket message
 */
const handleDeviceUpdateStatusMessage = (wsDeviceUpdateMessage: WsDeviceUpdateMessage): void => {
  const devicesStore: DevicesStore = useDevicesStore();
  const storesStore: StoresStore = useStoresStore();
  try {
    const wsWebhookPayload: WsDeviceUpdatePayload = wsDeviceUpdateMessage.payload;
    console.debug(
      `Received websocket message; Device long id ${wsWebhookPayload.updatedDevice.longId} Update ${wsWebhookPayload.updatedDevice.onlineStatus} Store ${wsWebhookPayload.updatedDevice.storeId}`
    );
    const updatedDevice: Device = Device.fromJSON(wsWebhookPayload.updatedDevice);
    const existingStore: Optional<Store> = storesStore.getCurrentStore(updatedDevice.storeId);
    if (!existingStore) {
      return;
    }
    devicesStore.updateDevice(updatedDevice);
    storesStore.populateDevice(existingStore, updatedDevice);
  } catch (e) {
    console.error(`Could not update local state for incoming updated device from webhook.`, e);
  }
};

/**
 * Handle a websocket message containing a device online status update
 * @param wsDeviceOnlineStatusMessage the device online status websocket message
 */
const handleDeviceOnlineStatusMessage = (wsDeviceOnlineStatusMessage: WsDeviceOnlineStatusMessage): void => {
  const devicesStore: DevicesStore = useDevicesStore();
  const storesStore: StoresStore = useStoresStore();
  try {
    const wsWebhookPayload: WsDeviceUpdatePayload = wsDeviceOnlineStatusMessage.payload;
    console.debug(
      `Received websocket message; Device long id ${wsWebhookPayload.updatedDevice.longId} OnlineStatus ${wsWebhookPayload.updatedDevice.onlineStatus} Store ${wsWebhookPayload.updatedDevice.storeId}`
    );
    const updatedDevice: Device = Device.fromJSON(wsWebhookPayload.updatedDevice);
    const existingStore: Optional<Store> = storesStore.getCurrentStore(updatedDevice.storeId);
    if (!existingStore) {
      return;
    }
    devicesStore.updateDevice(updatedDevice);
    storesStore.populateDevice(existingStore, updatedDevice);
  } catch (e) {
    console.error(`Could not update local state for incoming updated device from webhook.`, e);
  }
};

/**
 * Handle a websocket message containing a device registered update
 * @param wsDeviceRegisteredMessage the device registered websocket message
 */
const handleDeviceRegisteredMessage = async (wsDeviceRegisteredMessage: WsDeviceRegisteredMessage): Promise<void> => {
  const devicesStore: DevicesStore = useDevicesStore();
  const payload: WsDeviceRegisteredPayload = wsDeviceRegisteredMessage.payload;
  if (!payload.longId || !payload.storeIdAzure || !payload.customerId) {
    console.debug(`Received invalid device registered websocket message ${wsDeviceRegisteredMessage}`);
  }
  console.debug(
    `Received websocket message; Device long id ${payload.longId} registered to store ${payload.storeIdAzure}`
  );
  await devicesStore.syncDevice(payload.longId, payload.storeIdAzure);
};

/**
 * Handle a websocket message containing a device unregistered update
 * @param wsDeviceUnregisteredMessage the device unregistered websocket message
 */
const handleDeviceUnregisteredMessage = async (
  wsDeviceUnregisteredMessage: WsDeviceUnregisteredMessage
): Promise<void> => {
  const devicesStore: DevicesStore = useDevicesStore();
  const storesStore: StoresStore = useStoresStore();
  const deviceJSON: DeviceJSON = wsDeviceUnregisteredMessage?.payload?.updatedDevice;
  if (!deviceJSON) {
    console.debug(`Received webhook with missing device data ${wsDeviceUnregisteredMessage}`);
    return;
  }
  const deviceToBeDeleted: Device = Device.fromJSON(wsDeviceUnregisteredMessage.payload.updatedDevice);

  // need to sync the whole store, since the hash was updated in the backend
  await storesStore.fetchStoreDetails(deviceToBeDeleted.storeId, true);
  devicesStore.deleteFromState(deviceToBeDeleted.longId);
};

export {
  handleDeviceRegisteredMessage,
  handleDeviceUnregisteredMessage,
  handleDeviceOnlineStatusMessage,
  handleDeviceMessage,
};
