
import { Component, Prop, Vue } from 'vue-property-decorator';
import VideoModel from '@client/models/ContentModels/Video.model';

/**
 * Renders the preview dialog, used to display a preview of a selected item in a playlist in a modal.
 */
@Component({})
export default class PlaylistItemPreviewDialog extends Vue {
  /* DECLARATIONS */
  @Prop()
  private showPlaylistItemPreview!: boolean;
  @Prop()
  private playlistItemToPreview!: VideoModel;
  @Prop()
  private playlistItemPreviewUrl!: string;
  @Prop()
  private closePreviewVideo!: () => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
