import { ActiveHourDayJSON, ActiveHourItemJSON } from '@common/active-hours/types';
import ActiveHourDay from './ActiveHourDay.model';
import { ScheduleDay } from '@common/enums/ScheduleDay';

export default class ActiveHourItem {
  day: ScheduleDay;
  schedule: ActiveHourDay[];

  constructor(day: ScheduleDay, schedule: ActiveHourDay[]) {
    this.day = day;
    this.schedule = schedule;
  }
  static fromJSON(activeHourItemJSON: ActiveHourItemJSON): ActiveHourItem {
    return new ActiveHourItem(
      activeHourItemJSON.day,
      activeHourItemJSON.schedule.map((jsonItem: ActiveHourDayJSON) => ActiveHourDay.fromJSON(jsonItem))
    );
  }

  toJSON(): ActiveHourItemJSON {
    return { day: this.day, schedule: this.schedule.map((activeHourDay: ActiveHourDay) => activeHourDay.toJSON()) };
  }

  clone(): ActiveHourItem {
    return new ActiveHourItem(
      this.day,
      this.schedule.map((activeHourDay: ActiveHourDay) => {
        return new ActiveHourDay(activeHourDay.start, activeHourDay.end, activeHourDay.dimmingLevel);
      })
    );
  }
}
export type ActiveHoursSchedule = ActiveHourItem[];
