
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component({})
export default class NotificationTitle extends Vue {
  @Prop()
  private closePanel!: () => void;
  @Prop({ default: '' })
  private link?: string;
  @Prop({ default: '' })
  private notificationTitle!: TranslateResult;
  @Prop({ default: '' })
  private notificationIdentifier!: string;
  @Prop({ default: false })
  private showLink!: boolean;
  @Prop({ default: '' })
  private extendClass?: string;

  navigateToStore(): void {
    if (!this.link) {
      return;
    }
    // Split the link to get the path and params separately
    const [path]: Array<string> = this.link.split('?');
    // Prevents navigation to the same route
    if (this.$router.currentRoute.path === path) {
      this.closePanel();
      return;
    }
    this.$router.push(this.link);
  }
}
