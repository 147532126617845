
import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalHeader from '@client/components/Layouts/ModalHeader.vue';
import { TranslateResult } from 'vue-i18n';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { Optional } from '@common/types';
import { NewTag } from '@client/models/SettingsModels/NewTag';
import {
  DEFAULT_COMMON_STRING_MAX_LENGTH,
  validateNoSymbolsInString,
  validateTextFieldLength,
} from '@client/utils/validateTextFieldLength';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import TagModel from '@client/models/SettingsModels/Tag.model';
import i18n from '@client/plugins/i18n/i18n';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import SaveButton from '@client/components/Settings/Tags/Actions/SaveButton.vue';
import TagPreview from '@client/components/Settings/Tags/TagPreview.vue';

@Component({
  methods: {
    validateTextFieldLength,
    DEFAULT_COMMON_STRING_MAX_LENGTH() {
      return DEFAULT_COMMON_STRING_MAX_LENGTH;
    },
    validateNoSymbolsInString,
  },
  components: { TagPreview, SaveButton, Tag, ModalHeader },
})
export default class TagDialog extends Vue {
  @Prop()
  private title!: TranslateResult;
  @Prop()
  private oldTagValues: Optional<TagModel>;

  private tagsStore: TagsStore = useTagsStore();
  private tag: Optional<NewTag> = null;

  openColorPicker: boolean = false;

  created() {
    this.tag = this.initTagValues();
  }

  initTagValues(): NewTag {
    if (this.oldTagValues) {
      return {
        name: this.oldTagValues.name,
        color: this.oldTagValues.color,
        description: this.oldTagValues.description,
      };
    }
    return {
      name: '',
      color: '',
      description: '',
    };
  }

  closeDialog(): void {
    this.$emit('dialogue-closed');
    this.tag = this.initTagValues();
  }

  initColor(): void {
    if (!this.tag) {
      return;
    }
    if (this.tag.color === '') {
      this.tag.color = '#FFFFFF';
    }
  }

  saveChanges(): void {
    if (this.oldTagValues) {
      this.updateTag();
    } else {
      this.createTag();
    }
  }

  validateName(name: string): boolean | TranslateResult {
    const possibleDuplicateTag: Optional<TagModel> = this.tagsStore.getTagByNameCaseInsensitive(name);
    if (!possibleDuplicateTag || possibleDuplicateTag._id === this.oldTagValues?._id) {
      return validateTextFieldLength(name, DEFAULT_COMMON_STRING_MAX_LENGTH, true);
    }
    return i18n.t(TranslationKeys.error.genericDuplicate.$path);
  }

  validateColor(color: string): boolean | TranslateResult {
    if (!color) {
      return false;
    }
    const possibleDuplicateTag: Optional<TagModel> = this.tagsStore.getTagByColor(color);
    if (!possibleDuplicateTag || possibleDuplicateTag._id === this.oldTagValues?._id) {
      return true;
    }
    return i18n.t(TranslationKeys.settings.tags.tagsDialog.colorError.$path);
  }

  async createTag(): Promise<void> {
    if (!this.isValid || !this.tag) {
      return;
    }
    await this.tagsStore.createTag(this.tag);
    this.closeDialog();
    this.tag = this.initTagValues();
  }

  async updateTag() {
    if (!this.tag || !this.oldTagValues) {
      return false;
    }
    await this.tagsStore.updateTag(this.oldTagValues._id, this.tag);
    this.closeDialog();
  }

  get isValid(): boolean {
    if (!this.tag) {
      return false;
    }
    return (
      this.validateName(this.tag.name) === true &&
      this.validateColor(this.tag.color) === true &&
      validateTextFieldLength(this.tag.description, DEFAULT_COMMON_STRING_MAX_LENGTH, true) == true
    );
  }

  get showPreview(): boolean {
    if (!this.tag) {
      return false;
    }
    return this.validateName(this.tag.name) === true && this.validateColor(this.tag.color) === true;
  }

  get isLoading(): boolean {
    return this.tagsStore.isFetching || this.tagsStore.isActionPending;
  }

  get isSameAsInitValue(): boolean {
    //checks if this modal is used to edit an existing tag
    if (!this.oldTagValues) {
      return false;
    }
    //checks if there are old values available
    if (!this.tag || !this.oldTagValues) {
      return false;
    }
    return (
      this.tag.name === this.oldTagValues.name &&
      this.tag.color === this.oldTagValues.color &&
      this.tag.description === this.oldTagValues.description
    );
  }
}
