
import { Component, Vue } from 'vue-property-decorator';
import Wrapper from '@client/components/Layouts/Wrapper.vue';

export const CURRENT_PUBLISHER_VERSION: string = '3.0.0-gamma';

@Component({
  components: { Wrapper },
})
export default class AboutTab extends Vue {
  /* DECLARATIONS */
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
  get buildVersion(): string {
    return `${process.env.VUE_APP_VERSION_RELEASE}.${process.env.VUE_APP_VERSION_BUILD}`;
  }

  get currentPublisherVersion(): string {
    return CURRENT_PUBLISHER_VERSION;
  }
}
