import { TranslateResult } from 'vue-i18n';
import { getComputedTranslationString } from '@client/plugins/i18n/TypedI18nPlugin';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { isStringSymbolsPure } from '@common/utils/validation';
import i18n from '@client/plugins/i18n/i18n';

type ValidationFunctionType = (value: string) => boolean | string | TranslateResult;

const DEFAULT_COMMON_STRING_MAX_LENGTH: number = 255;

const validateTextFieldLength = (
  value: string,
  maxLength: number,
  isRequired?: boolean,
  customRequiredMessage?: TranslateResult
): boolean | TranslateResult => {
  if (isRequired && !value) {
    return customRequiredMessage || getComputedTranslationString(TranslationKeys.error.requiredField.$path);
  }
  return value.length <= maxLength
    ? true
    : getComputedTranslationString(TranslationKeys.error.maxLength.$path, { maxLength: maxLength });
};

export { validateTextFieldLength, DEFAULT_COMMON_STRING_MAX_LENGTH, ValidationFunctionType };

export const validateNoSymbolsInString = (value: string): TranslateResult | boolean => {
  if (isStringSymbolsPure(value)) {
    return true;
  }
  return i18n.t(TranslationKeys.error.noSymbolsAllowed.$path);
};
