
import { Vue, Component, Prop } from 'vue-property-decorator';
import TagReference from '@client/models/SettingsModels/TagReference';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';

@Component({
  components: { Tag },
})
export default class TagsList extends Vue {
  @Prop()
  private tags!: Array<TagReference>;
  private tagStore: TagsStore = useTagsStore();
  private contentItemStore: ContentItemsStore = useContentItemsStore(); //for list of content items that tag is using

  async created() {
    await this.tagStore.fetch();
    await this.contentItemStore.fetchContentItems();
  }
}
