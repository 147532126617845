import { DeviceCSV } from '@client/models/DeviceOverviewModels';

/**
 * Transform array of {@link DeviceCSV} to string in format suitable for CSV file.
 * First row represent feature names, every following represents values of one instance of {@link DeviceCSV}
 */
export function convertToCSV(devices: Array<DeviceCSV>): string {
  const keys: string[] = Object.keys(devices[0]);
  const headerRow: string = keys.join(',');
  const dataRows: string = devices
    .map((device: DeviceCSV) => {
      const row: string = `${Object.values(device).join(',')}\n`;
      return row;
    })
    .join('');
  return `${headerRow}\n${dataRows}`;
}
