
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Schedule } from '@client/models';
import ModalDialog from '@client/components/ModalDialog/ModalDialog.vue';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';
import { Optional } from '@common/types';

@Component({
  components: {
    ModalDialog,
  },
})
export default class ScheduleActiveStatusSwitch extends Vue {
  @Prop()
  private schedule!: Schedule;

  private showConfirmOverrideSchedule: boolean = false;
  private overrideSchedulesText: string = '';

  private schedulesStore: SchedulesStore = useSchedulesStore();

  private cancelScheduleHandler = () => {
    // will be overridden later
  };
  private confirmScheduleHandler = () => {
    // will be overridden later
  };

  async setScheduleToActive(schedule: Schedule): Promise<void> {
    this.cancelScheduleHandler = () => {
      schedule.active = false;
      this.showConfirmOverrideSchedule = false;
    };
    if (!schedule._id || this.schedulesStore.isUpdatingActiveStatus) {
      return;
    }
    const result: Optional<{ schedule: Schedule; conflicts: Schedule[] }> = await this.schedulesStore.setScheduleActive(
      schedule._id,
      schedule.hash,
      false
    );
    if (!result) {
      return;
    }
    if (result.conflicts.length > 0) {
      const conflictsListAsHtml: Array<string> = result.conflicts.map(
        (conflict: Schedule) => `<li><strong>${conflict.name}</strong></li>`
      );
      this.overrideSchedulesText = `${this.$t(
        this.$i18nTranslationKeys.schedules.conflictWarning.$path
      )} <ul>${conflictsListAsHtml.join('')}</ul>`;
      this.confirmScheduleHandler = async () => {
        if (!result.schedule._id) {
          return;
        }
        const forcedResult: Optional<{ schedule: Schedule; conflicts: Array<Schedule> }> =
          await this.schedulesStore.setScheduleActive(result.schedule._id, result.schedule.hash, true);
        if (!forcedResult) {
          return;
        }
        this.schedulesStore.edit(forcedResult.schedule);
        this.schedulesStore.setSchedulesInactive(forcedResult.conflicts);
        this.showConfirmOverrideSchedule = false;
      };
      this.showConfirmOverrideSchedule = true;
    } else {
      this.showConfirmOverrideSchedule = false;
      this.schedulesStore.edit(result.schedule);
    }
  }

  get isScheduleActionDisabled(): boolean {
    return this.schedulesStore.isUpdatingActiveStatus;
  }
}
