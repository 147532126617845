
import { Component, Prop, Vue } from 'vue-property-decorator';
import videoDurationParser from '@client/utils/videoDurationParser';
import { ContentItemSelectionTarget } from '@client/enums';
import { getFPSDisplayValue } from '@client/components/ContentItems/utils';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { ContentItem } from '@client/models/ContentModels/types';
import VideoModel from '@client/models/ContentModels/Video.model';
import { ContentItemPreviewStates } from '@common/content-item/types';
import DownloadButton from './DownloadButton.vue';
import DeleteButton from './DeleteButton.vue';
import AssignButton from './AssignButton.vue';
import RecreatePreviewButton from '@client/components/ContentItems/ListItems/RecreatePreviewButton.vue';
import { getAncestorName } from '@client/components/ContentItems/ListItems/utils';
import ParentFolderColumn from '@client/components/ContentItems/ListItems/ParentFolderColumn.vue';
import FileNameField from '@client/components/ContentItems/ListItems/FileNameField.vue';
import MoveButton from '@client/components/ContentItems/ListItems/MoveButton.vue';
import Tag from '@client/components/Settings/Tags/Tag.vue';
import EditTagsButton from '@client/components/ContentItems/ListItems/EditTagsButton.vue';

/**
 * Renders a video row in the content items table.
 */
@Component({
  components: {
    EditTagsButton,
    Tag,
    RecreatePreviewButton,
    FileNameField,
    DownloadButton,
    DeleteButton,
    AssignButton,
    ParentFolderColumn,
    MoveButton,
  },
  methods: {
    getContentItemParentFolderName: getAncestorName,
    videoDurationParser,
    getFPSDisplayValue,
  },
})
export default class VideoItem extends Vue {
  /* DECLARATIONS */
  @Prop()
  private videoItem!: VideoModel;
  @Prop()
  private index!: number;
  @Prop()
  private expand!: (value: boolean) => void;
  @Prop()
  private isExpanded!: boolean;
  @Prop()
  private showParentFolder!: boolean;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private isModalEmbedded!: boolean;
  @Prop({ default: ContentItemSelectionTarget.DEVICE_LABEL_OR_BACKGROUND })
  private contentItemSelectionTarget!: ContentItemSelectionTarget;
  @Prop()
  private currentlyActiveContentItemName?: string;
  @Prop()
  private isSelected!: boolean;
  @Prop()
  private setSelectedRow!: (id: string) => void;
  @Prop()
  private deleteContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  private updateContentItem!: (contentItem: ContentItem) => void;
  @Prop()
  setSelectedFolder!: (folderId: string) => void;
  @Prop()
  private openMoveDialog!: (contentItemToMove: ContentItem) => void;
  @Prop()
  private openEditTagsDialog!: (contentItemToEdit: ContentItem) => void;
  @Prop()
  private onToggleSelection!: (contentItem: ContentItem) => void;
  @Prop()
  private isChecked!: boolean;

  private contentItemsStore: ContentItemsStore = useContentItemsStore();
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /**
   * Cases where the button should not be visible:
   * 1. If we are in playlist edit mode, since here we only want to be able to
   *    add items to the playlist (and not delete ContentItems from the system)
   * 2. When the ContentItem that is currently selected (and rendered) for the active slot
   *    is the same as listed (i.e. given as parameter to this function) one
   */
  isActionsButtonVisible(contentItem: VideoModel): boolean {
    if (this.isModalEmbedded) {
      return false;
    }
    if (this.contentItemSelectionTarget === ContentItemSelectionTarget.PLAYLIST) {
      return false;
    }

    if (this.currentlyActiveContentItemName) {
      return this.currentlyActiveContentItemName !== contentItem.getName();
    }

    return true;
  }

  onRowClick(id: string): void {
    this.setSelectedRow(id);
    this.expand(!this.isExpanded);
  }

  onContentItemClick(item: VideoModel): void {
    if (!this.hasValidContentItemTarget) {
      return;
    }
    this.updateContentItem(item);
  }

  recomputeContentItemMetadata(item: VideoModel): void {
    this.contentItemsStore.updateContentItemDimensions(item);
  }

  contentItemHasMetadata(item: VideoModel): boolean {
    return !!item.fps && !!item.duration;
  }

  isMetadataLoading(item: VideoModel): boolean {
    return this.contentItemsStore.getIsMetadataLoading(item._id);
  }

  /* GETTERS */
  get hasValidContentItemTarget(): boolean {
    return this.contentItemSelectionTarget !== ContentItemSelectionTarget.NONE;
  }

  get selectedRowClass(): string | null {
    return this.isSelected ? 'selected-highlight-row' : null;
  }

  get previewValue(): number {
    switch (this.videoItem.previewState) {
      case ContentItemPreviewStates.COMPLETED:
        return 100;
      case ContentItemPreviewStates.UPLOADING:
        return 85;
      case ContentItemPreviewStates.CREATING:
        return 65;
      case ContentItemPreviewStates.PROBING:
        return 45;
      case ContentItemPreviewStates.STARTING:
        return 25;
      case ContentItemPreviewStates.PENDING:
        return 10;
      default:
        break;
    }
    return 0;
  }

  get actionMenuWidth(): string {
    return this.contentItemHasMetadata(this.videoItem) && !this.videoItem.isPreviewFailed ? '150px' : '220px';
  }

  get hideDetailColumns(): boolean {
    return this.contentItemSelectionTarget === ContentItemSelectionTarget.MOVE_ITEM;
  }
}
