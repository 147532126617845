import { defineStore, StoreDefinition, Store } from 'pinia';
import i18n from '@client/plugins/i18n/i18n';
import { TranslateResult } from 'vue-i18n';
import { ContentItemsStore, useContentItemsStore } from '@client/stores/contentItems';
import { GondolaTemplatesStore, useGondolaTemplatesStore } from '@client/stores/gondolaTemplates';
import { StoresStore, useStoresStore } from '@client/stores/stores';
import { TranslationKeys } from '@client/plugins/i18n/locales';

export interface AppGlobalGetters {
  getUser: (state: AppGlobalState) => string | null;
  getCustomer: (state: AppGlobalState) => string;
}

export interface AppGlobalActions {
  updateInvalidHashErrorModal(show: boolean): void;
  updateGenericErrorModal(data: {
    showGenericErrorModal: boolean;
    showGenericErrorModalReloadButton?: boolean;
    genericErrorModalText?: string | TranslateResult;
    isValidationError?: boolean;
    forceShowIgnoreButton?: boolean;
  }): void;
  setCustomer(customer: string): void;
  setUser(user: string): void;
}

export interface AppGlobalState {
  customer: string;
  user: string | null;
  showGenericErrorModal: boolean;
  showGenericErrorModalReloadButton: boolean;
  genericErrorModalText: string;
  isValidationError: boolean;
  forceShowIgnoreButton: boolean;
}

export type AppGlobalStoreDefinition = StoreDefinition<'appGlobal', AppGlobalState, AppGlobalGetters, AppGlobalActions>;

export type AppGlobalStore = Store<'appGlobal', AppGlobalState, AppGlobalGetters, AppGlobalActions>;

export const useAppGlobalStore: AppGlobalStoreDefinition = defineStore('appGlobal', {
  state: (): AppGlobalState => ({
    customer: '',
    user: null,
    showGenericErrorModal: false,
    showGenericErrorModalReloadButton: false,
    genericErrorModalText: '',
    isValidationError: false,
    forceShowIgnoreButton: false,
  }),
  getters: {
    getCustomer: (state: AppGlobalState) => {
      return state.customer;
    },
    getUser: (state: AppGlobalState) => {
      return state.user;
    },
  },
  actions: {
    setCustomer(customer: string) {
      const contentItemsStore: ContentItemsStore = useContentItemsStore();
      const gondolaTemplatesStore: GondolaTemplatesStore = useGondolaTemplatesStore();
      const storesStore: StoresStore = useStoresStore();
      contentItemsStore.clearContentItems();
      gondolaTemplatesStore.clear();
      storesStore.clear();
      storesStore.setFetched(false);
      this.customer = customer;
    },
    setUser(user: string) {
      this.user = user;
    },
    updateGenericErrorModal(data: {
      showGenericErrorModal: boolean;
      showGenericErrorModalReloadButton: boolean;
      genericErrorModalText: string;
      isValidationError?: boolean;
      forceShowIgnoreButton?: boolean;
    }) {
      this.showGenericErrorModal = data.showGenericErrorModal || false;
      this.showGenericErrorModalReloadButton =
        data.showGenericErrorModalReloadButton === undefined ? true : data.showGenericErrorModalReloadButton;
      this.genericErrorModalText = data.genericErrorModalText || '';
      this.isValidationError = data.isValidationError === undefined ? false : data.isValidationError;
      this.forceShowIgnoreButton = data.forceShowIgnoreButton === undefined ? false : data.forceShowIgnoreButton;
    },
    updateInvalidHashErrorModal(show: boolean) {
      if (show) {
        this.showGenericErrorModal = true;
        this.showGenericErrorModalReloadButton = true;
        this.genericErrorModalText = i18n.t(TranslationKeys.error.concurrentModification.$path) as string;
      } else {
        this.showGenericErrorModal = false;
      }
    },
  },
});
