/**
 * Default start time of the day
 */
const defaultStartTime: string = '00:00';

/**
 * Default end time of the day
 */
const defaultEndTime: string = '23:59';

enum DateFilterPrefill {
  ONE_HOUR = 'ONE_HOUR',
  ONE_DAY = 'ONE_DAY',
  ONE_WEEK = 'ONE_WEEK',
  ONE_MONTH = 'ONE_MONTH',
  CUSTOM = 'CUSTOM',
}

export { defaultStartTime, defaultEndTime, DateFilterPrefill };
