import { MessagesStore, useMessagesStore } from '@client/stores/messages';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import i18n from '@client/plugins/i18n/i18n';
import { DownloadFormat, downloadFileFromUrl } from '@client/utils/DownloadUtils';
import { B2CUser, Store } from '@client/models';
import { Vue } from 'vue-property-decorator';

/**
 * This represents the width and height value of the copied QR Code.
 * Since a QR code is a square, height and width are equal
 */
const QRCODE_SIDE_LENGTH_IN_PX: number = 150;
const QRCODE_MARGIN_IN_PX: number = 20;
const QR_CODE_CONTAINER_SIZE_IN_PX: number = QRCODE_SIDE_LENGTH_IN_PX + QRCODE_MARGIN_IN_PX * 2;

/**
 * Returns the QR Code as a blob URL
 * format: "blob:url"
 * Example: blob:http://localhost:8080/7ba249c0-ded1-4068-84b2-8cb674596527
 */
const getQRCodeElementAsUrl: () => undefined | string = () => {
  let svgElementAsString: string = document.getElementById('qrcode')?.innerHTML || '';
  if (!svgElementAsString) {
    console.error('QR Code not found!');
    return;
  }
  if (!svgElementAsString.match(/xmlns="/im)) {
    // We need to append the namespace or else it won't be recognized as a proper svg
    svgElementAsString = svgElementAsString.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ');
  }
  const preface: string = '<?xml version="1.0" standalone="no"?>\r\n';
  // Create a Blob from the svg data
  const svgAsBlob: Blob = new Blob([preface, svgElementAsString], { type: 'image/svg+xml;charset=utf-8' });
  return URL.createObjectURL(svgAsBlob);
};

/**
 * Returns the QRCode as a blob object
 */
const getQRCodeAsBlob: () => Promise<Blob | undefined> = () => {
  return new Promise<Blob>((resolve: (value: Blob | PromiseLike<Blob>) => void, reject: () => void) => {
    const svgUrl: string | undefined = getQRCodeElementAsUrl();
    if (!svgUrl) {
      console.error('QR Code not found!');
      return;
    }
    // Create a canvas to draw the svg on as an image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    // Set the context of the canvas, in our case it is a two-dimensional canvas
    const canvasContext: CanvasRenderingContext2D | null = canvas.getContext('2d');
    // Set the width and height of the canvas
    canvas.width = QR_CODE_CONTAINER_SIZE_IN_PX;
    canvas.height = QR_CODE_CONTAINER_SIZE_IN_PX;
    if (canvasContext) {
      canvasContext.fillStyle = 'white';
      canvasContext.fillRect(0, 0, QR_CODE_CONTAINER_SIZE_IN_PX, QR_CODE_CONTAINER_SIZE_IN_PX);
    }
    // Create an image element to set the svg to
    const imageElement: HTMLImageElement = new Image();
    imageElement.onload = function () {
      // Draw the image on the canvas
      canvasContext?.drawImage(imageElement, 20, 20, QRCODE_SIDE_LENGTH_IN_PX, QRCODE_SIDE_LENGTH_IN_PX);
      // Remove the created URL
      URL.revokeObjectURL(svgUrl);
      // Convert the canvas to a blob and return it
      canvas.toBlob(async (blob: Blob | null) => {
        if (blob) {
          resolve(blob);
        } else {
          console.error('Could not create Blob!');
          reject();
        }
        // Destroy the elements after use
        canvas.remove();
        imageElement.remove();
      });
    };
    // Set the url to the image to trigger the onload event above
    imageElement.src = svgUrl;
  });
};

/**
 * Downloads the QR code as an SVG
 * @param fileName filename to download, .svg extension will be concatenated by default
 */
const downloadQRCodeAsSVG: (fileName: string) => void = (fileName: string) => {
  const svgUrl: string | undefined = getQRCodeElementAsUrl();
  if (!svgUrl) {
    console.error('QR Code not found!');
    return;
  }
  downloadFileFromUrl(svgUrl, fileName, DownloadFormat.SVG);
};

/**
 * Copies the QR Code to clipboard as an image
 */
const copyQRCodeToClipboard: () => Promise<void> = async () => {
  if (!isClipboardAPICompatibleWithBrowser()) {
    console.error('Could not copy to clipboard! Browser does not support clipboard.');
    return;
  }
  const messagesStore: MessagesStore = useMessagesStore();
  // Since clipboard can only copy png files, we need to convert it to a blob from the canvas first
  const blob: Blob | undefined = await getQRCodeAsBlob();
  if (blob) {
    try {
      await navigator.clipboard.write([new window.ClipboardItem({ [blob.type]: blob })]);
      messagesStore.showMessage(i18n.t(TranslationKeys.qrCode.copiedQRCodeToClipboard.$path));
    } catch (e) {
      console.error('Could not copy to clipboard!', e);
    }
  }
};

/**
 * Downloads the QR code as a png
 * @param fileName filename to download, .png extension will be concatenated by default
 */
const downloadQRCodeAsPNG: (fileName: string) => Promise<void> = async (fileName: string) => {
  // We need the image as transformed with a canvas blob (which will contain a png) to be able to export it to png
  const blob: Blob | undefined = await getQRCodeAsBlob();
  if (blob) {
    const pngUrl: string = URL.createObjectURL(blob);
    downloadFileFromUrl(pngUrl, fileName, DownloadFormat.PNG);
  } else {
    console.error('Could not create Blob!');
  }
};

/**
 * Checks if the currently used browser supports the clipboard API, and more specifically the `ClipboardItem` which is used to copy image data to the clipboard
 */
const isClipboardAPICompatibleWithBrowser: () => boolean = () => {
  return (
    'clipboard' in navigator &&
    typeof navigator.clipboard.writeText === 'function' &&
    typeof window.ClipboardItem === 'function'
  );
};

export { downloadQRCodeAsSVG, copyQRCodeToClipboard, downloadQRCodeAsPNG, isClipboardAPICompatibleWithBrowser };

/**
 Returns the qrcode's file name to be downloaded
 If both the store name and ID Azure are available it will be as the following: "<storeIdAzure>-<storeName>"
 Else it will be either one of the following, respecting this order:
 <ol>
 <li>storeIdAzure</li>
 <li>storeName</li>
 <li>storeId</li>
 </ol>
 */
export function getQRCodeFileName(store: Store): string {
  // If both the store name and azure id are available, return the following format
  if (store.name && store.idAzure) {
    return `${store.idAzure}-${store.name}`;
  }
  // Else return any of these fallback values
  return store.idAzure || store.name || store._id;
}

export function getStoreQRCodeData(store: Store, component: Vue): string | undefined {
  const b2cUser: B2CUser = component.$msal.data.user as B2CUser;
  const apimUrl: string = b2cUser.idToken.extension_gatewayUrl;
  const apimKey: string = b2cUser.idToken.extension_primaryKey;
  if (!store.idAzure || !apimUrl || !apimKey) {
    return undefined;
  }
  return `{"storeId":"${store.idAzure}","apiUrl":"${apimUrl}","env":"${process.env.VUE_APP_B2C_ENV}","apimKey":"${apimKey}","isPro":true}`;
}
