import { TranslateResult } from 'vue-i18n';
import { getComputedTranslationString } from '@client/plugins/i18n/TypedI18nPlugin';
import { TranslationKeys } from '@client/plugins/i18n/locales';
import { Optional } from '@common/types';

/**
 * Checks whether the email address provided is in a valid format.
 * @param value value to be checked
 * @param isRequired if checked field is mandatory
 */
export const validateTextFieldEmail = (
  value: Optional<string>,
  isRequired: boolean = true
): boolean | TranslateResult => {
  if (!value) {
    return isRequired ? getComputedTranslationString(TranslationKeys.error.requiredField.$path) : true;
  }
  if (/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(value)) {
    return true;
  }
  return getComputedTranslationString(TranslationKeys.error.invalidEmail.$path);
};
