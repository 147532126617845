
import { Component, Prop, Vue } from 'vue-property-decorator';
import AffectedEntitiesModal from '@client/components/Settings/Tags/AffectedEntitiesModal.vue';
import TagModel from '@client/models/SettingsModels/Tag.model';
import TagPreview from '@client/components/Settings/Tags/TagPreview.vue';
import { AffectedEntitiesViewOptions } from '@client/models/SettingsModels/AffectedEntitiesViewOptions';
import { TranslateResult } from 'vue-i18n';
import { TagsStore, useTagsStore } from '@client/stores/tags/store';
import { Optional } from '@common/types';

@Component({
  computed: {
    AffectedEntitiesViewOptions() {
      return AffectedEntitiesViewOptions;
    },
  },
  components: { TagPreview, AffectedEntitiesModal },
})
export default class Tag extends Vue {
  @Prop()
  private tagId!: string;
  private showDialog: boolean = false;

  private tagsStore: TagsStore = useTagsStore();

  closeDialog(): void {
    this.showDialog = false;
  }

  get tag(): Optional<TagModel> {
    return this.tagsStore.getTagById(this.tagId);
  }

  get isNotAssigned(): boolean {
    if (!this.tag) {
      return false;
    }
    return (
      this.tag.stores.length === 0 &&
      this.tag.templates.length === 0 &&
      this.tag.sections.length === 0 &&
      this.tag.contentItems.length === 0
    );
  }

  get dialogMessage(): TranslateResult {
    return this.isNotAssigned
      ? this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.assignedEntitiesEmptyText.$path)
      : this.$t(this.$i18nTranslationKeys.settings.tags.referenceModal.assignedEntitiesText.$path);
  }
}
