export enum PublishSectionsPageType {
  REPUBLISH = 'REPUBLISH',
  MATCHING_LAYOUTS = 'MATCHING_LAYOUTS',
}

/**
 * Enum for representing state of publishing process
 * Expected flow: NOT_STARTED -> IN_PROGRESS -> VALIDATION_SUCCESS -> IN_PROGRESS -> DONE
 * Validation error flow: NOT_STARTED -> IN_PROGRESS -> VALIDATION_FAILED
 */
export enum PublishProcessState {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  VALIDATION_SUCCESS = 'VALIDATION_SUCCESS',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  DONE = 'DONE',
}
