
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Moment from 'moment';
import { GGanttChart, GGanttRow } from '@wirecube/vue-ganttastic';
import ScheduleModel, {
  END_OF_DAY_TIME_WITH_SECONDS,
  START_OF_DAY_TIME,
} from '@client/models/ScheduleModels/Schedule.model';
import { IGanttBar, IGanttBarConfig, IGanttBarEvent, IGanttRow } from '@client/definitions/vue-ganttastic-types';
import { ScheduleDay } from '@common/enums/ScheduleDay';
import { Optional } from '@common/types';
import ScheduleSpanModel from '@client/models/ScheduleModels/ScheduleSpan.model';
import { SchedulesStore, useSchedulesStore } from '@client/stores/schedules';

export const TIME_SPAN_CONTAINER_PADDING: number = 32;
export const TIME_SPAN_ROW_PADDING: number = 24;

@Component({
  components: {
    GGanttChart,
    GGanttRow,
  },
})
export default class TimeSpanCalendar extends Vue {
  @Prop()
  private gondolaTemplateId?: string;
  @Prop({ default: [] })
  private schedules!: Array<ScheduleModel>;

  @Prop({ default: false })
  private readonly!: boolean;

  @Prop({ default: false })
  private collapsed!: boolean;

  private isCollapsed: boolean = this.collapsed;
  private maxSelectableHour: number = 24;
  private maxSliderValueInMinutes: number = this.maxSelectableHour * 60;

  private currentSliderValueInMinutes: number = Moment().hour() * 60 + Moment().minutes();
  private selectedDay: ScheduleDay = this.scheduleDayFromIsoWeekDay(Moment().isoWeekday());
  private currentWeekRange: Array<string> = this.toIsoWeekRange(new Date());

  private showPickerModal: boolean = false;

  private selectedDateRange: Array<string> = [];

  private ganttChartDateFormat: string = 'YYYY-MM-DD HH:mm';

  private rowMap: Map<ScheduleDay, IGanttRow> = new Map<ScheduleDay, IGanttRow>();

  // for future use: add event handler to the bar - @contextmenu-bar="onContextmenuBar($event)"
  private contextmenu: { x: number; y: number; show: boolean; timeout?: ReturnType<typeof setTimeout> } = {
    x: 0,
    y: 0,
    show: false,
  };

  private schedulesStore: SchedulesStore = useSchedulesStore();

  /*
   * This function will trigger when the slider value changes:
   * We compute which active scheduled content is on the slider's current value and we select the said schedule
   * */
  onSelectedValueChange(value: number): void {
    const scheduleRowsToFilter: Optional<IGanttRow> = [...this.rowMap.values()].find((row: IGanttRow) =>
      this.selectedDay.toUpperCase().includes(row.label.toUpperCase())
    );
    if (scheduleRowsToFilter && scheduleRowsToFilter.bars.length > 0) {
      const checkDate: Moment.Moment = Moment(scheduleRowsToFilter.bars[0].from);
      checkDate.set({
        hour: Math.trunc(value / 60),
        minute: value % 60,
      });
      let closestBar: IGanttBar | undefined = undefined;
      scheduleRowsToFilter.bars
        .filter((bar: IGanttBar) => bar.active)
        .forEach((bar: IGanttBar) => {
          const barFrom: Moment.Moment = Moment(bar.from);
          if (bar.to.includes('00:00')) {
            bar.to = bar.to.replace('00:00', '23:59');
          }
          const barTo: Moment.Moment = Moment(bar.to);
          if (barFrom.minutes() + barFrom.hours() * 60 <= value && barTo.minutes() + barTo.hours() * 60 >= value) {
            if (!closestBar) {
              closestBar = bar;
            } else {
              const closestBarFrom: Moment.Moment = Moment(closestBar.from);
              if (
                Math.abs(barFrom.minutes() + barFrom.hours() * 60 - value) <=
                Math.abs(closestBarFrom.minutes() + closestBarFrom.hours() * 60 - value)
              ) {
                closestBar = bar;
              }
            }
          }
        });

      closestBar = closestBar as IGanttBar | undefined;
      const scheduleToSelect: string = closestBar ? closestBar.group : '';
      this.schedulesStore.updateSelectedSchedule(scheduleToSelect);
    }
  }

  created(): void {
    this.onSelectedDateChange();
    this.setSelectedDateRangeToCurrentWeek();
    this.rowMap = this.populateRowMap();
    this.onSelectedValueChange(this.currentSliderValueInMinutes);
  }

  mounted() {
    this.calculateSliderHeight();
  }

  getChartStart(): string {
    return Moment(this.chartDay).startOf('day').format(this.ganttChartDateFormat);
  }

  getChartEnd(): string {
    return Moment(this.chartDay).hour(this.maxSelectableHour).format(this.ganttChartDateFormat);
  }

  /**
   * The chart is set to the start of the week and we render the weekdays as rows in the chart
   */
  get chartDay(): string {
    return this.selectedDateRange[0];
  }

  toIsoWeekRange(date: Date): Array<string> {
    return [Moment(date).startOf('isoWeek').toISOString(), Moment(date).endOf('isoWeek').toISOString()];
  }

  onSelectedDateRangeChange(selected: Array<string>): void {
    const dateRangeAsIsoWeekRange: Array<string> = this.toIsoWeekRange(new Date(selected[0]));
    this.schedulesStore.updateSelectedTimespanCalendarDateRange(dateRangeAsIsoWeekRange);
    this.selectedDateRange = dateRangeAsIsoWeekRange;
    this.showPickerModal = false;
    this.rowMap = this.populateRowMap();
  }

  @Watch('schedulesStore.schedules', { deep: true })
  @Watch('schedulesStore.selectedSchedule')
  onSchedulesChanged(): void {
    if (this.readonly) {
      return;
    }
    this.rowMap = this.populateRowMap();
  }

  @Watch('schedules', { deep: true })
  onStaticSchedulesChanged(): void {
    this.rowMap = this.populateRowMap();
  }

  isRowSelected(day: ScheduleDay): boolean {
    return this.selectedDay === day;
  }

  @Emit('change')
  onSelectedDateChange(): void {
    if (!this.selectedDay || isNaN(this.currentSliderValueInMinutes) || this.readonly) {
      return;
    }
    this.changeSliderLeftValue();
  }

  getSelectedDateRangeAsLocalizedString(): string {
    return `${Moment(this.selectedDateRange[0]).toDate().toLocaleDateString(this.$i18n.locale, {})} - ${Moment(
      this.selectedDateRange[1]
    )
      .toDate()
      .toLocaleDateString(this.$i18n.locale, {})}`;
  }

  getBarConfig(color: string, schedule: ScheduleModel, selectedSchedule: string): IGanttBarConfig {
    return {
      color: color,
      backgroundColor: schedule._id === selectedSchedule ? color : 'transparent',
      border: `2px ${schedule.active ? 'solid' : 'dashed'} ${color}`,
      opacity: 1,
      immobile: true,
      borderRadius: '4px',
      active: schedule.active,
      classes: !schedule.active && schedule._id === selectedSchedule ? ['selected-inactive-bar'] : [],
    };
  }

  setSelectedDateRangeToCurrentWeek(): void {
    this.selectedDateRange = this.currentWeekRange;
    this.onSelectedDateRangeChange(this.currentWeekRange);
  }

  isSetCurrentWeekButtonDisabled(): boolean {
    return (
      this.currentWeekRange[0] === this.selectedDateRange[0] && this.currentWeekRange[1] === this.selectedDateRange[1]
    );
  }

  /**
   * Translates a given {@param day} as
   * @param day
   */
  getDayAbbreviationAsLocalizedString(day: ScheduleDay): string {
    return `${Moment().day(day).toDate().toLocaleDateString(this.$i18n.locale, { weekday: 'short' })}`;
  }

  /**
   * Helper to translate row labels when the current locale changed
   */
  @Watch('$i18n.locale')
  onLocaleChanged(): void {
    for (const entry of this.rowMap.entries()) {
      const [day, row]: [day: ScheduleDay, row: IGanttRow] = entry;
      row.label = this.getDayAbbreviationAsLocalizedString(day);
    }
  }

  /**
   * initializes the row map
   * creates a row for each Schedule day
   */
  initRowMap(): Map<ScheduleDay, IGanttRow> {
    const rowMap: Map<ScheduleDay, { label: string; bars: Array<IGanttBar> }> = new Map();

    Object.values(ScheduleDay).forEach((day: ScheduleDay) => {
      rowMap.set(day, {
        label: this.getDayAbbreviationAsLocalizedString(day),
        bars: [],
      });
    });

    return rowMap;
  }

  /**
   * Parse the recurrence time and use the currently selected chart day to determine a concrete point in time.
   * Return this datetime formatted with the {@value this.ganttChartDateFormat}
   * @param recurrence the time to apply in format HH:mm:ss
   * @param isEndOfDayTime indicate if it's the end of day time of the recurrence
   */
  formatBarRecurrence(recurrence: string, isEndOfDayTime?: boolean): string {
    if (isEndOfDayTime && recurrence === START_OF_DAY_TIME) {
      recurrence = END_OF_DAY_TIME_WITH_SECONDS;
    }

    const dateMoment: Moment.Moment = Moment(this.chartDay);
    const time: Moment.Moment = Moment(recurrence, 'HH:mm:ss');

    dateMoment.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });
    return dateMoment.format(this.ganttChartDateFormat);
  }

  /**
   * populates the row map with bars for each schedule span
   */
  populateRowMap(): Map<ScheduleDay, IGanttRow> {
    const ganttRows: Map<ScheduleDay, IGanttRow> = this.initRowMap();
    if (!this.gondolaTemplateId && !this.schedules) {
      return ganttRows;
    }
    const schedules: Array<ScheduleModel> =
      this.schedules ?? this.schedulesStore.getSchedulesForTemplate(this.gondolaTemplateId ?? '');
    schedules.forEach((schedule: ScheduleModel) => {
      schedule.spans.forEach((span: ScheduleSpanModel) => {
        // skip spans without date overlap
        if (
          Moment(this.selectedDateRange[0]).isSameOrBefore(Moment(span.validityTo)) &&
          Moment(this.selectedDateRange[1]).isSameOrAfter(Moment(span.validityFrom))
        ) {
          span.recurrenceDays.forEach((day: ScheduleDay) => {
            const weekStart: Moment.Moment = Moment(this.selectedDateRange[0]).startOf('isoWeek');
            const currentDayMoment: Moment.Moment = weekStart.isoWeekday(day);
            const isInSelectedDateRange: boolean = currentDayMoment.isBetween(
              Moment(this.selectedDateRange[0]),
              Moment(this.selectedDateRange[1]),
              undefined,
              '[]'
            );
            // we need to use validity dates at start / end of day, since they are Dates and will contain timezone info
            const isInSpanValidityRange: boolean = currentDayMoment.isBetween(
              Moment(span.validityFrom).startOf('day'),
              Moment(span.validityTo).endOf('day'),
              undefined,
              '[]'
            );

            // check if bar is in selected date range and the current ScheduleDay is in the spans validity range
            if (isInSelectedDateRange && isInSpanValidityRange) {
              const bar: IGanttBar = {
                from: this.formatBarRecurrence(span.recurrenceStart),
                to: this.formatBarRecurrence(span.recurrenceEnd, true),
                ganttBarConfig: this.getBarConfig(
                  schedule.color,
                  schedule,
                  this.readonly ? '' : this.schedulesStore.selectedSchedule
                ),
                group: schedule._id || schedule.name,
                label: schedule.name,
                associatedRowId: day,
                active: schedule.active,
              };

              const row: Optional<IGanttRow> = ganttRows.get(day);
              if (row) {
                row.bars.push(bar);
              }
            }
          });
        }
      });
    });
    this.setSelectionToActiveSchedule([...ganttRows.values()]);
    return ganttRows;
  }

  setSelectionToActiveSchedule(gantRows: IGanttRow[]): void {
    const matches: Array<IGanttBar> = [];
    gantRows.forEach((row: IGanttRow) =>
      row.bars.some((bar: IGanttBar) => {
        if (bar.group === this.schedulesStore.selectedSchedule) {
          matches.push(bar);
        }
      })
    );
    if (matches[0]) {
      let isDaySelectedInSchedule: boolean = false;
      matches.forEach((bar: IGanttBar) => {
        if (ScheduleDay[bar.associatedRowId.toLocaleUpperCase() as keyof typeof ScheduleDay] === this.selectedDay) {
          isDaySelectedInSchedule = true;
        }
      });
      if (!isDaySelectedInSchedule) {
        this.selectedDay = ScheduleDay[matches[0].associatedRowId.toLocaleUpperCase() as keyof typeof ScheduleDay];
      }
      const time: Moment.Moment = Moment(matches[0].from);
      this.currentSliderValueInMinutes = time.hour() * 60 + time.minutes();
      this.changeSliderLeftValue();
    }
  }

  /**
   * formats the currently selected hour of the slider
   */
  getThumbLabel(value: number): string {
    return Moment()
      .startOf('day')
      .hours(value / 60)
      .minutes(value % 60)
      .format('HH:mm');
  }

  /**
   * on context menu event handler
   * for future use
   */
  onContextmenuBar(e: { bar: IGanttBar; event: MouseEvent; datetime?: string }): void {
    e.event.preventDefault();
    this.contextmenu.y = e.event.clientY;
    this.contextmenu.x = e.event.clientX;
    this.contextmenu.show = true;
    if (this.contextmenu.timeout) {
      clearTimeout(this.contextmenu.timeout);
    }
    this.contextmenu.timeout = setTimeout(() => (this.contextmenu.show = false), 3000);
  }

  /**
   * @click handler for rows
   *
   * @param day the ScheduleDay associated with the row
   */
  onRowClick(day: ScheduleDay): void {
    if (this.readonly) {
      return;
    }
    this.schedulesStore.clearSelectedSchedule();
    this.selectedDay = day;
    this.currentSliderValueInMinutes = 0;
    this.onSelectedDateChange();
  }

  /**
   * @click handler for bars
   *
   */
  onBarClick(event: IGanttBarEvent): void {
    this.schedulesStore.updateSelectedSchedule(event.bar.group);
    this.selectedDay = ScheduleDay[event.bar.associatedRowId.toLocaleUpperCase() as keyof typeof ScheduleDay];
    if (this.schedulesStore.selectedSchedule === event.bar.group) event.bar.ganttBarConfig.backgroundColor = '#FFFFFF';
    this.currentSliderValueInMinutes = Moment(event.time).hour() * 60 + Moment(event.time).minutes();
    this.onSelectedDateChange();
  }

  /**
   * Collapses / Expands the component
   */
  togglePanel(): void {
    this.isCollapsed = !this.isCollapsed;
    this.calculateSliderHeight();
  }

  /**
   * Maps Moment Iso weekday to ScheduleDay
   * @param isoWeekDay Moment isoWeekdays Monday = 1 to Sunday = 7
   */
  scheduleDayFromIsoWeekDay(isoWeekDay: number): ScheduleDay {
    return Object.values(ScheduleDay)[isoWeekDay - 1] as ScheduleDay;
  }

  changeSliderLeftValue(): void {
    if (this.readonly) {
      return;
    }
    const percentage: number = (this.currentSliderValueInMinutes * 100) / this.maxSliderValueInMinutes;
    document.documentElement.style.setProperty('--slider-left', `calc(${percentage}% - 0.5px)`);
    this.calculateSliderHeight();
  }

  calculateSliderHeight(): void {
    if (this.isCollapsed) {
      document.documentElement.style.setProperty('--slider-height', `0px`);
      return;
    }
    const calendarContentContainer: Optional<HTMLElement> = document.getElementById('g-gantt-rows-container');
    if (calendarContentContainer) {
      document.documentElement.style.setProperty(
        '--slider-height',
        // 32px is the padding of the container
        // 24px is the padding of the last row (the padding is added so that the tooltip is not cut off)
        `${
          calendarContentContainer.getBoundingClientRect().height + TIME_SPAN_CONTAINER_PADDING - TIME_SPAN_ROW_PADDING
        }px`
      );
    }
  }

  selectPreviousWeek(): void {
    this.selectedDateRange = this.toIsoWeekRange(Moment(this.selectedDateRange[0]).subtract(1, 'week').toDate());
    this.onSelectedDateRangeChange(this.selectedDateRange);
  }

  selectNextWeek(): void {
    this.selectedDateRange = this.toIsoWeekRange(Moment(this.selectedDateRange[0]).add(1, 'week').toDate());
    this.onSelectedDateRangeChange(this.selectedDateRange);
    this.calculateSliderHeight();
  }

  get componentCanBeRendered(): boolean {
    return !!this.gondolaTemplateId || !!this.schedules?.length;
  }
}
