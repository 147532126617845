
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ContentItem } from '@client/models/ContentModels/types';

@Component({})
export default class DeleteButton extends Vue {
  /* DECLARATIONS */
  @Prop()
  private contentItem!: ContentItem;
  @Prop()
  private isLoading!: boolean;
  @Prop()
  private deleteContentItem!: (contentItem: ContentItem) => void;
  /* LIFECYCLE EVENTS */
  /* METHODS */
  /* GETTERS */
}
